import { observable, runInAction } from 'mobx';

const LiquidationStepModel = observable({
  directors: [],
  liquidationDate: {
    year: '',
    month: '',
    day: '',
  },
  liquidationStepType: '',
  setLiquidation(data) {
    runInAction(() => {
      if (data.directors && data.directors.length) {
        this.directors = data.directors.map((director) => {
          return {
            addressChangeDate: director.addressChangeDate || {
              year: '',
              month: '',
              day: '',
            },
            birth: director.birth || {
              year: '',
              month: '',
              day: '',
            },
            detailAddress: director.detailAddress || '',
            englishName: director.englishName || '',
            foreignerYn: director.foreignerYn ?? null,
            hasSameAddressYn: director.hasSameAddressYn ?? null,
            id: director.id || '',
            koreanName: director.koreanName || '',
            kssn: director.kssn || '',
            nameOfBuilding: director.nameOfBuilding || '',
            nationalityOthers: director.nationalityOthers || '',
            nationalityType: director.nationalityType || '',
            newAddress: director.newAddress || {},
            newEngishForeignAddress: director.newEngishForeignAddress || '',
            newKoreanForeignAddress: director.newKoreanForeignAddress || '',
            originAddress: director.originAddress || '',
            position: director.position || '',
            residenceReportYn: director.residenceReportYn ?? null,
            selectedYn: director.selectedYn ?? false,
          };
        });
      } else {
        this.directors = [
          {
            addressChangeDate: {
              year: '',
              month: '',
              day: '',
            },
            birth: {
              year: '',
              month: '',
              day: '',
            },
            detailAddress: '',
            englishName: '',
            foreignerYn: null,
            hasSameAddressYn: null,
            id: '',
            koreanName: '',
            kssn: '',
            nameOfBuilding: '',
            nationalityOthers: '',
            nationalityType: '',
            newAddress: {},
            newEngishForeignAddress: '',
            newKoreanForeignAddress: '',
            originAddress: '',
            position: '',
            residenceReportYn: null,
            selectedYn: null,
          },
        ];
      }
      this.liquidationDate = data.liquidationDate || {
        year: '',
        month: '',
        day: '',
      };
      this.liquidationStepType = data.liquidationStepType || '';
    });
  },
});

export default LiquidationStepModel;
