import { runInAction, observable, toJS, reaction } from 'mobx';
import portalModel from '@model/portalModel';
import ShareholderModal from '@pages/ModalContent/establish/Shareholder/ShareholderModal';
import ResidenceRegistrationModal from '@pages/ModalContent/establish/Shareholder/ResidenceRegistrationModal';
import shareholderAndExecutiveInfoModel from '@model/establish/shareholderAndExecutiveInfoModel';
import companyInfoModel from '@model/establish/companyInfoModel';
import stepService from '@service/stepService';
import { onlyNumber } from '@common/module/replaceNumber';
import smartformAppModel from '@model/smartformAppModel';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import regExp from '@common/module/regExp';
import { _alert, _confirm } from '@model/dialogModel';

class viewModel {
  constructor() {
    this.state = observable({
      openIdx: null,
      koreanAddressModal: false,
      koreanAddressIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        this.state.errorArr = [];
        this.state.openIdx = null;
        let korean_person = false,
          foreigner_person = false,
          korean_company = false,
          foreigner_company = false;
        let residenceYes = false,
          residenceNo = false;
        shareholderAndExecutiveInfoModel.shareholders.forEach((shareholders, idx) => {
          if (shareholders.shareholderType === 'KOREAN_PERSON') korean_person = true;
          else if (shareholders.shareholderType === 'FOREIGNER_PERSON') {
            foreigner_person = true;
            if (shareholders.residenceRegistrationYn === true) residenceYes = true;
            else if (shareholders.residenceRegistrationYn === false) residenceNo = true;
          } else if (shareholders.shareholderType === 'KOREAN_COMPANY') korean_company = true;
          else if (shareholders.shareholderType === 'FOREIGNER_COMPANY') foreigner_company = true;
        });
        if (!korean_person && foreigner_person && !korean_company && !foreigner_company) {
          //거소등록하지 않은 외국인 1명만 입력한 경우
          if (!residenceYes && residenceNo) {
            return this.openModal('ShareholderModal', this.text.name);
          }
        } else if (!korean_person && !foreigner_person && !korean_company && foreigner_company) {
          //외국법인만 입력한 경우
          return this.openModal('ShareholderModal', this.text.name);
        } else if (!korean_person && foreigner_person && !korean_company && foreigner_company) {
          //거소등록하지 않은 외국인, 외국법인만 입력한 경우
          if (!residenceYes && residenceNo) {
            return this.openModal('ShareholderModal', this.text.name);
          }
        }
        //올바를 데이터를 입력한 경우
        let param = formDataToJson(e.target).data;
        param.shareholders.map((shareholders, idx) => {
          if (shareholders.koreanAddress) {
            param.shareholders[idx].koreanAddress = shareholderAndExecutiveInfoModel.shareholders[idx].koreanAddress;
          }
          if (shareholders.shareholderType === 'FOREIGNER_PERSON') {
            param.shareholders[idx].birth =
              shareholders.year + '년' + shareholders.month + '월' + shareholders.day + '일';
            delete shareholders.year;
            delete shareholders.month;
            delete shareholders.day;
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  openModal(content, name) {
    let modal = {};
    if (content === 'ShareholderModal') {
      modal = ShareholderModal;
    } else if (content === 'ResidenceRegistrationModal') {
      modal = ResidenceRegistrationModal;
    }
    runInAction(() => {
      portalModel.modalContent = content === 'ShareholderModal' ? modal.modalContent(name) : modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get shareholderDefaultValue() {
    var val = {
      year: '',
      month: '',
      day: '',
      detailAddress: '',
      englishName: '',
      foreignAddress: {
        englishAddress: '',
        koreanAddress: '',
      },
      foreignerCompanyRepresentatives: [
        {
          id: null,
          englishName: '',
          koreanName: '',
        },
      ],
      id: '',
      koreanAddress: {},
      koreanCompanyRepresentatives: [
        {
          id: null,
          name: '',
          position: '',
        },
      ],
      koreanName: '',
      kssn: '',
      nameOfBuilding: '',
      nationalityOthers: '',
      nationalityType: '',
      residenceRegistrationYn: null,
      shareholderType: '',
    };
    return val;
  }
  addShareholder() {
    runInAction(() => {
      shareholderAndExecutiveInfoModel.shareholders.push(this.shareholderDefaultValue);
      this.state.openIdx = this.shareholders.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get shareholders() {
    return shareholderAndExecutiveInfoModel.shareholders;
  }
  setTitle(idx) {
    const shareholder = shareholderAndExecutiveInfoModel.shareholders;
    const type = this.smartFormType;
    let name = shareholder[idx].koreanName; //이름
    let national = shareholder[idx].shareholderType; //국적
    switch (national) {
      case 'KOREAN_PERSON':
        national = '한국인';
        break;
      case 'FOREIGNER_PERSON':
        national = '외국인';
        break;
      case 'KOREAN_COMPANY':
        national = '한국법인';
        break;
      case 'FOREIGNER_COMPANY':
        national = '외국법인';
        break;
    }
    let title = '';
    if (!name && !national) {
      //이름, 국적 모두 없는 경우
      if (type === 'ESTABLISHMENT_LIMITED_COMPANY' || type === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') {
        title = <span>출자자(=사원)가 되실 분의 정보를 입력해 주세요.</span>;
      } else if (
        this.companySize === 'OTHER' ||
        (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS')
      ) {
        title = <span>주주가 되실 분의 정보를 입력해 주세요.</span>;
      } else if (
        (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_DIRECTOR') ||
        (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_AUDITOR')
      ) {
        title = <span>대표 겸 주주가 되실 분의 정보를 입력해 주세요.</span>;
      } else if (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR') {
        title = <span>이사 겸 주주가 되실 분의 정보를 입력해 주세요.</span>;
      } else if (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR') {
        title = <span>감사 겸 주주가 되실 분의 정보를 입력해 주세요.</span>;
      }
    } else if (!name) {
      //이름 없는 경우
      if (type === 'ESTABLISHMENT_LIMITED_COMPANY' || type === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') {
        title = '출자자' + ' : ' + national;
      } else {
        title = '주주' + ' : ' + national;
      }
    } else if (!national) {
      //국적 없는 경우
      title = name + ' : ' + '국적을 선택해 주세요';
    } else {
      title = name + ' : ' + national;
    }
    return title;
  }
  get displayTitle() {
    if (
      (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_DIRECTOR') ||
      (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_AUDITOR')
    ) {
      return `대표 겸 ${this.text.name}정보를 입력해주세요.`;
    } else if (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR') {
      return `이사 겸 ${this.text.name}정보를 입력해주세요.`;
    } else if (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR') {
      return `감사 겸 ${this.text.name}정보를 입력해주세요.`;
    } else {
      return `${this.text.name} 정보를 입력해주세요.`;
    }
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  async setCloseBtn(idx) {
    const shareholder = shareholderAndExecutiveInfoModel.shareholders;
    let name = shareholder[idx].koreanName;
    let isExecutive = shareholder[idx].isExecutive;
    if (!name) {
      name = '해당 주주';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    if (isExecutive) {
      confString = `${name}님의 정보를 삭제하시겠습니까?<br>${name}님은 임원으로도 등록하였기 때문에, ${name}님의 주주 정보를 삭제하면, ${name}님의 임원 정보도 함께 삭제됩니다.`;
    }
    const conf = await _confirm(confString);
    if (shareholder.length === 1) {
      if (conf) {
        runInAction(() => {
          shareholderAndExecutiveInfoModel.shareholders = [];
          shareholderAndExecutiveInfoModel.shareholders.push(this.shareholderDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return shareholder.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  get companySize() {
    return companyInfoModel.companySize;
  }
  get initiatorRole() {
    return companyInfoModel.initiatorRole;
  }
  setShareholderType(e, idx) {
    //사람(법인) 선택-
    shareholderAndExecutiveInfoModel.shareholders[idx].shareholderType = e.target.value;
  }
  //한국인
  setKoreanName(e, idx) {
    //성함 입력
    shareholderAndExecutiveInfoModel.shareholders[idx].koreanName = e.target.value;
  }
  setKssn(e, idx) {
    //주민등록번호 입력
    shareholderAndExecutiveInfoModel.shareholders[idx].kssn = e.target.value;
  }
  //도로명 주소
  openKoreanAddressModal(openYn, idx) {
    runInAction(() => {
      this.state.koreanAddressIdx = idx;
      this.state.koreanAddressModal = openYn;
    });
  }
  get koreanAddressModal() {
    return this.state.koreanAddressModal;
  }
  get koreanAddressIdx() {
    return this.state.koreanAddressIdx;
  }
  koreanAddressComplate(address) {
    runInAction(() => {
      shareholderAndExecutiveInfoModel.shareholders[this.koreanAddressIdx].koreanAddress = address;
      shareholderAndExecutiveInfoModel.shareholders[this.koreanAddressIdx].detailAddress = '';
      shareholderAndExecutiveInfoModel.shareholders[this.koreanAddressIdx].nameOfBuilding = address.buildingName;
    });
    this.openKoreanAddressModal(false, this.koreanAddressIdx);
  }
  koreanAddress_detailAddress(e, idx) {
    //상세주소
    shareholderAndExecutiveInfoModel.shareholders[idx].detailAddress = e.target.value;
  }
  koreanAddress_nameOfBuilding(e, idx) {
    //건물명
    shareholderAndExecutiveInfoModel.shareholders[idx].nameOfBuilding = e.target.value;
  }
  koreanAddress_bnameFontSizeChange(idx) {
    const bName = shareholderAndExecutiveInfoModel.shareholders[idx].koreanAddress.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
  //외국인
  residenceRegistrationYn(idx) {
    return shareholderAndExecutiveInfoModel.shareholders[idx].residenceRegistrationYn;
  }
  setResidenceRegistrationYn(e, idx) {
    //거소등록 여부
    shareholderAndExecutiveInfoModel.shareholders[idx].residenceRegistrationYn = e.target.value === 'true';
  }
  setNationalityType(e, idx) {
    //국적 선택
    shareholderAndExecutiveInfoModel.shareholders[idx].nationalityType = e.target.value;
  }
  setNationalityOthers(e, idx) {
    //국적 입력
    shareholderAndExecutiveInfoModel.shareholders[idx].nationalityOthers = e.target.value;
  }
  setEnglishName(e, idx) {
    //로마자 성함 입력
    shareholderAndExecutiveInfoModel.shareholders[idx].englishName = e.target.value;
  }
  setYear(e, idx) {
    shareholderAndExecutiveInfoModel.shareholders[idx].year = onlyNumber(e.target.value);
  }
  setMonth(e, idx) {
    shareholderAndExecutiveInfoModel.shareholders[idx].month = onlyNumber(e.target.value);
  }
  monthBlur(e, idx) {
    let month = e.target.value;
    if (month && month.length === 1) {
      e.target.value = '0' + month;
    }
    shareholderAndExecutiveInfoModel.shareholders[idx].month = e.target.value;
  }
  setDay(e, idx) {
    shareholderAndExecutiveInfoModel.shareholders[idx].day = onlyNumber(e.target.value);
  }
  dayBlur(e, idx) {
    let day = e.target.value;
    if (day && day.length === 1) {
      e.target.value = '0' + day;
    }
    shareholderAndExecutiveInfoModel.shareholders[idx].day = e.target.value;
  }
  foreignAddress_englishAddress(e, idx) {
    //거소등록X 로마자 주소
    shareholderAndExecutiveInfoModel.shareholders[idx].foreignAddress.englishAddress = e.target.value;
  }
  foreignAddress_koreanAddress(e, idx) {
    //거소등록X 한글 주소
    shareholderAndExecutiveInfoModel.shareholders[idx].foreignAddress.koreanAddress = e.target.value;
  }
  //한국법인
  addKoreanCompanyRepresentatives(idx) {
    if (shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives[0].position) {
      shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives[0].position,
      });
    } else {
      shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: '',
      });
    }
  }
  koreanCompanyRepresentatives_position(e, idx) {
    //직책
    shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives.forEach((item, idx2) => {
      shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives[idx2].position = e.target.value;
    });
  }
  koreanCompanyRepresentatives_name(e, idx, idx2) {
    //성함
    shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives[idx2].name = e.target.value;
  }
  async setKoreanCompanyRepresentativesCloseBtn(idx, idx2) {
    const koreanCompanyRepresentatives =
      shareholderAndExecutiveInfoModel.shareholders[idx].koreanCompanyRepresentatives;
    let name = koreanCompanyRepresentatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return koreanCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  //외국법인
  foreignerCompanyRepresentatives_englishName(e, idx, idx2) {
    //대표자 로마자 성함
    shareholderAndExecutiveInfoModel.shareholders[idx].foreignerCompanyRepresentatives[idx2].englishName =
      e.target.value;
  }
  foreignerCompanyRepresentatives_koreanName(e, idx, idx2) {
    //대표자 한글 성함
    shareholderAndExecutiveInfoModel.shareholders[idx].foreignerCompanyRepresentatives[idx2].koreanName =
      e.target.value;
  }
  foreignerCompanyRepresentatives(idx) {
    shareholderAndExecutiveInfoModel.shareholders[idx].foreignerCompanyRepresentatives.push({
      id: null,
      englishName: '',
      koreanName: '',
    });
  }
  async setForeignerCompanyRepresentativesCloseBtn(idx, idx2) {
    const foreignerCompanyRepresentatives =
      shareholderAndExecutiveInfoModel.shareholders[idx].foreignerCompanyRepresentatives;
    let name = foreignerCompanyRepresentatives[idx2].koreanName;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return foreignerCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  get text() {
    //폼타입별 문구
    const formType = this.smartFormType;
    switch (formType) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return { formType: '주식회사', name: '주주' };
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return { formType: '농업회사법인', name: '주주', workType: '농업' };
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return { formType: '어업회사법인', name: '주주', workType: '어업' };
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return { formType: '유한회사', name: '출자자', workType: '임원' };
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return { formType: '유한책임회사', name: '출자자', workType: '업무집행자' };
    }
  }
  get addYn() {
    //설립인원 3명이상이거나 유한회사, 유한책임회사일 경우_주주 추가버튼, 삭제버튼
    if (this.companySize === 'OTHER') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') return true;
    else return false;
  }
  get koreanCompanyDisplayYn() {
    //한국법인
    if (this.companySize === 'OTHER') return true;
    else if (this.companySize === 'TWO' && this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') return true;
    else return false;
  }
  get foreignerCompanyDisplayYn() {
    //외국법인
    if (this.companySize === 'OTHER' && this.smartFormType === 'ESTABLISHMENT_STOCK_COMPANY') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_COMPANY') return true;
    else if (this.smartFormType === 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY') return true;
    else return false;
  }
  get shareholderRegisterDisplayYn() {
    //주주 등록하기 tips
    const formType = this.smartFormType;
    switch (formType) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return true;
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return true;
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return true;
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return false;
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return false;
    }
  }
  displayBirthErrorMessage(idx, type) {
    const year = this.shareholders[idx].year;
    const month = this.shareholders[idx].month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
}

export default new viewModel();
