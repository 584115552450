import selectedCheckbox from '@common/components/asset/icon/btn_check_s.svg';
import ArrowRight from '@common/components/asset/icon/ico_arrowRight_gray.svg';
import IconDown from '@common/components/asset/icon/ico_down.svg';

import st from './style.module.scss';

const CorporationTypeExampleBox = ({ title = '', list = [] }) => {
  return (
    <div className={st.container}>
      <div className={st.leftBox}>예시</div>
      <div className={st.rightBox}>
        <div className={st.searchWrapper}>
          <div className={st.inputText}>{title}</div>
          <div className={st.searchButton}>검색하기</div>
        </div>
        <div className={st.selectedPurposeWrapper}>
          <div className={st.titleContainer}>
            <img className={st.selectedCheckbox} src={selectedCheckbox} alt="selectedCheckbox" />
            <div className={st.titleWrapper}>
              <img className={st.folderIcon} src="/images/Img_폴더.png" alt="folderIcon" />
              농업 · 어업 · 임업
              <img className={st.arrowIcon} src={ArrowRight} alt="arrowIcon" />
              <span style={{ color: '#006AC3' }}>{title}</span>
              <span className={st.countPurpose}>({list.length}개)</span>
              <img className={st.arrowDown} src={IconDown} alt="iconDown" />
            </div>
          </div>
          {list.map((list, idx) => (
            <div key={idx} className={st.purposeListWrapper}>
              <img className={st.selectedCheckbox} src={selectedCheckbox} alt="selectedCheckbox" />
              <img className={st.noteIcon} src="/images/Img_노트.png" alt="noteIcon" />
              <span>{list.content}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CorporationTypeExampleBox;
