import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './TCAgree.module.scss';
import Check from '@comComponents/atoms/Input/Check/Check';
import HyperText from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TCAgreeVm from './TCAgreeVm';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import clsx from 'clsx';
import { Fragment } from 'react';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import AiChat from '@pages/Establish/AiChat/AiChat';

function TCAgree() {
  return useObserver(() => (
    <form
      onSubmit={(e) => {
        TCAgreeVm.submit(e);
      }}
    >
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                아래<span className={Qst.bold}> 이용약관에 동의</span>하시면,{' '}
                <span className={Qst.bold}>법인설립에 유용한 헬프미의 프리미엄 정관(포함)을 함께 이용</span>하실 수
                있습니다.
              </h2>
            </div>
            <div className={clsx(st.agreeBox, TCAgreeVm.agreeAll ? st.allActive : null)} key={TCAgreeVm.agreeAll}>
              <Check
                className={clsx(st.allCheck, TCAgreeVm.agreeAll ? st.active : null)}
                style={{ top: '3px' }}
                boxType="circle"
                checked={TCAgreeVm.agreeAll}
                onChange={(e) => TCAgreeVm.setAgreeAll(e)}
              >
                전체동의
              </Check>
              <ul className={st.checkList}>
                {TCAgreeVm.tcagreements.map((item, idx) => {
                  return (
                    <Fragment key={idx}>
                      <li>
                        <input type="hidden" name={`tcagreements[${idx}].id`} value={item.id} />
                        <input type="hidden" name={`tcagreements[${idx}].termId`} value={item.termId} />
                        <Check
                          className={clsx(st.check, item.agreeYn ? st.active : null)}
                          style={{ top: '3px' }}
                          requiredText={item.required}
                          boxType="circle"
                          name={`tcagreements[${idx}].agreeYn`}
                          value={item.agreeYn ?? false}
                          checked={item.agreeYn === true}
                          onChange={(e) => TCAgreeVm.setAgreeYn(e, idx)}
                        >
                          {item.required ? '헬프미 법인등기 위임계약 약관' : '법인운영에 유용한 뉴스레터 등 홍보 약관'}
                        </Check>
                        <HyperText
                          title="약관보기로 이동"
                          className={st.hyper}
                          onClick={() => TCAgreeVm.openModal(item.required ? 'required' : 'unRequired')}
                        >
                          약관보기
                        </HyperText>
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
            </div>
            <Error
              name="tcagreements[0].agreeYn"
              value={TCAgreeVm.tcagreements[0].agreeYn}
              errorCase={{ required: '헬프미 법인등기 위임계약 약관에 동의해 주세요.', type: 'boolean' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <AiChat />
            <Qna tip="정관선택" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>헬프미 프리미엄 정관이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>헬프미의 변호사들의 연구하여 만든, 절세전략이 담긴 정관입니다.</Answer>
                  <p className={Qst.explain}>
                    정관은 회사의 헌법이라고 할 수 있는 중요한 규정입니다. 헬프미는 대형 로펌 출신 10년 경력의 금융전문
                    변호사들이 연구하여 제작한 프리미엄 정관으로 법인을 설립해드리고 있습니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>프리미엄 정관 제작은 포함인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    네 헬프미에서 설립하면, 추가 비용 없이 프리미엄 정관을 제작해드립니다.
                  </Answer>
                  <p className={Qst.explain}>
                    헬프미에서 설립등기를 하면, 추가 비용 없이 프리미엄 정관을 제작한 후, 가이드북과 함께 빠른 등기로
                    보내드립니다. 다른 곳에서 설립한 후, 헬프미에서 프리미엄 정관으로 업그레이드를 하는 경우, 22만 원의
                    수수료가 발생합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>프리미엄 정관의 장점은 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>절세 규정이 담겨있고, 투자를 대비한 Full Package입니다.</Answer>
                  <p className={Qst.explain}>
                    프리미엄 정관에는 절세 규정 완비되어 있습니다. 절세는 물론 부당한 세무조사 방지할 수 있습니다. 또한
                    우선주 관련 규정이 모두 들어가 있기 때문에, 나중에 투자를 받을 때 번거롭게 정관을 변경하지 않아도
                    됩니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>정관 규정이 잘못되어 있으면, 나중에 세금폭탄을 맞을 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    네. 오로지 정관 때문에 세무조사는 물론 세금폭탄까지 맞을 수 있습니다.
                  </Answer>
                  <p className={Qst.explain}>
                    정관에 절세를 위한 근거 규정이 없으면 법적인 절차를 모두 준수했음에도 불구하고 과세당국이 세금을
                    많이 부과할 수 있습니다. 특히 세금처리가 필요한 시점 직전에 정관을 변경하면 적법한 절차를 거쳐도
                    부당행위부인이 적용되어 세금폭탄을 맞을 수 있습니다. 따라서 법인을 설립할 때부터 절세전략을 담은
                    정관을 만들거나, 세금 문제가 발생하기 전에 미리 정관을 잘 정비해 두는 것이 필요합니다.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default TCAgree;
