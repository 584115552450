import st from './FarmerFisherModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title}>
        <span className={st.bold}>주주가 모두 농업인</span>이어야 합니다.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>농업회사법인을 설립할 때에는 농업인만 농업회사법인의 주주가 될 수 있습니다.</p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
