import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './index.css';

import { pageRouter } from '@model/pageStepModel';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';

import App from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://85888168d860a0e55ed56a9a18962405@o774769.ingest.sentry.io/4506301915004928',
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [/^https:\/\/smartform\.api\.help-me\.kr/],
        networkRequestHeaders: ['Authorization', 'Accept', 'User-Agent', 'Cache-Control'],
        networkResponseHeaders: ['Content-Type', 'Date'],
      }),
    ],
    environment: process.env.NODE_ENV,
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    maskAllText: false,
    maskAllInputs: false,
    blockAllMedia: false,
    ignoreErrors: ['AxiosError'],
    release: `smartform-front@${process.env.REACT_APP_VERSION}`,
  });
}

const queryClient = new QueryClient();

const browserHistory = createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, pageRouter);

// 로그인 상태로 임의로 만들어준다
const setToken = () => {
  window.opener = window;

  const getToken = (e) => {
    if (e.data === 'getToken') {
      window.postMessage({ token: 'token', corpId: 'corp._id' }, '*');
      window.removeEventListener('message', getToken);
    }
  };

  window.opener.addEventListener('message', getToken);
};

async function enableMocking() {
  const isMockingOn = false;

  if (process.env.NODE_ENV !== 'development' || !isMockingOn) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  setToken();

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  ReactDOM.render(
    <Router history={history}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>,
    document.getElementById('root'),
  );
});
