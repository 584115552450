import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import vm from './StandbyFileUploadVm';
import st from './StandbyFileUpload.module.scss';
import { Fragment } from 'react';
import BusinessRegistration from '../BusinessRegistration/BusinessRegistration';
import AOA from '../AOA/AOA';
import StockholderList from '../StockholderList/StockholderList';
import LimitStockholdersList from '../LimitStockholdersList/LimitStockholdersList';
import InvestmentContract from '../InvestmentContract/InvestmentContract';
import BalanceCertificate from '../BalanceCertificate/BalanceCertificate';
import TRAccount from '../TRAccount/TRAccount';
import MinorDocument from '../MinorDocument/MinorDocument';
import ForeignDocument from '../ForeignDocument/ForeignDocument';
import FinancialPosition from '../FinancialPosition/FinancialPosition';
import FreeIssueOfNewSharesDocument from '../FreeIssueOfNewSharesDocument/FreeIssueOfNewSharesDocument';
import Venture from '../Venture/Venture';

function StandbyFileUpload() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={st.existingFile}>
          <div className={st.title}>아래 파일이 최신본이 맞는지 확인해주세요.</div>
          <div className={st.explain}>※ 최신본이 아니라면, 삭제 후 최신본을 업로드해주세요.</div>
          {vm.existingFile('사업자등록증') && (
            <BusinessRegistration
              idx={vm.existingFileIdx('사업자등록증')}
              file={vm.fileGroupTypes[vm.existingFileIdx('사업자등록증')]}
              parentVm={vm}
            />
          )}
          {vm.existingFile('기존_정관') && (
            <AOA
              idx={vm.existingFileIdx('기존_정관')}
              file={vm.fileGroupTypes[vm.existingFileIdx('기존_정관')]}
              parentVm={vm}
            />
          )}
          {vm.existingFile('주주명부') && (
            <StockholderList
              idx={vm.existingFileIdx('주주명부')}
              file={vm.fileGroupTypes[vm.existingFileIdx('주주명부')]}
              parentVm={vm}
              regularShareholderMeetingYn={vm.regularShareholderMeetingYn}
            />
          )}
        </ContentBox>
      </Question>
      {vm.fileGroupTypes?.map((file, idx) => (
        <Fragment key={idx}>
          {file.fileGroupType === '사원명부' && <LimitStockholdersList idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '투자계약서' && <InvestmentContract idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '잔고증명서' && <BalanceCertificate idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '가수금_계정별원장' && <TRAccount idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '미성년서류' && <MinorDocument idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '외국인서류' && <ForeignDocument idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '재무상태표_재산목록' && <FinancialPosition idx={idx} file={file} parentVm={vm} />}
          {file.fileGroupType === '무상증자_서류' && (
            <FreeIssueOfNewSharesDocument idx={idx} file={file} parentVm={vm} />
          )}
          {file.fileGroupType === '벤처기업확인증' && <Venture idx={idx} file={file} parentVm={vm} />}
        </Fragment>
      ))}
    </form>
  ));
}
export default StandbyFileUpload;
