import { useObserver } from 'mobx-react';
import NewSharesAcquirer from './NewSharesAcquirer/NewSharesAcquirer';
import ClassStockAndInvestment from './ClassStockAndInvestment/ClassStockAndInvestment';
import MethodOfIssuingNewShares from './MethodOfIssuingNewShares/MethodOfIssuingNewShares';
import { Route } from 'react-router-dom';

function PaidInCapitalIncreaseStep() {
  return useObserver(() => (
    <>
      {/* 신주인수인 */}
      <Route
        path="/step/changeRegistration/PaidInCapitalIncreaseStep/NewSharesAcquirer"
        component={NewSharesAcquirer}
      ></Route>
      {/* 주식종류, 투자금 */}
      <Route
        path="/step/changeRegistration/PaidInCapitalIncreaseStep/ClassStockAndInvestment"
        component={ClassStockAndInvestment}
      ></Route>
      {/* 신주발행 방법 */}
      <Route
        path="/step/changeRegistration/PaidInCapitalIncreaseStep/MethodOfIssuingNewShares"
        component={MethodOfIssuingNewShares}
      ></Route>
    </>
  ));
}

export default PaidInCapitalIncreaseStep;
