import { observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';

import CheckBox from '../../../../ui/CheckBox';
import GroupListItem from '../../../../ui/GroupListItem';
import st from './style.module.scss';

const GroupForContent = observer(({ purposeGroup = {}, defaultOpen = true, highlightText, searchResultMob }) => {
  const [showAllPurposes, setShowAllPurposes] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(defaultOpen);

  const { groupNames = [], purposes = [] } = purposeGroup;

  const isGroupSelected = purposes.every((item) => searchResultMob.checkSelected(item.recommendedPurposeId));

  // 검색 처음에는 검색에 관한 사업목적만 출력하다가 한번이라도 노출됐으면 전체 노출된다.
  const visiblePurposes = purposes.filter((item) => item.searchYn || showAllPurposes);

  const toggleGroup = useCallback((e) => {
    e.stopPropagation();

    const checked = e.target.checked;

    if (checked) {
      purposes.forEach((item) => searchResultMob.selectPurpose(item.recommendedPurposeId));
      setOpenDropDown(true);
    } else {
      purposes.forEach((item) => searchResultMob.unselectPurpose(item.recommendedPurposeId));
    }
  }, []);

  useEffect(() => {
    if (!openDropDown || isGroupSelected) {
      setShowAllPurposes(true);
    }
  }, [openDropDown, isGroupSelected]);

  return (
    <div className={st.container}>
      <div className={st.group}>
        <CheckBox checked={isGroupSelected} onChange={toggleGroup} />
        <GroupListItem.GroupTitle
          groupNames={groupNames}
          contentLength={purposes.length}
          isDropDownOpened={openDropDown}
          onClickOpenDropDown={() => setOpenDropDown(!openDropDown)}
          highlightText={highlightText}
        />
      </div>
      {openDropDown && (
        <div className={st.dropDown}>
          {visiblePurposes.map((item, index) => (
            <React.Fragment key={item.recommendedPurposeId}>
              {index === 0 && <GroupListItem.Divider />}
              <div className={st.purposeWrap}>
                <CheckBox
                  checked={searchResultMob.checkSelected(item.recommendedPurposeId)}
                  onChange={() => searchResultMob.togglePurpose(item.recommendedPurposeId)}
                />
                <GroupListItem.PurposeRow content={item.content} highlightText={highlightText} />
              </div>
              <GroupListItem.Divider />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
});

export default GroupForContent;
