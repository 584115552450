import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import { runInAction, observable } from 'mobx';

class KssnVm {
  constructor(model) {
    this.model = model;
  }
  get kssn() {
    return this.model.kssn;
  }
  setKssn(e) {
    runInAction(() => {
      this.model.kssn = e.target.value;
    });
  }
}

export default KssnVm;
