import { useObserver } from 'mobx-react';
import StandByUpdate from './StandByUpdate/StandByUpdate';
import { Route } from 'react-router-dom';

function StandByUpdateStep() {
  return useObserver(() => (
    <>
      {/* 스탠바이 자동업데이트 */}
      <Route path="/step/changeRegistration/StandByUpdateStep/StandByUpdate" component={StandByUpdate}></Route>
    </>
  ));
}

export default StandByUpdateStep;
