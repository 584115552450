import { useObserver } from 'mobx-react';
import StandbyFreeIssueOfNewSharesCheck from './StandbyFreeIssueOfNewSharesCheck/StandbyFreeIssueOfNewSharesCheck';
import StandbyFreeIssueOfNewShares from './StandbyFreeIssueOfNewShares/StandbyFreeIssueOfNewShares';
import { Route } from 'react-router-dom';

function StandbyFreeIssueOfNewSharesStep() {
  return useObserver(() => (
    <>
      {/* 스탠바이 무상증자 */}
      <Route
        path="/step/changeRegistration/StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewSharesCheck"
        component={StandbyFreeIssueOfNewSharesCheck}
      ></Route>
      {/* 스탠바이 무상증자 - 아니요 선택 후 다음 페이지 */}
      <Route
        path="/step/changeRegistration/StandbyFreeIssueOfNewSharesStep/StandbyFreeIssueOfNewShares"
        component={StandbyFreeIssueOfNewShares}
      ></Route>
    </>
  ));
}

export default StandbyFreeIssueOfNewSharesStep;
