import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ErrorText from '../../atoms/Text/ErrorText/ErrorText';
import './ErrorValidation.css';
import clsx from 'clsx';

/**
 * input 값에 대한 유효성 검사를 진행하는 컴포넌트
 */

function ErrorValidation(props) {
  let value = props.value;
  let errorCase = props.errorCase;

  const [errorState, setErrorState] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    //유효성 검사가 필요한 인풋이 처음 렌더링 되었을 때, data-error 속성이 없고 value도 없다면 data-error 속성을 넣어줌. (onChange가 일어나지 않은 상태)
    if (
      !document.querySelector(`[name="${props.name}"]`).hasAttribute('data-error') &&
      (value === null || value === ' ' || value === '')
    ) {
      document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'yet');
      setErrorMessage(errorCase?.required);
      setErrorState('yet');
    }
    //유효성 검사가 필요한 인풋이 여러개일 때, onChange가 일어나지 않은 인풋에 대해서 return 해줌.
    else if (
      document.querySelector(`[name="${props.name}"]`).getAttribute('data-error') === 'yet' &&
      (value === null || value === ' ' || value === '')
    ) {
      return;
    }
    //유효성 검사
    else {
      if (!errorCase?.type && (value === null || value === ' ' || value === '')) {
        //value 타입이 boolean이 아닌 경우 필수값 체크. (false가 값에 일부)
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'error');
        setErrorMessage(errorCase?.required);
        setErrorState('error');
      } else if (errorCase?.type === 'boolean' && !value) {
        //value 타입이 boolean인 경우 필수값 체크. (false가 진짜 false)
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'error');
        setErrorMessage(errorCase?.required);
        setErrorState('error');
      } else if (errorCase?.min && errorCase?.min?.value > value) {
        //value가 최소값(min)보다 작은 경우 체크.
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'error');
        setErrorMessage(errorCase?.min?.message);
        setErrorState('error');
      } else if (errorCase?.validate && errorCase?.validate?.func === false) {
        //함수를 통해 value에 대한 검증 진행.
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'error');
        setErrorMessage(errorCase?.validate?.message);
        setErrorState('error');
      } else if (errorCase?.pattern && errorCase?.pattern?.value === false) {
        //value에 대한 정규식 패턴 검증.
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'error');
        setErrorMessage(errorCase?.pattern?.message);
        setErrorState('error');
      } else {
        //유효성 검사 통과
        document.querySelector(`[name="${props.name}"]`).setAttribute('data-error', 'success');
        setErrorMessage('');
        setErrorState('success');
      }
    }
  });
  return (
    <ErrorText
      className={clsx('errorText', props.className)}
      style={props.style}
      dataError={errorState}
      dataInputName={props.name}
    >
      {errorMessage}
    </ErrorText>
  );
}
ErrorValidation.propTypes = {
  /**
   * class명
   */
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   *  validation value
   */
  value: PropTypes.any,
  /**
   *  validation case
   */
  errorCase: PropTypes.object,
  /**
   * 연결된 인풋의 name
   */
  name: PropTypes.string,
  /**
   *  error 상태
   */
  dataError: PropTypes.string,
  /**
   * 연결된 인풋의 name
   */
  dataInputName: PropTypes.string,
};

ErrorValidation.defaultProps = {
  className: null,
  style: {},
  value: null,
  errorCase: {},
  name: '',
  dataError: null,
  dataInputName: null,
};

export default ErrorValidation;
