import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function StockholdersList(props) {
  return useObserver(() => (
    <Question>
      <ContentBox className={Qst.spaceBetween}>
        {props.file.details.map((detail, idx2) => (
          <Fragment key={idx2}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>기존 사원명부</span>를 업로드해 주세요.
                </h2>
              </div>
              <div className={st.LimitStockholdersListInfoBox}>
                <img
                  className={st.LimitStockholdersListInfoImg}
                  src="/images/limit_stockholdersList_info.png"
                  alt="사원명부 안내"
                />
              </div>
              {detail.fileType === '사원명부_서면결의' && (
                <div className={st.LimitStockholdersListBox}>
                  <img
                    className={st.LimitStockholdersListBoxImg}
                    src="/images/limit_stockholdersList.png"
                    alt="사원명부 예시"
                  />
                </div>
              )}
              {(detail.fileType === '사원명부_공증' || detail.fileType === '사원명부_서면결의') && (
                <>
                  <div className={st.LimitStockholdersFileInfoBox}>
                    <img
                      className={st.LimitStockholdersFileInfoImg}
                      src="/images/limit_stockholders_file_info.png"
                      alt="파일 확장자 안내"
                    />
                  </div>
                  <Upload
                    id={detail.fileType}
                    className={st.fileUpload}
                    multiple
                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                    deleteModel={props.parentVm.deleteIds}
                    files={toJS(props.parentVm.files(props.idx, idx2))}
                    uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}
                    explainText={
                      detail.fileType === '사원명부_서면결의' && (
                        <div>
                          <span className="bold">모든 사원의 주민/법인등록번호</span>가 기재된 파일이 필요합니다.
                        </div>
                      )
                    }
                    customEx={<li>워드 / 한글 / PDF를 권장합니다.</li>}
                  >
                    <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                  </Upload>
                  {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                    <ErrorText>기존 사원명부를 업로드해 주세요.</ErrorText>
                  )}
                </>
              )}
            </div>
          </Fragment>
        ))}
      </ContentBox>
    </Question>
  ));
}

export default StockholdersList;
