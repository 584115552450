import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import vm from './StockholderListVm';

function StockholderList(props) {
  return useObserver(() => (
    <div className={st.fileDetail}>
      {props.file.details.map((detail, idx2) => (
        <Fragment key={idx2}>
          {(detail.fileType === '기존_주주명부' ||
            detail.fileType === '주주명부_공증' ||
            detail.fileType === '주주명부_서면결의') && (
            <>
              <div className={st.title}>
                {props.regularShareholderMeetingYn && '정기주총 '}기존 주주명부
                {detail.finalSubmittedDate &&
                  props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.fileName &&
                  !props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]?.uploadFiles && (
                    <span className={st.date}>
                      ({detail.finalSubmittedDate?.year}년 {detail.finalSubmittedDate?.month}월{' '}
                      {detail.finalSubmittedDate?.day}일 변경 주주명부)
                    </span>
                  )}
                {props.regularShareholderMeetingYn && (
                  <div className={st.explain}>※ 정기주총은 결산월 마지막날 기준 주주명부 필요</div>
                )}
              </div>
              <FileUpload
                id={detail.fileType}
                className={st.fileUpload}
                accept=".pdf, .jpg, .png"
                downloadBtn
                fileName={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName}
                fileSize={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileSize}
                fileExtension={vm.fileExtension(
                  props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].fileName,
                )}
                fileUrl={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0].url}
                model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files[0]}
              >
                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
              </FileUpload>
              {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                <ErrorText>사업자등록증을 업로드해 주세요.</ErrorText>
              )}
            </>
          )}
        </Fragment>
      ))}
    </div>
  ));
}

export default StockholderList;
