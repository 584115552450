import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import smartformAppModel from '@model/smartformAppModel';
import finalCheckModel from '@model/changeRegistration/finalCheckModel';

class finalCheckService {
  async load() {
    const res = await get(`/changeRegistration/${smartformAppModel.smartformId}/finalCheck`, {}, {});
    const data = res.data;
    finalCheckModel.setFinalCheck(data);
  }
  async before_load() {
    const res = await get(`/changeRegistration/${smartformAppModel.smartformId}/BeforeFinalCheck`, {}, {});
    const data = res.data;
    finalCheckModel.setFinalCheck(data);
  }
  async submitFinalCheck() {
    await post(`/smartform/${smartformAppModel.smartformId}/submit`, {}, {});
  }
}
export default new finalCheckService();
