import { observable } from 'mobx';

const StandByUpdateStepModel = observable({
  updateYn: null,
  setStandByUpdate(data) {
    this.updateYn = data?.updateYn ?? null;
  },
});

export default StandByUpdateStepModel;
