import BranchOfficeInstallStepModel from '@model/changeRegistration/BranchOfficeInstallStepModel';
import stepService from '@service/stepService';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import regExp from '@common/module/regExp';
import portalModel from '@model/portalModel';
import ResidenceRegistrationModal from '@pages/ModalContent/changeRegistration/BranchOfficeInstall/ResidenceRegistrationModal';
import { _alert, _confirm } from '@model/dialogModel';

class BranchOfficeInstallVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      branchAddressModal: false,
      managerAddressModal: false,
      addressIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        //올바를 데이터를 입력한 경우
        this.state.errorArr = [];
        this.state.openIdx = null;
        let param = formDataToJson(e.target).data;
        param.branchOfficeInstalls.map((branchOffice, idx) => {
          if (branchOffice.address) {
            param.branchOfficeInstalls[idx].address = BranchOfficeInstallStepModel.branchOffices[idx].address;
          }
          param.branchOfficeInstalls[idx].causeDate =
            branchOffice.causeDateYear + '년' + branchOffice.causeDateMonth + '월' + branchOffice.causeDateDay + '일';
          delete branchOffice.causeDateYear;
          delete branchOffice.causeDateMonth;
          delete branchOffice.causeDateDay;
          if (branchOffice.manager) {
            if (branchOffice.manager.address) {
              param.branchOfficeInstalls[idx].manager.address =
                BranchOfficeInstallStepModel.branchOffices[idx].manager.address;
            }
            if (branchOffice.manager.birthYear && branchOffice.manager.birthMonth && branchOffice.manager.birthDay) {
              param.branchOfficeInstalls[idx].manager.birth =
                branchOffice.manager.birthYear +
                '년' +
                branchOffice.manager.birthMonth +
                '월' +
                branchOffice.manager.birthDay +
                '일';
              delete branchOffice.manager.birthYear;
              delete branchOffice.manager.birthMonth;
              delete branchOffice.manager.birthDay;
            }
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  addNewBranchOffice() {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices.push(this.newBranchDefaultValue);
      this.state.openIdx = this.branchOffices.length - 1;
      const newBranch = setInterval(() => {
        clearInterval(newBranch);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  get newBranchDefaultValue() {
    var val = {
      causeDateYear: '',
      causeDateMonth: '',
      causeDateDay: '',
      id: '',
      koreanYn: null,
      address: {},
      officeName: '',
      hasManagerYn: null,
      manager: {
        address: {},
        birthYear: '',
        birthMonth: '',
        birthDay: '',
        detailAddress: '',
        koreanYn: null,
        kssn: '',
        name: '',
        englishName: '',
        nameOfBuilding: '',
        nationalityOthers: '',
        nationalityType: '',
        residenceRegistrationYn: null,
      },
      nameOfBuilding: '',
      detailAddress: '',
    };
    return val;
  }
  openModal() {
    const modal = ResidenceRegistrationModal;
    runInAction(() => {
      portalModel.modalContent = modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get branchOffices() {
    return BranchOfficeInstallStepModel.branchOffices;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setTitle(idx) {
    let title = '';
    let address = this.branchOffices[idx].address?.address;
    let bname = this.branchOffices[idx].address?.bname || this.branchOffices[idx].address?.bname1;
    let detailAddress = this.branchOffices[idx].detailAddress;
    let nameOfBuilding = this.branchOffices[idx].nameOfBuilding;
    if (address && detailAddress && nameOfBuilding) {
      if (this.branchOffices.length === 1) {
        title = `신규 지점 : ${address}, ${detailAddress} (${bname}, ${nameOfBuilding})`;
      } else {
        title = `신규 지점${idx + 1} : ${address}, ${detailAddress} (${bname}, ${nameOfBuilding})`;
      }
    } else {
      if (this.branchOffices.length === 1) {
        title = '신규 지점의 정보를 입력해 주세요.';
      } else {
        title = `신규 지점${idx + 1}의 정보를 입력해 주세요.`;
      }
    }
    return title;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  async setCloseBtn(idx) {
    const branchOffice = BranchOfficeInstallStepModel.branchOffices;
    const conf = await _confirm('해당 신규지점의 정보를 삭제할까요?');
    if (branchOffice.length === 1) {
      if (conf) {
        runInAction(() => {
          BranchOfficeInstallStepModel.branchOffices = [];
          BranchOfficeInstallStepModel.branchOffices.push(this.newBranchDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return branchOffice.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  openBranchAddressModal(openYn, idx) {
    this.state.addressIdx = idx;
    this.state.branchAddressModal = openYn;
  }
  openManagerAddressModal(openYn, idx) {
    this.state.addressIdx = idx;
    this.state.managerAddressModal = openYn;
  }
  branchAddressComplate(address) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].address = address;
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].detailAddress = '';
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].nameOfBuilding = address.buildingName;
    });
    this.openBranchAddressModal(false, this.state.addressIdx);
  }
  managerAddressComplate(address) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].manager.address = address;
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].manager.detailAddress = '';
      BranchOfficeInstallStepModel.branchOffices[this.state.addressIdx].manager.nameOfBuilding = address.buildingName;
    });
    this.openManagerAddressModal(false, this.state.addressIdx);
  }
  branchAddress_detailAddress(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].detailAddress = e.target.value;
    });
  }
  branchAddress_nameOfBuilding(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].nameOfBuilding = e.target.value;
    });
  }
  branchAddress_bnameFontSizeChange(idx) {
    const bName = BranchOfficeInstallStepModel.branchOffices[idx].address.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
  managerAddress_detailAddress(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.detailAddress = e.target.value;
    });
  }
  managerAddress_nameOfBuilding(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.nameOfBuilding = e.target.value;
    });
  }
  managerAddress_bnameFontSizeChange(idx) {
    const bName = BranchOfficeInstallStepModel.branchOffices[idx].manager.address.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
  setCauseDateYear(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].causeDateYear = e.target.value;
    });
  }
  setCauseDateMonth(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].causeDateMonth = e.target.value;
    });
  }
  setCauseDateDay(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].causeDateDay = e.target.value;
    });
  }
  causeDateBlur(e, idx, target) {
    let val = e.target.value;
    if (val && val.length === 1) {
      e.target.value = '0' + val;
    }
    if (target === 'month') {
      BranchOfficeInstallStepModel.branchOffices[idx].causeDateMonth = e.target.value;
    }
    if (target === 'day') {
      BranchOfficeInstallStepModel.branchOffices[idx].causeDateDay = e.target.value;
    }
  }
  displayCauseDateErrorMessage(idx, type) {
    const year = BranchOfficeInstallStepModel.branchOffices[idx].causeDateYear;
    const month = BranchOfficeInstallStepModel.branchOffices[idx].causeDateMonth;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  setOfficeName(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].officeName = e.target.value;
    });
  }
  setHasManagerYn(e, idx) {
    //지배인 여부
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].hasManagerYn = e.target.value === 'true';
    });
  }
  //지배인_한국인
  setKoreanYn(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.koreanYn = e.target.value === 'true';
    });
  }
  setName(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.name = e.target.value;
    });
  }
  setKssn(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.kssn = e.target.value;
    });
  }
  setManagerDetailAddress(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.detailAddress = e.target.value;
    });
  }
  setManagerNameOfBuilding(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.nameOfBuilding = e.target.value;
    });
  }
  //지배인_외국인
  setResidenceReportYn(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.residenceRegistrationYn = e.target.value === 'true';
    });
  }
  setNationalityType(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.nationalityOthers = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.englishName = e.target.value;
    });
  }
  setBirthYear(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.birthYear = e.target.value;
    });
  }
  setBirthMonth(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.birthMonth = e.target.value;
    });
  }
  setBirthDay(e, idx) {
    runInAction(() => {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.birthDay = e.target.value;
    });
  }
  birthDateBlur(e, idx, target) {
    let val = e.target.value;
    if (val && val.length === 1) {
      e.target.value = '0' + val;
    }
    if (target === 'month') {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.birthMonth = e.target.value;
    }
    if (target === 'day') {
      BranchOfficeInstallStepModel.branchOffices[idx].manager.birthDay = e.target.value;
    }
  }
  displayBirthErrorMessage(idx, type) {
    const year = BranchOfficeInstallStepModel.branchOffices[idx].manager.birthYear;
    const month = BranchOfficeInstallStepModel.branchOffices[idx].manager.birthMonth;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
}
export default new BranchOfficeInstallVm();
