class AddressVm {
  bnameFontSizeChange(item) {
    const bName = item.newAddress.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
}

export default new AddressVm();
