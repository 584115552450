import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import clsx from 'clsx';

function RepresentativeCheckStep(props) {
  return useObserver(() => (
    <>
      {props.step.representatives.map((item, idx) => {
        return (
          <div className={Ast.row} key={idx}>
            <div className={Ast.dName}>
              <div>
                <CopyBtn onClick={() => copyText(item.koreanName)} style={{ textAlign: 'left' }}>
                  {item.koreanName}
                </CopyBtn>
              </div>
              <div>{item.originPosition}</div>
            </div>
            <div className={Ast.data}>
              <div>
                {item.foreignerYn ? '생년월일' : '주민등록번호'} :&nbsp;
                {item.birth ? (
                  <CopyBtn onClick={() => copyText(item.birth)} style={{ textAlign: 'left' }}>
                    {' '}
                    {item.birth}
                  </CopyBtn>
                ) : (
                  ' -'
                )}
              </div>
              <div>
                {item.addressSameYn === true ? (
                  <>주소 : 등기부상 주소 변동 없음</>
                ) : (
                  <>
                    {item?.newAddresses[0]?.newAddress !== null ? (
                      <>
                        {item.newAddresses.map((item2, idx2) => {
                          return (
                            <div className={Ast.newAddressBox}>
                              <div>
                                주소 :
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.sido)}
                                  className={Ast.addressCopyBtn}
                                  style={{ marginLeft: '3px' }}
                                >
                                  {item2.newAddress?.sido}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.sigungu)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {item2.newAddress?.sigungu}
                                </CopyBtn>
                                {item2.newAddress?.bname1 && (
                                  <CopyBtn
                                    onClick={() => copyText(item2.newAddress?.bname1)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {item2.newAddress?.bname1}
                                  </CopyBtn>
                                )}
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.roadname)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {item2.newAddress?.roadname}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.buildingNo)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {item2.newAddress?.buildingNo}
                                </CopyBtn>
                                ,
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.detailAddress)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {item2.newAddress?.detailAddress}
                                </CopyBtn>
                                <div className={Ast.addressCopyBracket}>
                                  (
                                  <CopyBtn
                                    onClick={() => copyText(item2.newAddress?.bname)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {item2.newAddress?.bname}
                                  </CopyBtn>
                                  ,
                                  <CopyBtn
                                    onClick={() => copyText(item2.newAddress?.nameOfBuilding)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {item2.newAddress?.nameOfBuilding}
                                  </CopyBtn>
                                  )
                                </div>
                                <CopyBtn
                                  onClick={() => copyText(item2.newAddress?.fullAddress)}
                                  className={Ast.addressCopyAllBtn}
                                >
                                  <Icon icon="copy" className={Ast.copyIcon} />
                                </CopyBtn>
                              </div>
                              <div>
                                전입신고 일자 :
                                {item2.causeDate ? (
                                  <CopyBtn onClick={() => copyText(item2.causeDate)} style={{ textAlign: 'left' }}>
                                    {item2.causeDate}
                                  </CopyBtn>
                                ) : (
                                  ' -'
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      '주소 : -'
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </>
  ));
}

export default RepresentativeCheckStep;
