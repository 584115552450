import { useObserver } from 'mobx-react';
import st from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import { addCommas, percent } from '@common/module/replaceNumber';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function CorpNameStep(props) {
  return useObserver(() => (
    <>
      <div className={st.row}>
        <div className={st.dName}>
          기존목적 삭제
          <div style={{ marginTop: '5px' }}>
            ({props.step.originalBusinessPurposes.filter((item) => item.deleteYn).length}개 삭제)
          </div>
        </div>
        <div className={clsx(st.data, st.businessPurpose)}>
          {props.step.originalBusinessPurposes.length !== 0 &&
            props.step.originalBusinessPurposes.map((item, idx) => (
              <span className={clsx(st.purpose, item.deleteYn ? st.lineThrough : null)} key={idx}>
                {item.content} {item.autoDeleteYn ? '(자동삭제)' : ''}
              </span>
            ))}
          {props.step.originalBusinessPurposes.length === 0 && <span className={st.purpose}>없음</span>}
        </div>
      </div>
      <div className={st.row} style={{ marginTop: '8px' }}>
        <div className={st.dName}>
          신규목적 추가
          <div style={{ marginTop: '5px' }}>
            ({props.step.newBusinessPurposes.filter((item) => !item.autoDeleteYn).length}개 추가)
          </div>
        </div>
        <div className={clsx(st.data, st.businessPurpose)}>
          {props.step.newBusinessPurposes.length !== 0 &&
            props.step.newBusinessPurposes.map((item, idx) => (
              <span className={st.purpose} key={idx}>
                <CopyBtn onClick={() => copyText('1. ' + item.content)}>1. {item.content}</CopyBtn>{' '}
                {item.autoDeleteYn ? '(자동추가)' : ''}
              </span>
            ))}
          {props.step.newBusinessPurposes.length === 0 && <span className={st.purpose}>없음</span>}
        </div>
      </div>
      {props.step.newBusinessPurposes.filter((item) => !item.autoDeleteYn).length > 20 && (
        <div className={st.row} style={{ marginTop: '8px' }}>
          <div className={st.dName}>
            20개 초과
            <div style={{ marginTop: '5px' }}>추가비용</div>
          </div>
          <div className={st.data}>
            {props.step.newBusinessPurposes.filter((item) => !item.autoDeleteYn).length - 20}개 x 5,500 ={' '}
            {addCommas((props.step.newBusinessPurposes.filter((item) => !item.autoDeleteYn).length - 20) * 5500)}원
            추가비용
            <div style={{ marginTop: '5px' }}>
              (총 {props.step.newBusinessPurposes.filter((item) => !item.autoDeleteYn).length}개 추가)
            </div>
          </div>
        </div>
      )}
    </>
  ));
}
export default CorpNameStep;
