import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';

import AllPurposesModal from '../../modals/AllPurposesModal';
import AllPurposesModalStyle from '../../modals/AllPurposesModal/style.module.scss';
import Group from './components/Group';
import st from './style.module.scss';
import useGroupSelect from './useGroupSelect';

const PopularPurposeGroupList = observer(({ popularPurposeGroups = [], selectedRecommendedPurposesMob }) => {
  const { groupSelectMob } = useGroupSelect({
    popularPurposeGroups,
    selectedRecommendedPurposesMob,
  });

  const openAllPurposesModal = () => {
    runInAction(() => {
      portalModel.modalClassName = AllPurposesModalStyle.modal;
      portalModel.modalContent = <AllPurposesModal />;
      portalModel.btn = null;
      portalModel.closeBtn = true;
    });
  };

  return (
    <div className={st.container}>
      <div className={st.header}>
        <div>
          많이 사용된 <span>인기 사업목적 모음</span>
        </div>
        <div className={st.divider} />
      </div>
      <div className={st.content}>
        {popularPurposeGroups.map((item, index) => (
          <Group
            key={item.groupNames.join()}
            purposeGroup={item}
            defaultOpen={index === 0}
            isAdded={groupSelectMob.checkGroupSelected(item.groupNames)}
            onClickAdd={() => groupSelectMob.selectGroup(item.groupNames)}
          />
        ))}
      </div>
      <div className={st.footer}>
        <button type="button" onClick={openAllPurposesModal}>
          목적 전체보기
        </button>
      </div>
    </div>
  );
});

export default PopularPurposeGroupList;
