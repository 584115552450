import { post, get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import changeRegistrationModel from '@model/changeRegistrationModel';
import passwordModel from '@model/changeRegistration/passwordModel';
import corpNameStepModel from '@model/changeRegistration/corpNameStepModel';
import addStockOptionStepModel from '@model/changeRegistration/addStockOptionStepModel';
import businessPurposeStepModel from '@model/changeRegistration/businessPurposeStepModel';
import pageStepModel, { pageRouter, pageDepth } from '@model/pageStepModel';
import HeadOfficeRelocationStepModel from '@model/changeRegistration/HeadOfficeRelocationStepModel';
import announceMethodStepModel from '@model/changeRegistration/announceMethodStepModel';
import PaidInCapitalIncreaseStepModel from '@model/changeRegistration/PaidInCapitalIncreaseStepModel';
import NumOfSharesToBeIssuedStepModel from '@model/changeRegistration/NumOfSharesToBeIssuedStepModel';
import ExecutiveMemberChangeStepModel from '@model/changeRegistration/ExecutiveMemberChangeStepModel';
import BranchOfficeDeleteStepModel from '@model/changeRegistration/BranchOfficeDeleteStepModel';
import BranchOfficeRelocateStepModel from '@model/changeRegistration/BranchOfficeRelocateStepModel';
import BranchOfficeChangeStepModel from '@model/changeRegistration/BranchOfficeChangeStepModel';
import BranchOfficeInstallStepModel from '@model/changeRegistration/BranchOfficeInstallStepModel';
import BoardMeetingSealProvidingStepModel from '@model/changeRegistration/BoardMeetingSealProvidingStepModel';
import StockholdersMeetingSealProvidingStepModel from '@model/changeRegistration/StockholdersMeetingSealProvidingStepModel';
import FileUploadStepModel from '@model/changeRegistration/FileUploadStepModel';
import FreeIssueOfNewSharesStepModel from '@model/changeRegistration/FreeIssueOfNewSharesStepModel';
import TCAgreeAndDeliveryAddressStepModel from '@model/changeRegistration/TCAgreeAndDeliveryAddressStepModel';
import RepresentativeCheckModel from '@model/changeRegistration/RepresentativeCheckModel';
import capitalIncreaseStepModel from '@model/changeRegistration/capitalIncreaseStepModel';
import ParValueDivisionStepModel from '@model/changeRegistration/ParValueDivisionStepModel';
import LiquidationStepModel from '@model/changeRegistration/LiquidationStepModel';
import StockCompanySealStepModel from '@model/changeRegistration/StockCompanySealStepModel';
import CorporateTaxStepModel from '@model/changeRegistration/CorporateTaxStepModel';
import StandByUpdateStepModel from '@model/changeRegistration/StandByUpdateStepModel';
import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import StandByStockholderMeetingInfoStepModel from '@model/changeRegistration/StandByStockholderMeetingInfoStepModel';
import StandByBoardMeetingInfoStepModel from '@model/changeRegistration/StandByBoardMeetingInfoStepModel';
import StandbyFileUploadStepModel from '@model/changeRegistration/StandbyFileUploadStepModel';
import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import StandbyFreeIssueOfNewSharesStepModel from '@model/changeRegistration/StandbyFreeIssueOfNewSharesStepModel';
import StandbyParValueDivisionStepModel from '@model/changeRegistration/StandbyParValueDivisionStepModel';
import TrademarkStepModel from '@model/changeRegistration/TrademarkStepModel';
import { runInAction } from 'mobx';
import stepService from './stepService';

class changeRegistrationService {
  async loadChangeRegistrationBasic(smartformId) {
    //변경등기 공용 기본필수데이터 로드
    const res = await get(`/changeRegistration/${smartformId}/basic`, {}, {});
    changeRegistrationModel.setIsSubmitted(res.data.isSubmitted);
    passwordModel.setHasPassword(res.data.hasPassword);
    if (window.performance.navigation.type === 0 && smartformAppModel.authority !== 'manager') {
      pageRouter.replace(res.data.redirectUrl);
    }
    smartformAppModel.setSmartFormType(res.data.companyType);
    smartformAppModel.setStandbyYn(res.data.standbyYn);
  }
  async loadChangeRegistrationStep() {
    const res = await get(
      `/step/changeRegistration/${smartformAppModel.smartformId}`,
      {
        lastPage: pageStepModel.nowPage,
        lastStep: pageStepModel.nowStep,
      },
      {},
    );
    smartformAppModel.setIsLoadData(false);
    smartformAppModel.setIsSubmitData(true);
    runInAction(() => {
      pageDepth.setPageDepthChangeRegistration(res.data.steps);
      if (pageStepModel.nowPage === 'CorpNameChange') {
        corpNameStepModel.setCorpNameStepModel(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'AddStockOption') {
        addStockOptionStepModel.setAddStockOption(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BusinessPurposeRevision') {
        businessPurposeStepModel.setBusinessPurposeRevision(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'HeadOfficeAddressRelocation') {
        HeadOfficeRelocationStepModel.setHeadOfficeAddressRelocation(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'AnnounceMethod') {
        announceMethodStepModel.setAnnounceMethod(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'NewSharesAcquirer') {
        PaidInCapitalIncreaseStepModel.setNewSharesAcquirer(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ClassStockAndInvestment') {
        PaidInCapitalIncreaseStepModel.setClassStockAndInvestmentt(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'MethodOfIssuingNewShares') {
        PaidInCapitalIncreaseStepModel.setMethodOfIssuingNewSharest(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'NumOfSharesToBeIssued') {
        NumOfSharesToBeIssuedStepModel.setNumOfSharesToBeIssued(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ExecutiveMemberChangeCheck') {
        ExecutiveMemberChangeStepModel.setExecutiveMemberChangeCheck(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ExecutiveMemberChangeOriginChange') {
        ExecutiveMemberChangeStepModel.setExecutiveMemberChangeOriginChange(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ExecutiveMemberChangeNewChange') {
        ExecutiveMemberChangeStepModel.setExecutiveMemberChangeNewChange(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ExecutiveMemberChangeRepresentativeCheck') {
        RepresentativeCheckModel.setRepresentativeCheck(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BranchOfficeDelete') {
        BranchOfficeDeleteStepModel.setBranchOfficeDelete(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BranchOfficeRelocate') {
        BranchOfficeRelocateStepModel.setBranchOfficeRelocate(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BranchOfficeChange') {
        BranchOfficeChangeStepModel.setBranchOfficeChange(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BranchOfficeInstall') {
        BranchOfficeInstallStepModel.setBranchOfficeInstall(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'TCAgreeAndDeliveryAddress') {
        TCAgreeAndDeliveryAddressStepModel.setTCAgreeAndDeliveryAddress(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'RepresentativeAddressChange') {
        RepresentativeCheckModel.setRepresentativeCheck(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'BoardMeetingSealProviding') {
        BoardMeetingSealProvidingStepModel.setBoardMeetingSealProviding(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StockholdersMeetingSealProviding') {
        StockholdersMeetingSealProvidingStepModel.setStockholdersMeetingSealProviding(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'FileUpload') {
        FileUploadStepModel.setFileUpload(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'FreeIssueOfNewShares') {
        FreeIssueOfNewSharesStepModel.setFreeIssueOfNewShares(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'NewSharesAcquirer') {
        capitalIncreaseStepModel.setNewSharesAcquirer(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'CapitalIncrease') {
        capitalIncreaseStepModel.setCapitalIncrease(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ParValueDivision') {
        ParValueDivisionStepModel.setParValueDivision(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'Liquidation') {
        LiquidationStepModel.setLiquidation(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StockholdersMeetingMusterNotice') {
        StockCompanySealStepModel.setStockholdersMeetingMusterNotice(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StockholdersMeetingSealProvidingStockholdersCaseOne') {
        StockCompanySealStepModel.setStockholdersMeetingSealProvidingStockholdersCaseOne(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StockholdersMeetingAttendStockholders') {
        StockCompanySealStepModel.setStockholdersMeetingAttendStockholders(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StockholdersMeetingSealProvidingStockholdersCaseTwo') {
        StockCompanySealStepModel.setStockholdersMeetingSealProvidingStockholdersCaseTwo(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'CorporateTax') {
        CorporateTaxStepModel.setCorporateTax(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandByUpdate') {
        StandByUpdateStepModel.setStandByUpdate(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'CheckAsEstimate') {
        StandbyPaidInCapitalIncreaseStepModel.setCheckAsEstimate(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbySimpleNewSharesAcquirer') {
        StandbyPaidInCapitalIncreaseStepModel.setStandbySimpleNewSharesAcquirer(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyNewSharesAcquirer') {
        StandbyPaidInCapitalIncreaseStepModel.setStandbyNewSharesAcquirer(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyCapitalIncrease') {
        StandbyPaidInCapitalIncreaseStepModel.setStandbyCapitalIncrease(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyMethodOfIssuingNewShares') {
        StandbyPaidInCapitalIncreaseStepModel.setStandbyMethodOfIssuingNewShares(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandByStockholderMeetingBasicInfo') {
        StandByStockholderMeetingInfoStepModel.setStandByStockholderMeetingBasicInfo(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandByStockholderMeetingParticipant') {
        StandByStockholderMeetingInfoStepModel.setStandByStockholderMeetingParticipant(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandByBoardMeetingBasicInfo') {
        StandByBoardMeetingInfoStepModel.setStandByBoardMeetingBasicInfo(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandByBoardMeetingParticipant') {
        StandByBoardMeetingInfoStepModel.setStandByBoardMeetingParticipant(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyFileUpload') {
        StandbyFileUploadStepModel.setStandbyFileUpload(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ConvertibleBondAcquirer') {
        ConvertibleBondStepModel.setConvertibleBondAcquirer(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ConvertibleBondAcquisitionAmount') {
        ConvertibleBondStepModel.setConvertibleBondAcquisitionAmount(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'ConvertibleBondDepositProof') {
        ConvertibleBondStepModel.setConvertibleBondDepositProof(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyFreeIssueOfNewSharesCheck') {
        StandbyFreeIssueOfNewSharesStepModel.setStandbyFreeIssueOfNewSharesCheck(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyFreeIssueOfNewShares') {
        StandbyFreeIssueOfNewSharesStepModel.setStandbyFreeIssueOfNewShares(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyParValueDivisionCheck') {
        StandbyParValueDivisionStepModel.setStandbyParValueDivisionCheck(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'StandbyParValueDivision') {
        StandbyParValueDivisionStepModel.setStandbyParValueDivision(res.data.pageDetail);
      }
      if (pageStepModel.nowPage === 'Trademark') {
        TrademarkStepModel.setTrademarkStepModel(res.data.pageDetail);
      }
    });
    smartformAppModel.setIsLoadData(true);
  }
  async uploadFiles() {
    //파일 업로드
    let formData = new FormData();
    let index = 0;
    formData.append('smartformId', smartformAppModel.smartformId);
    formData.append('deleteIds', FileUploadStepModel.deleteIds);
    FileUploadStepModel.fileGroupTypes.forEach((item) => {
      item.details.forEach((detail) => {
        if (detail.uploadFiles.length) {
          for (let i = 0; i < detail.uploadFiles.length; i++) {
            formData.append(`files[${index}].fileType`, detail.fileType);
            formData.append(`files[${index}].file`, detail.uploadFiles[i]);
            index++;
          }
        }
      });
    });
    stepService.saveStep(formData);
  }
}
export default new changeRegistrationService();
