import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import st from '../StandbyFileUpload/StandbyFileUpload.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import clsx from 'clsx';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';

function FreeIssueOfNewSharesDocument(props) {
  return useObserver(() => (
    <Question>
      <ContentBox>
        <div className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>무상증자</span> 서류 업로드 안내
              </h2>
            </div>
            <div className={st.freeIssueOfNewSharesInfo}>
              <img
                className={st.freeIssueOfNewSharesInfoImg}
                src="/images/freeIssue_of_newShares_Info.png"
                alt="무상증자 서류 업로드 안내 이미지"
              />
            </div>
            {props.file.details.map((detail, idx2) => (
              <Fragment key={idx2}>
                {detail.fileType === '무상증자_최종_재무제표' && (
                  <>
                    <div className={Qst.titleWrap} style={{ marginTop: '100px' }}>
                      <h2 className={Qst.title}>
                        <span className={st.highLight}>원칙</span> 정기주주총회 의사록과 함께 공증된 최종의
                        재무제표(별지로 첨부)를 업로드해 주세요.
                      </h2>
                      <div className={Qst.titleExplain}>
                        서면결의로 진행한 경우 정기주주총회를 갈음하는 주주전원의 서면결의서 + 최종의 재무제표(별지로
                        첨부)를 업로드해 주시면 됩니다.
                      </div>
                    </div>
                    <Badge className={st.financialStatementsBadge} title="예시" type="example" border={false}>
                      <img
                        className={st.financialStatementsBadgeImg}
                        src="/images/financial_statements.png"
                        alt="재무제표 예시"
                      />
                    </Badge>
                  </>
                )}
                {detail.fileType === '무상증자_예외' && (
                  <>
                    <div className={Qst.titleWrap} style={{ marginTop: '100px' }}>
                      <h2 className={Qst.title}>
                        <span className={st.highLight}>예외</span> 아래 3가지 서류를 업로드해 주세요. (자본준비금으로
                        무상증자하는 경우에 한정)
                      </h2>
                    </div>
                    <div className={st.financialStatementsExceptionBadge}>
                      <img
                        className={st.financialStatementsExceptionBadgeImg}
                        src="/images/financial_statements_execption.png"
                        alt="업로드 서류 안내 이미지"
                      />
                    </div>
                  </>
                )}
                <Upload
                  id={detail.fileType}
                  style={{ marginTop: '10px' }}
                  className={st.fileUpload}
                  multiple
                  accept=".pdf, .jpg, .png"
                  model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                  uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                  deleteModel={props.parentVm.deleteIds}
                  files={toJS(props.parentVm.files(props.idx, idx2))}
                  uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}
                >
                  <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                </Upload>
                {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                  <ErrorText>원칙 또는 예외 파일을 업로드해 주세요.</ErrorText>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </ContentBox>
    </Question>
  ));
}

export default FreeIssueOfNewSharesDocument;
