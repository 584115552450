import st from './ExecutiveModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title} style={{ width: '370px' }}>
        <span className={st.bold}>인감증명서 또는 본인서명사실확인서 제출이 가능</span>한 분의 정보를 입력해 주세요.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>
          고객님! 거소등록 또는 외국인등록을 한 외국인분의 경우, 임원이 되려면 인감증명서 또는 본인서명사실확인서 제출이
          꼭 필요합니다. 주소지 관할 주민센터에 직접 방문하면 당일 신규등록 및 발급이 가능하니 참고 부탁드립니다.
        </p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
