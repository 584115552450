import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';

class ClassStockAndInvestmentVm {
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error === false) {
        let param = formDataToJson(e.target).data;
        param.newShares.forEach((newShare) => {
          newShare?.유상증자?.stocks.forEach((stock) => {
            stock.investment = removeCommas(stock.investment);
            stock.number = removeCommas(stock.number);
          });
          newShare?.가수금증자?.stocks.forEach((stock) => {
            stock.investment = removeCommas(stock.investment);
            stock.number = removeCommas(stock.number);
          });
        });
        stepService.saveStep(param);
      }
    });
  }
  get classStocksOnRegister() {
    //기존 등기부 정보
    return StandbyPaidInCapitalIncreaseStepModel.sharesOnRegister.classStocksOnRegister;
  }
  get totalCapitalStock() {
    //등기부상 주식수 합계
    return StandbyPaidInCapitalIncreaseStepModel.sharesOnRegister.totalCapitalStock;
  }
  get totalNumberOfSharesOnRegister() {
    //등기부상 자본금 합계
    return StandbyPaidInCapitalIncreaseStepModel.sharesOnRegister.totalNumberOfSharesOnRegister;
  }
  get newShares() {
    return StandbyPaidInCapitalIncreaseStepModel.newShares;
  }
  //유상증자
  set유상증자StockType(e, index, idx1) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].stockType = e.target.value;
    });
  }
  set유상증자StockName(e, index, idx1) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].name = e.target.value;
    });
  }
  add유상증자Stock(index) {
    StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks.push({
      id: '',
      stockType: '',
      name: '',
      investment: null,
      number: null,
    });
  }
  delete유상증자Stock(index, idx1) {
    StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks.splice(idx1, 1);
  }
  set유상증자Investment(e, index, idx1) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].investment = onlyNumber(
        removeCommas(e.target.value),
      );
    });
  }
  set유상증자Number(e, index, idx1) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].number = onlyNumber(
        removeCommas(e.target.value),
      );
    });
  }
  유상증자issuePrice(index, idx1) {
    const investment = StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].investment;
    const number = StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].number;
    let issuePrice = 0;
    if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        issuePrice = investment / number;
        return issuePrice;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        issuePrice = (investment / number).toFixed(2);
        const priceList = issuePrice.split('.');
        return { issuePrice: priceList[0], point: priceList[1] };
      }
    }
    return issuePrice;
  }
  유상증자투자금ValidateFunc(index, idx1) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].investment);
    const number = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].number);
    if (investment === 0) {
      _validation = false;
      _message = '투자금을 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '발행가액이 원단위로 나와야 합니다. 주식수 또는 투자금을 조정해주세요.';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  유상증자주식수ValidateFunc(index, idx1) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].investment);
    const number = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].유상증자.stocks[idx1].number);
    if (number === 0) {
      _validation = false;
      _message = '주식수를 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  //가수금증자
  set가수금증자StockType(e, index, idx2) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].stockType = e.target.value;
    });
  }
  set가수금증자StockName(e, index, idx2) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].name = e.target.value;
    });
  }
  add가수금증자Stock(index) {
    StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks.push({
      id: '',
      stockType: '',
      name: '',
      investment: null,
      number: null,
    });
  }
  delete가수금증자Stock(index, idx2) {
    StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks.splice(idx2, 1);
  }
  set가수금증자Investment(e, index, idx2) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].investment = onlyNumber(
        removeCommas(e.target.value),
      );
    });
  }
  set가수금증자Number(e, index, idx2) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].number = onlyNumber(
        removeCommas(e.target.value),
      );
    });
  }
  가수금증자issuePrice(index, idx2) {
    const investment = StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].investment;
    const number = StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].number;
    let issuePrice = 0;
    if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        issuePrice = investment / number;
        return issuePrice;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        issuePrice = (investment / number).toFixed(2);
        const priceList = issuePrice.split('.');
        return { issuePrice: priceList[0], point: priceList[1] };
      }
    }
    return issuePrice;
  }
  가수금증자투자금ValidateFunc(index, idx2) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = Number(
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].investment,
    );
    const number = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].number);
    if (investment === 0) {
      _validation = false;
      _message = '투자금을 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '발행가액이 원단위로 나와야 합니다. 주식수 또는 투자금을 조정해주세요.';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  가수금증자주식수ValidateFunc(index, idx2) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = Number(
      StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].investment,
    );
    const number = Number(StandbyPaidInCapitalIncreaseStepModel.newShares[index].가수금증자.stocks[idx2].number);
    if (number === 0) {
      _validation = false;
      _message = '주식수를 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }

  investmentSum() {
    let 유상증자Investment = 0;
    let 가수금증자Investment = 0;
    let investmentSum = 0;
    this.newShares.forEach((newShare, index) => {
      {
        newShare?.유상증자?.stocks.forEach((stock) => {
          유상증자Investment = 유상증자Investment + Number(stock.investment);
        });
      }
      {
        newShare?.가수금증자?.stocks.forEach((stock) => {
          가수금증자Investment = 가수금증자Investment + Number(stock.investment);
        });
      }
    });
    investmentSum = 유상증자Investment + 가수금증자Investment;
    if (investmentSum) {
      return addCommas(investmentSum);
    } else {
      return investmentSum;
    }
  }
  numberSum() {
    let 유상증자Number = 0;
    let 가수금증자Number = 0;
    let numberSum = 0;
    this.newShares.forEach((newShare, index) => {
      {
        newShare?.유상증자?.stocks.forEach((stock) => {
          유상증자Number = 유상증자Number + Number(stock.number);
        });
      }
      {
        newShare?.가수금증자?.stocks.forEach((stock) => {
          가수금증자Number = 가수금증자Number + Number(stock.number);
        });
      }
    });
    numberSum = 유상증자Number + 가수금증자Number;
    if (numberSum) {
      return addCommas(numberSum);
    } else {
      return numberSum;
    }
  }
}
export default new ClassStockAndInvestmentVm();
