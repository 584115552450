import StockCompanySealStepModel from '@model/changeRegistration/StockCompanySealStepModel';
import { runInAction, observable, toJS } from 'mobx';
import formDataToJson, { errorFocus, findPos, objectToFormData } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import stepService from '@service/stepService';
class StockholdersMeetingSealProvidingStockholdersCaseTwoVm {
  constructor() {
    this.state = observable({
      isChanged: false,
    });
  }
  get pageModel() {
    return StockCompanySealStepModel.StockholdersMeetingSealProvidingStockholdersCaseTwo;
  }
  get stockholders() {
    return this.pageModel.stockholders;
  }
  get stockholderMeetingType() {
    return this.pageModel.stockholderMeetingType;
  }
  changeCodeSealProviderType(code) {
    if (code === '펀드_투자조합') {
      return '펀드 / 투자조합';
    }
    if (code === '외국인_거주등록') {
      return '외국인 (거주등록)';
    }
    return code;
  }
  setSealProvidingYn(e, index) {
    runInAction(() => {
      this.pageModel.stockholders[index].sealProvidingYn = e.target.checked;
      this.state.isChanged = true;
    });
  }
  sealProvidingYnFalse() {
    this.pageModel.stockholders.map((stockholder) => {
      stockholder.sealProvidingYn = false;
    });
  }

  get isCheckedAll() {
    if (this.stockholders.length === 0) {
      return false;
    }
    let isCheckedAll = true;
    this.stockholders.forEach((stockholder) => {
      if (
        !stockholder.sealProvidingYn &&
        stockholder.sealProviderType !== '외국인' &&
        stockholder.sealProviderType !== '외국법인'
      ) {
        isCheckedAll = false;
      }
    });
    return isCheckedAll;
  }
  get files() {
    return this.pageModel.files;
  }
  get uploadFiles() {
    return this.pageModel.uploadFiles;
  }
  get deleteFileIds() {
    return this.pageModel.deleteFileIds;
  }
  checkedAll(e) {
    const value = e.target.checked;
    runInAction(() => {
      this.state.isChanged = true;
      this.stockholders.forEach((stockholder) => {
        if (stockholder.sealProviderType !== '외국인' && stockholder.sealProviderType !== '외국법인') {
          stockholder.sealProvidingYn = value;
        }
      });
    });
  }
  async submit(e) {
    e.preventDefault();
    this.state.isChanged = true;
    if (this.stockholders.filter((stockholder) => stockholder.sealProvidingYn).length === 0) {
      let top = findPos(document.querySelector('.selectTable')) - 185;
      window.scroll(0, top);
      return;
    }
    if (
      this.stockholders.filter(
        (stockholder) => stockholder.sealProviderType === '펀드_투자조합' && stockholder.sealProvidingYn,
      ).length !== 0
    ) {
      if (!this.files?.length === 0 && !this.uploadFiles?.length) {
        const fileCheck = setInterval(() => {
          clearInterval(fileCheck);
          let top = findPos(document.querySelector('.fileUpload')) - 185;
          window.scroll(0, top);
        }, 30);
        await _alert('등록원부 또는 고유번호증을 업로드해 주세요.');
        return;
      }
    }
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      param.stockholders.map((stockholder) => {
        if (!stockholder?.sealProvidingYn) {
          stockholder.sealProvidingYn = false;
        }
      });
      if (
        this.stockholders.filter(
          (stockholder) => stockholder.sealProviderType === '펀드_투자조합' && stockholder.sealProvidingYn,
        ).length !== 0
      ) {
        param.deleteFileIds = toJS(this.deleteFileIds);
      }
      const formData = new FormData();
      objectToFormData(formData, param);
      if (
        this.stockholders.filter(
          (stockholder) => stockholder.sealProviderType === '펀드_투자조합' && stockholder.sealProvidingYn,
        ).length !== 0
      ) {
        this.uploadFiles.forEach((file, index) => {
          formData.append(`files[${index}]`, file);
        });
      }
      stepService.saveStep(formData);
    }
  }
}
export default new StockholdersMeetingSealProvidingStockholdersCaseTwoVm();
