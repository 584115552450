import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function ParValueDivisionStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>변경할 액면금</div>
        <div className={Ast.data}>{addCommas(props.step.newParValue) + ' 원' || '-'}</div>
      </div>
    </>
  ));
}
export default ParValueDivisionStep;
