import { createQueryKeys } from '@lukemorales/query-key-factory';
import BusinessPurpose from '@service/businessPurposeService.js';
import { useMutation, useQuery } from '@tanstack/react-query';

const queryKeys = createQueryKeys('businessPurpose', {
  searchPurpose: (keyword) => [keyword],
  popularPurpose: null,
  allRecommendPurpose: null,
});

const useSearchPurposeQuery = (keyword) => {
  return useQuery({
    ...queryKeys.searchPurpose(keyword),
    queryFn: () => BusinessPurpose.searchPurpose(keyword),
    enabled: !!keyword,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });
};

const usePopularPurposesQuery = () => {
  const { data, isSuccess, ...rest } = useQuery({
    ...queryKeys.popularPurpose,
    queryFn: () => BusinessPurpose.getPopularPurposes(),
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  return {
    data: isSuccess
      ? data
      : {
          purposeGroups: [],
        },
    isSuccess,
    ...rest,
  };
};

const useEditAndSavePurposeMutation = () => {
  return useMutation({
    mutationFn: ({ smartformId, businessPurposes }) => BusinessPurpose.editPurpose(smartformId, businessPurposes),
  });
};

const useAllRecommendPurposesQuery = () => {
  const { data, isSuccess, ...rest } = useQuery({
    ...queryKeys.allRecommendPurpose,
    queryFn: () => BusinessPurpose.getAllRecommendPurposes(),
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
  });

  return {
    data: isSuccess
      ? data
      : {
          headers: [],
          results: [],
        },
    isSuccess,
    ...rest,
  };
};

export { useAllRecommendPurposesQuery, useEditAndSavePurposeMutation, usePopularPurposesQuery, useSearchPurposeQuery };
