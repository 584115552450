import { observable } from 'mobx';

const termsAndConditionsAgreeModel = observable({
  tcagreements: [], //이용약관동의
  setTcagreements(tcagreements) {
    if (tcagreements && tcagreements.length !== 0) {
      this.tcagreements = tcagreements.map((tcagreements) => {
        return {
          agreeYn: tcagreements.agreeYn ?? null,
          content: tcagreements.content || '',
          id: tcagreements.id ?? '',
          required: tcagreements.required ?? null,
          termId: tcagreements.termId ?? '',
        };
      });
    } else {
      this.tcagreements = [];
    }
  },
});

export default termsAndConditionsAgreeModel;
