import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Revise from '../../atoms/Button/ReviseBtn/ReviseBtn';
import Close from '../../atoms/Button/CloseBtn/CloseBtn';
import Icon from '../../atoms/Icon/Icon';

const ContentBox = styled.div`
  padding: 0 45px;
  background-color: #fff;
  border-radius: 4px;
`;

const Header = styled.div`
  position: relative;
  height: 105px;
  .revise {
    position: absolute;
    right: 57px;
    top: 50%;
    transform: translateY(-50%);
  }
  .close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Title = styled.button`
  display: inline-block;
  padding-left: 29px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: #000;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: -1.04px;
  text-align: left;
  line-height: 105px;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 47px;
    left: 0;
    width: 14px;
    height: 14px;
    border: 4px solid #000;
    border-radius: 50%;
  }
`;

const DropdownBox = styled.div`
  overflow: hidden;
  transition: padding 0.15s ease-out;
  ${(props) =>
    props.isOpen === false
      ? `height: 0;
    `
      : `height: auto;
    padding: 40px 0;
    padding-left: 1px;
    border-top: 1px solid #c5cede;
    `}
`;

const ErrorText = styled.span`
  position: relative;
  top: -2px;
  margin-left: 5px;
  color: #eb4d59;
  font-size: 18px;
`;

function Dropdown(props) {
  return (
    <>
      <ContentBox className={props.className} style={props.style}>
        <Header className="header">
          <Title type="button" onClick={(e) => props.onClick(e)}>
            {props.title}
            {props.isOpen === false && props.error ? (
              <>
                <Icon icon="error" style={{ marginLeft: '13px', width: '20px' }} />
                <ErrorText>입력하지 않은 정보가 있습니다.</ErrorText>
              </>
            ) : null}
          </Title>
          {props.edit && (
            <Revise className="revise" onClick={(e) => props.onClick(e)}>
              수정하기
            </Revise>
          )}
          {props.closeBtn === null ? null : <Close className="close" onClick={props.closeBtn} />}
        </Header>
        <DropdownBox isOpen={props.isOpen} name={props.name} data-eq={props.dataEq}>
          {props.children}
        </DropdownBox>
      </ContentBox>
    </>
  );
}

Dropdown.propTypes = {
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * 직책 및 이름
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * closeBtn event
   */
  closeBtn: PropTypes.func,
  /**
   * dropdown 열림 여부
   */
  isOpen: PropTypes.bool,
  /**
   * dropdown 콘텐츠
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * dropdown error 여부
   */
  error: PropTypes.bool,
  /**
   * 수정하기 띄울지 여부
   */
  edit: PropTypes.bool,
};

Dropdown.defaultProps = {
  className: null,
  style: {},
  onClick: () => {},
  title: 'Title Empty',
  closeBtn: null,
  isOpen: false,
  children: 'Empty',
  error: false,
  edit: true,
};

export default Dropdown;
