import { useSearchPurposeQuery } from '@pages/Establish/EtcInfo/BusinessPurpose/queries';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';

const defaultValue = {
  purposeGroupsForGroupName: [],
  purposeGroupsForContent: [],
  purposes: [],
};

const useSearchPurpose = () => {
  const searchMob = useLocalObservable(() => ({
    keyword: '',
    searchKeyword: '',
    setKeyword: (keyword) => {
      searchMob.keyword = keyword;
    },
    search() {
      searchMob.searchKeyword = searchMob.keyword;
    },
    reset() {
      runInAction(() => {
        searchMob.keyword = '';
        searchMob.searchKeyword = '';
      });
    },
  }));

  const { data: searchResult } = useSearchPurposeQuery(searchMob.searchKeyword);

  return {
    searchResult: searchMob.searchKeyword && searchResult ? searchResult : defaultValue,
    searchMob,
  };
};

export default useSearchPurpose;
