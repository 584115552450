import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import { runInAction } from 'mobx';

class KoreanPersonVm {
  setKoreanName(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanName = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanAddress = e.target.value;
    });
  }
}

export default new KoreanPersonVm();
