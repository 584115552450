class StandbyFreeIssueOfNewSharesStepVm {
  sum(freeIssueOfNewSharesAllotmentInfos) {
    let sum = 0;
    freeIssueOfNewSharesAllotmentInfos?.forEach((item) => {
      sum = sum + item.newStockAmount;
    });
    return sum;
  }
}

export default new StandbyFreeIssueOfNewSharesStepVm();
