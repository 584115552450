import AddWhite from '@common/components/asset/icon/btn_add_white.svg';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import React, { useState } from 'react';

import GroupListItem from '../../../../ui/GroupListItem';
import st from './style.module.scss';

const Group = observer(({ purposeGroup = {}, defaultOpen = false, onClickAdd, isAdded }) => {
  const [openDropDown, setOpenDropDown] = useState(defaultOpen);

  const { groupNames = [], purposes = [] } = purposeGroup;

  return (
    <div className={st.container}>
      <div className={st.group}>
        <button type="button" onClick={onClickAdd} className={clsx(st.addButton, isAdded && st.added)}>
          <img src={isAdded ? '/images/체크표시.svg' : AddWhite} alt="선택 아이콘" className={st.icon} />
          {isAdded ? '선택' : '추가'}
        </button>
        <GroupListItem.GroupTitle
          groupNames={groupNames}
          contentLength={purposes.length}
          isDropDownOpened={openDropDown}
          onClickOpenDropDown={() => setOpenDropDown(!openDropDown)}
        />
      </div>
      {openDropDown && (
        <div className={st.dropDown}>
          {purposes.map((item, index) => (
            <React.Fragment key={item.recommendedPurposeId}>
              {index === 0 && <GroupListItem.Divider />}
              <GroupListItem.PurposeRow content={item.content} />
              <GroupListItem.Divider />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
});

export default Group;
