import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import ContentBox from '@pages/common/ContentBox/ContentBox';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import PurposeSearch from './components/PurposeSearch';
import SelectedPurpose from './components/SelectedPurpose';
import st from './style.module.scss';

const PurposeSelectContainer = observer(() => {
  return (
    <ContentBox className={clsx(Qst.spaceBetween, st.container)}>
      <PurposeSearch />
      <SelectedPurpose />
    </ContentBox>
  );
});

export default PurposeSelectContainer;
