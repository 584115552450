import { observable, runInAction } from 'mobx';

const BranchOfficeRelocateStepModel = observable({
  branchOffices: [],
  setBranchOfficeRelocate(data) {
    runInAction(() => {
      if (data.branchOffices && data.branchOffices.length) {
        this.branchOffices = data.branchOffices.map((branchOffice) => {
          let year = '',
            month = '',
            day = '';
          if (branchOffice.causeDate) {
            year = branchOffice.causeDate.substring(0, 4);
            month = branchOffice.causeDate.substring(5, 7);
            day = branchOffice.causeDate.substring(8, 10);
          }
          return {
            year: year || '',
            month: month || '',
            day: day || '',
            id: branchOffice.id || '',
            checkedYn: branchOffice.checkedYn ?? false,
            originAddress: branchOffice.originAddress || '',
            address: branchOffice.address || {},
            detailAddress: branchOffice.detailAddress || '',
            nameOfBuilding: branchOffice.nameOfBuilding || '',
            officeName: branchOffice.officeName || '',
          };
        });
      } else {
        this.branchOffices = [];
      }
    });
  },
});

export default BranchOfficeRelocateStepModel;
