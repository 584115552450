import { useObserver } from 'mobx-react';
import NewSharesAcquirer from './NewSharesAcquirer/NewSharesAcquirer';
import CapitalIncrease from './CapitalIncrease/CapitalIncrease';
import { Route } from 'react-router-dom';

function CapitalIncreaseStep() {
  return useObserver(() => (
    <>
      {/* 신주인수인 */}
      <Route
        path="/step/changeRegistration/CapitalIncreaseStep/NewSharesAcquirer"
        component={NewSharesAcquirer}
      ></Route>
      {/* 주식종류, 투자금 */}
      <Route path="/step/changeRegistration/CapitalIncreaseStep/CapitalIncrease" component={CapitalIncrease}></Route>
    </>
  ));
}

export default CapitalIncreaseStep;
