import StepBtn from '@comComponents/atoms/Button/StepBtn/StepBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import Loading from '@comComponents/atoms/Loading/Loading';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { useObserver } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';

import st from '../MinorDocumentUpload.module.scss';
import familyRelationshipCertificateVm from './familyRelationshipCertificateVm';

function FamilyRelationshipCertificate() {
  const [isHidden, setIsHidden] = useState(false);

  const onScroll = useCallback(() => {
    if (window.scrollY > 10) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, []);

  useEffect(() => {
    familyRelationshipCertificateVm.loadMinorShareholderDocuments();
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return useObserver(() => (
    <div>
      <header className={clsx(st.questionHeader, isHidden ? st.hidden : null)}>
        <div className={st.content}>
          <div className={st.leftBg}></div>
          <div className={st.rightBg}></div>
          <ContentBox>
            <div className={st.titleBox}>
              <h1 className={st.title}>
                <Icon icon="q" />
                <span className={st.text}>미성년자 서류 업로드 (2/2)</span>
              </h1>
              <div className={st.btnArea}>
                <StepBtn
                  step="pre"
                  size={isHidden ? 'small' : 'medium'}
                  onClick={() => familyRelationshipCertificateVm.pre()}
                  className={st.stepBtnPre}
                >
                  이전단계
                </StepBtn>
                <StepBtn
                  step="next"
                  size={isHidden ? 'small' : 'medium'}
                  onClick={() => familyRelationshipCertificateVm.upload()}
                  className={st.stepBtn}
                  disabled={familyRelationshipCertificateVm.state.submitBtnDisabledYn}
                >
                  업로드 후 완료
                </StepBtn>
              </div>
            </div>
          </ContentBox>
        </div>
      </header>
      <div className={clsx('mainContent', st.minorDocContent)} style={{ top: '177px' }}>
        <Question>
          {familyRelationshipCertificateVm.state.submitBtnDisabledYn && <Loading />}
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>
                    미성년 {familyRelationshipCertificateVm.name()}의 가족관계증명서(상세)
                  </span>
                  를 업로드해
                  <br />
                  주세요.
                </h2>
              </div>
              <FileUpload
                className={st.fileUpload}
                multiple
                accept=".pdf, .jpg, .png"
                model={familyRelationshipCertificateVm.familyRelationshipCertificate}
                uploadModel={familyRelationshipCertificateVm.familyRelationshipCertificateUploadFiles}
                deleteModel={familyRelationshipCertificateVm.familyRelationshipCertificateDeleteFiles}
                files={toJS(familyRelationshipCertificateVm.familyRelationshipCertificate)}
                uploadFiles={toJS(familyRelationshipCertificateVm.familyRelationshipCertificateUploadFiles)}
                explainText={
                  <span>
                    미성년자 <span className="bold">전원</span>의 서류를 업로드해 주세요.
                  </span>
                }
              >
                <span className={st.bold}>여기</span>를 눌러 가족관계증명서(상세) 를 업로드해 주세요.
              </FileUpload>
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="미성년자서류 업로드" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>미성년 {familyRelationshipCertificateVm.name()}의 서류는 어떻게 발급받나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      미성년 {familyRelationshipCertificateVm.name()}의 서류는 온라인(전자가족관계등록시스템) 또는
                      주민센터에서 발급 가능합니다.
                    </Answer>
                    <div className={Qst.explain}>
                      <Ul>
                        <li>
                          아래 [전자가족관계등록시스템] 링크 에서 미성년 {familyRelationshipCertificateVm.name()}{' '}
                          부모님의 공동인증서(구 공인인증서)로 로그인 후, 발급대상자를 "가족"으로 선택해서 미성년{' '}
                          {familyRelationshipCertificateVm.name()} 본인을 선택해 주세요.
                        </li>
                        <li>상세증명서, 주민등록번호 전부공개를 꼭 선택해 주세요.</li>
                      </Ul>
                    </div>
                    <a
                      className={st.aLink}
                      href="https://efamily.scourt.go.kr/index.jsp"
                      target="_blank"
                      title="전자가족관계등록시스템으로 이동"
                      rel="noreferrer"
                    />
                    <img
                      className={st.efamilyImg}
                      src="/images/efamily_example.png"
                      alt="가족관계등록부 열람/발급 신청 예시"
                    />
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      </div>
    </div>
  ));
}

export default FamilyRelationshipCertificate;
