import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './CompanyName.module.scss';
import clsx from 'clsx';
import Select from '@comComponents/atoms/Input/Select/Select';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import CompanyNameVm from './CompanyNameVm';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import regExp from '@common/module/regExp';
import AiChat from '@pages/Establish/AiChat/AiChat';

function CompanyName() {
  return useObserver(() => (
    <form
      onSubmit={(e) => {
        CompanyNameVm.submit(e);
      }}
    >
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>한글 회사이름을 입력</span>하고, <span className={Qst.bold}>중복확인</span>을
                해 주세요.
              </h2>
            </div>
            <div className={Qst.rowWrap}>
              <InputText
                className={clsx(
                  Qst.inputText,
                  CompanyNameVm.smartFormType !== 'ESTABLISHMENT_STOCK_COMPANY' &&
                    CompanyNameVm.smartFormType !== 'ESTABLISHMENT_LIMITED_COMPANY'
                    ? st.placeholder
                    : null,
                )}
                style={{ width: '443px' }}
                name="koreanName"
                value={CompanyNameVm.companyName.koreanName}
                onChange={CompanyNameVm.koreanName}
                explain="한글 회사이름"
                placeholder={`[${CompanyNameVm.text.formType}]문구는 제외하고 입력해 주세요.`}
                errorText={
                  <Error
                    name="koreanName"
                    value={CompanyNameVm.companyName.koreanName}
                    style={{ width: '540px' }}
                    errorCase={{
                      required: '회사이름을 입력해 주세요.',
                      validate: {
                        func: CompanyNameVm.validateFunc.includeYn,
                        message: CompanyNameVm.validateFunc.message,
                      },
                      pattern: {
                        value: regExp.onlyKorNum_noSpacing().test(CompanyNameVm.companyName.koreanName),
                        message: '한글 회사이름에는 한글, 한글+숫자만 사용 가능합니다. 띄어쓰기는 불가능합니다.',
                      },
                    }}
                  />
                }
              />
              <SquareBtn
                onClick={() => CompanyNameVm.companyNameCheck()}
                disabled={CompanyNameVm.state.companyNameDisabledYn}
              >
                중복확인
              </SquareBtn>
            </div>
            {CompanyNameVm.companyName.companyNameCheck === 'CANNOT_USE' && (
              <ErrorText>
                <span className="bold">관할에 같은 회사이름이 있어</span>서 사용 불가능 합니다.
                <br />
                다른 회사이름을 입력하고, 다시 중복확인을 해 주세요.
              </ErrorText>
            )}
            {(CompanyNameVm.companyName.companyNameCheck === 'SKIP' ||
              CompanyNameVm.companyName.companyNameCheck === 'CHECKED') && (
              <ErrorText style={{ color: '#006ac3' }}>
                인터넷등기소의 상호검색 서비스 제공시간이 아니어서, 현재 중복 확인이 어렵습니다. 저장 후 다음단계로
                진행하면, 내일 담당 매니저가 직접 확인 후 별도로 알려드리겠습니다.
              </ErrorText>
            )}
            {CompanyNameVm.companyName.companyNameCheck === 'CAN_USE' && (
              <ErrorText style={{ color: '#006ac3' }}>사용가능한 회사이름입니다.</ErrorText>
            )}
            {CompanyNameVm.companyName.companyNameCheck === 'API_ERROR' && (
              <ErrorText>
                인터넷등기소 통신오류로 인하여 중복확인이 어렵습니다.
                <br />
                다음 질문으로 넘어가주시면, 저희가 추후 검토하겠습니다.
              </ErrorText>
            )}
          </div>
          <div className={Qst.qnaSection}>
            <AiChat />
            <Qna tip="이름 설정방법" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>왜 중복확인을 해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    관할구역에 같은 회사이름이 있으면, 회사설립이 허용되지 않습니다.
                  </Answer>
                  <p className={Qst.explain}>
                    {CompanyNameVm.text.formType} 위치가 다르거나, 회사 종류가 다르거나, 영문 회사이름이 다르더라도,
                    한글 회사이름이 같으면 그 이름은 사용하기 어렵습니다.
                  </p>
                  <TextBox className={Qst.textBox} title="예시">
                    <div className={Qst.textWrap}>
                      <span className={Qst.true}>주식회사 헬프미</span>
                      <Icon icon="equal" className={Qst.iconEqual} />
                      <span className={Qst.true}>헬프미 주식회사</span>
                    </div>
                    <div className={Qst.textWrap}>
                      <span className={Qst.true}>주식회사 헬프미</span>
                      <Icon icon="equal" className={Qst.iconEqual} />
                      <span className={Qst.true}>유한회사 헬프미</span>
                    </div>
                    <div className={Qst.textWrap}>
                      <span className={Qst.true}>유한책임회사 헬프미</span>
                      <Icon icon="equal" className={Qst.iconEqual} />
                      <span className={Qst.true}>농업회사법인 헬프미</span>
                    </div>
                  </TextBox>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>영어와 한글을 띄어쓰기 섞어 쓰거나, 띄어쓰기를 할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    한글 회사이름에는 영어를 섞어 쓰거나 띄어쓰기를 할 수 없습니다.
                  </Answer>
                  <p className={Qst.explain}>특수기호도 사용할 수 없습니다. 숫자는 사용할 수 있습니다.</p>
                  <TextBox className={Qst.textBox} title="예시">
                    <div className={Qst.textWrap}>
                      <span>
                        <span className={Qst.false}>헬프미 21</span>
                        <Icon icon="arrow" className={Qst.iconArrow} />
                        <span className={Qst.true}>헬프미21</span>
                      </span>
                      <span style={{ marginLeft: '30px' }}>
                        <span className={Qst.false}>Pg코리아</span>
                        <Icon icon="arrow" className={Qst.iconArrow} />
                        <span className={Qst.true}>피지코리아</span>
                      </span>
                    </div>
                    <div className={Qst.textWrap}>
                      <span>
                        <span className={Qst.false}>사랑 &amp; 믿음</span>
                        <Icon icon="arrow" className={Qst.iconArrow} />
                        <span className={Qst.true}>사랑과믿음</span>
                      </span>
                    </div>
                  </TextBox>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>회사 이름은 자유롭게 정할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>네. 원칙적으로는 자유롭지만, 특수업종에서 제한이 있습니다.</Answer>
                  <dl className={Qst.defList}>
                    <dt className={Qst.term} style={{ marginTop: '12px' }}>
                      대부업
                    </dt>
                    <dd className={Qst.def}>
                      <div className={Qst.defExplain}>
                        사업목적에 대부업을 포함하는 경우, 회사 이름에도 [대부]라는 단어를 넣어야 하고, 사업목적에
                        대부중개업을 포함하는 경우, 회사 이름에도 [대부중개]라는 단어를 넣어야 합니다.
                      </div>
                      <div className={Qst.defExplain}>
                        만약 사업목적에 대부업, 대부중개업을 모두 포함한다면, [대부]라는 단어만 넣어주시면 됩니다.
                      </div>
                      <div className={Qst.defExplain}>
                        또한 대부, 대부중개업의 경우, 회사설립 후 대부업 등록을 꼭 해주세요.
                      </div>
                    </dd>
                    <dt className={Qst.term}>금융업</dt>
                    <dd className={Qst.def}>
                      [은행] [증권] [금융] [인베스트먼트] [신탁] [투자] 등의 명칭은 회사를 설립할 때 회사이름에 넣을 수
                      없습니다. 일단 다른 이름으로 설립을 한 후, 인가를 받은 후에 회사이름변경을 진행해 주세요.
                    </dd>
                    <dt className={Qst.term}>부동산중개</dt>
                    <dd className={Qst.def}>
                      [공인중개] [부동산중개] 라는 명칭을 회사이름에 넣으려면 부동산중개법인 설립요건을 갖추어야 합니다.
                    </dd>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://info.help-me.kr/hc/ko/articles/360029648612"
                      target="_blank"
                    >
                      부동산중개법인 요건 보러 가기
                    </LikeBtn>
                  </dl>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>[{CompanyNameVm.text.formType}] 문구는 어디에 붙일</span> 예정인가요?
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="prefixed"
                  value={true}
                  activeBackground
                  checked={CompanyNameVm.companyName.prefixed === true}
                  onChange={CompanyNameVm.prefixed}
                >
                  <span className="highlight">{CompanyNameVm.text.formType}</span>{' '}
                  {CompanyNameVm.companyName.koreanName || '가나다라'}{' '}
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="prefixed"
                  value={false}
                  activeBackground
                  checked={CompanyNameVm.companyName.prefixed === false}
                  onChange={CompanyNameVm.prefixed}
                >
                  {CompanyNameVm.companyName.koreanName || '가나다라'}{' '}
                  <span className="highlight">{CompanyNameVm.text.formType}</span>
                </Radio>
              </li>
            </ul>
            <Error
              name="prefixed"
              value={CompanyNameVm.companyName.prefixed}
              errorCase={{ required: '문구 위치를 선택해 주세요.' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna className={Qst.noTitle}>
              {CompanyNameVm.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' ||
              CompanyNameVm.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' ? (
                <QText>
                  [{CompanyNameVm.text.formType}] 문구 위치에 따른 차이가
                  <br />
                  있나요?
                </QText>
              ) : (
                <QText>[{CompanyNameVm.text.formType}] 문구 위치에 따른 차이가 있나요?</QText>
              )}
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  [{CompanyNameVm.text.formType}] 문구 위치에 따른 기능상 차이점은 없습니다.
                </Answer>
                <p className={Qst.explain}>
                  다만 80%의 회사는 [{CompanyNameVm.text.formType}]를 회사이름 앞에 붙입니다. 그리고 회사를 설립할 때,
                  등기부등본에는 [{CompanyNameVm.text.formType}]를 (주) (유)로 축약하거나 생략할 수는 없다는 점 주의해
                  주세요. 사업자등록증 에는 축약해서 넣을 수 있습니다. (생략은 불가능)
                </p>
                <p className={Qst.explain}>
                  또한 회사이름이 아주 긴 경우에는 [{CompanyNameVm.text.formType}]를 뒤에 붙이는 편을 추천드립니다. 추후
                  사업을 운영할 때, 인터넷뱅킹의 송금인 칸은 글자 숫자제한이 있어서, 주식회사를 앞에 붙이는 경우, 송금인
                  칸의 회사이름이 잘려서 보일 수 있기 때문입니다.
                </p>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>영문 회사이름도 추가</span>하시나요? (선택사항)
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="englishNameAddYn"
                  value={true}
                  activeBackground
                  checked={CompanyNameVm.companyName.englishNameAddYn === true}
                  onChange={CompanyNameVm.englishNameAddYn}
                >
                  네, 영문 회사이름을 추가합니다.
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="englishNameAddYn"
                  value={false}
                  activeBackground
                  checked={CompanyNameVm.companyName.englishNameAddYn === false}
                  onChange={CompanyNameVm.englishNameAddYn}
                >
                  아니오, 영문 회사이름을 추가하지 않습니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="englishNameAddYn"
              value={CompanyNameVm.companyName.englishNameAddYn}
              errorCase={{ required: '영문 회사이름 추가 여부를 선택해 주세요.' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna className={Qst.noTitle}>
              <QText>영문 회사 이름을 꼭 넣어야 하나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>영문 회사이름은 넣지 않아도 무방합니다.</Answer>
                <p className={Qst.explain}>
                  같은 관할구역에 같은 이름의 회사가 있는지 판단할 때에도 영문 회사이름은 고려하지 않습니다. 다만 설립
                  이후에 추가하는 경우, 회사이름변경등기 비용이 들기 때문에, 영문 회사이름을 추가할 계획이 있다면 설립할
                  때 함께 추가해 주세요.
                </p>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      {CompanyNameVm.companyName.englishNameAddYn === true && (
        <Question>
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>영문 회사이름을 입력</span>하고,{' '}
                  <span className={Qst.bold}>표기법을 선택</span>해 주세요.
                </h2>
                <div className={Qst.titleExplain}>
                  한글 회사이름의 발음을 그대로 옮겨주세요. (ex 헬프미 &rarr; help me)
                </div>
                <div className={Qst.titleExplain}>
                  한글 회사이름이 있는 표현을 삭제하거나, 없는 표현을 추가할 수 없습니다.
                </div>
              </div>
              <div className={Qst.rowWrap}>
                <InputText
                  className={Qst.inputText}
                  name="englishName"
                  style={{ width: '310px' }}
                  value={CompanyNameVm.companyName.englishName}
                  onChange={CompanyNameVm.englishName}
                  errorText={
                    <Error
                      name="englishName"
                      value={CompanyNameVm.companyName.englishName}
                      errorCase={{
                        required: '영문 회사이름을 입력해 주세요.',
                        pattern: {
                          value: regExp.onlyEnglish_symbol().test(CompanyNameVm.companyName.englishName),
                          message: '로마자(영문)로 입력해 주세요.',
                        },
                      }}
                    />
                  }
                />
                <Select
                  name="englishNotation"
                  style={{ width: '244px' }}
                  value={CompanyNameVm.companyName.englishNotation}
                  onChange={CompanyNameVm.englishNotation}
                  errorText={
                    <Error
                      name="englishNotation"
                      value={CompanyNameVm.companyName.englishNotation}
                      errorCase={{ required: '표기법을 선택해 주세요.' }}
                    />
                  }
                >
                  <option value="">표기법</option>
                  {CompanyNameVm.englishNotationSelectList.map((select, idx) => {
                    return (
                      <option key={idx} value={select.value}>
                        {select.text}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.noTitle}>
                <div className={Qst.qnaBox}>
                  <QText>영문 회사이름을 정할 때, 주의할 점이 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer} iconText="A1">
                      한글 회사이름의 발음을 그대로 옮겨서 적는 경우만 등록이 가능합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      해석상 의미가 통하는 영문 회사이름을 정하거나, 이니셜을 따서 영문 회사이름을 정하는 경우,
                      등기소에서 거절합니다.
                    </p>
                    <div className={st.textBoxWrap}>
                      <TextBox className={Qst.textBox} title="예시">
                        <div className={clsx(Qst.textWrap, st.flex)}>
                          <div>
                            <span className={clsx(Qst.normal, st.a1Width)}>산과바다</span>
                            <Icon icon="arrow" className={Qst.iconArrow} />
                            <span className={Qst.true}>Sangwabada</span>
                          </div>
                          <div className={st.falseWidth}>
                            <span className={Qst.false}>Mountain And Sea</span>
                          </div>
                        </div>
                        <div className={clsx(Qst.textWrap, st.flex)}>
                          <div>
                            <span className={clsx(Qst.normal, st.a1Width)}>에이앤비</span>
                            <Icon icon="arrow" className={Qst.iconArrow} />
                            <span className={Qst.true}>Aanb</span>
                          </div>
                          <div className={st.falseWidth}>
                            <span className={Qst.false}>Art And Business</span>
                          </div>
                        </div>
                        <div className={clsx(Qst.textWrap, st.flex)}>
                          <div>
                            <span className={clsx(Qst.normal, st.a1Width)}>한마음</span>
                            <Icon icon="arrow" className={Qst.iconArrow} />
                            <span className={Qst.true}>Hanmaum</span>
                          </div>
                          <div className={st.falseWidth}>
                            <span className={Qst.false}>HME</span>
                          </div>
                        </div>
                      </TextBox>
                      <div className={st.textBoxExplain}>
                        ※ 회사 종류, 업종표시는 한글 회사이름과 의미만 같아도 되지만, 등기관 판단에 따라 허용 여부가
                        다를 수 있어 추천드리지 않습니다.
                      </div>
                    </div>
                    <Answer className={clsx(Qst.answer, st.answer)} iconText="A2">
                      한글 회사이름에 있는 단어를 삭제하거나, 없는 단어를 추가하는 것은 불가능합니다.
                    </Answer>
                    <TextBox className={Qst.textBox} title="예시">
                      <div className={clsx(Qst.textWrap, st.flex)}>
                        <div>
                          <span className={clsx(Qst.normal, st.a2Width)}>에이비씨카드</span>
                          <Icon icon="arrow" className={Qst.iconArrow} />
                          <span className={Qst.true}>ABC Card</span>
                        </div>
                        <div className={st.falseWidth}>
                          <span className={clsx(Qst.false, st.falseTop)}>ABC</span>
                        </div>
                      </div>
                      <div className={clsx(Qst.textWrap, st.flex)}>
                        <div>
                          <span className={clsx(Qst.normal, st.a2Width)}>라라랩</span>
                          <Icon icon="arrow" className={Qst.iconArrow} />
                          <span className={Qst.true}>Lala Lab</span>
                        </div>
                        <div className={st.falseWidth}>
                          <span className={clsx(Qst.false, st.falseTop)}>LALA Design Lab</span>
                        </div>
                      </div>
                    </TextBox>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>띄어쓰기, 대소문자, 특수기호를 사용할 수 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      영문 회사이름은 띄어쓰기가 가능하고, 대소문자도 쓸 수 있습니다.
                    </Answer>
                    <p className={Qst.explain}>
                      특수기호는 앤드(&amp;) 아포스트로피(&#96;) 쉼표(&#44;) 대시(&#45;) 마침표(&#46;)
                      가운뎃점(&middot;)만 허용됩니다.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      )}
    </form>
  ));
}

export default CompanyName;
