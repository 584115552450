import { useObserver } from 'mobx-react';
import BranchOfficeDelete from './BranchOfficeDelete/BranchOfficeDelete';
import { Route } from 'react-router-dom';

function BranchOfficeDeleteStep() {
  return useObserver(() => (
    <>
      {/* 지점폐지 */}
      <Route
        path="/step/changeRegistration/BranchOfficeDeleteStep/BranchOfficeDelete"
        component={BranchOfficeDelete}
      ></Route>
    </>
  ));
}

export default BranchOfficeDeleteStep;
