import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';

function HeadOfficeRelocationStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>이전하는 주소</div>
        <div className={Cst.data}>{props.step.address || '-'}</div>
      </div>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>주소이전 일자</div>
        <div className={Cst.data}>{props?.step?.relocationDate || '-'}</div>
      </div>
    </>
  ));
}
export default HeadOfficeRelocationStep;
