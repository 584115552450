import EstablishmentVm from './EstablishmentVm';
import { useObserver } from 'mobx-react';
import EstablishStep from './EstablishStep/EstablishStep';
import QuestionHeader from '@commonComponents/QuestionHeader/QuestionHeader';
import { Route, withRouter } from 'react-router-dom';

function Establishment() {
  return useObserver(() =>
    EstablishmentVm.userToken && EstablishmentVm.smartformId ? (
      <>
        {EstablishmentVm.isStep && (
          <>
            {EstablishmentVm.isLoadData && <QuestionHeader />}
            <div className="mainContent">
              <Route path="/step/establish" component={EstablishStep}></Route>
            </div>
          </>
        )}
      </>
    ) : (
      <></>
    ),
  );
}
export default Establishment;
