import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import document from '../../../asset/icon/ico_document.svg';

const Btn = styled.button`
  position: relative;
  height: 58px;
  padding: 0 20px;
  background-color: #006ac3;
  border: none;
  border-radius: 2px;

  ${(props) =>
    props.addimage === 'document' &&
    `
    height: 49px;
    padding: 0 24px 0 45px;

    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 14px;
        top: 12px;
        background-image: url(${document});
        background-repeat: no-repeat;
        background-size: cover;
        width: 25px;
        height: 25px;
    }
    `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.45;
  }
`;

const Text = styled.span`
  position: relative;
  top: -1px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;

  ${(props) => props.addimage === 'document' && `font-size: 19px;`}
`;

function SquareBtn(props) {
  return (
    <Btn
      type={props.type}
      disabled={props.disabled}
      className={props.className}
      onClick={(e) => props.onClick(e)}
      style={props.style}
      addimage={props.addimage}
    >
      <Text addimage={props.addimage}>{props.children}</Text>
    </Btn>
  );
}

SquareBtn.propTypes = {
  /**
   * 버튼 타입
   */
  type: PropTypes.string,
  /**
   * 버튼 사용 여부
   */
  disabled: PropTypes.bool,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 이미지 삽입 <br/>
   * 삽입하는 이미지 이름 값
   */
  addimage: PropTypes.oneOf(['document']),
  /**
   * 버튼 텍스트 값
   */
  children: PropTypes.string,
};

SquareBtn.defaultProps = {
  type: 'button',
  disabled: false,
  className: null,
  onClick: () => {},
  style: {},
  addimage: null,
  children: 'Empty',
};

export default SquareBtn;
