import { runInAction, observable } from 'mobx';
import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import stepService from '@service/stepService';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import josa from '@common/module/josa';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';

class ConvertibleBondAcquirerVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  load() {
    if (ConvertibleBondStepModel.convertibleBondAcquirerInfos.length === 0) {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos.push(this.defaultValue);
      this.state.openIdx = 0;
    }
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        this.state.errorArr = [];
        this.state.openIdx = null;
        //올바를 데이터를 입력한 경우
        let param = formDataToJson(e.target).data;
        stepService.saveStep(param);
      }
    });
  }
  get defaultValue() {
    var val = {
      id: null,
      acquirerType: '',
      koreanName: '',
      koreanAddress: '',
      existingShareholdersYn: null,
      englishName: '',
      foreignAddress: '',
      nationalityOthers: '',
      nationalityType: '',
      residenceRegistrationYn: null,
      koreanCompanyRepresentatives: [
        {
          id: null,
          name: '',
          position: '',
        },
      ],
      foreignerCompanyRepresentatives: [
        {
          id: null,
          koreanName: '',
          englishName: '',
        },
      ],
      representatives: [
        {
          id: null,
          name: '',
        },
      ],
    };
    return val;
  }
  addConvertibleBond() {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos.push(this.defaultValue);
      this.state.openIdx = this.convertibleBondAcquirerInfos.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 255;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  get convertibleBondAcquirerInfos() {
    return ConvertibleBondStepModel.convertibleBondAcquirerInfos;
  }
  setTitle(idx) {
    const convertibleBondAcquirerInfo = ConvertibleBondStepModel.convertibleBondAcquirerInfos;
    let name = convertibleBondAcquirerInfo[idx].koreanName; //이름
    let national = convertibleBondAcquirerInfo[idx].acquirerType; //국적
    switch (national) {
      case 'KOREAN_PERSON':
        national = '한국인';
        break;
      case 'FOREIGNER_PERSON':
        national = '외국인';
        break;
      case 'KOREAN_COMPANY':
        national = '한국법인';
        break;
      case 'FOREIGNER_COMPANY':
        national = '외국법인';
        break;
      case 'FUND_AND_INVESTMENT_ASSOCIATION':
        national = '펀드/투자조합';
        break;
    }
    let title = '';
    if (!name && !national) {
      //이름, 국적 모두 없는 경우
      title = <span>전환사채 인수인(=투자자)님이 되실 분의 정보를 입력해 주세요.</span>;
    } else if (!name) {
      //이름 없는 경우
      title = '전환사채 인수인' + ' : ' + national;
    } else if (!national) {
      //국적 없는 경우
      title = name + ' : ' + '국적을 선택해 주세요';
    } else {
      title = name + ' : ' + national;
    }
    return title;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  async setCloseBtn(idx) {
    const convertibleBondAcquirerInfo = ConvertibleBondStepModel.convertibleBondAcquirerInfos;
    let name = convertibleBondAcquirerInfo[idx].koreanName;
    if (!name) {
      name = '해당 전환사채 인수인';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (convertibleBondAcquirerInfo.length === 1) {
      if (conf) {
        runInAction(() => {
          ConvertibleBondStepModel.convertibleBondAcquirerInfos = [];
          ConvertibleBondStepModel.convertibleBondAcquirerInfos.push(this.defaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return convertibleBondAcquirerInfo.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  setShareholderType(e, idx) {
    //사람(법인) 선택
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].acquirerType = e.target.value;
    });
  }
  displayKoreanName(type, name) {
    if (type === 'KOREAN_PERSON' || type === 'FOREIGNER_PERSON') {
      if (name) {
        return (
          <span>
            <span className={Qst.bold}>{name}님</span>은
          </span>
        );
      } else {
        return (
          <span>
            <span className={Qst.bold}>전환사채 인수인님</span>은
          </span>
        );
      }
    } else {
      if (name) {
        let _name = josa(name, '은는');
        let nameJosa = _name.charAt(_name.length - 1);
        return (
          <span>
            <span className={Qst.bold}>{name}</span>
            {nameJosa}
          </span>
        );
      } else {
        return (
          <span>
            <span className={Qst.bold}>전환사채 인수인님</span>은
          </span>
        );
      }
    }
  }
  setExistingShareholdersYn(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].existingShareholdersYn = e.target.value === 'true';
    });
  }
}

export default new ConvertibleBondAcquirerVm();
