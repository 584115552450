import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

/**
 *
 * @param {{popularPurposeGroups: Array, selectedRecommendedPurposesMob: import('../../hook/useSelectedRecommendedPurposes').SelectedPurposeManager}} param
 */
const useGroupSelect = ({ selectedRecommendedPurposesMob, popularPurposeGroups = [] }) => {
  // 선택할 수 있는 groups
  const groupSelectMob = useLocalObservable(() => ({
    /**
     * 선택가능한 그룹
     * key: groupNames.join(), value: { isSelected: boolean, purposeMap: Map<recommendedPurposeId: number, purpose: object> }
     */
    selectableGroupMap: new Map(),
    /**
     *
     * @param {Array} purposeGroups
     * @param {import('../../hook/useSelectedRecommendedPurposes').SelectedPurposeManager} selectedRecommendedPurposesMob
     */
    initSelectableGroups(purposeGroups = [], selectedRecommendedPurposesMob) {
      runInAction(() => {
        groupSelectMob.selectableGroupMap.clear();

        purposeGroups.forEach((group) => {
          const purposeMap = new Map();

          group.purposes.forEach((purpose) => {
            purposeMap.set(purpose.recommendedPurposeId, purpose);
          });

          groupSelectMob.selectableGroupMap.set(group.groupNames.join(), {
            isSelected: group.purposes.every((purpose) =>
              selectedRecommendedPurposesMob.checkSelected(purpose.recommendedPurposeId),
            ),
            purposeMap,
          });
        });
      });
    },
    checkGroupSelected(groupNames = []) {
      const group = groupSelectMob.selectableGroupMap.get(groupNames.join());

      return group ? group.isSelected : false;
    },
    selectGroup(groupNames = []) {
      runInAction(() => {
        const group = groupSelectMob.selectableGroupMap.get(groupNames.join());

        if (!group) {
          return;
        }

        group.isSelected = !group.isSelected;

        if (group.isSelected) {
          group.purposeMap.forEach((purpose) => {
            selectedRecommendedPurposesMob.selectPurpose(purpose);
          });
        } else {
          group.purposeMap.forEach((purpose) => {
            selectedRecommendedPurposesMob.unselectPurpose(purpose);
          });
        }
      });
    },
  }));

  useEffect(() => {
    groupSelectMob.initSelectableGroups(popularPurposeGroups, selectedRecommendedPurposesMob);
  }, [popularPurposeGroups, selectedRecommendedPurposesMob, selectedRecommendedPurposesMob.selectedPurposeMap.size]);

  return {
    groupSelectMob,
  };
};

export default useGroupSelect;
