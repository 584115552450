import smartformAppModel from '@model/smartformAppModel';
import { useObserver } from 'mobx-react';
import { Route } from 'react-router-dom';

import AgriculturalNoticeOfEstablishment from './AgriculturalNoticeOfEstablishment/AgriculturalNoticeOfEstablishment';
import BusinessPurpose from './BusinessPurpose';
import Certificate from './Certificate/Certificate';
import ChooseStamp from './ChooseStamp/ChooseStamp';
import FisheryNoticeOfEstablishment from './FisheryNoticeOfEstablishment/FisheryNoticeOfEstablishment';
import FreeLicenseRegistration from './FreeLicenseRegistration/FreeLicenseRegistration';
import Option from './Option/Option';

function EtcInfo() {
  return useObserver(() => (
    <>
      {/* 사업목적 */}
      <Route path="/step/establish/EtcInfo/BusinessPurpose" component={BusinessPurpose}></Route>
      {/* 도장선택 */}
      <Route path="/step/establish/EtcInfo/ChooseStamp" component={ChooseStamp}></Route>
      {/* 옵션 */}
      <Route path="/step/establish/EtcInfo/Option" component={Option}></Route>
      {/* 공인인증서 */}
      <Route path="/step/establish/EtcInfo/Certificate" component={Certificate}></Route>
      {/* 무료사업자등록 */}
      <Route path="/step/establish/EtcInfo/FreeLicenseRegistration" component={FreeLicenseRegistration}></Route>
      {/* 농업회사법인 설립통지 */}
      {smartformAppModel.smartFormType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' && (
        <Route
          path="/step/establish/EtcInfo/AgriculturalNoticeOfEstablishment"
          component={AgriculturalNoticeOfEstablishment}
        ></Route>
      )}
      {/* 어업회사법인 설립통지 */}
      {smartformAppModel.smartFormType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && (
        <Route
          path="/step/establish/EtcInfo/FisheryNoticeOfEstablishment"
          component={FisheryNoticeOfEstablishment}
        ></Route>
      )}
    </>
  ));
}

export default EtcInfo;
