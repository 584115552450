import StandbyParValueDivisionStepModel from '@model/changeRegistration/StandbyParValueDivisionStepModel';
import formDataToJson from '@common/module/submit';
import stepService from '@service/stepService';
import { runInAction } from 'mobx';

class StandbyParValueDivisionCheckVm {
  get parValueOnRegister() {
    return StandbyParValueDivisionStepModel.parValueOnRegister;
  }
  get newParValue() {
    return StandbyParValueDivisionStepModel.newParValue;
  }
  get keepGoingYn() {
    return StandbyParValueDivisionStepModel.keepGoingYn;
  }
  setKeepGoingYn(e) {
    runInAction(() => {
      StandbyParValueDivisionStepModel.keepGoingYn = e.target.value === 'true';
    });
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
}

export default new StandbyParValueDivisionCheckVm();
