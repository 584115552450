import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import q from '../../../asset/icon/question.svg';

const QuestionWrap = styled.div`
  display: inline-flex;
`;

const QuestionIcon = styled.div`
  display: inline-block;
  position: relative;
  top: 3px;
  padding: 0 8px;
  width: 23px;
  height: 20px;
  border-radius: 50px;
  background-color: #585a5f;
  background-image: url(${q});
  background-size: 9px 11px;
  background-repeat: no-repeat;
  background-position: left 7px top 5px;
`;

const Question = styled.div`
  display: inline-block;
  margin-left: 8px;
  color: #585a5f;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: -0.9px;
  line-height: 1.33;
`;

function QuestionText(props) {
  return (
    <QuestionWrap className={props.className}>
      <QuestionIcon />
      <Question style={props.style}>{props.children}</Question>
    </QuestionWrap>
  );
}

QuestionText.propTypes = {
  /**
   * class명 <br/>
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * question 텍스트
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.any]),
};

QuestionText.defaultProps = {
  className: null,
  style: {},
  children: 'Empty',
};

export default QuestionText;
