import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import st from './StockCompanySealStep.module.scss';
function StockCompanySealStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>소집통지 방법</div>
        <div className={Cst.data}>
          <span>{props.step.musterNoticeMethod}</span>
        </div>
      </div>
      {props.step.stockholderMeetingMusterNoticeType !== 'FOUR' && (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>주주총회일</div>
            <div className={Cst.data}>
              <span>{props.step.stockholderMeetingDate}</span>
            </div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              주주총회 개최
              <br />
              장소
            </div>
            <div className={Cst.data}>
              <span>{props.step.stockholderMeetingAtHeadOfficeYn ? '본점' : '본점에 가까운 장소'}</span>
              <span className={st.subContent}>주소 : {props.step.stockholderMeetingPlaceAddress}</span>
            </div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>온라인/우편</div>
            <div className={Cst.data}>
              <span>{props.step.musterNoticeSendMethod}</span>
            </div>
          </div>
        </>
      )}
      {(props.step.stockholderMeetingMusterNoticeType === 'ONE' ||
        props.step.stockholderMeetingMusterNoticeType === 'TWO') && (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              소집통지문
              <br />
              업로드
            </div>
            <div className={Cst.data}>
              {props.step.musterNoticeFiles.map((file, idx) => (
                <a className={st.download} href={file.url} key={idx} download>
                  {file.fileName}
                </a>
              ))}
            </div>
          </div>
        </>
      )}
      {props.step.stockholderMeetingMusterNoticeType === 'THREE' && (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>소집통지예정일</div>
            <div className={Cst.data}>
              <span>{props.step.musterNoticeDate}</span>
            </div>
          </div>
        </>
      )}
      {props.step.stockholderMeetingMusterNoticeType === 'FOUR' && (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              참석, 인감증명서
              <br />
              제공할 주주
            </div>
            <div className={Cst.data}>
              {props.step.stockholders.map((stockholder, idx) => (
                <span className={st.person} key={idx}>
                  {idx + 1}. {stockholder.name} ({stockholder.sealProviderType})
                </span>
              ))}
            </div>
          </div>
        </>
      )}
      {props.step.stockholderMeetingMusterNoticeType !== 'FOUR' && (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              참석, 인감증명서
              <br />
              제공할 주주
            </div>
            <div className={Cst.data}>
              <table className={st.table}>
                <colgroup>
                  <col />
                  <col width="250px" />
                  <col width="140px" />
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>성함</th>
                    <th style={{ textAlign: 'left' }}>구별</th>
                    <th style={{ textAlign: 'center' }}>인감증명서 제공여부</th>
                  </tr>
                </thead>
                <tbody>
                  {props.step.stockholders.map((stockholder, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: 'left' }}>{stockholder.name}</td>
                      <td style={{ textAlign: 'left' }}>{stockholder.sealProviderType}</td>
                      <td style={{ textAlign: 'center' }}>{stockholder.sealProvidingYn ? 'O' : 'X'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  ));
}
export default StockCompanySealStep;
