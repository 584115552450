import smartformAppModel from '@model/smartformAppModel';

class CapitalIncreaseStepVm {
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get limitCompanyYn() {
    //폼타입별 문구
    const smartFormType = this.smartFormType;
    if (smartFormType === 'LIMITED_COMPANY') {
      return true;
    } else {
      return false;
    }
  }
}

export default new CapitalIncreaseStepVm();
