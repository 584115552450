import PlusIcon from '@comComponents/asset/icon/btn_plus_white.svg';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import etcInfoModel from '@model/establish/etcInfoModel';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import Sortable from './components/Sortable';
import useSelectedPurpose from './hook/useSelectedPurpose';
import st from './style.module.scss';

const SelectedPurpose = observer(() => {
  const { selectedPurposeMob, onSortStart, onSortEnd, recommendPurposeCount, chooseOwnPurposeCount, removePurposes } =
    useSelectedPurpose();

  return (
    <div className={clsx(st.container)} data-has-after={etcInfoModel.businessPurposes.length < 9}>
      {etcInfoModel.businessPurposes.length > 0 ? (
        <div>
          <div className={st.checkHeader}>
            <div className={st.checkTitle}>선택하신 목적</div>
            <TextBtn onClick={() => removePurposes()} className={st.deleteBtn}>
              전체삭제
            </TextBtn>
          </div>

          <div className={st.subTitleWrapper}>
            <div className={st.purposeCount} style={{ color: '#00b4e3' }}>
              추천 목적 {recommendPurposeCount()}개
            </div>
            {chooseOwnPurposeCount() > 0 && (
              <>
                <div className={st.dot} />
                <div className={st.purposeCount} style={{ color: '#6c6c6c' }}>
                  직접입력 목적 {chooseOwnPurposeCount()}개
                </div>
              </>
            )}
          </div>
          <ul className={clsx(Qst.answerList, st.answerList)}>
            <Sortable.Container
              onSortStart={() => onSortStart()}
              onSortEnd={(e) => onSortEnd(e)}
              useDragHandle
              helperClass={st.helperClass}
            >
              {etcInfoModel.businessPurposes.map((businessPurpose, idx) => (
                <Sortable.Item
                  businessPurpose={businessPurpose}
                  index={idx}
                  idx={idx}
                  isDragging={selectedPurposeMob.isDragging}
                  key={idx}
                  length={etcInfoModel.businessPurposes.length}
                  sortYn={etcInfoModel.businessPurposes.length > 1}
                />
              ))}
              <li className={clsx(st.sortableItem, st.fixedText)}>
                <span className={st.number}>1.</span>위 각호에 관련된 부대사업 일체
              </li>
            </Sortable.Container>
          </ul>
        </div>
      ) : (
        <>
          <span className={st.title}>선택하신 목적</span>
          <span className={st.explainTitle}>(등기부에 들어갈 목적)</span>
          <div className={st.bodyContainer}>
            <div className={st.bodyText}>
              좌측의 [
              <span className={st.plusButton}>
                <img src={PlusIcon} className={st.buttonIcon} alt="plusIcon" />
                추가
              </span>
              ] 버튼을 클릭해 목적을 선택해 주세요.
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default SelectedPurpose;
