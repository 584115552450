import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4500;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Appear = keyframes`
from {
    opacity: 0;
    transform : translateY(20%);
    transform: scale(0.5);
}
to {
    opacity: 1;
    transform : translateY(0);
    transform: scale(1);
}
`;

const BoxWrap = styled.div`
  animation: ${Appear} 0.4s cubic-bezier(0.57, 0.11, 0.12, 1.18);
`;

const BoxContainer = styled.div`
  position: relative;
  padding: 25px 30px 85px;
  z-index: 5000;
  width: 440px;
  min-height: 168px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 8px 19px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
`;

const BtnWrap = styled.div`
  position: absolute;
  bottom: 25px;
  right: 30px;
  height: 38px;
`;

const Text = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 400;
  word-spacing: -1.65px;
  line-height: 1.42;
`;

const AlertBtn = styled(Button)`
  background-color: #006ac3;
  color: #fff;
`;

const ConfirmBtn = styled(Button)`
  margin-right: 9px;
  background-color: #006ac3;
  color: #fff;
`;

const CancelBtn = styled(Button)`
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: #006ac3;
`;

function Dialog(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  if (props.type) {
    return ReactDOM.createPortal(
      <Box>
        <BoxWrap>
          <BoxContainer>
            {props.type === 'alert' && (
              <>
                <Text dangerouslySetInnerHTML={{ __html: props.content.replace(/\n/g, '<br/>') }}></Text>
                <BtnWrap>
                  <AlertBtn onClick={props.btn?.alertBtn}>확인</AlertBtn>
                </BtnWrap>
              </>
            )}
            {props.type === 'confirm' && (
              <>
                <Text dangerouslySetInnerHTML={{ __html: props.content.replace(/\n/g, '<br/>') }}></Text>
                <BtnWrap>
                  <ConfirmBtn onClick={props.btn?.confirmBtn}>확인</ConfirmBtn>
                  <CancelBtn onClick={props.btn?.cancelBtn}>취소</CancelBtn>
                </BtnWrap>
              </>
            )}
          </BoxContainer>
        </BoxWrap>
      </Box>,
      document.getElementById('dialog'),
    );
  }

  return null;
}

Dialog.propTypes = {
  /**
   * dialog 속성_alert, confirm
   */
  type: PropTypes.string,
  /**
   * dialog text
   */
  content: PropTypes.any,
  /**
   * dialog 버튼_alertBtn, confirmBtn, cancelBtn
   */
  btn: PropTypes.object,
};

Dialog.defaultProps = {
  type: null,
  content: null,
  btn: {},
};

export default Dialog;
