import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import stepService from '@service/stepService';

class StockholdersMeetingMusterNoticeVm {
  direct(formData) {
    runInAction(() => {
      portalModel.setModalClose();
      stepService.saveStep(formData);
    });
  }
  service(formData) {
    runInAction(() => {
      window.open(`${process.env.REACT_APP_STANDBY_URL}auth/joinMembership`, '_blank');
      portalModel.setModalClose();
      stepService.saveStep(formData);
    });
  }
}

export default new StockholdersMeetingMusterNoticeVm();
