import etcInfoModel from '@model/establish/etcInfoModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { reaction, runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

/**
 * @typedef {Object} Purpose
 * @property {number} recommendedPurposeId - 추천 사업목적의 고유 ID입니다.
 * @property {string} content - 사업목적의 이름입니다.
 * @property {boolean} noteYn - 이 사업목적이 유의사항을 포함하는지 여부입니다.
 * @property {Object|null} note - 유의사항. `noteYn`가 `true`일 경우에만 존재하며, 그렇지 않으면 `null`입니다.
 * @property {string} note.minCapital
 * @property {string} note.cautions
 * @property {string} note.postRegistrationRequirements
 */

/**
 * @typedef {Object} SelectedPurposeManager
 * @property {Map<number, Purpose>} selectedPurposeMap - 선택된 사업목적을 저장하는 맵(Map)입니다.
 * @property {function(Purpose[]): void} initSelectedPurposes - 선택된 사업목적을 초기화하는 함수입니다.
 * @property {function(Purpose): boolean} checkSelected - 특정 사업목적 ID가 선택되었는지 확인하는 함수입니다.
 * @property {function(Purpose): void} selectPurpose - 사업목적을 선택하고 맵에 추가하는 함수입니다.
 * @property {function(Purpose): void} unselectPurpose - 선택된 사업목적을 해제하고 맵에서 제거하는 함수입니다.
 */

/**
 * 선택한 추천 목적들을 관리하는 mobx (직접입력은 etcInfoModel.BusinessPurposes로 직접 관리)
 * etcInfoModel.BusinessPurposes와 selectedPurposeMap을 동기화되어 있음.
 */
const useSelectedRecommendedPurposes = () => {
  /**
   * @type {SelectedPurposeManager}
   */
  const selectedRecommendedPurposesMob = useLocalObservable(() => ({
    selectedPurposeMap: new Map(),
    initSelectedPurposes(purposes = []) {
      runInAction(() => {
        selectedRecommendedPurposesMob.selectedPurposeMap.clear();

        purposes
          // 여기서는 선택된 추천 목적만 중요.
          .filter((purpose) => purpose.purposeType === purposeType.RECOMMENDED)
          .forEach((purpose) => {
            selectedRecommendedPurposesMob.selectedPurposeMap.set(Number(purpose.recommendedPurposeId), purpose);
          });
      });
    },
    checkSelected(recommendedPurposeId) {
      return selectedRecommendedPurposesMob.selectedPurposeMap.has(Number(recommendedPurposeId));
    },
    selectPurpose(purpose) {
      const purposes = etcInfoModel.businessPurposes;

      runInAction(() => {
        /**
         * 중복된 추천 목적 있으면 제외
         */
        if (
          purposes
            .filter((item) => item.purposeType === purposeType.RECOMMENDED)
            .some((item) => Number(item.recommendedPurposeId) === Number(purpose.recommendedPurposeId))
        ) {
          return;
        }

        purposes.push({ ...purpose, purposeType: purposeType.RECOMMENDED, noteYn: Boolean(purpose.note), id: null });
      });
    },
    unselectPurpose(purpose) {
      runInAction(() => {
        etcInfoModel.businessPurposes = etcInfoModel.businessPurposes.filter(
          (item) => Number(item.recommendedPurposeId) !== Number(purpose.recommendedPurposeId),
        );
      });
    },
  }));

  /**
   * 자동으로 etcInfoModel.BusinessPurposes와 selectedPurposeMap을 동기화한다.
   */
  useEffect(() => {
    if (selectedRecommendedPurposesMob.selectedPurposeMap.size === 0) {
      selectedRecommendedPurposesMob.initSelectedPurposes(etcInfoModel.businessPurposes);
    }

    const disposer = reaction(
      () => etcInfoModel.businessPurposes.map((item) => ({ ...item })),
      (businessPurposes) => {
        selectedRecommendedPurposesMob.initSelectedPurposes(businessPurposes);
      },
    );

    return () => disposer();
  }, []);

  return {
    selectedRecommendedPurposesMob,
  };
};

export default useSelectedRecommendedPurposes;
