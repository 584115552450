import { useObserver } from 'mobx-react';
import ConvertibleBondAcquirer from './ConvertibleBondAcquirer/ConvertibleBondAcquirer';
import ConvertibleBondAcquisitionAmount from './ConvertibleBondAcquisitionAmount/ConvertibleBondAcquisitionAmount';
import ConvertibleBondDepositProof from './ConvertibleBondDepositProof/ConvertibleBondDepositProof';
import { Route } from 'react-router-dom';

function ConvertibleBondStep() {
  return useObserver(() => (
    <>
      {/* 전환사채 발행 Page1 */}
      <Route
        path="/step/changeRegistration/ConvertibleBondStep/ConvertibleBondAcquirer"
        component={ConvertibleBondAcquirer}
      ></Route>
      {/* 전환사채 발행 Page2*/}
      <Route
        path="/step/changeRegistration/ConvertibleBondStep/ConvertibleBondAcquisitionAmount"
        component={ConvertibleBondAcquisitionAmount}
      ></Route>
      {/* 전환사채 발행 Page3*/}
      <Route
        path="/step/changeRegistration/ConvertibleBondStep/ConvertibleBondDepositProof"
        component={ConvertibleBondDepositProof}
      ></Route>
    </>
  ));
}

export default ConvertibleBondStep;
