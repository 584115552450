import portalModel from '@model/portalModel';
import { runInAction } from 'mobx';
import stepService from '@service/stepService';

class StockholdersMeetingMusterNoticeVm {
  direct(param) {
    runInAction(() => {
      portalModel.setModalClose();
      stepService.saveStep(param);
    });
  }
  service(param) {
    runInAction(() => {
      window.open(`${process.env.REACT_APP_STANDBY_URL}auth/joinMembership`, '_blank');
      portalModel.setModalClose();
      stepService.saveStep(param);
    });
  }
}

export default new StockholdersMeetingMusterNoticeVm();
