import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './StandByBoardMeetingInfoStep.module.scss';
import clsx from 'clsx';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function StandByBoardMeetingInfoStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>이사회 개최일</div>
        <div className={Ast.data}>{props?.step?.boardMeetingDate || '-'}</div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>의장</div>
        <div className={Ast.data}>{props?.step?.chairman || '-'}</div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>
          출석,찬성,인감증명서
          <br />
          제공할 이사
        </div>
        <div className={Ast.data}>
          <table className={st.table}>
            <colgroup>
              <col />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>성함</th>
                <th>구분</th>
                <th>출석여부</th>
                <th>찬성여부</th>
                <th>인감증명서 제공여부</th>
              </tr>
            </thead>
            <tbody>
              {props?.step?.directors.map((item, idx) => (
                <tr key={idx}>
                  <td style={{ textAlign: 'left' }}>
                    <CopyBtn onClick={() => copyText(item.name)}>{item.name}</CopyBtn>
                  </td>
                  <td>{item.executiveMemberType || '-'}</td>
                  <td>
                    {item.attendYn === null && '-'}
                    {item.attendYn && 'O'}
                    {item.attendYn === false && 'X'}
                  </td>
                  <td>
                    {item.agreeYn === null && '-'}
                    {item.agreeYn && 'O'}
                    {item.agreeYn === false && 'X'}
                  </td>
                  <td>
                    {item.sealProvidingYn === null && '-'}
                    {item.sealProvidingYn && 'O'}
                    {item.sealProvidingYn === false && 'X'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={st.explain}>기타 정보는 헬스 대시보드를 확인하세요.</div>
    </>
  ));
}
export default StandByBoardMeetingInfoStep;
