import { observable, runInAction } from 'mobx';

const BranchOfficeChangeStepModel = observable({
  branchOffices: [],
  setBranchOfficeChange(data) {
    runInAction(() => {
      if (data.branchOffices && data.branchOffices.length) {
        this.branchOffices = data.branchOffices.map((branchOffice, idx) => {
          return {
            id: branchOffice.id || '',
            address: branchOffice.address || '',
            changeYn: branchOffice.changeYn ?? false,
            changeNameYn: branchOffice.changeNameYn ?? null,
            officeName: branchOffice.officeName || '',
            causeDate: branchOffice.causeDate || {
              day: '',
              month: '',
              year: '',
            },
            managerChangeType: branchOffice.managerChangeType || '',
            originManager: {
              name: branchOffice.originManager?.name || '',
              englishName: branchOffice.originManager?.englishName || '',
              originAddress: branchOffice.originManager?.originAddress || '',
              kssn: branchOffice.originManager?.kssn || '',
              koreanYn: branchOffice.originManager?.koreanYn ?? null,
              nationalityType: branchOffice.originManager?.nationalityType || '',
              nationalityOthers: branchOffice.originManager?.nationalityOthers || '',
              birth: branchOffice.originManager?.birth || {
                day: '',
                month: '',
                year: '',
              },
              residenceRegistrationYn: branchOffice.originManager?.residenceRegistrationYn ?? null,
              address: branchOffice.originManager?.address || {},
              nameOfBuilding: branchOffice.originManager?.nameOfBuilding || '',
              detailAddress: branchOffice.originManager?.detailAddress || '',
              causeDate: branchOffice.originManager?.causeDate || {
                day: '',
                month: '',
                year: '',
              },
            },
            newManager: {
              name: branchOffice.newManager?.name || '',
              englishName: branchOffice.newManager?.englishName || '',
              kssn: branchOffice.newManager?.kssn || '',
              koreanYn: branchOffice.newManager?.koreanYn ?? null,
              nationalityOthers: branchOffice.newManager?.nationalityOthers || '',
              nationalityType: branchOffice.newManager?.nationalityType || '',
              birth: branchOffice.newManager?.birth || {
                day: '',
                month: '',
                year: '',
              },
              residenceRegistrationYn: branchOffice.newManager?.residenceRegistrationYn ?? null,
              address: branchOffice.newManager?.address || {},
              detailAddress: branchOffice.newManager?.detailAddress || '',
              nameOfBuilding: branchOffice.newManager?.nameOfBuilding || '',
              causeDate: branchOffice.newManager?.causeDate || {
                day: '',
                month: '',
                year: '',
              },
            },
          };
        });
      } else {
        this.branchOffices = [];
      }
    });
  },
});

export default BranchOfficeChangeStepModel;
