import StandbyFreeIssueOfNewSharesStepModel from '@model/changeRegistration/StandbyFreeIssueOfNewSharesStepModel';
import formDataToJson from '@common/module/submit';
import stepService from '@service/stepService';
import { runInAction } from 'mobx';

class StandbyFreeIssueOfNewSharesCheckVm {
  get capital() {
    return StandbyFreeIssueOfNewSharesStepModel.capital;
  }
  get freeIssueOfNewSharesCapital() {
    return StandbyFreeIssueOfNewSharesStepModel.freeIssueOfNewSharesCapital;
  }
  get freeIssueOfNewSharesType() {
    return StandbyFreeIssueOfNewSharesStepModel.freeIssueOfNewSharesType;
  }
  get keepGoingYn() {
    return StandbyFreeIssueOfNewSharesStepModel.keepGoingYn;
  }
  get standbyFreeIssueOfNewSharesAllotmentInfos() {
    return StandbyFreeIssueOfNewSharesStepModel.standbyFreeIssueOfNewSharesAllotmentInfos;
  }
  setKeepGoingYn(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.keepGoingYn = e.target.value === 'true';
    });
  }
  get sum() {
    let sum = 0;
    StandbyFreeIssueOfNewSharesStepModel.standbyFreeIssueOfNewSharesAllotmentInfos.forEach((item) => {
      sum = sum + item.newStockAmount;
    });
    return sum;
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
}

export default new StandbyFreeIssueOfNewSharesCheckVm();
