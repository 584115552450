export const 농업회사법인 = [
  { content: '농업경영업' },
  { content: '농산물의 출하, 유통, 가공, 판매, 수출업' },
  { content: '농작업 대행업' },
  { content: '농어촌관광휴양사업' },
  { content: '농촌융복합산업' },
  { content: '영농에 필요한 자재의 생산, 공급업' },
  { content: '영농에 필요한 종자생산 및 종균배양사업' },
  { content: '농산물의 구매, 비축사업' },
  { content: '농업기계 기타 장비의 임대, 수리, 보관업' },
  { content: '소규모 관개시설의 수탁, 관리사업' },
  { content: '농축산물의 식품제조 가공업' },
  { content: '농축산물의 통신판매업' },
];

export const 어업회사법인 = [
  { content: '어업경영업' },
  { content: '수산물의 출하, 유통, 가공, 판매, 수출업' },
  { content: '어촌관광휴양사업' },
  { content: '어촌융복합산업' },
  { content: '영어에 필요한 자재의 생산 및 공급사업' },
  { content: '영어에 필요한 종묘생산사업' },
  { content: '수산물의 구매 및 비축사업' },
  { content: '수산장비 등의 임대, 수리 및 부관사업' },
  { content: '수산물의 식품제조 가공업' },
  { content: '수산물의 통신판매업' },
];
