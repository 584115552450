import { useObserver } from 'mobx-react';
//import RepresentativeAddressChange from '../RepresentativeCheck/RepresentativeCheck';
import RepresentativeAddressChange from './RepresentativeAddressChange/RepresentativeAddressChange';
import { Route } from 'react-router-dom';

function RepresentativeAddressChangeStep() {
  return useObserver(() => (
    <>
      {/* 대표 주소, 주민번호 확인 */}
      <Route
        path="/step/changeRegistration/RepresentativeAddressChangeStep/RepresentativeAddressChange"
        component={RepresentativeAddressChange}
      ></Route>
    </>
  ));
}

export default RepresentativeAddressChangeStep;
