import capitalIncreaseStepModel from '@model/changeRegistration/capitalIncreaseStepModel';
import smartformAppModel from '@model/smartformAppModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import { addCommas, removeCommas, onlyNumber } from '@common/module/replaceNumber';

class ClassStockAndInvestmentVm {
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error === false) {
        let param = formDataToJson(e.target).data;
        param.newCapitals.forEach((newCapital) => {
          if (newCapital?.유상증자) {
            newCapital.유상증자.investment = removeCommas(newCapital.유상증자.investment);
            newCapital.유상증자.number = removeCommas(newCapital.유상증자.number);
          }
          if (newCapital?.가수금) {
            newCapital.가수금.investment = removeCommas(newCapital.가수금.investment);
            newCapital.가수금.number = removeCommas(newCapital.가수금.number);
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  get parValue() {
    //등기부상 출자 1좌의 금액
    return capitalIncreaseStepModel.capitalsOnRegister.parValue;
  }
  get totalCapital() {
    //등기부상 자본금
    return capitalIncreaseStepModel.capitalsOnRegister.totalCapital;
  }
  get newCapitals() {
    return capitalIncreaseStepModel.newCapitals;
  }
  //유상증자
  set유상증자Investment(e, index) {
    runInAction(() => {
      capitalIncreaseStepModel.newCapitals[index].유상증자.investment = onlyNumber(removeCommas(e.target.value));
    });
  }
  set유상증자Number(e, index) {
    runInAction(() => {
      capitalIncreaseStepModel.newCapitals[index].유상증자.number = onlyNumber(removeCommas(e.target.value));
    });
  }
  유상증자issuePrice(index) {
    const investment = capitalIncreaseStepModel.newCapitals[index].유상증자.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].유상증자.number;
    let issuePrice = 0;
    if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        issuePrice = investment / number;
        return issuePrice;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        issuePrice = (investment / number).toFixed(2);
        const priceList = issuePrice.split('.');
        return { issuePrice: priceList[0], point: priceList[1] };
      }
    }
    return issuePrice;
  }
  유상증자투자금ValidateFunc(index) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = capitalIncreaseStepModel.newCapitals[index].유상증자.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].유상증자.number;
    if (investment === 0) {
      _validation = false;
      _message = '투자금을 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '발행가액이 원단위로 나와야 합니다. 투자금 또는 출자좌수를 조정해주세요.';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  유상증자출자좌수ValidateFunc(index) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = capitalIncreaseStepModel.newCapitals[index].유상증자.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].유상증자.number;
    if (number === 0) {
      _validation = false;
      _message = '출자좌수를 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  //가수금증자
  set가수금Investment(e, index) {
    runInAction(() => {
      capitalIncreaseStepModel.newCapitals[index].가수금.investment = onlyNumber(removeCommas(e.target.value));
    });
  }
  set가수금Number(e, index) {
    runInAction(() => {
      capitalIncreaseStepModel.newCapitals[index].가수금.number = onlyNumber(removeCommas(e.target.value));
    });
  }
  가수금issuePrice(index) {
    const investment = capitalIncreaseStepModel.newCapitals[index].가수금.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].가수금.number;
    let issuePrice = 0;
    if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        issuePrice = investment / number;
        return issuePrice;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        issuePrice = (investment / number).toFixed(2);
        const priceList = issuePrice.split('.');
        return { issuePrice: priceList[0], point: priceList[1] };
      }
    }
    return issuePrice;
  }
  가수금투자금ValidateFunc(index) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = capitalIncreaseStepModel.newCapitals[index].가수금.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].가수금.number;
    if (investment === 0) {
      _validation = false;
      _message = '투자금을 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '발행가액이 원단위로 나와야 합니다. 투자금 또는 출자좌수를 조정해주세요.';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  가수금출자좌수ValidateFunc(index) {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const investment = capitalIncreaseStepModel.newCapitals[index].가수금.investment;
    const number = capitalIncreaseStepModel.newCapitals[index].가수금.number;
    if (number === 0) {
      _validation = false;
      _message = '출자좌수를 입력해 주세요.';
      _numberCheckYn = true;
    } else if (investment > 0 && number > 0) {
      //투자금, 주식수 모두 입력된 경우
      if (investment % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  investmentSum() {
    let 유상증자Investment = 0;
    let 가수금증자Investment = 0;
    let investmentSum = 0;
    this.newCapitals.forEach((newCapital, index) => {
      if (newCapital?.유상증자) {
        유상증자Investment = 유상증자Investment + Number(newCapital.유상증자.investment);
      }
      if (newCapital?.가수금) {
        가수금증자Investment = 가수금증자Investment + Number(newCapital.가수금.investment);
      }
    });
    investmentSum = 유상증자Investment + 가수금증자Investment;
    if (investmentSum) {
      return addCommas(investmentSum);
    } else {
      return investmentSum;
    }
  }
  numberSum() {
    let 유상증자Number = 0;
    let 가수금증자Number = 0;
    let numberSum = 0;
    this.newCapitals.forEach((newCapital, index) => {
      if (newCapital?.유상증자) {
        유상증자Number = 유상증자Number + Number(newCapital.유상증자.number);
      }
      if (newCapital?.가수금) {
        가수금증자Number = 가수금증자Number + Number(newCapital.가수금.number);
      }
    });
    numberSum = 유상증자Number + 가수금증자Number;
    if (numberSum) {
      return addCommas(numberSum);
    } else {
      return numberSum;
    }
  }
  setInvestment(e, index) {
    runInAction(() => {
      capitalIncreaseStepModel.newCapitals[index].일반_자본금_증액.investment = onlyNumber(
        removeCommas(e.target.value),
      );
    });
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get limitCompanyYn() {
    //폼타입별 문구
    const smartFormType = this.smartFormType;
    if (smartFormType === 'LIMITED_COMPANY') {
      return true;
    } else {
      return false;
    }
  }
}
export default new ClassStockAndInvestmentVm();
