import clsx from 'clsx';

// import st from './style.module.scss';

/**
 * @description 특정 문자열을 하이라이트하여 스타일링하는 컴포넌트
 * @param {Object} props - 컴포넌트 속성
 * @param {string | undefined} props.className - 전체 문자 class
 * @param {string} props.children - 전체 문자열
 * @param {string | undefined} props.highlight - 하이라이팅할 문자열
 * @param {string} props.highlightClassname - 하이라이팅 문자 class
 */
const HighlightTypography = ({ className, children = '', highlight, highlightClassname }) => {
  if (!highlight || typeof children !== 'string') return <span className={className}>{children}</span>;

  // 공백 제거한 하이라이트 문자열 생성
  const normalizedHighlight = highlight.replace(/\s+/g, '').toLowerCase();

  // children 문자열을 한 글자씩 나누어서 하이라이팅 여부를 판단
  const parts = children.split('').map((char) => ({
    char,
    isHighlight: normalizedHighlight.includes(char.toLowerCase()),
  }));

  return (
    <span className={className}>
      {parts.map((part, index) =>
        part.isHighlight ? (
          <span key={index} className={clsx(highlightClassname)}>
            {part.char}
          </span>
        ) : (
          <span key={index}>{part.char}</span>
        ),
      )}
    </span>
  );
};

export default HighlightTypography;
