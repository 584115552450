import st from './QuestionContent.module.scss';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';

function QusetionContent(props) {
  return useObserver(() => (
    <div
      className={props.list === true ? clsx(st.QusetionContent, st.ListQusetionContent) : st.QusetionContent}
      style={props.style}
    >
      {props.children}
    </div>
  ));
}

export default QusetionContent;
