import { useObserver } from 'mobx-react';
import Liquidation from './Liquidation/Liquidation';
import { Route } from 'react-router-dom';

function LiquidationStep() {
  return useObserver(() => (
    <>
      {/* 법인해산, 청산 */}
      <Route path="/step/changeRegistration/LiquidationStep/Liquidation" component={Liquidation}></Route>
    </>
  ));
}

export default LiquidationStep;
