import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import ExecutiveMemberChangeStepVm from './ExecutiveMemberChangeStepVm';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './ExecutiveMemberChangeStep.module.scss';
import clsx from 'clsx';
import Icon from '@comComponents/atoms/Icon/Icon';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function ExecutiveMemberChangeStep(props) {
  return useObserver(() => (
    <>
      <div className={Ast.row}>
        {props.step.standbyFormYn ? (
          <div className={Ast.dName}>
            스탠바이 입력내용
            <br />
            일치여부
          </div>
        ) : (
          <div className={Ast.dName}>
            견적대로
            <br />
            진행여부
          </div>
        )}
        {props?.step?.keepProceedYn === null && <div className={Ast.data}>-</div>}
        {props?.step?.keepProceedYn === true && <div className={Ast.data}>네</div>}
        {props?.step?.keepProceedYn === false && (
          <div className={Ast.data} style={{ color: 'red' }}>
            아니오
          </div>
        )}
      </div>
      <div className={Ast.row}>
        <div className={Ast.dName}>
          등기 후<br />
          임원 숫자
        </div>
        <div className={Ast.data}>
          <table className={st.executiveCntTable}>
            <thead>
              <tr>
                {props?.step?.executiveMemberCount.map((executiveCnt, idx) => {
                  return <th key={idx}>{executiveCnt.position}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {props?.step?.executiveMemberCount.map((executiveCnt, idx) => {
                  return <td key={idx}>{executiveCnt.count}</td>;
                })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={Ast.row} style={{ alignItems: 'flex-start', marginTop: '20px' }}>
        <div className={Ast.dName}>기존 임원</div>
        <div className={Ast.data}>
          <table className={st.executiveTable}>
            <colgroup>
              <col width="192px" />
              <col width="135px" />
              <col width="135px" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>기존 직책, 이름</th>
                <th>등기 변경사항</th>
                <th>주민번호</th>
                <th>주소</th>
              </tr>
            </thead>
            <tbody>
              {props?.step?.executiveMembers.map((executive, idx) => {
                if (executive.changeType === '사임_퇴임') {
                  executive.changeType = '사임/퇴임';
                }
                return (
                  <Fragment key={idx}>
                    {executive.newYn === false && (
                      <>
                        <tr className={executive.changeType === '변경없음' ? st.notChange : null}>
                          <td>
                            <div className={st.position}>{executive.originPosition}</div>
                            <div className={st.executiveInfo}>
                              <div className={st.koreanName}>
                                <CopyBtn
                                  onClick={() => copyText(executive.koreanName)}
                                  className={st.addressCopyBtn}
                                  style={{ textAlign: 'left' }}
                                >
                                  {executive.koreanName}
                                </CopyBtn>
                              </div>
                              {executive.englishName && (
                                <div className={st.foreign}>
                                  <div>{executive.englishName}</div>
                                  <div>{executive.nationality}</div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>{executive.changeType}</div>
                            {executive.changeType === '보직변경' && (
                              <div className={st.newPosition}>
                                <CopyBtn onClick={() => copyText(executive.newPosition)} className={st.addressCopyBtn}>
                                  {executive.newPosition}
                                </CopyBtn>
                              </div>
                            )}
                            {/* {executive.addressCauseDate &&
                                                        <div className={st.addressCauseDate}>
                                                            전입신고 일자 : <CopyBtn onClick={() => copyText(executive.addressCauseDate)} className={st.addressCopyBtn}>{executive.addressCauseDate}</CopyBtn>
                                                        </div>
                                                    } */}
                            {executive.changedInPage4 && <div className={st.pageAlert}>page4에서 입력</div>}
                          </td>
                          <td>
                            {executive.birth ? (
                              <CopyBtn onClick={() => copyText(executive.birth)} className={st.addressCopyBtn}>
                                {executive.birth}
                              </CopyBtn>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            <div>
                              {executive.addressSameYn !== null ? (
                                <>
                                  {executive.addressSameYn && '변경 X'}
                                  {executive.addressSameYn === false && '변경 O'}
                                </>
                              ) : (
                                '-'
                              )}
                            </div>
                            <div>
                              {executive?.newAddresses && executive?.newAddresses.length !== 0 && (
                                <>
                                  {executive.newAddresses.map((item, idx) => {
                                    return (
                                      <>
                                        <div>
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.sido)}
                                            className={Ast.addressCopyBtn}
                                          >
                                            {item?.newAddress?.sido}
                                          </CopyBtn>
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.sigungu)}
                                            className={Ast.addressCopyBtn}
                                          >
                                            {item?.newAddress?.sigungu}
                                          </CopyBtn>
                                          {item?.newAddress?.bname1 && (
                                            <CopyBtn
                                              onClick={() => copyText(item?.newAddress?.bname1)}
                                              className={Ast.addressCopyBtn}
                                            >
                                              {item?.newAddress?.bname1}
                                            </CopyBtn>
                                          )}
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.roadname)}
                                            className={Ast.addressCopyBtn}
                                          >
                                            {item?.newAddress?.roadname}
                                          </CopyBtn>
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.buildingNo)}
                                            className={Ast.addressCopyBtn}
                                          >
                                            {item?.newAddress?.buildingNo}
                                          </CopyBtn>
                                          ,
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.detailAddress)}
                                            className={Ast.addressCopyBtn}
                                          >
                                            {item?.newAddress?.detailAddress}
                                          </CopyBtn>
                                          <div className={Ast.addressCopyBracket}>
                                            (
                                            <CopyBtn
                                              onClick={() => copyText(item?.newAddress?.bname)}
                                              className={Ast.addressCopyBtn}
                                            >
                                              {item?.newAddress?.bname}
                                            </CopyBtn>
                                            ,
                                            <CopyBtn
                                              onClick={() => copyText(item?.newAddress?.nameOfBuilding)}
                                              className={Ast.addressCopyBtn}
                                            >
                                              {item?.newAddress?.nameOfBuilding}
                                            </CopyBtn>
                                            )
                                          </div>
                                          <CopyBtn
                                            onClick={() => copyText(item?.newAddress?.fullAddress)}
                                            className={Ast.addressCopyAllBtn}
                                          >
                                            <Icon icon="copy" className={Ast.copyIcon} />
                                          </CopyBtn>
                                        </div>
                                        {item.addressCauseDate && (
                                          <div style={{ marginTop: '2px' }}>
                                            {executive.englishName ? '주소변경 일자 : ' : '전입신고 일자 : '}
                                            <CopyBtn
                                              onClick={() => copyText(item.addressCauseDate)}
                                              className={st.addressCopyBtn}
                                            >
                                              {item.addressCauseDate}
                                            </CopyBtn>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className={Ast.row} style={{ alignItems: 'flex-start', marginTop: '20px' }}>
        <div className={Ast.dName}>신규 임원</div>
        <div className={Ast.data}>
          <table className={st.executiveTable}>
            <colgroup>
              <col width="192px" />
              <col width="135px" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>직책, 이름</th>
                <th>주민번호</th>
                <th>주소</th>
              </tr>
            </thead>
            <tbody>
              {props?.step?.executiveMembers.map((executive, idx) => {
                return (
                  <Fragment key={idx}>
                    {executive.newYn && (
                      <>
                        <tr>
                          <td>
                            <div className={st.position}>{executive.newPosition}</div>
                            <div className={st.executiveInfo}>
                              <div className={st.koreanName}>
                                <CopyBtn
                                  onClick={() => copyText(executive.koreanName)}
                                  className={st.addressCopyBtn}
                                  style={{ textAlign: 'left' }}
                                >
                                  {executive.koreanName}
                                </CopyBtn>
                              </div>
                              {executive.englishName && (
                                <div className={st.foreign}>
                                  <div>{executive.englishName}</div>
                                  <div>{executive.nationality}</div>
                                </div>
                              )}
                            </div>
                          </td>
                          <td>
                            {executive.birth ? (
                              <CopyBtn onClick={() => copyText(executive.birth)} className={st.addressCopyBtn}>
                                {executive.birth}
                              </CopyBtn>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td>
                            {executive?.newAddresses.length !== 0 && executive?.newAddresses[0]?.newAddress !== null ? (
                              <>
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.sido)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {executive?.newAddresses[0]?.newAddress?.sido}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.sigungu)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {executive?.newAddresses[0]?.newAddress?.sigungu}
                                </CopyBtn>
                                {executive?.newAddresses[0]?.newAddress?.bname1 && (
                                  <CopyBtn
                                    onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.bname1)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {executive?.newAddresses[0]?.newAddress?.bname1}
                                  </CopyBtn>
                                )}
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.roadname)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {executive?.newAddresses[0]?.newAddress?.roadname}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.buildingNo)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {executive?.newAddresses[0]?.newAddress?.buildingNo}
                                </CopyBtn>
                                ,
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.detailAddress)}
                                  className={Ast.addressCopyBtn}
                                >
                                  {executive?.newAddresses[0]?.newAddress?.detailAddress}
                                </CopyBtn>
                                <div className={Ast.addressCopyBracket}>
                                  (
                                  <CopyBtn
                                    onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.bname)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {executive?.newAddresses[0]?.newAddress?.bname}
                                  </CopyBtn>
                                  ,
                                  <CopyBtn
                                    onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.nameOfBuilding)}
                                    className={Ast.addressCopyBtn}
                                  >
                                    {executive?.newAddresses[0]?.newAddress?.nameOfBuilding}
                                  </CopyBtn>
                                  )
                                </div>
                                <CopyBtn
                                  onClick={() => copyText(executive?.newAddresses[0]?.newAddress?.fullAddress)}
                                  className={Ast.addressCopyAllBtn}
                                >
                                  <Icon icon="copy" className={Ast.copyIcon} />
                                </CopyBtn>
                              </>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ));
}

export default ExecutiveMemberChangeStep;
