import { useObserver } from 'mobx-react';
import NumOfSharesToBeIssued from './NumOfSharesToBeIssued/NumOfSharesToBeIssued';
import { Route } from 'react-router-dom';

function NumOfSharesToBeIssuedStep() {
  return useObserver(() => (
    <>
      {/* 발행예정주식수 */}
      <Route
        path="/step/changeRegistration/NumOfSharesToBeIssuedStep/NumOfSharesToBeIssued"
        component={NumOfSharesToBeIssued}
      ></Route>
    </>
  ));
}

export default NumOfSharesToBeIssuedStep;
