import { useObserver } from 'mobx-react';
import RepresentativeCheck from '../../RepresentativeCheck/RepresentativeCheck';

function RepresentativeAddressChange() {
  return useObserver(() => (
    <>
      {/* 대표 주소, 주민번호 확인 */}
      <RepresentativeCheck />
    </>
  ));
}

export default RepresentativeAddressChange;
