import clsx from 'clsx';
import { observer } from 'mobx-react';

import CheckBox from '../../../../ui/CheckBox';
import GroupListItem from '../../../../ui/GroupListItem';
import st from './style.module.scss';

const Group = observer(({ groupNames = [], purposes = [], onClick, isSelected = false, isExpanded = false }) => {
  return (
    <div className={clsx(st.container, isSelected && st.selected)} onClick={onClick}>
      <div className={st.titleWrap}>
        <CheckBox checked={isSelected} onChange={onClick} />
        <GroupListItem.GroupTitle groupNames={groupNames} contentLength={purposes.length} useDropDown={false} />
      </div>
      {isExpanded && (
        <div className={st.purposesWrap}>
          {purposes.map((item) => (
            <GroupListItem.PurposeRow key={item.recommendedPurposeId} content={item.content} className={st.purpose} />
          ))}
        </div>
      )}
    </div>
  );
});

export default Group;
