import st from './AiChat.module.scss';
import useAiChat from './hook/useAiChat';

const AiChat = ({ showTitle = true }) => {
  const { keyword, handleInputChange, onClickSearchButton } = useAiChat();

  return (
    <div className={st.container}>
      {showTitle && <h2 className={st.title}>실시간 TIP 검색</h2>}
      <div className={st.explain}>헬프미의 방대한 등기 정보를 학습한 AI의 도움을 실시간으로 받아보세요!</div>
      <div className={st.searchWrapper}>
        <input
          className={st.input}
          type="text"
          placeholder="검색어를 입력해주세요."
          value={keyword}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              e.stopPropagation();
              e.preventDefault();
              onClickSearchButton();
            }
          }}
        />
        <button className={st.button} type="button" onClick={onClickSearchButton}>
          검색하기
        </button>
      </div>
    </div>
  );
};

export default AiChat;
