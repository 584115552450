import st from './ResidenceRegistrationModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title} style={{ width: '370px' }}>
        <span className={st.bold}>거소등록(외국인등록)한 분</span>의 정보를 입력해 주세요.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>
          고객님! 현재 청산인에 대한 정보를 입력하고 계신데, 거소등록(외국인등록)하지 않은 외국인을 청산인으로 등록하는
          경우, 서비스 제공이 어렵습니다. 이 경우 아포스티유 인증 등이 필요하고 절차가 상당히 복잡하기 때문입니다. 양해
          부탁드립니다.
        </p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
