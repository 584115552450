import st from './ContentBox.module.scss';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
function ContentBox(props) {
  return useObserver(() => (
    <div id={props?.id || null} className={clsx(st.contentBox, props.className)} style={props.style}>
      {props.children}
    </div>
  ));
}
ContentBox.defaultProps = {
  className: null,
  children: 'empty',
};
export default ContentBox;
