import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import { runInAction } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';

class FundAndInvestmentAssociationVm {
  setKoreanName(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanName = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanAddress = e.target.value;
    });
  }
  setRepresentativesName(e, idx, idx2) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].representatives[idx2].name = e.target.value;
    });
  }
  addRepresentatives(idx) {
    ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].representatives.push({
      id: '',
      name: '',
    });
  }
  async setRepresentativesCloseBtn(idx, idx2) {
    const representatives = ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].representatives;
    let name = representatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return representatives.splice(idx2, 1);
    } else {
      return;
    }
  }
}

export default new FundAndInvestmentAssociationVm();
