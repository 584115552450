import { useObserver } from 'mobx-react';
import React, { useState, useEffect, useCallback } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import StepBtn from '@comComponents/atoms/Button/StepBtn/StepBtn';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import clsx from 'clsx';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../AgriculturalFisheryDocumentUpload.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import agriculturalVm from './agriculturalVm';
import FileUpload from '@comComponents/molecules/FileUpload/FileUpload';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import { toJS } from 'mobx';
import Loading from '@comComponents/atoms/Loading/Loading';

function Agricultural() {
  const [isHidden, setIsHidden] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const onScroll = useCallback(() => {
    if (window.scrollY > 10) {
      setIsHidden(true);
    } else {
      setIsHidden(false);
    }
  }, []);
  useEffect(() => {
    agriculturalVm.loadAgriculturalFisheryDocuments();
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return useObserver(() => (
    <div>
      <header className={clsx(st.questionHeader, isHidden ? st.hidden : null)}>
        <div className={st.content}>
          <div className={st.leftBg}></div>
          <div className={st.rightBg}></div>
          <ContentBox>
            <div className={st.titleBox}>
              <h1 className={st.title}>
                <Icon icon="q" />
                <span className={st.text}>농업회사법인 서류 업로드</span>
              </h1>
              <div className={st.btnArea}>
                <StepBtn
                  step="next"
                  size={isHidden ? 'small' : 'medium'}
                  onClick={() => agriculturalVm.upload()}
                  className={st.stepBtn}
                  disabled={agriculturalVm.state.submitBtnDisabledYn}
                >
                  업로드 후 완료
                </StepBtn>
              </div>
            </div>
          </ContentBox>
        </div>
      </header>
      <div className={clsx('mainContent', st.agriculturalFishery)} style={{ top: '177px' }}>
        <Question>
          {agriculturalVm.state.submitBtnDisabledYn && <Loading />}
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection} style={{ width: '560px' }}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  주주 <span className={Qst.bold}>전원</span>의{' '}
                  <span className={Qst.bold}>농업인확인서 또는 농업경영체등록확인서</span>를 업로드해 주세요.
                </h2>
              </div>
              <FileUpload
                className={st.fileUpload}
                multiple
                accept=".pdf, .jpg, .png"
                model={agriculturalVm.documentsFiles}
                uploadModel={agriculturalVm.documentsUploadFiles}
                deleteModel={agriculturalVm.documentsDeleteFiles}
                files={toJS(agriculturalVm.documentsFiles)}
                uploadFiles={toJS(agriculturalVm.documentsUploadFiles)}
                explainText={
                  <span>
                    주주 <span className="bold">전원</span>의 <span className="bold">농업인 서류</span>를 업로드해
                    주세요.
                  </span>
                }
              >
                <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
              </FileUpload>
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="농업회사법인 서류 업로드" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>왜 모든 주주들의 농업인확인서 또는 농업경영체등록확인서가 필요한가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      농업회사법인 설립 시 농업인만 주주가 될 수 있어서 증빙을 위해 필요합니다.
                    </Answer>
                    <div className={Qst.explain}>
                      모든 주주들의 농업확인서 또는 농업경영체등록확인서가 꼭 필요하다는 점을 기억해 주세요. 만약 주주가
                      되려는 분이 농업인이 아니라면, 일단 농업인들만 참여해서 법인을 설립한 후, 지분양도절차(전체 90%
                      한도)를 진행해 주시면 됩니다.
                    </div>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://www.110.go.kr/data/faqView.do?num=62034&curPage=4&scType=&scText="
                      target="_blank"
                    >
                      농업경영체등록확인서 발급방법 자세히 보기
                    </LikeBtn>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      </div>
    </div>
  ));
}

export default Agricultural;
