import { useObserver } from 'mobx-react';
import StandbyParValueDivisionCheck from './StandbyParValueDivisionCheck/StandbyParValueDivisionCheck';
import StandbyParValueDivision from './StandbyParValueDivision/StandbyParValueDivision';
import { Route } from 'react-router-dom';

function StandbyParValueDivisionStep() {
  return useObserver(() => (
    <>
      {/* 스탠바이 액면금분할 */}
      <Route
        path="/step/changeRegistration/StandbyParValueDivisionStep/StandbyParValueDivisionCheck"
        component={StandbyParValueDivisionCheck}
      ></Route>
      {/* 스탠바이 액면금분할 - 아니오로 진행할 경우 */}
      <Route
        path="/step/changeRegistration/StandbyParValueDivisionStep/StandbyParValueDivision"
        component={StandbyParValueDivision}
      ></Route>
    </>
  ));
}

export default StandbyParValueDivisionStep;
