import BranchOfficeRelocateStepModel from '@model/changeRegistration/BranchOfficeRelocateStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import regExp from '@common/module/regExp';

class BranchOfficeRelocateVm {
  checkedYnError() {
    let error = false;
    let checkCnt = 0;
    BranchOfficeRelocateStepModel.branchOffices.forEach((branchOffice, idx) => {
      if (branchOffice.checkedYn) checkCnt++;
    });
    if (checkCnt === 0) {
      error = true;
    }
    return error;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (this.checkedYnError() === false && formDataToJson(e.target).error === false) {
        let param = formDataToJson(e.target).data;
        param.branchOffices.map((branchOffice, idx) => {
          if (branchOffice.checkedYn) {
            if (branchOffice.address) {
              param.branchOffices[idx].address = BranchOfficeRelocateStepModel.branchOffices[idx].address;
            }
            param.branchOffices[idx].causeDate =
              branchOffice.year + '년' + branchOffice.month + '월' + branchOffice.day + '일';
            delete branchOffice.year;
            delete branchOffice.month;
            delete branchOffice.day;
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  get branchOffices() {
    return BranchOfficeRelocateStepModel.branchOffices;
  }
  setCheckAll(_value) {
    let value;
    if (typeof _value === 'boolean') {
      value = _value;
    } else {
      value = _value.target.checked;
    }
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices.forEach((branchOffice, idx) => {
        branchOffice.checkedYn = value;
      });
    });
  }
  get checkAll() {
    let checked = true;
    if (BranchOfficeRelocateStepModel.branchOffices.length) {
      BranchOfficeRelocateStepModel.branchOffices.forEach((item) => {
        if (item.checkedYn === false) {
          checked = false;
        }
      });
    } else {
      checked = false;
    }
    return checked;
  }
  get checkCount() {
    let count = 0;
    BranchOfficeRelocateStepModel.branchOffices.forEach((branchOffice, idx) => {
      if (branchOffice.checkedYn) {
        count++;
      }
    });
    return count;
  }
  setCheckedYn(e, idx) {
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices[idx].checkedYn = e.target.checked;
    });
  }
  setYear(e, idx) {
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices[idx].year = e.target.value;
    });
  }
  setMonth(e, idx) {
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices[idx].month = e.target.value;
    });
  }
  setDay(e, idx) {
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices[idx].day = e.target.value;
    });
  }
  dateBlur(e, idx, target) {
    let val = e.target.value;
    if (val && val.length === 1) {
      e.target.value = '0' + val;
    }
    if (target === 'month') {
      BranchOfficeRelocateStepModel.branchOffices[idx].month = e.target.value;
    }
    if (target === 'day') {
      BranchOfficeRelocateStepModel.branchOffices[idx].day = e.target.value;
    }
  }
  displayBirthErrorMessage(idx, type) {
    const year = BranchOfficeRelocateStepModel.branchOffices[idx].year;
    const month = BranchOfficeRelocateStepModel.branchOffices[idx].month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  setOfficeName(e, idx) {
    runInAction(() => {
      BranchOfficeRelocateStepModel.branchOffices[idx].officeName = e.target.value;
    });
  }
}

export default new BranchOfficeRelocateVm();
