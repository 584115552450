import { observable, runInAction } from 'mobx';

const FreeIssueOfNewSharesStepModel = observable({
  amount: null,
  number: null,
  parValue: null,
  recordDateForNewSharesAllotment: {
    year: '',
    month: '',
    day: '',
  },
  resourceTypes: [],
  setFreeIssueOfNewShares(data) {
    runInAction(() => {
      this.amount = data?.amount || null;
      this.number = data?.number || null;
      this.parValue = data?.parValue || null;
      this.recordDateForNewSharesAllotment = data?.recordDateForNewSharesAllotment || {
        year: '',
        month: '',
        day: '',
      };
      this.resourceTypes = data?.resourceTypes || [
        {
          resoureceType: '',
          checkedYn: false,
        },
      ];
    });
  },
});

export default FreeIssueOfNewSharesStepModel;
