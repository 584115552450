import { observable, runInAction } from 'mobx';

const ParValueDivisionStepModel = observable({
  newParValue: null,
  parValueOnRegister: null,
  setParValueDivision(data) {
    runInAction(() => {
      this.newParValue = data?.newParValue || null;
      this.parValueOnRegister = data?.parValueOnRegister || null;
    });
  },
});

export default ParValueDivisionStepModel;
