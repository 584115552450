import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AnswerWrap = styled.div`
  display: inline-flex;
`;

const Icon = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
  padding: 0 8px;
  margin-right: 7px;
  height: 20px;
  background-color: #006ac3;
  border-radius: 50px;
`;

const IconText = styled.div`
  position: relative;
  top: -1px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
`;

const Answer = styled.div`
  display: inline-block;
  color: #006ac3;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.9px;
`;

function AnswerText(props) {
  return (
    <AnswerWrap className={props.className}>
      <Icon>
        <IconText>{props.iconText}</IconText>
      </Icon>
      <Answer style={props.style}>{props.children}</Answer>
    </AnswerWrap>
  );
}

AnswerText.propTypes = {
  /**
   * class명 <br/>
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * icon text <br/>
   * A1, A2, A3 ...
   */
  iconText: PropTypes.string,
  /**
   * answer 텍스트
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.any]),
};

AnswerText.defaultProps = {
  className: null,
  style: {},
  iconText: 'A',
  children: 'Empty',
};

export default AnswerText;
