import { useObserver } from 'mobx-react';
import basicCertificate from './basicCertificate/basicCertificate';
import familyRelationshipCertificate from './familyRelationshipCertificate/familyRelationshipCertificate';
import { Route } from 'react-router-dom';
import { useEffect } from 'react';
import MinorDocumentUploadVm from './MinorDocumentUploadVm';

function MinorDocumentUpload() {
  return useObserver(() => (
    <>
      {/* 미성년자 서류 업로드 (1/2) */}
      <Route path="/MinorDocumentUpload/BasicCertificate" component={basicCertificate}></Route>
      {/* 미성년자 서류 업로드 (2/2) */}
      <Route
        path="/MinorDocumentUpload/FamilyRelationshipCertificate"
        component={familyRelationshipCertificate}
      ></Route>
    </>
  ));
}

export default MinorDocumentUpload;
