import StockholdersMeetingSealProvidingStepModel from '@model/changeRegistration/StockholdersMeetingSealProvidingStepModel';
import smartformAppModel from '@model/smartformAppModel';
import { runInAction, observable } from 'mobx';
import stepService from '@service/stepService';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import josa from '@common/module/josa';

class StockholdersMeetingSealProvidingVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        this.state.errorArr = [];
        this.state.openIdx = null;
        //올바를 데이터를 입력한 경우
        let param = formDataToJson(e.target).data;
        stepService.saveStep(param);
      }
    });
  }
  get shareholdersDefaultValue() {
    const val = {
      id: '',
      name: '',
      englishName: '',
      nationalityType: '',
      nationalityOthers: '',
      sealProviderType: '',
    };
    return val;
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get shareholders() {
    return StockholdersMeetingSealProvidingStepModel.shareholders;
  }
  get stockholderMeetingType() {
    return StockholdersMeetingSealProvidingStepModel.stockholderMeetingType;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setTitle(idx) {
    let name = this.shareholders[idx].name;
    let type = this.shareholders[idx].sealProviderType;
    let text = this.text;
    if (type === '외국인_거주등록') {
      type = '외국인';
    }
    let title = '';
    if (name && type) {
      title = name + ` ${text}님(` + type.replace('_', '/') + ')';
    } else if (!name) {
      title = `인감증명서를 제공할 ${text}님의 정보를 입력해 주세요.`;
    }
    return title;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  async setCloseBtn(idx) {
    const shareholders = StockholdersMeetingSealProvidingStepModel.shareholders;
    let text = this.text;
    let name = shareholders[idx].name;
    if (!name) {
      name = `해당 ${text}`;
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (shareholders.length === 1) {
      if (conf) {
        runInAction(() => {
          StockholdersMeetingSealProvidingStepModel.shareholders = [];
          StockholdersMeetingSealProvidingStepModel.shareholders.push(this.shareholdersDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return shareholders.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  addShareholders() {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders.push(this.shareholdersDefaultValue);
      this.state.openIdx = this.shareholders.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  setSealProviderType(e, idx) {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders[idx].sealProviderType = e.target.value;
    });
  }
  setName(e, idx) {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders[idx].name = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders[idx].englishName = e.target.value;
    });
  }
  setNationalityType(e, idx) {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders[idx].nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    runInAction(() => {
      StockholdersMeetingSealProvidingStepModel.shareholders[idx].nationalityOthers = e.target.value;
    });
  }
  get text() {
    const smartFormType = this.smartFormType;
    switch (smartFormType) {
      case 'STOCK_COMPANY':
        return '주주';
      case 'AGRICULTURAL_STOCK_COMPANY':
        return '주주';
      case 'FISHERY_STOCK_COMPANY':
        return '주주';
      case 'LIMITED_COMPANY':
        return '사원';
      case 'LIMITED_LIABILITY_COMPANY':
        return '사원';
    }
  }
  text은는() {
    const text = this.text;
    return josa(text, '은는');
  }
  text이가() {
    const text = this.text;
    return josa(text, '이가');
  }
  displayBadgeImg() {
    const smartFormType = this.smartFormType;
    const stockholderMeetingType = this.stockholderMeetingType;
    if (smartFormType === 'LIMITED_COMPANY' || smartFormType === 'LIMITED_LIABILITY_COMPANY') {
      if (stockholderMeetingType === 'NORMAL') {
        return '/images/stockholdersMeeting_Normal_Limit.png';
      } else {
        return '/images/stockholdersMeeting_Special_Limit.png';
      }
    } else {
      if (stockholderMeetingType === 'NORMAL') {
        return '/images/stockholdersMeeting_Normal.png';
      } else {
        return '/images/stockholdersMeeting_Special.png';
      }
    }
  }
  get companyTypeLimitYn() {
    const smartFormType = this.smartFormType;
    if (smartFormType === 'LIMITED_COMPANY' || smartFormType === 'LIMITED_LIABILITY_COMPANY') {
      return true;
    } else {
      return false;
    }
  }
}

export default new StockholdersMeetingSealProvidingVm();
