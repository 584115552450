import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import CheckAsEstimateVm from './CheckAsEstimateVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './CheckAsEstimate.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import { addCommas } from '@common/module/replaceNumber';

function CheckAsEstimate() {
  return useObserver(() => (
    <form onSubmit={(e) => CheckAsEstimateVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={st.title}>
            아래는 고객님이 <span className={st.bold}>스탠바이</span>에서 입력하신 내용입니다.
          </div>
          <table className={st.table}>
            <colgroup>
              <col width="216" />
              <col width="168" />
              <col width="225" />
              <col width="176" />
              <col width="170" />
              <col />
            </colgroup>
            <thead>
              <tr>
                <th>신주인수인</th>
                <th>신주대금 입금방법</th>
                <th>주식종류</th>
                <th>투자금</th>
                <th>주식수</th>
                <th>발행가액(자동계산)</th>
              </tr>
            </thead>
            {CheckAsEstimateVm.standbyNewSharesInputs?.map((item, idx) => (
              <tbody key={idx}>
                {item?.shareDetails.map((stock, idx2) => (
                  <tr key={idx2}>
                    {idx2 === 0 && (
                      <>
                        <td rowSpan={item.shareDetails.length}>
                          <span className={st.index}>{idx + 1}</span>
                          <span className={st.name}>{item.name}님</span>
                          <br />
                          <span className={st.shareTag}>{item.isNewYn ? '(새로운 분)' : '(주주)'}</span>
                        </td>
                        <td rowSpan={item.shareDetails.length}>{item.paymentMethod}</td>
                      </>
                    )}
                    <td className={st.stockName}>{stock.name}</td>
                    <td className={st.investment}>{addCommas(stock.capital)}원</td>
                    <td className={st.stock}>{addCommas(stock.count)}주</td>
                    <td className={st.price}>{addCommas(CheckAsEstimateVm.price(stock.capital, stock.count))}원</td>
                  </tr>
                ))}
              </tbody>
            ))}
            <tfoot>
              <tr>
                <td>합계</td>
                <td colSpan={3}>총 {addCommas(CheckAsEstimateVm.capitalTotal)}원</td>
                <td>총 {addCommas(CheckAsEstimateVm.countTotal)}주</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>위 내용대로 진행하는 것이 맞나요?</span>
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={true}
                  activeBackground
                  checked={CheckAsEstimateVm.keepGoingYn === true}
                  onChange={(e) => CheckAsEstimateVm.setKeepGoingYn(e)}
                  name="keepGoingYn"
                >
                  네. 위 내용대로 진행합니다.
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={false}
                  activeBackground
                  checked={CheckAsEstimateVm.keepGoingYn === false}
                  onChange={(e) => CheckAsEstimateVm.setKeepGoingYn(e)}
                  name="keepGoingYn"
                >
                  아니오. 위 내용과 다르게 진행합니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="keepGoingYn"
              value={CheckAsEstimateVm.keepGoingYn}
              errorCase={{ required: '진행하실 방법을 선택해주세요.' }}
            />
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default CheckAsEstimate;
