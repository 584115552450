import { observable, runInAction } from 'mobx';

const TrademarkStepModel = observable({
  agreeYn: null,
  setTrademarkStepModel(data) {
    runInAction(() => {
      this.agreeYn = data?.agreeYn ?? null;
    });
  },
});

export default TrademarkStepModel;
