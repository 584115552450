import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './StandbyFileUploadStep.module.scss';

function StandbyFileUploadStep(props) {
  return useObserver(() => (
    <>
      {props.step.filesByFileType.map((item, idx) => {
        let fileType = item.fileType;
        switch (fileType) {
          case '기존_정관':
            fileType = '기존 정관';
            break;
          case '주주명부_공증':
            fileType = '주주명부';
            break;
          case '주주명부_서면결의':
            fileType = '주주명부';
            break;
          case '가수금_계정별원장':
            fileType = '가수금 계정별원장';
            break;
          case '무상증자_최종_재무제표':
            fileType = '무상증자 서류';
            break;
          case '무상증자_예외':
            fileType = '무상증자 서류';
            break;
          case '미성년_기본증명서':
            fileType = '미성년 기본증명서';
            break;
          case '미성년_가족관계증명서':
            fileType = '미성년 가족관계증명서';
            break;
        }
        return (
          <div className={Ast.row} key={idx}>
            <div className={Ast.dName}>{fileType}</div>
            <div className={Ast.data}>
              {item.files.length === 0 && '-'}
              {item?.files.map((file, idx2) => {
                return (
                  <span key={idx2} className={st.fileName}>
                    <a href={file.url} download target="_blank">
                      {file.fileName}
                    </a>
                  </span>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  ));
}
export default StandbyFileUploadStep;
