import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import AnnounceMethodVm from './AnnounceMethodVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './AnnounceMethod.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Select from '@comComponents/atoms/Input/Select/Select';
import clsx from 'clsx';

function AnnounceMethod() {
  return useObserver(() => (
    <form onSubmit={(e) => AnnounceMethodVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>기존 공고방법</span>을 확인해주세요.
              </h2>
              <div className={st.basicAnnounceBox}>
                <div className={st.basicAnnounce}>
                  <div className={st.title}>기존 공고방법</div>
                  <div className={st.announce}>{AnnounceMethodVm.originAnnounceMethod}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="공고방법" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>공고방법이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    공고방법이란 주주나 채권자에게 중대한 영향을 미치는 사항을 다수의 사람에게 알리는 방법을 말합니다.
                  </Answer>
                  <p className={Qst.explain}>
                    공고방법은 등기부에 반드시 기재해야 하는 사항이지만, 실제로 공고를 해야 하는 경우는 많지 않습니다.
                    설립을 할 때에는 공고를 할 필요가 없고, 회사를 청산하거나, 합병을 하거나, 주주배정 신주발행을 하는
                    등의 상황에서만 공고가 필수입니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>신문공고와 홈페이지 공고는 무엇이 다른가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    신문공고는 일간 신문사에 공고문을 올리는 것이고, 홈페이지 공고는 단독 홈페이지에 공고문을 올리는
                    것입니다.
                  </Answer>
                  <p className={Qst.explain}>
                    홈페이지 공고를 선택하는 경우, 홈페이지 공고가 불가능한 경우를 대비하여, 미리 공고할 신문사를 아울러
                    정해두어야 합니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>신문사 별로 공고방법은 어떤 차이가 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>신문사 별로 공고방법의 효력상 차이는 없고, 비용만 다릅니다.</Answer>
                  <p className={Qst.explain}>
                    예를 들면 아시아경제 신문의 1회당 공고비용은 11만 원이고, 조선일보의 1회당 공고비용은 40만 원
                    입니다. 신문사 공고를 할 일이 거의 없기는 하지만, 만일을 대비하여 공고방법이 저렴한 아시아경제
                    신문을 추천드립니다.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                공고할 <span className={Qst.bold}>단독 홈페이지</span>가 있나요? (현재 접속가능해야함)
              </h2>
              <div className={Qst.titleExplain}>
                네이버카페, 블로그, 다른 회사와 함께 쓰는 홈페이지는 공고문을 올릴 수 없으므로, 그 경우 아니오를
                선택해주세요.
              </div>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={true}
                  activeBackground
                  checked={AnnounceMethodVm.hasHomepageYn === true}
                  onChange={AnnounceMethodVm.setHasHomepageYn}
                  name="hasHomepageYn"
                >
                  네. 공고할 단독 홈페이지가 있습니다.
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={false}
                  activeBackground
                  checked={AnnounceMethodVm.hasHomepageYn === false}
                  onChange={AnnounceMethodVm.setHasHomepageYn}
                  name="hasHomepageYn"
                >
                  아니오. 공고할 단독 홈페이지가 없습니다.
                </Radio>
              </li>
            </ul>
          </div>
        </ContentBox>
        {AnnounceMethodVm.hasHomepageYn === true && (
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  공고할 <span className={Qst.bold}>단독 홈페이지 주소를 입력</span>하고,{' '}
                  <span className={Qst.bold}>접속 확인</span> 버튼을 눌러주세요.
                </h2>
                <div className={Qst.titleExplain}>
                  네이버카페, 블로그, 공동 홈페이지 등은 홈페이지 공고방법으로 사용할 수 없습니다.
                </div>
              </div>
              <div className={Qst.rowWrap}>
                <InputText
                  className={clsx(Qst.inputText, 'homepageUrl')}
                  style={{ width: '443px' }}
                  name="homepageUrl"
                  value={AnnounceMethodVm.homepageUrl}
                  onChange={AnnounceMethodVm.setHomepageUrl}
                  errorText={
                    <Error
                      name="homepageUrl"
                      value={AnnounceMethodVm.homepageUrl}
                      style={{ width: '540px' }}
                      errorCase={{
                        required: '홈페이지 주소를 입력해 주세요.',
                        validate: {
                          func: regExp.urlCheck().test(AnnounceMethodVm.homepageUrl),
                          message: 'https:// 또는 http://을 제외하고 입력해주세요.',
                        },
                      }}
                    />
                  }
                />
                <SquareBtn
                  onClick={() => AnnounceMethodVm.setValidUrlCheckType()}
                  disabled={AnnounceMethodVm.state.homepageAddressDisabledYn}
                >
                  접속확인
                </SquareBtn>
              </div>
              {AnnounceMethodVm.validUrlCheckType === 'CAN_ACCESS' && (
                <ErrorText>
                  <span style={{ color: '#006ac3' }}>접속가능한 홈페이지 주소입니다.</span>
                  <br />
                  (다만 접속가능한 홈페이지라고 하더라도, 네이버 블로그, 카페, 도메인판매 회사 사이트 등은 등기소에서
                  허용하지 않으므로 주의 부탁드립니다.)
                </ErrorText>
              )}
              {AnnounceMethodVm.validUrlCheckType === 'CANNOT_ACCESS' && (
                <ErrorText>접속 불가능한 홈페이지 주소입니다. 주소를 다시 입력해주세요.</ErrorText>
              )}
              {AnnounceMethodVm.validUrlCheckType === 'CONNECTION_ERROR ' && (
                <ErrorText>
                  <span style={{ color: '#006ac3' }}>접속가능한 홈페이지 주소입니다.</span>
                  <br />
                  다만 접속가능한 홈페이지라고 하더라도, 네이버 블로그, 카페, 도메인판매 회사 사이트 등은 등기소에서
                  허용하지 않으므로 주의 부탁드립니다.
                </ErrorText>
              )}
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>나중에 만들 홈페이지 주소를 적어도 되나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>단독 홈페이지 주소는 현재 접속가능해야 합니다.</Answer>
                    <p className={Qst.explain}>
                      등기관이 심사를 할 때, 현재 접속이 불가능하다면, 변경등기를 허용해주지 않습니다. 나중에 홈페이지를
                      만들 예정이라면, 신문사 공고방법을 선택한 후, 홈페이지 개설 이후에 변경등기를 진행해 주세요.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        )}
        {AnnounceMethodVm.hasHomepageYn !== null && (
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>공고할 신문사를 선택</span>해 주세요.
                </h2>
                {AnnounceMethodVm.hasHomepageYn === true && (
                  <div className={Qst.titleExplain}>
                    홈페이지 공고를 선택할 경우에도, 홈페이지 접속이 불가능한 경우를 대비해서 공고할 신문사를 미리
                    정해두어야 합니다.
                  </div>
                )}
              </div>
              <div className={Qst.rowWrap}>
                <Select
                  className={Qst.select}
                  style={{ width: '317px' }}
                  name="newspaperCompany"
                  value={AnnounceMethodVm.newspaperCompany}
                  onChange={(e) => AnnounceMethodVm.setNewspaperCompany(e)}
                  errorText={
                    <Error
                      name="newspaperCompany"
                      value={AnnounceMethodVm.newspaperCompany}
                      errorCase={{ required: '신문사를 선택해 주세요.' }}
                    />
                  }
                >
                  <option value="">-</option>
                  {AnnounceMethodVm.newspaperCompanyList.map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Select>
              </div>
              {AnnounceMethodVm.newspaperCompany === '직접입력' && (
                <div className={Qst.rowWrap} style={{ marginTop: '20px' }}>
                  <InputText
                    explain="신문사 이름"
                    className={Qst.inputText}
                    style={{ width: '317px' }}
                    name="customNewspaperCompany"
                    value={AnnounceMethodVm.customNewspaperCompany}
                    onChange={AnnounceMethodVm.setCustomNewspaperCompany}
                    errorText={
                      <Error
                        name="customNewspaperCompany"
                        value={AnnounceMethodVm.customNewspaperCompany}
                        style={{ width: '540px' }}
                        errorCase={{
                          required: '신문사 이름을 입력해주세요',
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>공고비를 당장 내야 하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      공고비는 당장 낼 필요가 없고, 특수한 경우에 공고비가 발생합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      회사를 청산하거나, 합병, 액면분할 등 매우 특수한 상황에서만 공고가 필수입니다. 따라서 공고방법
                      등기를 할 때에는 공고비를 낼 필요가 없습니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>신문사 별로 공고의 효력에 차이가 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>신문사 별로 공고의 효력에는 아무 차이가 없습니다.</Answer>
                    <p className={Qst.explain}>따라서 공고비가 저렴한 이사아경제신문을 추천드립니다.</p>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        )}
      </Question>
    </form>
  ));
}
export default AnnounceMethod;
