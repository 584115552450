import { useObserver } from 'mobx-react';
import AddStockOption from './AddStockOption/AddStockOption.jsx';
import { Route } from 'react-router-dom';

function AddStockOptionStep() {
  return useObserver(() => (
    <>
      {/* 스톡옵션 */}
      <Route path="/step/changeRegistration/AddStockOptionStep/AddStockOption" component={AddStockOption}></Route>
    </>
  ));
}

export default AddStockOptionStep;
