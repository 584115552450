import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import btnCheckNoSquare from '../../../asset/icon/btn_check_n.svg';
import btnCheckNoCircle from '../../../asset/icon/btn_check_nor.svg';
import btnCheckSquare from '../../../asset/icon/btn_check_s.svg';
import btnCheckCircle from '../../../asset/icon/btn_check_sel.svg';
import checkRed from '../../../asset/icon/btn_check_red.svg';
import btnCheckSquareDisabled from '../../../asset/icon/btn_check_disabled.svg';

const Label = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  ${(props) => props.activeBackground && props.checked && `background-color:rgba(0, 106, 195, 0.1);`}
  ${(props) =>
    props.disabled &&
    props.disabledBackground &&
    `cursor: not-allowed;
        background-color: #f1f2f5;`}
    ${(props) => props.disabled && !props.disabledBackground && `cursor: not-allowed;`}
`;
const Checkinput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -10000;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + div {
    ${((props) => props.boxType === 'square' && props.boxType === 'allSquare') &&
    `
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;
            width: 22px;
            height: 22px;
            background-image: url(${btnCheckSquare});
            background-size: cover;
            background-repeat: no-repeat;
        }
        `}
    ${(props) =>
      props.boxType === 'circle' &&
      `
        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 1px;
            width: 26px;
            height: 26px;
            background-image: url(${btnCheckCircle});
            background-size: cover;
            background-repeat: no-repeat;
        }
        `}
        ${(props) =>
      props.removeCheck &&
      `
        &:before {
            background-image: url(${checkRed});
        }
        `}
        + div {
      color: ${(props) => (props.removeCheck ? '#eb4d59' : '#006ac3')};
      text-decoration: ${(props) => (props.removeCheck && props.lineThrough ? 'line-through' : 'none')};
    }
  }
`;

// const Check = styled.div`

//     ${((props) => props.boxType === 'square' && props.boxType === 'allSquare') &&
//     `
//     display: inline-block;
//     position: absolute;
//     top: 1px;
//     margin-right: 14px;
//     width: 22px;
//     height: 22px;
//     background-image: url(${btnCheckNoSquare});
//     background-size: cover;
//     background-repeat: no-repeat;
//     vertical-align: top;
//     `
//     }

//     ${(props) => props.boxType === 'circle' &&
//     `
//     display: inline-block;
//     position: absolute;
//     top: 29px;
//     margin-right: 16px;
//     width: 26px;
//     height: 26px;
//     background-image: url(${btnCheckNoCircle});
//     background-size: cover;
//     background-repeat: no-repeat;
//     vertical-align: top;
//     `
//     }

//     ${(props) => (props.boxType === 'square' || props.boxType === 'allSquare' ) && props.disabled &&
//     `
//     background-image: url(${btnCheckSquareDisabled});
//     `
//     }
// `;

const Text = styled.div`
  display: inline-block;
  position: relative;
  padding-left: ${(props) => (props.onlyCheckBox ? '22px' : '37px')};
  color: #000;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: ${(props) => (props.boxType === 'square' ? '-0.5px' : '-0.88px')};
  ${(props) => props.boxType === 'allSquare' && `letter-spacing: -0.34px;`}

  ${((props) => props.boxType === 'square' && props.boxType === 'allSquare') &&
  `
    &:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 22px;
        height: 22px;
        background-image: url(${btnCheckNoSquare});
        background-size: cover;
        background-repeat: no-repeat;
    }
    `}

    ${(props) =>
    props.boxType === 'circle' &&
    `
    &:before {
        display: inline-block;
        position: absolute;
        left: 0;
        top: 1px;
        width: 26px;
        height: 26px;
        background-image: url(${btnCheckNoCircle});
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: top;
    }
    `}

    ${(props) =>
    (props.boxType === 'square' || props.boxType === 'allSquare') &&
    props.disabled &&
    `
    &:before {
        background-image: url(${btnCheckSquareDisabled});
    }
    `}



    ${(props) =>
    props.requiredText === true &&
    `
        &:after {
            content: '(필수)';
            display: inline-block;
            margin-left: 6px;
            color: #006ac3;           
        }
    `} 

    ${(props) =>
    props.requiredText === false &&
    `
        &:after {
            content: '(선택)';
            display: inline-block;
            margin-left: 6px;
            color: #0fac88;
        }
    `} 

    ${(props) => props.disabled && `color: #878c96;`}
`;

const Input = React.forwardRef((props, ref) => {
  return (
    <Label
      className={props.className}
      activeBackground={props.activeBackground}
      checked={props.checked}
      disabled={props.disabled}
      disabledBackground={props.disabledBackground}
    >
      <Checkinput
        removeCheck={props.removeCheck}
        lineThrough={props.lineThrough}
        type="checkbox"
        required={props.required}
        name={props.name}
        id={props.id}
        checked={props.checked}
        value={props.value}
        onChange={(e) => props.onChange(e)}
        disabled={props.disabled}
        boxType={props.boxType}
        ref={ref}
      />
      {/* <Check boxType={props.boxType} disabled={props.disabled}/> */}
      <Text
        style={props.style}
        onlyCheckBox={props.onlyCheckBox}
        boxType={props.boxType}
        required={props.required}
        requiredText={props.requiredText}
        disabled={props.disabled}
      >
        {props.onlyCheckBox === false && props.children}
      </Text>
    </Label>
  );
});

Input.propTypes = {
  /**
   * 필수값
   */
  required: PropTypes.bool,
  /**
   * (필수), (선택) 표시 여부
   */
  requiredText: PropTypes.bool,
  /**
   * 체크박스 이름
   */
  name: PropTypes.string,
  /**
   * 체크박스 id값
   */
  id: PropTypes.string,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 체크박스 defaultChecked
   */
  defaultChecked: PropTypes.bool,
  /**
   * 체크박스 checked
   */
  checked: PropTypes.bool,
  /**
   * 체크박스 value값
   */
  value: PropTypes.any,
  /**
   * change 이벤트
   */
  onChange: PropTypes.func,
  /**
   * 체크박스 디자인 <br/>
   * allSquare: 전체선택 체크박스
   */
  boxType: PropTypes.oneOf(['square', 'allSquare', 'circle']),
  /**
   * 체크박스 텍스트
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  /**
   * 삭제리스트 스타일
   */
  removeCheck: PropTypes.bool,
  /**
   * 삭제리스트 스타일_`text decoration: line-through` 여부
   */
  lineThrough: PropTypes.bool,
  /**
   * 체크박스 선택할 때, 배경색
   */
  activeBackground: PropTypes.bool,
  /**
   * 체크박스 disabled 여부
   */
  disabled: PropTypes.bool,
  /**
   * 체크박스 글자여부
   */
  onlyCheckBox: PropTypes.bool,
  /**
   * 체크박스 disabled label 색상여부
   */
  disabledBackground: PropTypes.bool,
};

Input.defaultProps = {
  required: false,
  requiredText: null,
  name: null,
  id: null,
  className: null,
  style: {},
  defaultChecked: null,
  checked: null,
  value: '',
  onChange: () => {},
  boxType: 'square',
  children: 'Empty',
  removeCheck: false,
  lineThrough: true,
  activeBackground: false,
  disabled: false,
  onlyCheckBox: false,
  disabledBackground: true,
};

export default Input;
