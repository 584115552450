import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowNextSel from '../../asset/icon/ico_arrow_next_sel.svg';
import arrowNext from '../../asset/icon/ico_arrow_next.svg';

const StepWrap = styled.div`
  position: relative;
  padding-bottom: 10px;
  width: 100%;
`;

const StepState = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: ${(props) => props.widthPercent};
  height: 5px;
  border-radius: 40px;
  background-color: #ff9d00;
  transition: 0.5s ease-in;
`;

const Li = styled.li`
  white-space: pre;
  display: inline-block;
  position: relative;
  padding-right: 28px;
  font-size: 22px;
  font-weight: ${(props) => (props.select === true ? '500' : '400')};
  letter-spacing: -0.68px;
  color: ${(props) => (props.select === true ? '#fff' : '#4ba8ff')};
  margin-bottom: 10px;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 2px;
    width: 24px;
    height: 24px;
    background-image: url(${(props) => (props.select === true ? arrowNextSel : arrowNext)});
    background-size: cover;
    background-repeat: no-repeat;
  }

  &:last-child:after {
    content: none;
  }
`;

function ProgressBar(props) {
  const widthPercent = (attr, idx, length) => {
    if (attr) {
      if (length === idx) {
        return 100 + '%';
      }
      return (100 / (length + 1)) * idx + '%';
    } else {
      if (idx + 1 === length) {
        return 100 + '%';
      }
      return (100 / length) * (idx + 1) + '%';
    }
  };

  return (
    <StepWrap>
      <StepState
        widthPercent={
          props.attr
            ? widthPercent(props.attr, props.pageStackIdx, props.pageCnt)
            : widthPercent(props.attr, props.stepIdx, props.item.length)
        }
      ></StepState>
      <ul>
        {props.item.map((item, idx) => {
          return (
            <Li select={idx === props.stepIdx} onClick={item.event || null} key={idx}>
              {item.title}
            </Li>
          );
        })}
      </ul>
    </StepWrap>
  );
}

ProgressBar.propTypes = {
  /**
   * ProgressBar 정보
   */
  item: PropTypes.array,
  /**
   * ProgressBar step 아래 page 여부
   */
  attr: PropTypes.bool,
  /**
   * ProgressBar step index
   */
  stepIdx: PropTypes.number,
  /**
   * ProgressBar 현재 page index
   */
  pageStackIdx: PropTypes.number,
  /**
   * ProgressBar 전체 page 개수
   */
  pageCnt: PropTypes.number,
};

ProgressBar.defaultProps = {
  item: [{ title: 'Empty title', event: () => {} }],
  attr: false,
  stepIdx: 0,
  pageStackIdx: null,
  pageCnt: null,
};

export default ProgressBar;
