import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import close from '../../../asset/icon/btn_close.svg';
import closeBig from '../../../asset/icon/btn_close_big.svg';

const Btn = styled.button`
  width: 35px;
  height: 35px;
  border: none;
  background-color: transparent;
  background-image: url(${(props) => (props.use === 'modal' ? closeBig : close)});
  background-repeat: no-repeat;
  background-size: cover;
`;

function CloseBtn(props) {
  return (
    <Btn
      type={props.type}
      className={props.className}
      style={props.style}
      onClick={(e) => props.onClick(e)}
      use={props.use}
    ></Btn>
  );
}

CloseBtn.propTypes = {
  /**
   * 버튼 타입
   */
  type: PropTypes.string,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * CloseBtn 사용 위치에 따른 변경
   */
  use: PropTypes.oneOf(['basic', 'modal']),
};

CloseBtn.defaultProps = {
  type: 'button',
  className: null,
  style: {},
  onClick: () => {},
  use: 'basic',
};

export default CloseBtn;
