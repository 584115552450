import BranchOfficeChangeStepModel from '@model/changeRegistration/BranchOfficeChangeStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction, observable, toJS } from 'mobx';
import regExp from '@common/module/regExp';
import portalModel from '@model/portalModel';
import ResidenceRegistrationModal from '@pages/ModalContent/changeRegistration/BranchOfficeChange/ResidenceRegistrationModal';
import { onlyNumber } from '@common/module/replaceNumber';

class BranchOfficeChangeVm {
  constructor() {
    this.state = observable({
      addressIdx: null,
      addressModal: false,
      addressTarget: null,
    });
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (this.changeYnError() === false && formDataToJson(e.target).error === false) {
        let param = formDataToJson(e.target).data;
        param.branchOffices.map((item, idx) => {
          if (item.originManager?.address) {
            //기존 지배인 도로명 주소
            param.branchOffices[idx].originManager.address =
              BranchOfficeChangeStepModel.branchOffices[idx].originManager.address;
          }
          if (item.newManager?.address) {
            //신규 지배인 도로명 주소
            param.branchOffices[idx].newManager.address =
              BranchOfficeChangeStepModel.branchOffices[idx].newManager.address;
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  changeYnError() {
    let error = false;
    let changeCnt = 0;
    BranchOfficeChangeStepModel.branchOffices.forEach((item, idx) => {
      if (item.changeYn) changeCnt++;
    });
    if (changeCnt === 0) {
      error = true;
    }
    return error;
  }
  openModal() {
    const modal = ResidenceRegistrationModal;
    runInAction(() => {
      portalModel.modalContent = modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get branchOffices() {
    return BranchOfficeChangeStepModel.branchOffices;
  }
  get checkAll() {
    let checked = true;
    if (BranchOfficeChangeStepModel.branchOffices.length) {
      BranchOfficeChangeStepModel.branchOffices.forEach((item) => {
        if (item.changeYn === false) {
          checked = false;
        }
      });
    }
    return checked;
  }
  setCheckAll(_value) {
    let value;
    if (typeof _value === 'boolean') {
      value = _value;
    } else {
      value = _value.target.checked;
    }
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices.forEach((item, idx) => {
        item.changeYn = value;
      });
    });
  }
  get checkCount() {
    let count = 0;
    BranchOfficeChangeStepModel.branchOffices.forEach((item, idx) => {
      if (item.changeYn) {
        count++;
      }
    });
    return count;
  }
  setChangeYn(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].changeYn = e.target.checked;
    });
  }
  //지점명칭 변경
  setChangeNameYn(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].changeNameYn = e.target.value === 'true';
    });
  }
  setOfficeName(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].officeName = e.target.value;
    });
  }
  //지배인 추가 or 변경
  setManagerChangeType(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].managerChangeType = e.target.value;
    });
  }
  //newManager 관련 데이터
  setKoreanYn(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.koreanYn = e.target.value === 'true';
    });
  }
  //신규지배인_한국인
  setNewManagerName(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.name = e.target.value;
    });
  }
  //신규지배인_외국인
  setResidenceReportYn(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.residenceRegistrationYn = e.target.value === 'true';
    });
  }
  setNationalityType(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.nationalityOthers = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.englishName = e.target.value;
    });
  }
  //신규지배인 생년월일
  setNewManagerBirthYear(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.year = onlyNumber(e.target.value);
    });
  }
  setNewManagerBirthMonth(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.month = onlyNumber(e.target.value);
    });
  }
  setNewManagerBirthDay(e, idx) {
    runInAction(() => {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.day = onlyNumber(e.target.value);
    });
  }
  newManagerBirthDateBlur(e, idx, target) {
    let val = e.target.value;
    if (val && val.length === 1) {
      e.target.value = '0' + val;
    }
    if (target === 'month') {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.month = e.target.value;
    }
    if (target === 'day') {
      BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.day = e.target.value;
    }
  }
  displayNewManagerBirthErrorMessage(idx, type) {
    const year = BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.year;
    const month = BranchOfficeChangeStepModel.branchOffices[idx].newManager.birth.month;
    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
}
export default new BranchOfficeChangeVm();
