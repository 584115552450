import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';
import Date from '@comComponents/atoms/Input/DatePicker/Date/Date';
import Loading from '@comComponents/atoms/Loading/Loading';
import copyText from '@common/module/copyText';
import { addCommas, percent } from '@common/module/replaceNumber';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import clsx from 'clsx';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import st from './AdminFinalCheckView.module.scss';
import AdminFinalCheckViewVm from './AdminFinalCheckViewVm';
import { purposeType } from '../EtcInfo/BusinessPurpose/constant';

function AdminFinalCheckView() {
  useEffect(() => {
    AdminFinalCheckViewVm.loadAdminFinalSubmission();
    AdminFinalCheckViewVm.managerMode();
  }, []);

  return useObserver(() => (
    <>
      <div className="mainContent" style={{ top: '14px' }}>
        <Question style={{ marginBottom: '30px', width: '1019px' }}>
          {AdminFinalCheckViewVm.state.isChange === false ? <Loading /> : null}
          <ContentBox>
            {AdminFinalCheckViewVm.isResponse === false ? (
              <Loading />
            ) : (
              <div>
                <div className={st.btnArea}>
                  <div className={st.btnBox}>
                    <div className={st.causeCopyBtn}>
                      등기사유 <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.copyText())}>[보통등기]</CopyBtn>
                    </div>
                    <Link to={AdminFinalCheckViewVm.state.redirectUrl}>
                      <button type="button" className={st.managerModeBtn}>
                        매니저 모드로 수정하기
                      </button>
                    </Link>
                    <button type="button" className={st.estimateBtn} onClick={() => AdminFinalCheckViewVm.estimate()}>
                      수정사항 견적반영
                    </button>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>회사정보</div>
                  <div className={st.info}>
                    {AdminFinalCheckViewVm.smartFormType() === '주식회사' ||
                    AdminFinalCheckViewVm.smartFormType() === '농업회사법인' ||
                    AdminFinalCheckViewVm.smartFormType() === '어업회사법인' ? (
                      <>
                        <div className={st.row}>
                          <div className={st.dName}>
                            참여 인원
                            <Link to="/step/establish/CompanyInfo/CompanySize">
                              <button type="button" className={st.reviseBtn}>
                                <Icon icon="revise" className={st.reviseIcon} />
                              </button>
                            </Link>
                          </div>
                          <div className={st.data}>{AdminFinalCheckViewVm.companySize()}</div>
                        </div>
                        {AdminFinalCheckViewVm.initiatorRole() && (
                          <div className={st.row}>
                            <div className={st.dName}>
                              참여자 역할
                              <Link to="/step/establish/CompanyInfo/CompanySize">
                                <button type="button" className={st.reviseBtn}>
                                  <Icon icon="revise" className={st.reviseIcon} />
                                </button>
                              </Link>
                            </div>
                            <div className={st.data}>
                              <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.initiatorRole())}>
                                {AdminFinalCheckViewVm.initiatorRole()}
                              </CopyBtn>
                            </div>
                          </div>
                        )}
                      </>
                    ) : null}
                    <div className={st.row}>
                      <div className={st.dName}>
                        한글 회사명
                        <Link to="/step/establish/CompanyInfo/CompanyName">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.koreanCorpName)}>
                          {AdminFinalCheckViewVm.koreanCorpName}
                        </CopyBtn>
                      </div>
                    </div>
                    {AdminFinalCheckViewVm.englishCorpName ? (
                      <div className={st.row}>
                        <div className={st.dName}>
                          영문 회사명
                          <Link to="/step/establish/CompanyInfo/CompanyName">
                            <button type="button" className={st.reviseBtn}>
                              <Icon icon="revise" className={st.reviseIcon} />
                            </button>
                          </Link>
                        </div>
                        <div className={st.data}>
                          <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.englishCorpName)}>
                            {AdminFinalCheckViewVm.englishCorpName}
                          </CopyBtn>
                        </div>
                      </div>
                    ) : null}
                    <div className={st.row}>
                      <div className={st.dName}>
                        회사 주소
                        <Link to="/step/establish/CompanyInfo/CompanyAddress">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        {AdminFinalCheckViewVm.corpAddress !== null && (
                          <>
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.sido)}
                              className={st.addressCopyBtn}
                            >
                              {AdminFinalCheckViewVm.corpAddress?.sido}
                            </CopyBtn>
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.sigungu)}
                              className={st.addressCopyBtn}
                            >
                              {AdminFinalCheckViewVm.corpAddress?.sigungu}
                            </CopyBtn>
                            {AdminFinalCheckViewVm.corpAddress?.bname1 && (
                              <CopyBtn
                                onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.bname1)}
                                className={st.addressCopyBtn}
                              >
                                {AdminFinalCheckViewVm.corpAddress?.bname1}
                              </CopyBtn>
                            )}
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.roadname)}
                              className={st.addressCopyBtn}
                            >
                              {AdminFinalCheckViewVm.corpAddress?.roadname}
                            </CopyBtn>
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.buildingNo)}
                              className={st.addressCopyBtn}
                            >
                              {AdminFinalCheckViewVm.corpAddress?.buildingNo}
                            </CopyBtn>
                            ,
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.detailAddress)}
                              className={st.addressCopyBtn}
                            >
                              {AdminFinalCheckViewVm.corpAddress?.detailAddress}
                            </CopyBtn>
                            <div className={clsx(st.addressCopyBracket)}>
                              (
                              <CopyBtn
                                onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.bname)}
                                className={st.addressCopyBtn}
                              >
                                {AdminFinalCheckViewVm.corpAddress?.bname}
                              </CopyBtn>
                              ,
                              <CopyBtn
                                onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.nameOfBuilding)}
                                className={st.addressCopyBtn}
                              >
                                {AdminFinalCheckViewVm.corpAddress?.nameOfBuilding}
                              </CopyBtn>
                              )
                            </div>
                            <CopyBtn
                              onClick={() => copyText(AdminFinalCheckViewVm.corpAddress?.fullAddress)}
                              className={st.addressCopyAllBtn}
                            >
                              <Icon icon="copy" className={st.copyIcon} />
                            </CopyBtn>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>
                        우편물 받을 주소
                        <Link to="/step/establish/CompanyInfo/CompanyAddress">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        {AdminFinalCheckViewVm.corpAddress !== null && (
                          <>
                            {AdminFinalCheckViewVm.deliveryAddress ? (
                              <>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.sido)}
                                  className={st.addressCopyBtn}
                                >
                                  {AdminFinalCheckViewVm.deliveryAddress?.sido}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.sigungu)}
                                  className={st.addressCopyBtn}
                                >
                                  {AdminFinalCheckViewVm.deliveryAddress?.sigungu}
                                </CopyBtn>
                                {AdminFinalCheckViewVm.deliveryAddress?.bname1 && (
                                  <CopyBtn
                                    onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.bname1)}
                                    className={st.addressCopyBtn}
                                  >
                                    {AdminFinalCheckViewVm.deliveryAddress.bname1}
                                  </CopyBtn>
                                )}
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.roadname)}
                                  className={st.addressCopyBtn}
                                >
                                  {AdminFinalCheckViewVm.deliveryAddress?.roadname}
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.buildingNo)}
                                  className={st.addressCopyBtn}
                                >
                                  {AdminFinalCheckViewVm.deliveryAddress?.buildingNo}
                                </CopyBtn>
                                ,
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.detailAddress)}
                                  className={st.addressCopyBtn}
                                >
                                  {AdminFinalCheckViewVm.deliveryAddress?.detailAddress}
                                </CopyBtn>
                                <div className={clsx(st.addressCopyBracket)}>
                                  (
                                  <CopyBtn
                                    onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.bname)}
                                    className={st.addressCopyBtn}
                                  >
                                    {AdminFinalCheckViewVm.deliveryAddress?.bname}
                                  </CopyBtn>
                                  ,
                                  <CopyBtn
                                    onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.nameOfBuilding)}
                                    className={st.addressCopyBtn}
                                  >
                                    {AdminFinalCheckViewVm.deliveryAddress?.nameOfBuilding}
                                  </CopyBtn>
                                  )
                                </div>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.deliveryAddress?.fullAddress)}
                                  className={st.addressCopyAllBtn}
                                >
                                  <Icon icon="copy" className={st.copyIcon} />
                                </CopyBtn>
                              </>
                            ) : (
                              '회사주소와 같음'
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>{AdminFinalCheckViewVm.name()}정보</div>
                  <div className={st.info}>
                    {AdminFinalCheckViewVm.smartFormType() !== '유한책임회사' && (
                      <div className={st.row}>
                        <div className={st.dName}>
                          {AdminFinalCheckViewVm.smartFormType() === '유한회사' ? '출자 1좌의 금액' : '1주당 금액'}
                          <Link to="/step/establish/ShareholderAndExecutiveInfo/ParValue">
                            <button type="button" className={st.reviseBtn}>
                              <Icon icon="revise" className={st.reviseIcon} />
                            </button>
                          </Link>
                        </div>
                        <div className={st.data}>
                          {AdminFinalCheckViewVm.parValue !== null && (
                            <>
                              <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.parValue)}>
                                {addCommas(AdminFinalCheckViewVm.parValue)}
                              </CopyBtn>{' '}
                              원
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className={st.row}>
                      <div className={st.dName}>발행예정주식수</div>
                      <div className={st.data}>
                        {AdminFinalCheckViewVm.numOfSharesToBeIssued !== null && (
                          <>
                            <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.numOfSharesToBeIssued)}>
                              {addCommas(AdminFinalCheckViewVm.numOfSharesToBeIssued)}
                            </CopyBtn>{' '}
                            주
                          </>
                        )}
                      </div>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>
                        자본금
                        <Link to="/step/establish/ShareholderAndExecutiveInfo/ShareholderInvestment">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        {AdminFinalCheckViewVm.capital !== null && (
                          <>
                            <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.capital)}>
                              {addCommas(AdminFinalCheckViewVm.capital)}
                            </CopyBtn>{' '}
                            원
                          </>
                        )}
                      </div>
                    </div>
                    <div className={clsx(st.row, st.shareholdersRow)}>
                      <div className={st.dName}>
                        {AdminFinalCheckViewVm.name()}정보
                        <Link to="/step/establish/ShareholderAndExecutiveInfo/Shareholder">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        {AdminFinalCheckViewVm.shareholders.map((item, idx) => {
                          return (
                            <div className={st.shareholder} key={idx}>
                              <div className={st.shareholderPosition}>
                                <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.name())}>
                                  {AdminFinalCheckViewVm.name()}
                                </CopyBtn>
                              </div>
                              <div className={st.shareholderInfo}>
                                <div className={st.person}>
                                  <span>
                                    {item.nationality && (
                                      <CopyBtn
                                        onClick={() => copyText(item.nationality)}
                                        className={st.shareholderCopyBtn}
                                      >
                                        {item.nationality}
                                      </CopyBtn>
                                    )}{' '}
                                    <CopyBtn
                                      onClick={() => copyText(item.koreanName)}
                                      className={st.shareholderCopyBtn}
                                    >
                                      {item.koreanName}
                                    </CopyBtn>
                                    {item.englishName && (
                                      <CopyBtn
                                        onClick={() => copyText(item.englishName)}
                                        className={st.shareholderCopyBtn}
                                      >
                                        ({item.englishName})
                                      </CopyBtn>
                                    )}{' '}
                                    {item.birth ? (
                                      <CopyBtn onClick={() => copyText(item.birth)} className={st.shareholderCopyBtn}>
                                        ({item.birth})
                                      </CopyBtn>
                                    ) : null}
                                  </span>
                                  <span>
                                    {AdminFinalCheckViewVm.investmentPercent(idx) &&
                                      percent(AdminFinalCheckViewVm.investmentPercent(idx))}{' '}
                                    지분
                                  </span>
                                  {AdminFinalCheckViewVm.smartFormType() !== '유한책임회사' && (
                                    <span>
                                      {AdminFinalCheckViewVm.stock(idx) && (
                                        <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.stock(idx))}>
                                          {addCommas(AdminFinalCheckViewVm.stock(idx))}
                                        </CopyBtn>
                                      )}{' '}
                                      주
                                    </span>
                                  )}
                                  <span>투자금 {item.investment && `${addCommas(item.investment)}원`}</span>
                                  {AdminFinalCheckViewVm.smartFormType() === '농업회사법인' && (
                                    <div className={st.personType}>농업인</div>
                                  )}
                                  {AdminFinalCheckViewVm.smartFormType() === '어업회사법인' && (
                                    <div className={st.personType}>어업인</div>
                                  )}
                                </div>
                                <div className={st.address}>
                                  {item.address?.sido ? (
                                    <>
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.sido)}
                                        className={st.addressCopyBtn}
                                      >
                                        {item.address?.sido}
                                      </CopyBtn>
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.sigungu)}
                                        className={st.addressCopyBtn}
                                      >
                                        {item.address?.sigungu}
                                      </CopyBtn>
                                      {item.address?.bname1 && (
                                        <CopyBtn
                                          onClick={() => copyText(item.address?.bname1)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address?.bname1}
                                        </CopyBtn>
                                      )}
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.roadname)}
                                        className={st.addressCopyBtn}
                                      >
                                        {item.address?.roadname}
                                      </CopyBtn>
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.buildingNo)}
                                        className={st.addressCopyBtn}
                                      >
                                        {item.address?.buildingNo}
                                      </CopyBtn>
                                      ,
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.detailAddress)}
                                        className={st.addressCopyBtn}
                                      >
                                        {item.address?.detailAddress}
                                      </CopyBtn>
                                      <div className={clsx(st.addressCopyBracket)}>
                                        (
                                        <CopyBtn
                                          onClick={() => copyText(item.address?.bname)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address?.bname}
                                        </CopyBtn>
                                        ,
                                        <CopyBtn
                                          onClick={() => copyText(item.address?.nameOfBuilding)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address?.nameOfBuilding}
                                        </CopyBtn>
                                        )
                                      </div>
                                      <CopyBtn
                                        onClick={() => copyText(item.address?.fullAddress)}
                                        className={st.addressCopyAllBtn}
                                      >
                                        <Icon icon="copy" className={st.copyIcon} />
                                      </CopyBtn>
                                    </>
                                  ) : (
                                    <CopyBtn
                                      onClick={() => copyText(item.address.fullAddress)}
                                      className={st.addressCopyBtn}
                                    >
                                      {item.address.fullAddress}
                                    </CopyBtn>
                                  )}
                                  {item.address?.englishAddress && (
                                    <CopyBtn
                                      onClick={() => copyText(item.address.englishAddress)}
                                      className={st.addressCopyBtn}
                                    >
                                      ({item.address.englishAddress})
                                    </CopyBtn>
                                  )}
                                </div>
                                {item.representatives && (
                                  <div className={st.representativeInfo}>
                                    {item.shareholderType === 'FOREIGNER_COMPANY' && (
                                      <div className={st.representativeTitle}>대표자 정보</div>
                                    )}
                                    <div className={st.representative}>
                                      {item.representatives?.map((representative, idx) => {
                                        if (item.shareholderType === 'KOREAN_COMPANY') {
                                          return (
                                            <div key={idx}>
                                              <CopyBtn
                                                onClick={() => copyText(representative.position)}
                                                className={st.addressCopyBtn}
                                              >
                                                {representative.position}
                                              </CopyBtn>
                                              <CopyBtn
                                                onClick={() => copyText(representative.koreanName)}
                                                className={st.addressCopyBtn}
                                              >
                                                {representative.koreanName}
                                              </CopyBtn>
                                            </div>
                                          );
                                        } else if (item.shareholderType === 'FOREIGNER_COMPANY') {
                                          return (
                                            <div key={idx}>
                                              <CopyBtn
                                                onClick={() => copyText(representative.koreanName)}
                                                className={st.addressCopyBtn}
                                              >
                                                {representative.koreanName}
                                              </CopyBtn>
                                              <CopyBtn
                                                onClick={() => copyText(representative.englishName)}
                                                className={st.addressCopyBtn}
                                              >
                                                ({representative.englishName})
                                              </CopyBtn>
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>임원정보</div>
                  <div className={st.info}>
                    <div className={clsx(st.row, st.executiveRow)}>
                      <div className={st.dName}>
                        임원정보
                        <Link to="/step/establish/ShareholderAndExecutiveInfo/Executive">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        <div className={st.tdWrap}>
                          {AdminFinalCheckViewVm.executives.map((item, idx) => {
                            return (
                              <div className={st.executive} key={idx}>
                                <div className={st.executivePosition}>
                                  <CopyBtn onClick={() => copyText(item.position)}>{item.position}</CopyBtn>
                                </div>
                                <div className={st.executiveInfo}>
                                  <div className={st.person}>
                                    <span>
                                      {item.nationality && (
                                        <CopyBtn
                                          onClick={() => copyText(item.nationality)}
                                          className={st.executiveCopyBtn}
                                        >
                                          {item.nationality}
                                        </CopyBtn>
                                      )}{' '}
                                      <CopyBtn
                                        onClick={() => copyText(item.koreanName)}
                                        className={st.executiveCopyBtn}
                                      >
                                        {item.koreanName}
                                      </CopyBtn>
                                      {item.englishName && (
                                        <CopyBtn
                                          onClick={() => copyText(item.englishName)}
                                          className={st.executiveCopyBtn}
                                        >
                                          ({item.englishName})
                                        </CopyBtn>
                                      )}{' '}
                                      <CopyBtn onClick={() => copyText(item.birth)} className={st.executiveCopyBtn}>
                                        ({item.birth})
                                      </CopyBtn>{' '}
                                      {item.resignationYn ? (
                                        <span className={st.resignation}>설립후 이어서 사임등기 신청</span>
                                      ) : null}
                                    </span>
                                    {AdminFinalCheckViewVm.smartFormType() === '농업회사법인' &&
                                      (AdminFinalCheckViewVm.isShareholder(idx) ||
                                        AdminFinalCheckViewVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>농업인</div>
                                      )}
                                    {AdminFinalCheckViewVm.smartFormType() === '어업회사법인' &&
                                      (AdminFinalCheckViewVm.isShareholder(idx) ||
                                        AdminFinalCheckViewVm.isFarmerOrFisherman(idx)) && (
                                        <div className={st.personType}>어업인</div>
                                      )}
                                  </div>
                                  <div className={st.address}>
                                    {item.address && (
                                      <>
                                        <CopyBtn
                                          onClick={() => copyText(item.address.sido)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address.sido}
                                        </CopyBtn>
                                        <CopyBtn
                                          onClick={() => copyText(item.address.sigungu)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address.sigungu}
                                        </CopyBtn>
                                        {item.address.bname1 && (
                                          <CopyBtn
                                            onClick={() => copyText(item.address.bname1)}
                                            className={st.addressCopyBtn}
                                          >
                                            {item.address.bname1}
                                          </CopyBtn>
                                        )}
                                        <CopyBtn
                                          onClick={() => copyText(item.address.roadname)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address.roadname}
                                        </CopyBtn>
                                        <CopyBtn
                                          onClick={() => copyText(item.address.buildingNo)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address.buildingNo}
                                        </CopyBtn>
                                        ,
                                        <CopyBtn
                                          onClick={() => copyText(item.address.detailAddress)}
                                          className={st.addressCopyBtn}
                                        >
                                          {item.address.detailAddress}
                                        </CopyBtn>
                                        <div className={clsx(st.addressCopyBracket)}>
                                          (
                                          <CopyBtn
                                            onClick={() => copyText(item.address.bname)}
                                            className={st.addressCopyBtn}
                                          >
                                            {item.address.bname}
                                          </CopyBtn>
                                          ,
                                          <CopyBtn
                                            onClick={() => copyText(item.address.nameOfBuilding)}
                                            className={st.addressCopyBtn}
                                          >
                                            {item.address.nameOfBuilding}
                                          </CopyBtn>
                                          )
                                        </div>
                                        <CopyBtn
                                          onClick={() => copyText(item.address.fullAddress)}
                                          className={st.addressCopyAllBtn}
                                        >
                                          <Icon icon="copy" className={st.copyIcon} />
                                        </CopyBtn>
                                      </>
                                    )}
                                  </div>
                                  {AdminFinalCheckViewVm.positionInfoDisplay(idx) === 'unLimitedLiabilityCompany' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 이사가 1명이기 때문에, 등기부에는 대표권 있는
                                      사내이사로 표기됩니다.
                                    </div>
                                  )}
                                  {AdminFinalCheckViewVm.positionInfoDisplay(idx) === 'limitedLiabilityCompany' && (
                                    <div className={st.positionInfo}>
                                      <span className={st.star}>*</span> 유한책임회사의 경우, 등기부에는 업무집행자로
                                      표기됩니다.
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.title}>기타정보</div>
                  <div className={st.info}>
                    <div className={clsx(st.row, st.businessPurposeRow)}>
                      <div className={st.dName}>
                        사업목적
                        <Link to="/step/establish/EtcInfo/BusinessPurpose">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>
                        <div className={st.businessPurposeBox}>
                          <div className={st.businessPurposeWrapper}>
                            {AdminFinalCheckViewVm.purposes.map((purpose, idx) => (
                              <span className={clsx(st.purpose, st.btnGap)} key={idx}>
                                <CopyBtn
                                  style={
                                    purpose.purposeType === purposeType.RECOMMENDED
                                      ? { color: '#006ac3' }
                                      : { color: '#404040' }
                                  }
                                  onClick={() => copyText('1. ' + purpose.content)}
                                >
                                  1. {purpose.content}
                                </CopyBtn>
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className={st.alarmText}>
                          * 총 {AdminFinalCheckViewVm.totalPurposeCounts}개 ={' '}
                          <span style={{ color: '#006ac3' }}>
                            추천 목적 {AdminFinalCheckViewVm.recommendedPurposeCounts}개
                          </span>{' '}
                          {AdminFinalCheckViewVm.directedPurposeCounts > 0 && (
                            <span style={{ color: '#404040' }}>
                              <span style={{ color: '#999fac' }}> + </span>
                              직접입력 목적 {AdminFinalCheckViewVm.directedPurposeCounts}개
                            </span>
                          )}
                          {` (추가금액 : ${addCommas(AdminFinalCheckViewVm.totalAdditionalChargeCounts * 5500) || 0}원 / 총 ${AdminFinalCheckViewVm.totalAdditionalChargeCounts}개 초과)`}
                        </div>
                      </div>
                    </div>
                    {AdminFinalCheckViewVm.smartFormType() === '주식회사' ||
                    AdminFinalCheckViewVm.smartFormType() === '농업회사법인' ||
                    AdminFinalCheckViewVm.smartFormType() === '어업회사법인' ? (
                      <>
                        <div className={st.row}>
                          <div className={st.dName}>
                            스톡옵션
                            <Link to="/step/establish/EtcInfo/Option">
                              <button type="button" className={st.reviseBtn}>
                                <Icon icon="revise" className={st.reviseIcon} />
                              </button>
                            </Link>
                          </div>
                          <div className={st.data}>
                            {AdminFinalCheckViewVm.stockOptionYn}
                            {AdminFinalCheckViewVm.stockOptionYn === '추가함' && (
                              <>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.스톡옵션Text(1))}
                                  className={st.stockOptionCopyBtn}
                                >
                                  [1]
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.스톡옵션Text(2))}
                                  className={st.stockOptionCopyBtn}
                                >
                                  [2]
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.스톡옵션Text(3))}
                                  className={st.stockOptionCopyBtn}
                                >
                                  [3]
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.스톡옵션Text(4))}
                                  className={st.stockOptionCopyBtn}
                                >
                                  [4]
                                </CopyBtn>
                                <CopyBtn
                                  onClick={() => copyText(AdminFinalCheckViewVm.스톡옵션Text(5))}
                                  className={st.stockOptionCopyBtn}
                                >
                                  [5]
                                </CopyBtn>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>
                            주식양도제한
                            <Link to="/step/establish/EtcInfo/Option">
                              <button type="button" className={st.reviseBtn}>
                                <Icon icon="revise" className={st.reviseIcon} />
                              </button>
                            </Link>
                          </div>
                          <div className={st.data}>
                            {AdminFinalCheckViewVm.shareTransferRestrictionsYn()}
                            {AdminFinalCheckViewVm.shareTransferRestrictionsYn() === '추가함' && (
                              <CopyBtn
                                onClick={() => copyText(AdminFinalCheckViewVm.주식양도제한Text)}
                                style={{ marginLeft: '8px' }}
                              >
                                [복사]
                              </CopyBtn>
                            )}
                          </div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>
                            공고방법
                            <Link to="/step/establish/EtcInfo/Option">
                              <button type="button" className={st.reviseBtn}>
                                <Icon icon="revise" className={st.reviseIcon} />
                              </button>
                            </Link>
                          </div>
                          <div className={st.data}>
                            {AdminFinalCheckViewVm.announcementNewspaperCompany && (
                              <>
                                신문사{' '}
                                <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.announcementNewspaperCompany)}>
                                  {AdminFinalCheckViewVm.announcementNewspaperCompany}
                                </CopyBtn>
                              </>
                            )}
                            {AdminFinalCheckViewVm.announcementHomepageAddress && (
                              <span style={{ marginLeft: '8px' }}>
                                홈페이지{' '}
                                <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.announcementHomepageAddress)}>
                                  {AdminFinalCheckViewVm.announcementHomepageAddress}
                                </CopyBtn>
                              </span>
                            )}
                            {AdminFinalCheckViewVm.announcementNewspaperCompany && (
                              <CopyBtn
                                onClick={() => copyText(AdminFinalCheckViewVm.공고방법Text)}
                                style={{ marginLeft: '8px' }}
                              >
                                [복사]
                              </CopyBtn>
                            )}
                          </div>
                        </div>
                        <div className={st.row}>
                          <div className={st.dName}>
                            사회적기업
                            <Link to="/step/establish/EtcInfo/Option">
                              <button type="button" className={st.reviseBtn}>
                                <Icon icon="revise" className={st.reviseIcon} />
                              </button>
                            </Link>
                          </div>
                          <div className={st.data}>{AdminFinalCheckViewVm.prospectiveSocialEnterpriseYn()}</div>
                        </div>
                      </>
                    ) : null}
                    <div className={clsx(st.row, st.corpSealRow)}>
                      <div className={st.dName}>
                        법인인감
                        <Link to="/step/establish/EtcInfo/ChooseStamp">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>{AdminFinalCheckViewVm.corpSeal()}</div>
                    </div>
                    <div className={clsx(st.row, st.corpSealRow)}>
                      <div className={st.dName}>
                        사용인감
                        <Link to="/step/establish/EtcInfo/ChooseStamp">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>{AdminFinalCheckViewVm.usageSeal()}</div>
                    </div>
                    {AdminFinalCheckViewVm.canUseYn() && (
                      <div className={st.row}>
                        <div className={st.dName}>
                          공동인증서
                          <Link to="/step/establish/EtcInfo/Certificate">
                            <button type="button" className={st.reviseBtn}>
                              <Icon icon="revise" className={st.reviseIcon} />
                            </button>
                          </Link>
                        </div>
                        <div className={st.data}>{AdminFinalCheckViewVm.canUseYn()}</div>
                      </div>
                    )}
                    <div className={st.row}>
                      <div className={st.dName}>
                        무료사업자등록
                        <Link to="/step/establish/EtcInfo/FreeLicenseRegistration">
                          <button type="button" className={st.reviseBtn}>
                            <Icon icon="revise" className={st.reviseIcon} />
                          </button>
                        </Link>
                      </div>
                      <div className={st.data}>{AdminFinalCheckViewVm.freeLicenseRegistrationYn()}</div>
                    </div>
                    {AdminFinalCheckViewVm.smartFormType() === '농업회사법인' && (
                      <div className={st.row}>
                        <div className={st.dName}>
                          설립통지
                          <Link to="/step/establish/EtcInfo/AgriculturalNoticeOfEstablishment">
                            <button type="button" className={st.reviseBtn}>
                              <Icon icon="revise" className={st.reviseIcon} />
                            </button>
                          </Link>
                        </div>
                        <div className={st.data}>{AdminFinalCheckViewVm.noticeOfEstablishmentYn()}</div>
                      </div>
                    )}
                    {AdminFinalCheckViewVm.smartFormType() === '어업회사법인' && (
                      <div className={st.row}>
                        <div className={st.dName}>
                          설립통지
                          <Link to="/step/establish/EtcInfo/FisheryNoticeOfEstablishment">
                            <button type="button" className={st.reviseBtn}>
                              <Icon icon="revise" className={st.reviseIcon} />
                            </button>
                          </Link>
                        </div>
                        <div className={st.data}>{AdminFinalCheckViewVm.noticeOfEstablishmentYn()}</div>
                      </div>
                    )}
                    <div className={st.row}>
                      <div className={st.dName}>등기사유</div>
                      <div className={st.data}>
                        <CopyBtn onClick={() => copyText(AdminFinalCheckViewVm.등기사유Text)}>[복사]</CopyBtn>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={st.table}>
                  <div className={st.balanceCertificateBtnArea}>
                    <button
                      type="button"
                      className={st.balanceCertificateChangeBtn}
                      onClick={() => AdminFinalCheckViewVm.changeBalanceCertificate()}
                    >
                      변경사항 저장하기
                    </button>
                  </div>
                  <div className={st.title}>잔고증명서</div>
                  <div className={st.info}>
                    <div className={st.row}>
                      <div className={st.dName}>증명일자</div>
                      <Date
                        value={AdminFinalCheckViewVm.balanceProofDate}
                        onChange={(date) => AdminFinalCheckViewVm.setBalanceProofDate(date)}
                      ></Date>
                    </div>
                    <div className={st.row}>
                      <div className={st.dName}>은행(지점)명</div>
                      <div className={st.data}>
                        <input
                          type="text"
                          className={st.balanceBankInfo}
                          value={AdminFinalCheckViewVm.bank}
                          onChange={(e) => {
                            AdminFinalCheckViewVm.setBank(e);
                          }}
                          placeholder="신한은행 테헤란로지점"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ContentBox>
        </Question>
      </div>
    </>
  ));
}

export default AdminFinalCheckView;
