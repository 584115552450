import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../atoms/Icon/Icon';
import DeleteBtn from '../../atoms/Button/CloseBtn/CloseBtn';
import { _alert, _confirm } from '../../../../model/dialogModel';
import { runInAction, toJS } from 'mobx';
import downloadIcon from '../../asset/icon/ico_fileDownload.svg';

const UploadBox = styled.div`
  padding: 20px 25px;
  width: 100%;
  background-color: #ecf0f8;
  border-radius: 4px;
`;

const File = styled.input`
  display: none;
`;

const DropContainer = styled.div`
  cursor: pointer;
  position: relative;
  width: 509px;
  height: 90px;
  border: 2px dashed #999fac;
  border-radius: 2px;
  background-color: #fff;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 90px;
  text-align: center;
  &.over {
    background-color: #d6dce8;
  }
`;

const UploadFile = styled.div`
  position: relative;
  padding: 19px 25px;
  margin-top: 20px;
  &:nth-child(2) {
    margin-top: 0;
  }
  width: 509px;
  height: 90px;
  border-radius: 2px;
  background-color: #d6dce8;
  .fileInfo {
    position: relative;
    top: -7px;
    display: inline-block;
    margin-left: 8px;
    width: 365px;
    .fileName {
      color: #000;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: -0.45px;
      &.finish {
        cursor: pointer;
        &:hover {
          color: #006ac3;
        }
      }
      .downloadBtn {
        position: absolute;
        top: 0;
        right: 3px;
        width: 35px;
        height: 35px;
        background-color: transparent;
        background-image: url(${downloadIcon});
        background-repeat: no-repeat;
      }
      .uploadFinish {
        display: inline-block;
        margin-left: 7px;
        color: #006ac3;
        font-size: 14px;
        font-weight: 400;
      }
    }
    .fileSize {
      margin-top: 4px;
      color: #777c86;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: -0.4px;
    }
  }
  .deleteBtn {
    position: absolute;
    top: 27px;
    right: 25px;
    &:active {
      top: 28px;
      right: 24px;
    }
  }
`;

const ExplainText = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: -3px;
  padding-left: 18px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.8px;

  div {
    .bold {
      color: #006ac3;
    }
    &:before {
      display: inline-block;
      content: '※';
      position: absolute;
      left: 0;
    }
  }
`;

const UplaodText = styled.div`
  display: inline-block;
  margin-top: 10px;
`;

const Ul = styled.ul`
  li {
    position: relative;
    margin-top: 6px;
    &:first-child {
      margin-top: 0;
    }
    padding-left: 9px;
    color: #555;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.56px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #555;
    }
  }
`;

function FileUpload(props) {
  const [mouseOver, setMouseOver] = useState(false);
  const fileInputRef = useRef();
  const dragOver = (e) => {
    e.preventDefault();
    setMouseOver(true);
  };
  const dragEnter = (e) => {
    e.preventDefault();
    setMouseOver(false);
  };
  const dragLeave = (e) => {
    e.preventDefault();
    setMouseOver(false);
  };
  const fileInputClicked = () => {
    fileInputRef.current.click();
  };
  const filesSelected = (e) => {
    if (fileInputRef.current.files.length) {
      fileUpload(fileInputRef.current.files);
      e.target.value = '';
    }
  };
  const fileUpload = async (files) => {
    let acceptFile = props.accept;
    let acceptList = [];
    if (acceptFile) {
      acceptList = acceptFile.split(',');
      acceptList.forEach((item, idx) => {
        acceptList[idx] = item.replace(/\./g, '').trim().toLowerCase();
      });
      for (let i = 0; i < files.length; i++) {
        const fileNameExtension = files[i].name.split('.')[files[i].name.split('.').length - 1].toLowerCase();
        if (acceptList.includes(fileNameExtension)) {
          if (files[i].size > 5242880) {
            //최대 5MB 업로드 가능
            await _alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
          } else {
            if (props.multiple) {
              //파일 여러개 업로드
              props.uploadModel.push(files[i]);
            } else {
              //파일 1개 업로드
              runInAction(() => {
                props.model.uploadFiles = files[0];
                props.model.fileName = props.model.uploadFiles.name;
                props.model.fileSize = props.model.uploadFiles.size;
              });
            }
          }
        } else {
          return _alert(`${acceptFileList()} 파일만 업로드 가능합니다.`);
        }
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 5242880) {
          //최대 5MB 업로드 가능
          await _alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
        } else {
          if (props.multiple) {
            //파일 여러개 업로드
            props.uploadModel.push(files[i]);
          } else {
            //파일 1개 업로드
            runInAction(() => {
              props.model.uploadFiles = files[0];
              props.model.fileName = props.model.uploadFiles.name;
              props.model.fileSize = props.model.uploadFiles.size;
            });
          }
        }
      }
    }
  };
  const fileChange = async (files) => {
    //파일 1개 업로드일 때, drag&drop 하면서 사용하는 함수
    if (await _confirm('파일은 변경하시겠습니까?')) {
      runInAction(() => {
        props.model.uploadFiles = null;
        props.model.fileName = '';
        props.model.fileSize = null;
        props.model.id = null;
        props.model.url = '';
        fileUpload(files);
      });
    }
  };
  const fileDelete = async (idx) => {
    if (props.multiple) {
      //파일 여러개 업로드
      if (await _confirm('업로드한 파일을 삭제하시겠습니까?')) {
        props.uploadModel.splice(idx, 1);
      }
    } else {
      //파일 1개 업로드
      if (await _confirm('업로드한 파일을 삭제하시겠습니까?')) {
        runInAction(() => {
          props.model.uploadFiles = null;
          props.model.fileName = '';
          props.model.fileSize = null;
          props.model.id = null;
          props.model.url = '';
        });
      }
    }
  };
  const uploadFileDelete = async (idx) => {
    //파일 여러개 업로드 할 때, uploadFiles 삭제하는 함수
    if (await _confirm('업로드한 파일을 삭제하시겠습니까?')) {
      props.deleteModel.push(props.model[idx].id);
      props.model.splice(idx, 1);
    }
  };
  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      if (props.multiple) {
        fileUpload(files);
        setMouseOver(false);
      } else {
        if (props.fileName) {
          fileChange(files);
          setMouseOver(false);
        } else {
          fileUpload(files);
          setMouseOver(false);
        }
      }
    }
  };
  const acceptFileList = () => {
    let acceptFile = props.accept;
    let acceptList = [];
    let acceptFileList = '';
    acceptList = acceptFile.split(',');
    acceptList.forEach((item, idx) => {
      acceptList[idx] = item.replace(/\./g, '').trim().toUpperCase();
      if (acceptList[idx] === 'DOCX') {
        acceptList[idx] = '워드';
      } else if (acceptList[idx] === 'HWP') {
        acceptList[idx] = '한글';
      }
      if (idx !== acceptList.length - 1) {
        acceptList[idx] = acceptList[idx].concat(' / ');
      }
    });
    acceptFileList = acceptList.join().replace(/\,/g, '');
    return acceptFileList;
  };
  const fileDownload = (fileUrl) => {
    window.location.href = fileUrl;
  };

  return (
    <UploadBox className={props.className} style={props.style} id={props.id}>
      <File
        type="file"
        name={props.name}
        multiple={props.multiple}
        accept={props.accept}
        ref={fileInputRef}
        onChange={(e) => filesSelected(e)}
        model={props.model}
        //파일 1개 업로드 관련된 props
        fileName={props.fileName}
        fileSize={props.fileSize}
        fileExtension={props.fileExtension}
        fileUrl={props.fileUrl}
        //파일 여러개 업로드 관련된 props
        files={props.files}
        uploadFiles={props.uploadFiles}
        uploadModel={props.uploadModel}
        deleteModel={props.deleteModel}
      />
      {/* 파일 여러개 업로드 */}
      {props.multiple && (
        <>
          <DropContainer
            className={mouseOver ? 'over' : null}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onClick={fileInputClicked}
            onDrop={(e) => fileDrop(e)}
          >
            {props.children}
          </DropContainer>
          {props.files.length !== 0
            ? props.files.map((files, idx) => {
                //기존 업로드 된 파일
                if (files.fileName.indexOf('\\') !== -1) {
                  //ie 파일명 처리
                  files.fileName = files.fileName.split('\\').reverse()[0];
                }
                return (
                  <UploadFile key={idx}>
                    <Icon icon={files.fileName.split('.')[files.fileName.split('.').length - 1].toLowerCase()} />
                    <div
                      className="fileInfo"
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={(e) => fileDrop(e)}
                    >
                      <a
                        className="fileName finish"
                        href={files.url}
                        download={files.fileName}
                        title={`${files.fileName} 다운로드`}
                      >
                        {files.fileName}
                        <span className="uploadFinish">업로드 완료</span>
                      </a>
                      <div className="fileSize">{Math.ceil(files.fileSize / 1000)}KB</div>
                    </div>
                    <DeleteBtn className="deleteBtn" onClick={() => uploadFileDelete(idx)} />
                  </UploadFile>
                );
              })
            : null}
          {props.uploadFiles.length !== 0
            ? props.uploadFiles.map((files, idx) => {
                //업로드 파일
                return (
                  <UploadFile key={idx}>
                    <Icon icon={files.name.split('.')[files.name.split('.').length - 1].toLowerCase()} />
                    <div
                      className="fileInfo"
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={(e) => fileDrop(e)}
                    >
                      <div className="fileName">{files.name}</div>
                      <div className="fileSize">{Math.ceil(files.size / 1000)}KB</div>
                    </div>
                    <DeleteBtn className="deleteBtn" onClick={() => fileDelete(idx)} />
                  </UploadFile>
                );
              })
            : null}
        </>
      )}
      {/* 파일 1개 업로드 */}
      {props.multiple === false && (
        <>
          {props.fileName ? (
            <UploadFile>
              <Icon icon={props.fileExtension.toLowerCase()} />
              <div
                className="fileInfo"
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={(e) => fileDrop(e)}
              >
                {props.fileUrl && (
                  <>
                    {props.downloadBtn ? (
                      <div className="fileName" style={{ width: '320px' }}>
                        {props.fileName.indexOf('\\') !== -1 ? props.fileName.split('\\').reverse()[0] : props.fileName}
                        <button type="button" className="downloadBtn" onClick={() => fileDownload(props.fileUrl)} />
                      </div>
                    ) : (
                      <a
                        className="fileName finish"
                        href={props.fileUrl}
                        download={props.fileName}
                        title={`${props.fileName} 다운로드`}
                      >
                        {props.fileName.indexOf('\\') !== -1 ? props.fileName.split('\\').reverse()[0] : props.fileName}
                        <span className="uploadFinish">업로드 완료</span>
                      </a>
                    )}
                  </>
                )}
                {!props.fileUrl && <div className="fileName">{props.fileName}</div>}
                {/* {props.fileUrl ? 
                            <a className="fileName finish" href={props.fileUrl} download={props.fileName} title={`${props.fileName} 다운로드`}>
                                {props.fileName.indexOf("\\") !== -1 ? props.fileName.split("\\").reverse()[0] : props.fileName}<span className="uploadFinish">업로드 완료</span>
                            </a> 
                        : 
                            <div className="fileName">{props.fileName}</div>
                        } */}
                <div className="fileSize" style={props.downloadBtn && { width: '320px' }}>
                  {props.fileSize}KB
                </div>
              </div>
              <DeleteBtn className="deleteBtn" onClick={() => fileDelete(null)} />
            </UploadFile>
          ) : (
            <DropContainer
              className={mouseOver ? 'over' : null}
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onClick={fileInputClicked}
              onDrop={(e) => fileDrop(e)}
            >
              {props.children}
            </DropContainer>
          )}
        </>
      )}
      {props.explainText && <ExplainText>{props.explainText}</ExplainText>}
      <UplaodText className="uplaodText">
        <Ul>
          {props.uploadText && (
            <>
              {props.customEx}
              {props.accept && <li>{acceptFileList()} 파일만 업로드 가능</li>}
              <li>PDF 파일은 보안(비밀번호)이 걸려있지 않은 파일로 업로드 가능</li>
            </>
          )}
          {props.addUploadText && <li>{props.addUploadText}</li>}
        </Ul>
      </UplaodText>
    </UploadBox>
  );
}

FileUpload.propTypes = {
  /**
   * name
   */
  name: PropTypes.string,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * id명
   */
  id: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 파일 여러개 업로드 여부
   */
  multiple: PropTypes.bool,
  /**
   * 첨부 가능한 파일 확장자
   */
  accept: PropTypes.string,
  /**
   * onClick 이벤트
   */
  onClick: PropTypes.func,
  /**
   * onChange 이벤트
   */
  onChange: PropTypes.func,
  /**
   * onDragOver 이벤트
   */
  onDragOver: PropTypes.func,
  /**
   * onDragEnter 이벤트
   */
  onDragEnter: PropTypes.func,
  /**
   * onDragLeave 이벤트
   */
  onDragLeave: PropTypes.func,
  /**
   * onDrop 이벤트
   */
  onDrop: PropTypes.func,
  /**
   * 파일명
   */
  fileName: PropTypes.string,
  /**
   * 파일 크기
   */
  fileSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * 파일 확장자
   */
  fileExtension: PropTypes.string,
  /**
   * 파일 url
   */
  fileUrl: PropTypes.string,
  /**
   *  이전에 업로드한 파일
   */
  files: PropTypes.array,
  /**
   *  업로드 파일
   */
  uploadFiles: PropTypes.array,
  /**
   * 파일 업로드 설명
   */
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * 파일 업로드 메시지 추가
   */
  addUploadText: PropTypes.string,
  /**
   * 부모 model
   */
  model: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * uploadFile model_업로드 하는 파일 담겨 있는 데이터
   */
  uploadModel: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * deleteIds model_삭제하는 파일의 id가 담겨 있는 데이터
   */
  deleteModel: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * 파일업로드 안내 문구
   */
  explainText: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.any]),
  /**
   * 파일업로드 관련 설명 여부
   */
  uploadText: PropTypes.bool,
  /**
   * 파일업로드 관련 설명 추가하는 경우
   */
  customEx: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.object, PropTypes.any]),
};

FileUpload.defaultProps = {
  name: null,
  className: null,
  id: null,
  style: {},
  multiple: false,
  accept: null,
  onClick: () => {},
  onChange: () => {},
  onDragOver: () => {},
  onDragEnter: () => {},
  onDragLeave: () => {},
  onDrop: () => {},
  fileName: 'File Name Empty',
  fileSize: null,
  fileExtension: null,
  fileUrl: null,
  files: [],
  uploadFiles: [],
  children: 'Empty',
  addUploadText: null,
  model: [],
  uploadModel: [],
  deleteModel: [],
  explainText: null,
  uploadText: true,
  customEx: false,
};

export default FileUpload;
