import { useObserver } from 'mobx-react';
import st from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';

function TCAgreeAndDeliveryAddressStep(props) {
  return useObserver(() => (
    <div className={clsx(st.row, st.before)}>
      <div className={st.dName}>주소</div>
      <div className={st.data}>{props.step.address || '-'}</div>
    </div>
  ));
}

export default TCAgreeAndDeliveryAddressStep;
