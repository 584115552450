import { useState } from 'react';

const useAiChat = () => {
  const [keyword, setKeyword] = useState('');

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const onClickSearchButton = () => {
    const encodedKeyword = encodeURIComponent(keyword);

    window.open(`https://blog.help-me.kr?aisearch=${encodedKeyword}`, '_blank');
  };

  return {
    keyword,
    handleInputChange,
    onClickSearchButton,
  };
};

export default useAiChat;
