import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';

function NewPosition(props) {
  return useObserver(() => (
    <div className={Qst.spaceBetween}>
      <div className={Qst.infoSection}>
        <div className={Qst.titleWrap}>
          <h2 className={Qst.title}>
            <span className={Qst.bold}>{props.parentVm.displayKoreanName(props.idx)}님</span>은 앞으로{' '}
            <span className={Qst.bold}>어떤 직책</span>을 맡나요?
          </h2>
        </div>
        <ul className={Qst.answerList}>
          {props.parentVm.newPositionList.map((position, idx2) => {
            return (
              <li key={idx2}>
                <Radio
                  className={Qst.answerLabel}
                  value={position.value}
                  activeBackground
                  checked={props.item.newPosition === position.value}
                  onChange={(e) => props.parentVm.setNewPosition(e, props.idx)}
                  name={`executiveMembers[${props.idx}].newPosition`}
                >
                  {position.text}
                </Radio>
              </li>
            );
          })}
        </ul>
        <Error
          name={`executiveMembers[${props.idx}].newPosition`}
          value={props.item.newPosition}
          errorCase={{ required: '현재 주민등록상 주소가 등기부상 주소와 같은지 선택해 주세요.' }}
        />
      </div>
      <div className={Qst.qnaSection}>
        <Qna className={Qst.noTitle}>
          <div className={Qst.qnaBox}>
            <QText>단독대표, 각자대표, 공동대표의 차이점은 무엇인가요?</QText>
            <div className="answerBox">
              <Answer className={Qst.answer} iconText="A1">
                단독대표는 1명이 회사의 대표를 맡는 경우입니다.
              </Answer>
              <p className={Qst.explain}>90% 고객님들은 단독대표를 선택합니다.</p>
              <Answer className={Qst.answer} iconText="A2">
                각자대표는 2명 이상의 대표가 각각 독립적으로 회사의 업무를 결정하는 경우입니다.
              </Answer>
              <p className={Qst.explain}>
                각자 대표의 경우, 각 대표가 단독으로 무엇이든 결정할 수 있기에, 회사 경영이 편리합니다. 다만 대표가
                잘못된 결정을 할 때, 사전에 막기 어렵다는 단점이 있습니다.
              </p>
              <Answer className={Qst.answer} iconText="A3">
                공동대표는 2명 이상의 대표가 항상 함께 회사의 업무를 결정하는 경우입니다.
              </Answer>
              <p className={Qst.explain}>
                공동대표는 항상 함께 결정해야 하고, 계약서에도 공동대표의 도장이 모두 들어가야 하므로, 회사 경영시,
                번거롭습니다. 하지만 경영권을 감시하기 위한 목적에는 적합합니다.
              </p>
            </div>
          </div>
        </Qna>
      </div>
    </div>
  ));
}

export default NewPosition;
