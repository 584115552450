import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Ul = styled.ul`
  li {
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${(props) => (props.bold === true ? '#2e2e2e' : '#585a5f')};
    }

    position: relative;
    padding-left: 13px;
    margin-top: 6px;
    color: ${(props) => (props.bold === true ? '#2e2e2e' : '#585a5f')};
    font-size: 16px;
    font-weight: ${(props) => (props.bold === true ? '500' : '400')};
    line-height: 1.44;
    letter-spacing: -0.96px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

function UnorderList(props) {
  return (
    <Ul className={props.className} style={props.style} bold={props.bold}>
      {props.children}
    </Ul>
  );
}

UnorderList.propTypes = {
  /**
   * class명 <br/>
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * text bold 여부
   */
  bold: PropTypes.bool,
  /**
   * &lt;li&gt;&lt;/li&gt; <br/>
   * 리스트 값
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

UnorderList.defaultProps = {
  className: null,
  style: {},
  bold: false,
  children: <li>Empty</li>,
};

export default UnorderList;
