import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function ParValueDivisionStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>변경할 액면금</div>
        <div className={Cst.data}>{addCommas(props.step.newParValue) + ' 원' || '-'}</div>
      </div>
    </>
  ));
}
export default ParValueDivisionStep;
