import ExecutiveMemberChangeStepModel from '@model/changeRegistration/ExecutiveMemberChangeStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import st from './ExecutiveMemberChangeCheck.module.scss';
import smartformAppModel from '@model/smartformAppModel';

class ExecutiveMemberChangeCheckVm {
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error === false) {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get originExecutiveMembers() {
    return ExecutiveMemberChangeStepModel.originExecutiveMembers;
  }
  changeTypes(idx) {
    let changeType = [];
    ExecutiveMemberChangeStepModel.originExecutiveMembers[idx]?.changeTypes?.forEach((type) => {
      changeType.push(type);
    });
    return changeType;
  }
  requiresDescriptionYn(idx) {
    return ExecutiveMemberChangeStepModel.originExecutiveMembers[idx].requiresDescriptionYn;
  }
  descriptionType(idx) {
    return ExecutiveMemberChangeStepModel.originExecutiveMembers[idx].descriptionType;
  }
  setFontSize(idx) {
    const koreanName = ExecutiveMemberChangeStepModel.originExecutiveMembers[idx].koreanName;
    let _className = null;
    if (koreanName.length > 10) {
      _className = st.long;
    }
    return _className;
  }
  setClassName(idx) {
    const changeTypeList = this.changeTypes(idx);
    let _className = null;
    if (changeTypeList.includes('사임_퇴임')) {
      _className = st.retire;
    } else if (changeTypeList.includes('변경없음')) {
      _className = st.noChange;
    }
    return _className;
  }
  setClassNameAndTitle(idx, idx2) {
    const changeType = ExecutiveMemberChangeStepModel.originExecutiveMembers[idx]?.changeTypes[idx2];
    let _className = null;
    let _title = '';
    switch (changeType) {
      case '임기연장':
        _title = '임기연장';
        _className = st.blue;
        break;
      case '보직변경':
        _title = '보직변경';
        _className = st.blue;
        break;
      case '집주소변경':
        _title = '집주소변경';
        _className = st.blue;
        break;
      case '사임_퇴임':
        _title = '사임/퇴임';
        _className = st.red;
        break;
      case '신규':
        _title = '신규';
        _className = st.green;
        break;
      default:
        _title = '변경없음';
        _className = st.gray;
        break;
    }
    return {
      className: _className,
      title: _title,
    };
  }
  get keepProceedYn() {
    return ExecutiveMemberChangeStepModel.keepProceedYn;
  }
  setKeepProceedYn(e) {
    runInAction(() => {
      ExecutiveMemberChangeStepModel.keepProceedYn = e.target.value === 'true';
    });
  }
  get text() {
    const smartFormType = this.smartFormType;
    switch (smartFormType) {
      case 'STOCK_COMPANY':
        return '사내이사';

      case 'AGRICULTURAL_STOCK_COMPANY':
        return '사내이사';

      case 'FISHERY_STOCK_COMPANY':
        return '사내이사';

      case 'LIMITED_COMPANY':
        return '이사';

      case 'LIMITED_LIABILITY_COMPANY':
        return '업무집행자';
    }
  }
  get standbyFormYn() {
    return ExecutiveMemberChangeStepModel.standbyFormYn;
  }
}

export default new ExecutiveMemberChangeCheckVm();
