import { runInAction, observable, toJS } from 'mobx';
import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import smartformAppModel from '@model/smartformAppModel';
import regExp from '@common/module/regExp';
import formDataToJson from '@common/module/submit';
import stepService from '@service/stepService';

class CheckAsEstimateVm {
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error === false) {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
  get standbyNewSharesInputs() {
    return StandbyPaidInCapitalIncreaseStepModel.standbyNewSharesInputs;
  }
  price(capital, count) {
    return capital / count;
  }
  get keepGoingYn() {
    return StandbyPaidInCapitalIncreaseStepModel.keepGoingYn;
  }
  setKeepGoingYn(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.keepGoingYn = e.target.value === 'true';
    });
  }
  get capitalTotal() {
    let total = 0;
    StandbyPaidInCapitalIncreaseStepModel.standbyNewSharesInputs.forEach((item) => {
      item?.shareDetails.forEach((stock) => {
        total = total + stock.capital;
      });
    });
    return total;
  }
  get countTotal() {
    let total = 0;
    StandbyPaidInCapitalIncreaseStepModel.standbyNewSharesInputs.forEach((item) => {
      item?.shareDetails.forEach((stock) => {
        total = total + stock.count;
      });
    });
    return total;
  }
}
export default new CheckAsEstimateVm();
