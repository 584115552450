import { observable, runInAction } from 'mobx';

const StandbyPaidInCapitalIncreaseStepModel = observable({
  keepGoingYn: null,
  standbyNewSharesInputs: [],
  newSharesAcquirers: [],
  newShares: [],
  sharesOnRegister: {
    classStocksOnRegister: [],
    totalCapitalStock: null,
    totalNumberOfSharesOnRegister: null,
  },
  existingShareholders: [],
  issuingNewShareProcess: null,
  newShareAssignmentType: null,
  noticeDate: {
    year: '',
    month: '',
    day: '',
  },
  noticeType: '',
  setCheckAsEstimate(data) {
    runInAction(() => {
      this.keepGoingYn = data.keepGoingYn ?? null;
      if (data?.standbyNewSharesInputs && data?.standbyNewSharesInputs.length !== 0) {
        this.standbyNewSharesInputs = data.standbyNewSharesInputs.map((item) => {
          return {
            isNewYn: item.isNewYn ?? false,
            name: item.name || '',
            paymentMethod: item.paymentMethod || '',
            shareDetails: item.shareDetails || [
              {
                name: '',
                count: -1,
                capital: -1,
              },
            ],
          };
        });
      } else {
        this.standbyNewSharesInputs = [];
      }
    });
  },
  setStandbySimpleNewSharesAcquirer(data) {
    runInAction(() => {
      if (data?.newSharesAcquirers && data?.newSharesAcquirers.length !== 0) {
        this.newSharesAcquirers = data.newSharesAcquirers.map((item) => {
          return {
            id: item.id || null,
            isNewYn: item.isNewYn ?? false,
            koreanAddress: item.koreanAddress || '',
            koreanName: item.koreanName || '',
            kssn: item.kssn || '',
            shareholderType: item.shareholderType || '',
            koreanCompanyRepresentatives: item.koreanCompanyRepresentatives
              ? item?.koreanCompanyRepresentatives?.map((item) => {
                  return {
                    id: item.id || null,
                    name: item.name || '',
                    position: item.position || '',
                  };
                })
              : [
                  {
                    id: null,
                    name: '',
                    position: '',
                  },
                ],
            birth: item.birth || {
              year: '',
              month: '',
              day: '',
            },
            englishName: item.englishName || '',
            englishAddress: item.englishAddress || '',
            nationalityOthers: item.nationalityOthers || '',
            nationalityType: item.nationalityType || '',
            residenceRegistrationYn: item.residenceRegistrationYn ?? false,
            foreignerCompanyRepresentatives: item.foreignerCompanyRepresentatives
              ? item.foreignerCompanyRepresentatives?.map((item) => {
                  return {
                    id: item.id || null,
                    koreanName: item.koreanName || '',
                    englishName: item.englishName || '',
                  };
                })
              : [
                  {
                    id: null,
                    koreanName: '',
                    englishName: '',
                  },
                ],
            representatives: item.representatives
              ? item?.representatives?.map((item) => {
                  return {
                    id: item.id || null,
                    name: item.name || '',
                  };
                })
              : [
                  {
                    id: null,
                    name: '',
                  },
                ],
          };
        });
      } else {
        this.newSharesAcquirers = [];
      }
      // this.newSharesAcquirers = [
      //     {
      //         id: "1",
      //         isNewYn: false,
      //         koreanAddress: "주소주소",
      //         koreanName: "백지연",
      //         shareholderType: "FOREIGNER_PERSON",
      //         birth: {
      //             year: "2023",
      //             month: "10",
      //             day: "10"
      //         },
      //         englishName: "englishNam",
      //         foreignAddress: "aaa",
      //         nationalityOthers: "",
      //         nationalityType: "일본",
      //         residenceRegistrationYn: false,
      //     }
      // ]
    });
  },
  setStandbyNewSharesAcquirer(data) {
    runInAction(() => {
      if (data?.newSharesAcquirers && data?.newSharesAcquirers.length) {
        this.newSharesAcquirers = data.newSharesAcquirers.map((newSharesAcquirer) => {
          let year = '',
            month = '',
            day = '';
          if (newSharesAcquirer.birth) {
            year = newSharesAcquirer.birth.substring(0, 4);
            month = newSharesAcquirer.birth.substring(5, 7);
            day = newSharesAcquirer.birth.substring(8, 10);
          }
          return {
            year: year || '',
            month: month || '',
            day: day || '',
            detailAddress: newSharesAcquirer.detailAddress || '',
            englishName: newSharesAcquirer.englishName || '',
            foreignAddress: newSharesAcquirer.foreignAddress || {
              englishAddress: '',
              koreanAddress: '',
            },
            foreignerCompanyRepresentatives: newSharesAcquirer.foreignerCompanyRepresentatives || [
              {
                id: '',
                englishName: '',
                koreanName: '',
              },
            ],
            id: newSharesAcquirer.id ?? '',
            koreanAddress: newSharesAcquirer.koreanAddress || '',
            englishAddress: newSharesAcquirer.englishAddress || '',
            koreanCompanyRepresentatives: newSharesAcquirer.koreanCompanyRepresentatives || [
              {
                id: '',
                name: '',
                position: '',
              },
            ],
            koreanName: newSharesAcquirer.koreanName || '',
            kssn: newSharesAcquirer.kssn || '',
            nameOfBuilding: newSharesAcquirer.nameOfBuilding || '',
            nationalityOthers: newSharesAcquirer.nationalityOthers || '',
            nationalityType: newSharesAcquirer.nationalityType || '',
            residenceRegistrationYn: newSharesAcquirer.residenceRegistrationYn ?? null,
            shareholderType: newSharesAcquirer.shareholderType || '',
            resourceType: newSharesAcquirer.resourceType || null,
            existingShareholdersYn: newSharesAcquirer.existingShareholdersYn ?? null,
            representatives: newSharesAcquirer.representatives || [
              {
                id: '',
                name: '',
              },
            ],
          };
        });
      } else {
        this.newSharesAcquirers = [];
      }
    });
  },
  setStandbyCapitalIncrease(data) {
    runInAction(() => {
      if (data?.newShares && data?.newShares.length !== 0) {
        this.newShares = data.newShares.map((newShare) => {
          return {
            id: newShare.id || '',
            name: newShare.name || '',
            가수금증자: newShare.가수금증자 ?? null,
            유상증자: newShare.유상증자 ?? null,
          };
        });
      } else {
        this.newShares = [];
      }
      this.sharesOnRegister.classStocksOnRegister = data?.sharesOnRegister?.classStocksOnRegister || [];
      this.sharesOnRegister.totalCapitalStock = data?.sharesOnRegister?.totalCapitalStock || null;
      this.sharesOnRegister.totalNumberOfSharesOnRegister =
        data?.sharesOnRegister?.totalNumberOfSharesOnRegister || null;
    });
  },
  setStandbyMethodOfIssuingNewShares(data) {
    if (data.existingShareholders && data.existingShareholders.length !== 0) {
      this.existingShareholders = data.existingShareholders.map((item) => {
        return {
          name: item.name || '',
          stocks: item.stocks || [
            {
              stockType: '',
              amount: null,
            },
          ],
        };
      });
    } else {
      this.existingShareholders = [];
    }
    this.issuingNewShareProcess = data?.issuingNewShareProcess || null;
    this.requiresNoti = data?.requiresNoti ?? false;
    this.newShareAssignmentType = data?.newShareAssignmentType || null;
    this.noticeDate = data?.noticeDate || {
      year: '',
      month: '',
      day: '',
    };
    this.noticeType = data?.noticeType || '';
  },
});

export default StandbyPaidInCapitalIncreaseStepModel;
