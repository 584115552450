import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './AddressVm.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import _AddressVm from './AddressVm';
import { useMemo } from 'react';

function Address(props) {
  const AddressVm = useMemo(() => new _AddressVm(props.model), [props.model]);
  return useObserver(() => (
    <>
      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
        <div className={Qst.infoSection}>
          <div className={Qst.titleWrap}>
            <h2 className={Qst.title}>
              {props.originAddress}의 임대차계약서상 <span className={Qst.bold}>신규주소를 입력</span>해 주세요.
            </h2>
          </div>
          <Badge className={st.addressBadge} title="예시" type="example" border={false}>
            <img
              className={st.addressBadgeImg}
              src="/images/contract_example.png?1"
              alt="임대차계약서 예시"
              style={{ width: '544px' }}
            />
          </Badge>
          <div className={Qst.rowWrap}>
            <InputText
              className={Qst.inputText}
              style={{ width: '380px' }}
              explain="도로명 주소"
              value={AddressVm.address?.address || ''}
              readOnly
              onClick={() => AddressVm.openAddressModal(true)}
              name={`${props.name}.address`}
              errorText={
                <Error
                  name={`${props.name}.address`}
                  value={AddressVm.address?.address || ''}
                  errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                />
              }
            />
            <SquareBtn onClick={() => AddressVm.openAddressModal(true)}>도로명 주소 찾기</SquareBtn>
            {AddressVm.state.addressModal && (
              <SearchAddress
                onClose={() => AddressVm.openAddressModal(false)}
                onComplete={(data) => AddressVm.addressComplate(data)}
              />
            )}
          </div>
          <div className={Qst.rowWrap}>
            <InputText
              className={Qst.inputText}
              style={{ width: '273px' }}
              explain="상세 주소"
              errorText={
                <Error
                  name={`${props.name}.detailAddress`}
                  value={AddressVm.detailAddress}
                  errorCase={{
                    required: '상세 주소를 입력해 주세요.',
                    pattern: {
                      value: regExp.onlyKorNum_hyphen_comma().test(AddressVm.detailAddress),
                      message: (
                        <span>
                          임대차 계약서상 영문이 있다면, <br />
                          한글로 옮겨적어주세요.
                          <br />
                          EX) B동 &gt; 비동
                        </span>
                      ),
                    },
                  }}
                />
              }
              placeholder="상세주소 없을 시, [없음] 입력"
              value={AddressVm.detailAddress}
              onChange={(e) => AddressVm.setDetailAddress(e)}
              name={`${props.name}.detailAddress`}
            />
            <div className={Qst.symbol}>
              <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
              <span className={clsx(Qst.symbolText, AddressVm.bnameFontSizeChange ? Qst.fontSize : null)}>
                {AddressVm.address?.bname || '법정동'},&nbsp;
              </span>
              <InputText
                style={{ width: '200px' }}
                explain="건물명"
                placeholder="없을 시, [없음] 입력"
                value={AddressVm.nameOfBuilding}
                onChange={(e) => AddressVm.setNameOfBuilding(e)}
                name={`${props.name}.nameOfBuilding`}
                errorText={
                  <Error
                    name={`${props.name}.nameOfBuilding`}
                    value={AddressVm.nameOfBuilding}
                    style={{ width: '260px' }}
                    errorCase={{
                      required: '건물명을 입력해 주세요.',
                      pattern: {
                        value: regExp.onlyKorNum_hyphen_comma().test(AddressVm.nameOfBuilding),
                        message: (
                          <span>
                            건물명이 영문인 경우, 한글
                            <br />
                            발음으로 옮겨적어 주세요. <br />
                            EX) AB타워 &gt; 에이비 타워
                          </span>
                        ),
                      },
                    }}
                  />
                }
              />
              <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
            </div>
          </div>
        </div>
        <div className={Qst.qnaSection}>
          <Qna className={Qst.noTitle}>
            <div className={Qst.qnaBox}>
              <QText>지번주소를 어떻게 도로명 주소로 바꾸나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>도로명주소 안내시스템 사이트에서 바꿀 수 있습니다.</Answer>
                <LikeBtn className={Qst.likeBtn} href="https://www.juso.go.kr/openIndexPage.do" target="_blank">
                  도로명주소 안내시스템 사이트 바로가기
                </LikeBtn>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>주소에는 영어를 쓸 수 있나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어 X)</Answer>
                <p className={Qst.explain}>임대차계약서상 영어가 들어가 있다면, 한글로 변환해 주세요.</p>
                <TextBox className={Qst.textBox} title="예시">
                  <div className={Qst.textWrap}>
                    <span className={Qst.false}>B동, 202-1001</span>
                    <Icon icon="arrow" className={Qst.iconArrow} />
                    <span className={Qst.true}>비동, 202동 1001호</span>
                  </div>
                </TextBox>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>임대차계약서를 사전에 준비해야 할까요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>임대차계약서를 미리 준비하는 것을 추천드립니다.</Answer>
                <p className={Qst.explain}>
                  지점이전등기 시에는 임대차계약서를 제출하지 않아도 되지만, 이전등기 후 사업자등록을 변경할 때 세무서에
                  제출해야 합니다. 이 때 임대차계약서의 주소와 등기부 주소가 일치하지 않으면 사업자등록이 거부됩니다.
                </p>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>공유오피스 주소로 지점주소를 이전할 수 있나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  원칙적으로 공유오피스로 지점주소를 이전할 수 있지만, 제한이 있습니다.
                </Answer>
                <p className={Qst.explain}>
                  세무서는 일부 업종의 경우, 공유오피스에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다.
                  (가정집보다는 사업자등록이 되는 경우가 더 많습니다.) 그 판단은 세무서마다 다릅니다. 따라서 관할
                  세무서에 미리 문의한 후, 진행하는 것을 추천드립니다. 이때 계약기간은 최소 3개월로 해 주세요. 계약
                  기간이 1개월이면 세무서에서 기간이 짧다는 이유로 3개월 혹은 6개월 이상의 계약서를 다시 제출하라고
                  요구하는 경우가 있습니다.
                </p>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>가정집 주소로 지점주소 이전이 가능한가요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  원칙적으로 가정집으로 지점주소 이전이 가능하지만, 제한이 있습니다.
                </Answer>
                <p className={Qst.explain}>
                  세무서는 일부 업종의 경우, 주거용 건물에서 영업할 수 없다고 판단해서 사업자등록을 허용하지 않습니다.
                  가정집에서 사업자등록이 어려운 업종의 경우, 다른 주소를 선택해 주세요.
                </p>
                <dl className={Qst.defList}>
                  <dt className={Qst.term}>가정집에 지점주소를 두는 것이 어려운 업종</dt>
                  <dd className={Qst.def}>도매업, 소매업, 판매업, 음식점업, 공장업, 제조업, 창고업, 건설업 등</dd>
                  <dt className={Qst.term} style={{ marginTop: '10px' }}>
                    가정집에 지점주소를 두는 것이 가능한 업종
                  </dt>
                  <dd className={Qst.def}>통신판매업, 소프트웨어 제작업, 컨설팅업 등</dd>
                </dl>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>전세집 주소로 지점주소 이전이 가능한가요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>
                  원칙적으로 전세집으로 지점주소를 이전할 수 있지만, 제한이 있습니다.
                </Answer>
                <p className={Qst.explain}>
                  가정집으로 지점주소 이전이 가능한 업종이어야 할 뿐만 아니라, 집주인으로부터 전대차동의서를 미리
                  받아두어야 합니다. 나중에 지점주소 이전 후 집주인이 전대차동의서를 써주지 않는다면, 사업자 등록을 할
                  수 없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해주세요. 전대차동의서 양식이
                  필요하다면 채팅상담으로 요청해주세요.
                </p>
              </div>
            </div>
            <div className={Qst.qnaBox}>
              <QText>임대차와 전대차 무엇이 다른가요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer} iconText="A1">
                  임대차는 회사가 건물주에게 직접 장소를 임차한 경우입니다.
                </Answer>
                <Answer className={Qst.answer} iconText="A2">
                  전대차는 회사가 건물주가 아닌 기존 임차인으로부터 다시 임차한 경우입니다.
                </Answer>
                <p className={Qst.explain}>
                  임대인과 신규 회사가 계약 당사자가 되어 전대차계약서를 작성합니다. 이 경우 반드시 건물주의 전대차
                  동의서를 첨부해야 합니다. 나중에 건물주가 전대차 동의서를 써주지 않는다면 사업자등록을 할 수 없습니다.
                  이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요.
                </p>
              </div>
            </div>
          </Qna>
        </div>
      </div>
    </>
  ));
}

export default Address;
