import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Ast from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function FreeIssueOfNewSharesStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>무상증자의 재원</div>
        <div className={Ast.data}>{props?.step?.resourceType || '-'}</div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>무상증자할 금액</div>
        <div className={Ast.data}>
          {props?.step?.amount ? (
            <>
              <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount))}>
                {addCommas(props?.step?.amount)}
              </CopyBtn>{' '}
              원
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>발행할 주식수</div>
        <div className={Ast.data}>
          {props?.step?.number ? (
            <>
              <CopyBtn onClick={() => copyText(addCommas(props?.step?.number))}>
                {addCommas(props?.step?.number)}
              </CopyBtn>{' '}
              주
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>발행가액(자동계산)</div>
        <div className={Ast.data}>
          {props?.step?.number ? (
            <>
              <CopyBtn onClick={() => copyText(addCommas(props?.step?.amount / props?.step?.number))}>
                {addCommas(props?.step?.amount / props?.step?.number)}
              </CopyBtn>{' '}
              원
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>배정기준일</div>
        <div className={Ast.data}>
          {props?.step?.recordDateForNewSharesAllotment ? (
            <CopyBtn onClick={() => copyText(props?.step?.recordDateForNewSharesAllotment)}>
              {props?.step?.recordDateForNewSharesAllotment}
            </CopyBtn>
          ) : (
            '-'
          )}
        </div>
      </div>
    </>
  ));
}
export default FreeIssueOfNewSharesStep;
