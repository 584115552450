import { observable, runInAction } from 'mobx';

const RepresentativeCheckModel = observable({
  representatives: [],
  setRepresentativeCheck(data) {
    runInAction(() => {
      if (data.representatives && data.representatives.length) {
        this.representatives = data.representatives.map((representative) => {
          return {
            id: representative.id || '',
            koreanName: representative.koreanName || '',
            foreignerYn: representative.foreignerYn ?? null,
            originPosition: representative.originPosition || '',
            kssn: representative.kssn || '',
            addressSameYn: representative.addressSameYn ?? null,
            originAddress: representative.originAddress || '',
            originCauseDateByEstablishmentYn: representative.originCauseDateByEstablishmentYn || false,
            newAddresses: representative.newAddresses || [
              {
                causeDate: {
                  year: '',
                  month: '',
                  day: '',
                },
                detailAddress: '',
                nameOfBuilding: '',
                newAddress: {},
              },
            ],
            originCauseDate: representative.originCauseDate || '',
          };
        });
      } else {
        this.representatives = [];
      }
    });
  },
});

export default RepresentativeCheckModel;
