import st from './ShareholderModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: function (name) {
    return (
      <div className={st.Modal}>
        <div className={st.title}>
          <span className={st.bold}>한국인 {name}</span> 또는 <span className={st.bold}>거소등록한 외국인 {name}</span>{' '}
          또는 <span className={st.bold}>한국법인 {name}</span>를 추가해 주세요.
        </div>
        <div className={st.modalContent}>
          <p className={st.content}>
            거소등록하지 않은 외국인 또는 외국법인이 단독 {name}로 참여하는 경우, 잔고증명서 발급을 위하여 아포스티유
            인증 또는 영사관 인증이 필요해서 서비스 제공을 하고 있지 않습니다.
          </p>
          <p className={st.content}>
            한국인 {name} 또는 거소등록한 외국인 {name} 또는 한국법인 {name}가 1명 더 있다면, 그 {name} 명의로
            잔고증명서 발급을 받으면 되므로, 서비스 제공이 가능합니다.
          </p>
        </div>
      </div>
    );
  },
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
