import { observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import BusinessPurposeRevision from './BusinessPurposeRevision/BusinessPurposeRevision';

const BusinessPurposeStep = observer(() => {
  return (
    <>
      {/* 목적 */}
      <Route
        path="/step/changeRegistration/BusinessPurposeStep/BusinessPurposeRevision"
        component={BusinessPurposeRevision}
      ></Route>
    </>
  );
});

export default BusinessPurposeStep;
