import { observable } from 'mobx';

const changeRegistrationModel = observable({
  isSubmitted: null,
  setIsSubmitted(isSubmitted) {
    this.isSubmitted = isSubmitted || false;
  },
});

export default changeRegistrationModel;
