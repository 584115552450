import StandbyFreeIssueOfNewSharesStepModel from '@model/changeRegistration/StandbyFreeIssueOfNewSharesStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction, observable, toJS } from 'mobx';
import regExp from '@common/module/regExp';
import { onlyNumber, removeCommas } from '@common/module/replaceNumber';

class StandbyFreeIssueOfNewSharesVm {
  checkedYnError() {
    let error = false;
    let checkCnt = 0;
    StandbyFreeIssueOfNewSharesStepModel.resourceTypes.forEach((resourceType, idx) => {
      if (resourceType.checkedYn) checkCnt++;
    });
    if (checkCnt === 0) {
      error = true;
    }
    return error;
  }
  submit(e) {
    e.preventDefault();
    if (this.checkedYnError() === false && formDataToJson(e.target).error === false) {
      let param = formDataToJson(e.target).data;
      param.amount = removeCommas(param.amount);
      param.number = removeCommas(param.number);
      stepService.saveStep(param);
    }
  }
  get resourceTypes() {
    return StandbyFreeIssueOfNewSharesStepModel.resourceTypes;
  }
  setCheckAll(_value) {
    let value;
    if (typeof _value === 'boolean') {
      value = _value;
    } else {
      value = _value.target.checked;
    }
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.resourceTypes.forEach((resourceType, idx) => {
        resourceType.checkedYn = value;
      });
    });
  }
  get checkAll() {
    let checked = true;
    if (StandbyFreeIssueOfNewSharesStepModel.resourceTypes.length) {
      StandbyFreeIssueOfNewSharesStepModel.resourceTypes.forEach((item) => {
        if (item.checkedYn === false) {
          checked = false;
        }
      });
    } else {
      checked = false;
    }
    return checked;
  }
  get checkCount() {
    let count = 0;
    StandbyFreeIssueOfNewSharesStepModel.resourceTypes.forEach((resourceType, idx) => {
      if (resourceType.checkedYn) {
        count++;
      }
    });
    return count;
  }
  setCheckedYn(e, idx) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.resourceTypes[idx].checkedYn = e.target.checked;
    });
  }
  get amount() {
    return StandbyFreeIssueOfNewSharesStepModel.amount;
  }
  get number() {
    return StandbyFreeIssueOfNewSharesStepModel.number;
  }
  get parValue() {
    return StandbyFreeIssueOfNewSharesStepModel.parValue;
  }
  setAmount(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.amount = onlyNumber(removeCommas(e.target.value));
    });
  }
  setNumber(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.number = onlyNumber(removeCommas(e.target.value));
    });
  }
  amountIssuePriceValidateFunc() {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const amount = Number(StandbyFreeIssueOfNewSharesStepModel.amount);
    const number = Number(StandbyFreeIssueOfNewSharesStepModel.number);
    if (amount === 0) {
      _validation = false;
      _message = '무상증자할 금액을 입력해 주세요.';
      _numberCheckYn = true;
    } else if (amount > 0 && number > 0) {
      //무상증자할 금액, 주식수 모두 입력된 경우
      if (amount % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '발행가액이 원단위로 나와야 합니다. 무상증자할 금액 또는 주식수를 조정해주세요.';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  numberIssuePriceValidateFunc() {
    let _validation = null;
    let _message = '';
    let _numberCheckYn = false;
    const amount = Number(StandbyFreeIssueOfNewSharesStepModel.amount);
    const number = Number(StandbyFreeIssueOfNewSharesStepModel.number);
    if (number === 0) {
      _validation = false;
      _message = '발행할 주식수를 입력해 주세요.';
      _numberCheckYn = true;
    } else if (amount > 0 && number > 0) {
      //무상증자할 금액, 주식수 모두 입력된 경우
      if (amount % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        _validation = true;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        _validation = false;
        _message = '';
      }
    } else {
      _validation = true;
    }
    return {
      validation: _validation,
      message: _message,
      numberCheckYn: _numberCheckYn,
    };
  }
  issuePrice() {
    const amount = Number(StandbyFreeIssueOfNewSharesStepModel.amount);
    const number = Number(StandbyFreeIssueOfNewSharesStepModel.number);
    let issuePrice = 0;
    if (amount > 0 && number > 0) {
      //무상증자할 금액, 주식수 모두 입력된 경우
      if (amount % number === 0) {
        //발행가액이 원단위로 떨어지는 경우
        issuePrice = amount / number;
        return issuePrice;
      } else {
        //발행가액이 원단위로 떨어지지 않는 경우
        issuePrice = (amount / number).toFixed(2);
        const priceList = issuePrice.split('.');
        return { issuePrice: priceList[0], point: priceList[1] };
      }
    }
    return String(issuePrice);
  }
  get recordDateForNewSharesAllotment() {
    return StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment;
  }
  setYear(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.year = e.target.value;
    });
  }
  setMonth(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.month = e.target.value;
    });
  }
  setDay(e) {
    runInAction(() => {
      StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.day = e.target.value;
    });
  }
  dateBlur(e, target) {
    let val = e.target.value;
    if (val && val.length === 1) {
      e.target.value = '0' + val;
    }
    if (target === 'month') {
      StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.month = e.target.value;
    }
    if (target === 'day') {
      StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.day = e.target.value;
    }
  }
  displayBirthErrorMessage(type) {
    const year = StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.year;
    const month = StandbyFreeIssueOfNewSharesStepModel.recordDateForNewSharesAllotment.month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  display액면금() {
    const amount = this.amount;
    const number = this.number;
    const parValue = this.parValue;
    const issuePrice = Number(amount / number);
    if (parValue === issuePrice) {
      return true;
    } else {
      return false;
    }
  }
}

export default new StandbyFreeIssueOfNewSharesVm();
