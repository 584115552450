import CloseBtn from '@common/components/atoms/Button/CloseBtn/CloseBtn';
import { observer } from 'mobx-react';

import st from './style.module.scss';

const SearchField = observer(({ searchMob }) => {
  return (
    <div className={st.container}>
      <div className={st.inputWrapper}>
        <input
          type="text"
          placeholder="사업목적 검색"
          value={searchMob.keyword}
          onChange={(e) => searchMob.setKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.keyCode === 13) {
              e.stopPropagation();
              e.preventDefault();

              searchMob.search();
            }
          }}
        />
        <CloseBtn type="button" className={st.close} onClick={() => searchMob.reset()} />
      </div>
      <button type="button" className={st.searchButton} onClick={() => searchMob.search()}>
        검색하기
      </button>
    </div>
  );
});

export default SearchField;
