import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import formDataToJson from '@common/module/submit';
import { runInAction } from 'mobx';
import regExp from '@common/module/regExp';
import stepService from '@service/stepService';
import portalModel from '@model/portalModel';
import Case1Modal from '@pages/ModalContent/changeRegistration/MethodOfIssuingNewShares/Case1Modal';
import Case2Modal from '@pages/ModalContent/changeRegistration/MethodOfIssuingNewShares/Case2Modal';

class MethodOfIssuingNewSharesVm {
  get existingShareholders() {
    return StandbyPaidInCapitalIncreaseStepModel.existingShareholders;
  }
  get newShareAssignmentType() {
    return StandbyPaidInCapitalIncreaseStepModel.newShareAssignmentType;
  }
  setNewShareAssignmentType(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newShareAssignmentType = e.target.value;
      StandbyPaidInCapitalIncreaseStepModel.issuingNewShareProcess = null; //신주발행 절차 초기화
    });
  }
  get issuingNewShareProcess() {
    return StandbyPaidInCapitalIncreaseStepModel.issuingNewShareProcess;
  }
  setIssuingNewShareProcess(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.issuingNewShareProcess = e.target.value;
    });
  }
  get noticeType() {
    return StandbyPaidInCapitalIncreaseStepModel.noticeType;
  }
  get noticeDate() {
    return StandbyPaidInCapitalIncreaseStepModel.noticeDate;
  }
  setNoticeDateYear(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.noticeDate.year = e.target.value;
    });
  }
  setNoticeDateMonth(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.noticeDate.month = e.target.value;
    });
  }
  setNoticeDateDay(e) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.noticeDate.day = e.target.value;
    });
  }
  noticeDateMonthBlur(e) {
    let month = e.target.value;
    if (month && month.length === 1) {
      e.target.value = '0' + month;
    }
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.noticeDate.month = e.target.value;
    });
  }
  noticeDateDayBlur(e) {
    let day = e.target.value;
    if (day && day.length === 1) {
      e.target.value = '0' + day;
    }
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.noticeDate.day = e.target.value;
    });
  }
  displayBirthErrorMessage(type) {
    const year = StandbyPaidInCapitalIncreaseStepModel.noticeDate.year;
    const month = StandbyPaidInCapitalIncreaseStepModel.noticeDate.month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;

      if (
        (this.issuingNewShareProcess === '신주발행_주주_통지' || this.issuingNewShareProcess === '주주_동의서_제출') &&
        StandbyPaidInCapitalIncreaseStepModel.requiresNoti === true
      ) {
        //팝업 & 저장(팝업에서 저장)
        let modal = null;
        if (this.issuingNewShareProcess === '신주발행_주주_통지') {
          modal = <Case1Modal param={param} />;
        } else if (this.issuingNewShareProcess === '주주_동의서_제출') {
          modal = modal = <Case2Modal param={param} />;
        }
        runInAction(() => {
          portalModel.modalContent = modal;
          portalModel.btn = null;
          portalModel.closeBtn = true;
        });
      } else if (
        this.issuingNewShareProcess === '신주발행_홈페이지_신문사_공고' ||
        StandbyPaidInCapitalIncreaseStepModel.requiresNoti === false
      ) {
        stepService.saveStep(param);
      }
    }
  }
}

export default new MethodOfIssuingNewSharesVm();
