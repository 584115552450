import { observer } from 'mobx-react';
import { forwardRef, useCallback, useEffect, useRef } from 'react';

import st from './style.module.scss';

const ConsonantGroup = observer(
  forwardRef(function ConsonantGroup({ index, consonant, children, consonantVisibility }, ref) {
    const currentRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              consonantVisibility[consonant] = index;
            } else {
              consonantVisibility[consonant] = null;
            }
          });
        },
        { root: document.getElementById('all-purpose-groups-container'), threshold: 0.1 },
      );

      if (currentRef.current) {
        observer.observe(currentRef.current);
      }

      return () => {
        if (currentRef.current) {
          observer.unobserve(currentRef.current);
        }
      };
    }, [currentRef.current]);

    const setRef = useCallback(
      (el) => {
        ref(el);
        currentRef.current = el;
      },
      [ref],
    );

    return (
      <div className={st.container} ref={setRef}>
        {children}
      </div>
    );
  }),
);

export default ConsonantGroup;
