import { useObserver } from 'mobx-react';
import st from './StandByStockholderMeetingParticipantModal.module.scss';

function StockholdersSpecialModal(props) {
  return useObserver(() => (
    <>
      {props.agreeType === '찬성' && (
        <div className={st.Modal}>
          <div className={st.title}>
            <span className={st.bold}>고객님!</span> 찬성한 주주가 부족해요.
          </div>
          <div className={st.modalContent}>
            주주총회 특별결의를 하려면, 출석한 주주의 주식 수의 3분의 2이상의 수와 발행 주식총수의 3분의 1 이상의 수가
            찬성해야 해요. (의결권 있는 주식 수 기준)
          </div>
        </div>
      )}
      {props.agreeType === '인감증명서' && (
        <div className={st.Modal}>
          <div className={st.title}>
            <span className={st.bold}>고객님!</span> 인감증명서를 제공할 주주가 부족합니다.
          </div>
          <div className={st.modalContent}>
            주주총회 특별결의를 하려면, 출석한 주주의 주식 수의 3분의 2이상의 수와 발행 주식총수의 3분의 1 이상의 수가
            인감증명서를 제공해야 합니다. (의결권 있는 주식 수 기준)
          </div>
        </div>
      )}
    </>
  ));
}

export default StockholdersSpecialModal;
