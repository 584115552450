import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function StandbyParValueDivisionStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>변경할 액면금</div>
        <div className={Ast.data}>{addCommas(props.step.newParValue) + ' 원' || '-'}</div>
      </div>
      <div className={clsx(Ast.row)}>
        <div className={Ast.dName}>
          스탠바이 입력
          <br />
          내용 일치여부
        </div>
        <div className={Ast.data}>{props.step.keepGoingYn ? '네' : '아니오'}</div>
      </div>
    </>
  ));
}
export default StandbyParValueDivisionStep;
