import { useObserver } from 'mobx-react';
import st from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { addCommas, percent } from '@common/module/replaceNumber';
import { Fragment } from 'react';
import vm from './StandbyPaidInCapitalIncreaseStepVm';

function StandbyPaidInCapitalIncreaseStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(st.row)}>
        <div className={st.dName}>
          신주배정된 이유
          <br />
          (기존주주)
        </div>
        <div className={st.data}>{vm.newShareAssignmentMethod(props.step.newShareAssignmentMethod)}</div>
      </div>
      <div className={clsx(st.row)}>
        <div className={st.dName}>신주발행절차</div>
        <div className={st.data}>
          {vm.issuingNewShareProcess(props.step.issuingNewShareProcess, props.step.noticeType)}
        </div>
      </div>
      {props.step.noticeDate && (
        <div className={clsx(st.row)}>
          <div className={st.dName}>
            {props.step.noticeType === '신주발행_주주_통지' && '통지일'}
            {props.step.noticeType === '신주발행_홈페이지_신문사_공고' && '공고일'}
          </div>
          <div className={st.data}>{props.step.noticeDate}</div>
        </div>
      )}
      <table className={st.lightTable} style={{ marginTop: '20px' }}>
        <colgroup>
          <col width="135px" />
          <col />
          <col width="140px" />
          <col width="140px" />
          <col width="140px" />
          <col width="140px" />
          <col width="180px" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>인적사항</th>
            <th style={{ textAlign: 'center' }}>발행방법</th>
            <th style={{ textAlign: 'center' }}>주식종류</th>
            <th style={{ textAlign: 'right' }}>투자금</th>
            <th style={{ textAlign: 'right' }}>주식수</th>
            <th style={{ textAlign: 'right' }}>발행가액(자동계산)</th>
          </tr>
        </thead>
        <tbody>
          {props.step.shareholders.map((shareholder, index) => (
            <Fragment key={index}>
              <tr>
                <td
                  className={clsx(st.bottom, st.top)}
                  rowSpan={shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length + 1}
                  style={{ paddingLeft: '0' }}
                >
                  <div className={st.name}>
                    {shareholder.koreanName}
                    {shareholder.englishName && <div className={st.englishName}>({shareholder.englishName})</div>}
                  </div>
                  <div>{shareholder.existingShareholdersYn ? '기존주주' : '제3자'}</div>
                </td>
                <td
                  className={clsx(st.bottom, st.top)}
                  rowSpan={shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length + 1}
                  style={{ textAlign: 'center' }}
                >
                  {(shareholder.shareholderType === 'FOREIGNER_COMPANY' ||
                    shareholder.shareholderType === 'KOREAN_COMPANY') && (
                    <div style={{ marginBottom: '8px' }}>
                      {shareholder.representatives.map((representative, index2) => (
                        <div key={index2}>
                          {representative.position} {representative.koreanName}
                          {representative.englishName && <span>({representative.englishName})</span>}
                        </div>
                      ))}
                    </div>
                  )}
                  {shareholder.shareholderType === 'FUND_AND_INVESTMENT_ASSOCIATION' && (
                    <div style={{ marginBottom: '8px' }}>
                      {shareholder.representatives.map((representative, index2) => (
                        <div key={index2}>대표 {representative.koreanName}</div>
                      ))}
                    </div>
                  )}
                  {shareholder.birth && <div style={{ marginBottom: '8px' }}>{shareholder.birth}</div>}
                  {shareholder.englishName && <div style={{ marginBottom: '8px' }}>{shareholder.nationality}</div>}
                  <div>
                    {shareholder.koreanAddress}
                    {shareholder.englishAddress && <div>({shareholder.englishAddress})</div>}
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {shareholder.유상증자Stocks.map((유상증자Stock, index2) => (
                <tr
                  key={index2}
                  className={clsx(
                    shareholder.가수금증자Stocks.length + shareholder.유상증자Stocks.length === index2 + 1
                      ? st.bottom
                      : null,
                    index2 === 0 ? st.top : null,
                  )}
                >
                  <td style={{ textAlign: 'center' }}>{index2 === 0 && '유상증자'}</td>
                  <td style={{ textAlign: 'center' }}>{유상증자Stock.name}</td>
                  <td style={{ textAlign: 'right' }}>{addCommas(유상증자Stock.investment)} 원</td>
                  <td style={{ textAlign: 'right' }}>{addCommas(유상증자Stock.number)} 주</td>
                  <td style={{ textAlign: 'right' }}>
                    {addCommas(유상증자Stock.investment / 유상증자Stock.number)} 원
                  </td>
                </tr>
              ))}
              {shareholder.가수금증자Stocks.map((가수금증자Stock, index2) => (
                <tr
                  key={index2}
                  className={clsx(
                    shareholder.가수금증자Stocks.length === index2 + 1 ? st.bottom : null,
                    shareholder.유상증자Stocks.length === 0 && index2 === 0 ? st.top : null,
                  )}
                >
                  <td style={{ textAlign: 'center' }}>{index2 === 0 && '가수금증자'}</td>
                  <td style={{ textAlign: 'center' }}>{가수금증자Stock.name}</td>
                  <td style={{ textAlign: 'right' }}>{addCommas(가수금증자Stock.investment)} 원</td>
                  <td style={{ textAlign: 'right' }}>{addCommas(가수금증자Stock.number)} 주</td>
                  <td style={{ textAlign: 'right' }}>
                    {addCommas(가수금증자Stock.investment / 가수금증자Stock.number)} 원
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      <div className={clsx(st.row)}>
        <div className={st.dName}>
          스탠바이 입력
          <br />
          내용 일치여부
        </div>
        <div className={st.data}>{props.step.keepGoingYn ? '네' : '아니오'}</div>
      </div>
    </>
  ));
}
export default StandbyPaidInCapitalIncreaseStep;
