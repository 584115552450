import { useObserver } from 'mobx-react';
import { Route } from 'react-router-dom';
import ExecutiveMemberChangeCheck from './ExecutiveMemberChangeCheck/ExecutiveMemberChangeCheck';
import ExecutiveMemberChangeOriginChange from './ExecutiveMemberChangeOriginChange/ExecutiveMemberChangeOriginChange';
import ExecutiveMemberChangeNewChange from './ExecutiveMemberChangeNewChange/ExecutiveMemberChangeNewChange';
import ExecutiveMemberChangeRepresentativeCheck from './ExecutiveMemberChangeRepresentativeCheck/ExecutiveMemberChangeRepresentativeCheck';

function ExecutiveMemberChangeStep() {
  return useObserver(() => (
    <>
      {/* 임원변경 확인 */}
      <Route
        path="/step/changeRegistration/ExecutiveMemberChangeStep/ExecutiveMemberChangeCheck"
        component={ExecutiveMemberChangeCheck}
      ></Route>
      {/* 기존 등기부상 임원정보 입력 */}
      <Route
        path="/step/changeRegistration/ExecutiveMemberChangeStep/ExecutiveMemberChangeOriginChange"
        component={ExecutiveMemberChangeOriginChange}
      ></Route>
      {/* 신규 임원정보 입력 */}
      <Route
        path="/step/changeRegistration/ExecutiveMemberChangeStep/ExecutiveMemberChangeNewChange"
        component={ExecutiveMemberChangeNewChange}
      ></Route>
      {/* 대표자 주민번호 주소 확인 */}
      <Route
        path="/step/changeRegistration/ExecutiveMemberChangeStep/ExecutiveMemberChangeRepresentativeCheck"
        component={ExecutiveMemberChangeRepresentativeCheck}
      ></Route>
    </>
  ));
}

export default ExecutiveMemberChangeStep;
