import { toJS } from 'mobx';
import { Fragment } from 'react';
import { useObserver } from 'mobx-react';
import clsx from 'clsx';
import st from '../FileUploadStep.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import Upload from '@comComponents/molecules/FileUpload/FileUpload';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function AOA(props) {
  return useObserver(() => (
    <Question>
      <ContentBox className={Qst.spaceBetween}>
        <div className={Qst.infoSection}>
          {props.item.details.map((detail, idx2) => (
            <Fragment key={idx2}>
              {detail.fileType === '기존_정관' && (
                <>
                  <div className={Qst.titleWrap}>
                    <h2 className={Qst.title}>
                      <span className={Qst.bold}>기존 정관</span>을 업로드해 주세요.
                    </h2>
                  </div>
                  <div className={st.AOABox}>
                    <img className={st.AOABoxImg} src="/images/AOA_example.png" alt="기존 정관 안내 이미지" />
                  </div>
                  <Upload
                    className={clsx(st.fileUpload, `${detail.fileType}`)}
                    multiple
                    accept=".docx, .hwp, .pdf"
                    model={props.parentVm.fileGroupTypes[props.idx].details[idx2].files}
                    uploadModel={props.parentVm.fileGroupTypes[props.idx].details[idx2].uploadFiles}
                    deleteModel={props.parentVm.deleteIds}
                    files={toJS(props.parentVm.files(props.idx, idx2))}
                    uploadFiles={toJS(props.parentVm.uploadFiles(props.idx, idx2))}
                  >
                    <span className={st.bold}>여기</span>를 눌러 업로드해 주세요.
                  </Upload>
                  {props.parentVm.state.errorCheckYn && props.parentVm.displayErrorText(detail.fileType) && (
                    <ErrorText>기존 정관을 업로드해 주세요.</ErrorText>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </div>
        <div className={Qst.qnaSection}>
          <Qna className={Qst.noTitle}>
            <div className={Qst.qnaBox}>
              <QText>기존 정관이란 어떤 것을 말하나요?</QText>
              <div className="answerBox">
                <Answer className={Qst.answer}>현재 사용하고 있는 정관을 말합니다.</Answer>
                <TextBox className={Qst.textBox} title="예시">
                  <div className={clsx(Qst.textWrap, st.textWrap)}>
                    <span className={st.text}>설립 후 정관변경을 한 적이 없는 경우</span>
                    <Icon icon="arrow" className={st.arrow} />
                    <span className={st.bold}>최초 정관</span>
                  </div>
                  <div className={clsx(Qst.textWrap, st.textWrap)}>
                    <span className={st.text}>설립 후 정관변경을 한 적이 있는 경우</span>
                    <Icon icon="arrow" className={st.arrow} />
                    <span className={st.bold}>변경된 정관</span>
                  </div>
                  <div className={clsx(Qst.textWrap, st.textWrap)}>
                    <span className={st.text}>
                      이번에 헬프미에 정관변경
                      <br />
                      업무를 의뢰한 경우
                    </span>
                    <Icon icon="arrow" className={st.arrow} />
                    <span className={st.bold}>아직 변경되지 않은 정관</span>
                  </div>
                </TextBox>
              </div>
            </div>
          </Qna>
        </div>
      </ContentBox>
    </Question>
  ));
}

export default AOA;
