import ContentBox from '@commonComponents/ContentBox/ContentBox';
import { observer } from 'mobx-react';

import st from './style.module.scss';

const Banner = observer(() => {
  return (
    <ContentBox>
      <div className={st.container}>
        <img className={st.banner} src="/images/사업목적_상단배너.png" alt="사업목적_배너" />
      </div>
    </ContentBox>
  );
});

export default Banner;
