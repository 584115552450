import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './StandbyFreeIssueOfNewSharesStepVm';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './StandbyFreeIssueOfNewSharesStep.module.scss';
import clsx from 'clsx';
import { addCommas } from '@common/module/replaceNumber';

function StandbyFreeIssueOfNewSharesStep(props) {
  return useObserver(() => (
    <>
      {props.step.keepGoingYn ? (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>무상증자 방식</div>
            <div className={Cst.data}>{props.step.freeIssueOfNewSharesType || '-'}</div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              무상증자
              <br />
              배정표
            </div>
            <div className={Cst.data}>
              <table className={st.table}>
                <colgroup>
                  <col width="240px" />
                  <col width="100px" />
                  <col width="220px" />
                </colgroup>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'left' }}>주주명</th>
                    <th style={{ textAlign: 'center' }}>
                      지분율
                      <br />
                      (=배당률)
                    </th>
                    <th style={{ textAlign: 'center' }}>신주식 수</th>
                    <th style={{ textAlign: 'right' }}>무상증자할 주식수</th>
                  </tr>
                </thead>
                <tbody>
                  {props.step.freeIssueOfNewSharesAllotmentInfos.map((info, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: 'left' }}>{info.name}</td>
                      <td style={{ textAlign: 'center' }}>{info.shareRatio}%</td>
                      {idx === 0 && (
                        <td
                          rowSpan={props.step.freeIssueOfNewSharesAllotmentInfos.length}
                          style={{ textAlign: 'center' }}
                          className={st.sum}
                        >
                          {addCommas(vm.sum(props.step.freeIssueOfNewSharesAllotmentInfos))}
                        </td>
                      )}
                      <td style={{ textAlign: 'right' }} className={st.newStockAmount}>
                        {addCommas(info.newStockAmount)}주
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>
              등기부상 자본금
              <br />
              변동
            </div>
            <div className={Cst.data}>
              <ul className={st.changeCapital}>
                <li>
                  <div>기존</div>
                  <div className={st.light}>{addCommas(props.step.capital)}원</div>
                </li>
                <li>
                  <div>무상증자</div>
                  <div className={st.light}>+{addCommas(props.step.freeIssueOfNewSharesCapital)}원</div>
                </li>
                <li>
                  <div>신규</div>
                  <div className={st.important}>
                    {addCommas(props.step.capital + props.step.freeIssueOfNewSharesCapital)}원
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>무상증자의 재원</div>
            <div className={Cst.data}>{props?.step?.resourceType || '-'}</div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>무상증자할 금액</div>
            <div className={Cst.data}>{addCommas(props?.step?.amount) + ' 원' || '-'}</div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>발행할 주식수</div>
            <div className={Cst.data}>{addCommas(props?.step?.number) + ' 주' || '-'}</div>
          </div>
          <div className={clsx(Cst.row)}>
            <div className={Cst.dName}>배정기준일</div>
            <div className={Cst.data}>{props?.step?.recordDateForNewSharesAllotment || '-'}</div>
          </div>
        </>
      )}
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>
          스탠바이 입력
          <br />
          내용 일치여부
        </div>
        <div className={Cst.data}>{props.step.keepGoingYn ? '네' : '아니오'}</div>
      </div>
    </>
  ));
}
export default StandbyFreeIssueOfNewSharesStep;
