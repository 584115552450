import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import { runInAction, observable } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';

class KoreanPersonVm {
  setKoreanName(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress = e.target.value;
    });
  }
  addKoreanCompanyRepresentatives(idx) {
    if (StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx]?.koreanCompanyRepresentatives[0]?.position) {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position:
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[0].position,
      });
    } else {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: '',
      });
    }
  }
  koreanCompanyRepresentatives_position(e, idx) {
    //직책
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.forEach(
        (item, idx2) => {
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[idx2].position =
            e.target.value;
        },
      );
    });
  }
  koreanCompanyRepresentatives_name(e, idx, idx2) {
    //성함
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[idx2].name =
        e.target.value;
    });
  }
  async setKoreanCompanyRepresentativesCloseBtn(idx, idx2) {
    const koreanCompanyRepresentatives =
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives;
    let name = koreanCompanyRepresentatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return koreanCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  disabledRepresentativePositionYn(idx, idx2, reviseInfoListRepresentative) {
    let disabledYn = false;
    if (idx2 !== 0) {
      disabledYn = true;
    }
    if (reviseInfoListRepresentative === false) {
      disabledYn = true;
    }
    return disabledYn;
  }
}

export default new KoreanPersonVm();
