import st from './ExecutiveModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title}>
        <span className={st.bold}>주식을 가지고 있지 않은 임원</span>을 추가해 주세요.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>
          상법상 주식회사를 설립할 때에는 발기인총회에서 조사보고를 할 사람("조사 보고자")이 필요하기 때문입니다.
          조사보고자는 주식을 가지고 있지 않은 임원 또는 공증 변호사만이 맡을 수 있습니다. 모든 임원이 주주가 되는 경우,
          조사보고자를 맡을 사람이 없기 때문에, 공증 변호사를 선임해야 합니다. 이런 경우, 160만 원 가량의 추가 비용이
          들기 때문에, 경제적이지 않습니다.
        </p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
