import ArrowRight from '@common/components/asset/icon/ico_arrowRight.svg';
import HighlightTypography from '@pages/common/HighlightTypography';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import st from './style.module.scss';

/**
 * @description 인기 목적모음의 그룹 리스트 및 검색 결과의 그룹 리스트에 사용되는 ui 컴포넌트입니다.
 */

const GroupTitle = observer(
  ({
    groupNames = [],
    contentLength = 0,
    useDropDown = true,
    isDropDownOpened = false,
    onClickOpenDropDown,
    highlightText = '',
  }) => (
    <div className={st.titleWrap}>
      <div className={st.title}>
        <img src="/images/Img_폴더.png" alt="인기 목적모음" />
        <HighlightTypography highlight={highlightText} highlightClassname={st.highlight}>
          {groupNames[0] || ''}
        </HighlightTypography>
        <img src={ArrowRight} className={st.arrowRightIcon} alt="arrowRight" />
        <HighlightTypography className={st.strong} highlight={highlightText} highlightClassname={st.highlight}>
          {groupNames[1] || ''}
        </HighlightTypography>
        <span className={st.count}>{`(${contentLength}개)`}</span>
      </div>
      {useDropDown && (
        <button
          type="button"
          className={clsx(st.upDownButton, isDropDownOpened && st.open)}
          onClick={onClickOpenDropDown}
        />
      )}
    </div>
  ),
);

const Divider = () => <div className={st.divider} />;

const PurposeRow = ({ className, content, size = 'small', highlightText = '' }) => {
  return (
    <div className={clsx(st.purpose, st[size], className)}>
      <img src="/images/Img_노트.png" alt="사업 목적" />
      <HighlightTypography highlight={highlightText} highlightClassname={st.highlight}>
        {content}
      </HighlightTypography>
    </div>
  );
};

const GroupListItem = Object.assign({}, { GroupTitle, Divider, PurposeRow });

export default GroupListItem;
