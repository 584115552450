import { observable, runInAction } from 'mobx';

const FileUploadStepModel = observable({
  fileGroupTypes: [],
  deleteIds: [], //삭제 파일 id 목록
  setFileUpload(data) {
    runInAction(() => {
      if (data.fileGroupTypes && data.fileGroupTypes.length) {
        this.fileGroupTypes = data.fileGroupTypes.map((file) => {
          return {
            fileGroupType: file.fileGroupType || '',
            details:
              file.details.map((detail) => {
                return {
                  fileType: detail.fileType || '',
                  files: detail.files || [],
                  uploadFiles: [],
                };
              }) || [],
          };
        });
      } else {
        this.fileGroupTypes = [];
      }
      this.deleteIds = [];
    });
  },
});

export default FileUploadStepModel;
