import { useObserver } from 'mobx-react';
import TCAgree from './TCAgree/TCAgree';
import { Route } from 'react-router-dom';

function TermsAndConditionsAgree() {
  return useObserver(() => (
    <>
      {/* 이용약관동의 */}
      <Route path="/step/establish/TermsAndConditionsAgree/TCAgree" component={TCAgree}></Route>
    </>
  ));
}

export default TermsAndConditionsAgree;
