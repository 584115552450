import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
  height: 120px;
  padding: 0 18px;
  background-color: #006ac3;
  border: none;
  border-radius: 160px;
`;

const Text = styled.span`
  position: relative;
  top: -1px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
`;

function CircleBtn(props) {
  return (
    <Btn type={props.type} className={props.className} onClick={(e) => props.onClick(e)} style={props.style}>
      <Text>{props.children}</Text>
    </Btn>
  );
}

CircleBtn.propTypes = {
  /**
   * 버튼 타입
   */
  type: PropTypes.string,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 버튼 텍스트 값
   */
  children: PropTypes.string,
};

CircleBtn.defaultProps = {
  type: 'button',
  className: null,
  onClick: () => {},
  style: {},
  children: 'Empty',
};

export default CircleBtn;
