import { get, post } from '@common/module/httpRequest';
import agriculturalFisheryDocumentUploadModel from '@model/agriculturalFisheryDocumentUploadModel';
import balanceCertificateModel from '@model/balanceCertificateModel';
import foreignDocumentModel from '@model/foreignDocumentModel';
import minorDocumentUploadModel from '@model/minorDocumentUploadModel';
import smartformAppModel from '@model/smartformAppModel';
import { runInAction } from 'mobx';

class pageService {
  async loadBalanceCertificate() {
    //잔고증명서 조회
    const res = await get(
      `/balanceCertificate/find`,
      {
        smartformId: smartformAppModel.smartformId,
      },
      {},
    );
    const data = res.data;

    if (smartformAppModel.authority === 'manager') {
      const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
      const progressData = progressInfo.data;

      document.title = `${progressData.rnumber} ${progressData.companyName}`;
    }

    runInAction(() => {
      smartformAppModel.setSmartFormType(data.smartFormType);
      balanceCertificateModel.setBalanceCertificate(data);
    });
  }
  async uploadBalanceCertificate() {
    //잔고증명서 업로드
    const formData = new FormData();

    formData.append('uploadFile', balanceCertificateModel.uploadFiles);
    formData.append('smartformId', smartformAppModel.smartformId);

    await post(`/balanceCertificate/upload`, formData, {});
  }
  async loadMinorShareholderDocuments() {
    //미성년자 서류 조회
    const res = await get(
      '/minorShareholderDocuments/find',
      {
        smartformId: smartformAppModel.smartformId,
      },
      {},
    );
    const data = res.data;

    if (smartformAppModel.authority === 'manager') {
      const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
      const progressData = progressInfo.data;

      document.title = `${progressData.rnumber} ${progressData.companyName}`;
    }

    runInAction(() => {
      smartformAppModel.setSmartFormType(data.smartFormType);
      minorDocumentUploadModel.setMinorShareholderDocuments(data);
    });
  }
  async uploadMinorShareholderDocumentsBasic() {
    //미성년자 기본 증명서 업로드
    const formData = new FormData();

    formData.append('smartformId', smartformAppModel.smartformId);
    for (let i = 0; i < minorDocumentUploadModel.basicCertificateUploadFiles.length; i++) {
      formData.append('uploadFiles', minorDocumentUploadModel.basicCertificateUploadFiles[i]);
    }

    formData.append('deleteIds', minorDocumentUploadModel.basicCertificateDeleteFiles);

    await post(`/minorShareholderDocuments/upload/basicCertificate`, formData, {});
  }
  async uploadMinorShareholderDocumentsFamilyRelationship() {
    //미성년자 가족관계증명서 업로드
    const formData = new FormData();

    formData.append('smartformId', smartformAppModel.smartformId);
    for (let i = 0; i < minorDocumentUploadModel.familyRelationshipCertificateUploadFiles.length; i++) {
      formData.append('uploadFiles', minorDocumentUploadModel.familyRelationshipCertificateUploadFiles[i]);
    }

    formData.append('deleteIds', minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles);

    await post(`/minorShareholderDocuments/upload/familyRelationshipCertificate`, formData, {});
  }
  // 미성년자 각종 증명서 최종 플로우에서 업로드한 임시 파일들 나스로 폼 제출 반영 되도록하는 API
  async submitMinorShareholderDocumentForm() {
    const formData = new FormData();

    formData.append('smartformId', smartformAppModel.smartformId);

    await post(`/minorShareholderDocuments/submit`, formData, {});
  }
  async loadAgriculturalFisheryDocuments() {
    //농업어업 서류 조회
    const res = await get(
      `/agriculturalFisheryDocuments/find`,
      {
        smartformId: smartformAppModel.smartformId,
      },
      {},
    );
    const data = res.data;

    if (smartformAppModel.authority === 'manager') {
      const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
      const progressData = progressInfo.data;

      document.title = `${progressData.rnumber} ${progressData.companyName}`;
    }

    runInAction(() => {
      smartformAppModel.setSmartFormType(data.smartFormType);
      agriculturalFisheryDocumentUploadModel.setAgriculturalFisheryDocuments(data);
    });
  }
  async uploadAgriculturalFisheryDocuments() {
    //농업어업 서류 업로드
    const formData = new FormData();

    formData.append('smartformId', smartformAppModel.smartformId);
    formData.append('isFarmerFile', agriculturalFisheryDocumentUploadModel.isFarmerFile);
    for (let i = 0; i < agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles.length; i++) {
      formData.append('uploadFiles', agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsUploadFiles[i]);
    }

    formData.append('deleteIds', agriculturalFisheryDocumentUploadModel.agriculturalFisheryDocumentsDeleteFiles);

    await post(`/agriculturalFisheryDocuments/upload`, formData, {});
  }
  async loadForeignDocuments() {
    //외국인 서류 조회
    const res = await get(
      `/foreignDocuments/find`,
      {
        smartformId: smartformAppModel.smartformId,
      },
      {},
    );
    const data = res.data;

    if (smartformAppModel.authority === 'manager') {
      const progressInfo = await get(`admin/smartforms/${smartformAppModel.smartformId}/progressInfo`, {}, {});
      const progressData = progressInfo.data;

      document.title = `${progressData.rnumber} ${progressData.companyName}`;
    }

    runInAction(() => {
      smartformAppModel.setSmartFormType(data.smartFormType);
      foreignDocumentModel.setForeignDocuments(data);
    });
  }
  async uploadForeignDocuments() {
    //외국인 서류 업로드
    const formData = new FormData();

    formData.append('smartformId', smartformAppModel.smartformId);
    for (let i = 0; i < foreignDocumentModel.foreignDocumentsUploadFiles.length; i++) {
      formData.append('uploadFiles', foreignDocumentModel.foreignDocumentsUploadFiles[i]);
    }

    formData.append('deleteIds', foreignDocumentModel.foreignDocumentsDeleteFiles);

    await post(`/foreignDocuments/upload`, formData, {});
  }
}

export default new pageService();
