import { useObserver } from 'mobx-react';
import CorporateTax from './CorporateTax/CorporateTax';
import { Route } from 'react-router-dom';

function CorporateTaxStep() {
  return useObserver(() => (
    <>
      {/* 법인세 환급 */}
      <Route path="/step/changeRegistration/CorporateTaxStep/CorporateTax" component={CorporateTax}></Route>
    </>
  ));
}

export default CorporateTaxStep;
