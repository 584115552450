import { observable, runInAction } from 'mobx';

const BranchOfficeDeleteStepModel = observable({
  branchOffices: [],
  setBranchOfficeDelete(data) {
    runInAction(() => {
      if (data.branchOffices && data.branchOffices.length) {
        this.branchOffices = data.branchOffices.map((branchOffice) => {
          return {
            id: branchOffice.id || '',
            address: branchOffice.address || '',
            deleteYn: branchOffice.deleteYn ?? false,
          };
        });
      } else {
        this.branchOffices = [];
      }
    });
  },
});

export default BranchOfficeDeleteStepModel;
