import { observable } from 'mobx';

const etcInfoModel = observable({
  businessPurposes: [], //사업목적
  purposeVersion: 2, // 사업목적버전 1이면 개선 전 입력중 고객, 2면 개선 후 고객
  establishmentCorporateSeals: [], //법인인감들
  normalUsageSealSymbols: [], //기본형 사용인감 비표
  premiumUsageSealSymbols: [], //고급형 사용인감 비표
  usageSealNormalCount: '', //사용인감 기본형 도장 갯수
  usageSealPremiumCount: '', //사용인감 고급형 도장 갯수
  option: {
    //옵션
    announcementMethodChangeYn: null, //공고 방법 변경 여부
    validUrlCheckType: null, //공고할 홈페이지 주소 접속체크(체크안한상태: null, 유효한 url: CAN_ACCESS, 유효하지 않은 url: CANNOT_ACCESS)
    homepageAddress: '', //공고할 홈페이지 주소
    homepageAnnouncementYn: null, //홈페이지 공고 여부
    newspaperCompany: '', //신문사
    prospectiveSocialEnterpriseYn: null, //예비 사회적 기업 여부
    shareTransferRestrictionsYn: null, //주식 양도 제한 설정 여부
    stockOptionYn: null, //스톡 옵션 여부
  },
  canUseCertificateYn: null, //공인인증서 여부
  freeLicenseRegistrationYn: null, //무료사업자등록 여부
  noticeOfEstablishmentYn: null, //설립통지 여부
  trademarkAgreeYn: null, //상표등록 발송여부
  setBusinessPurposes(businessPurposes) {
    if (businessPurposes?.length > 0) {
      this.businessPurposes = businessPurposes.map((item) => {
        return {
          id: Number(item.id),
          recommendedPurposeId: item.recommendedPurposeId ? Number(item.recommendedPurposeId) : undefined, // 직접입력('DIRECT')의 경우 없음
          content: item.content || '',
          purposeType: item.purposeType || '',
          noteYn: Boolean(item.noteYn),
          note: item.noteYn
            ? {
                minCapital: item.note?.minCapital || '',
                cautions: item.note?.cautions || '',
                postRegistrationRequirements: item.note?.postRegistrationRequirements || '',
              }
            : null,
        };
      });
    } else {
      this.businessPurposes = [];
    }
  },
  setPurposeVersion(purposeVersion) {
    this.purposeVersion = Number(purposeVersion);
  },
  setChooseStamp(chooseStamp) {
    if (chooseStamp?.establishmentCorporateSeals && chooseStamp?.establishmentCorporateSeals.length !== 0) {
      this.establishmentCorporateSeals = chooseStamp?.establishmentCorporateSeals.map((establishmentCorporateSeals) => {
        return {
          defaultYn: establishmentCorporateSeals.defaultYn ?? true,
          executiveId: establishmentCorporateSeals.executiveId ?? '',
          executiveName: establishmentCorporateSeals.executiveName || '',
          position: establishmentCorporateSeals.position || '',
          symbol: establishmentCorporateSeals.symbol || '',
        };
      });
    } else {
      this.establishmentCorporateSeals = [];
    }
    if (chooseStamp?.normalUsageSealSymbols && chooseStamp?.normalUsageSealSymbols.length !== 0) {
      this.normalUsageSealSymbols = chooseStamp?.normalUsageSealSymbols.map((normalSealSymbol) => {
        return {
          id: normalSealSymbol.id || '',
          symbol: normalSealSymbol.symbol || '',
        };
      });
    } else {
      this.normalUsageSealSymbols = [];
    }
    if (chooseStamp?.premiumUsageSealSymbols && chooseStamp?.premiumUsageSealSymbols.length !== 0) {
      this.premiumUsageSealSymbols = chooseStamp?.premiumUsageSealSymbols.map((premiumSealSymbol) => {
        return {
          id: premiumSealSymbol.id || '',
          symbol: premiumSealSymbol.symbol || '',
        };
      });
    } else {
      this.premiumUsageSealSymbols = [];
    }
    this.usageSealNormalCount = Number(chooseStamp?.usageSealNormalCount) || 0;
    this.usageSealPremiumCount = Number(chooseStamp?.usageSealPremiumCount) || 0;
  },
  setOption(option) {
    this.option.announcementMethodChangeYn = option?.announcementMethodChangeYn ?? false;
    this.option.validUrlCheckType = option?.validUrlCheckType || null;
    this.option.homepageAddress = option?.homepageAddress || '';
    this.option.homepageAnnouncementYn = option?.homepageAnnouncementYn ?? null;
    this.option.newspaperCompany = option?.newspaperCompany || '';
    this.option.prospectiveSocialEnterpriseYn = option?.prospectiveSocialEnterpriseYn ?? false;
    this.option.shareTransferRestrictionsYn = option?.shareTransferRestrictionsYn ?? false;
    this.option.stockOptionYn = option?.stockOptionYn ?? false;
  },
  setCanUseCertificateYn(canUseCertificateYn) {
    this.canUseCertificateYn = canUseCertificateYn ?? null;
  },
  setFreeLicenseRegistrationYn(freeLicenseRegistrationYn) {
    this.freeLicenseRegistrationYn = freeLicenseRegistrationYn ?? null;
  },
  setNoticeOfEstablishmentYn(noticeOfEstablishmentYn) {
    this.noticeOfEstablishmentYn = noticeOfEstablishmentYn || null;
  },
  setTrademarkAgreeYn(trademarkAgreeYn) {
    this.trademarkAgreeYn = trademarkAgreeYn ?? null;
  },
});

export default etcInfoModel;
