import { _confirm } from '@model/dialogModel';
import etcInfoModel from '@model/establish/etcInfoModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { arrayMoveImmutable } from 'array-move';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';

const useSelectedPurpose = () => {
  const selectedPurposeMob = useLocalObservable(() => ({
    isDragging: false,
  }));

  const onSortStart = () => {
    runInAction(() => {
      selectedPurposeMob.isDragging = true;
    });
  };

  const onSortEnd = (e) => {
    const updatedPurposes = arrayMoveImmutable(etcInfoModel.businessPurposes, e.oldIndex, e.newIndex);

    runInAction(() => {
      etcInfoModel.businessPurposes = updatedPurposes;
      selectedPurposeMob.isDragging = false;
    });
  };

  const removePurposes = async () => {
    if (await _confirm('전체 목적을 삭제하시겠습니까?')) {
      etcInfoModel.businessPurposes = [];
    }
  };

  const recommendPurposeCount = () => {
    return etcInfoModel.businessPurposes.filter((purpose) => purpose.purposeType === purposeType.RECOMMENDED).length;
  };

  const chooseOwnPurposeCount = () => {
    return etcInfoModel.businessPurposes.filter((purpose) => purpose.purposeType == purposeType.DIRECT).length;
  };

  return {
    selectedPurposeMob,
    onSortStart,
    onSortEnd,
    recommendPurposeCount,
    chooseOwnPurposeCount,
    removePurposes,
  };
};

export default useSelectedPurpose;
