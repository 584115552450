import { post, get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import { runInAction } from 'mobx';

class smartformAppService {
  async loadSmartformBasic(smartformId) {
    //스마트폼 곹옹 기본필수데이터 로드
    const res = await get(`/smartform/${smartformId}/basic`, {}, {});
    smartformAppModel.setIsEstablishment(res.data.isEstablishment);
    smartformAppModel.setUserId(res.data.userId);
  }
}
export default new smartformAppService();
