import { observable } from 'mobx';

const StandByStockholderMeetingInfoStepModel = observable({
  chairmanId: null,
  stockholderMeetingType: '',
  totalStockAmount: null,
  executiveMembers: [],
  stockholders: [],
  participants: [],
  setStandByStockholderMeetingBasicInfo(data) {
    this.chairmanId = data?.chairmanId || null;
    this.stockholderMeetingType = data?.stockholderMeetingType || '';
    if (data?.executiveMembers && data?.executiveMembers.length !== 0) {
      this.executiveMembers = data.executiveMembers.map((item) => {
        return {
          id: item.id || null,
          name: item.name || '',
          position: item.position || '',
          representativeYn: item.representativeYn ?? false,
        };
      });
    } else {
      this.executiveMembers = [];
    }
    if (data?.stockholders && data?.stockholders.length !== 0) {
      this.stockholders = data.stockholders.map((item) => {
        return {
          id: item.id || null,
          name: item.name || '',
          attendYn: item.attendYn ?? false,
          stocks: item.stocks || [
            {
              stockType: '',
              amount: null,
              newYn: null,
            },
          ],
        };
      });
    } else {
      this.stockholders = [];
    }
  },
  setStandByStockholderMeetingParticipant(data) {
    this.stockholderMeetingType = data?.stockholderMeetingType || '';
    this.totalStockAmount = data?.totalStockAmount || null;
    if (data?.participants && data?.participants.length !== 0) {
      this.participants = data.participants.map((item) => {
        return {
          id: item.id || null,
          name: item.name || '',
          agreeYn: item.agreeYn ?? false,
          sealProvidingYn: item.sealProvidingYn ?? false,
          stocks: item.stocks || [
            {
              stockType: '',
              amount: null,
              newYn: null,
            },
          ],
        };
      });
    } else {
      this.participants = [];
    }
  },
});

export default StandByStockholderMeetingInfoStepModel;
