import { useObserver } from 'mobx-react';
import { Fragment } from 'react';
import vm from './MethodOfIssuingNewSharesVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './MethodOfIssuingNewShares.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import regExp from '@common/module/regExp';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';

import clsx from 'clsx';
import Select from '@comComponents/atoms/Input/Select/Select';
import PlusBtn from '@comComponents/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@comComponents/atoms/Button/MinusBtn/MinusBtn';

function MethodOfIssuingNewShares() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>신주발행 가이드</h2>
              </div>
              <div className={st.explainBox}>
                <div className={st.explain}>
                  <img src="/images/MethodOfIssuingNewSharesNotice_1.png" alt="신주발행 원칙" />
                </div>
                <div className={st.explain}>
                  <img src="/images/MethodOfIssuingNewSharesNotice_2.png" alt="신주발행 방법" />
                </div>
              </div>
              <div className={st.advertisementBox}>
                <div className={st.title}>
                  가장 쉬운 <span className={st.bold}>신주발행 주주동의 방법</span>
                </div>
                <div className={st.advertisement}>
                  <img src="/images/MethodOfIssuingNewSharesNotice_3.gif" alt="신주발행 예시 애니메이션 " />
                </div>
                <a
                  className={st.linkBtn}
                  href={`${process.env.REACT_APP_STANDBY_URL}auth/joinMembership`}
                  target="_blank"
                >
                  신주발행 주주동의 받기
                </a>
                <div className={st.explain}>서비스 이용시 별도 회원가입이 필요해요.</div>
              </div>
            </div>
          </div>
          {vm.existingShareholders.length !== 0 ? (
            <div className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={st.existingShareholders}>
                  <div className={st.title}>기존 주주에게 배정된 신주</div>
                  <table className={st.table}>
                    <colgroup>
                      <col />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>주주명</th>
                        <th>신규 배정 주식수</th>
                      </tr>
                    </thead>
                    <tbody>
                      {vm.existingShareholders.map((item, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <span className={st.index}>{idx + 1}</span>
                              {item.name} 주주님
                            </td>
                            <td>
                              {item.stocks?.map((stock, idx2) => (
                                <div key={idx2} className={st.newStock}>
                                  {stock.stockType} {addCommas(stock.amount)}주
                                </div>
                              ))}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className={Qst.titleWrap} style={{ marginTop: '100px' }}>
                  <h2 className={Qst.title}>주주분들에게 신주가 배정된 이유가 무엇인가요?</h2>
                </div>
                <ul className={Qst.answerList}>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      value="주주_배정"
                      checked={vm.newShareAssignmentType === '주주_배정'}
                      name="newShareAssignmentType"
                      onChange={vm.setNewShareAssignmentType}
                    >
                      신주를 배정받을 수 있는 <span className={st.radioImportant}>주주의 권리를 사용</span>해서
                      (주주배정)
                    </Radio>
                  </li>
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      value="제3자_배정"
                      checked={vm.newShareAssignmentType === '제3자_배정'}
                      name="newShareAssignmentType"
                      onChange={vm.setNewShareAssignmentType}
                    >
                      <span className={st.radioImportant}>재무구조 개선, 신기술의 도입 등 경영상 목적</span>을 위하여
                      <br />
                      (제3자배정)
                    </Radio>
                  </li>
                </ul>
                <Error
                  name="newShareAssignmentType"
                  value={vm.newShareAssignmentType}
                  errorCase={{ required: `배정된 이유를 선택해 주세요.` }}
                />
              </div>
            </div>
          ) : (
            <div className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>신주발행 절차를 선택하세요.</h2>
                </div>
                <ul className={Qst.answerList}>
                  <li style={{ position: 'relative' }}>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      value="주주_동의서_제출"
                      checked={vm.issuingNewShareProcess === '주주_동의서_제출'}
                      name="issuingNewShareProcess"
                      onChange={vm.setIssuingNewShareProcess}
                    >
                      <span className={st.radioImportant}>주주의 동의</span>를 받아,{' '}
                      <span className={st.radioImportant}>바로</span> 신주발행을 진행합니다.
                      <span className={st.radioTag}>추천</span>
                    </Radio>
                  </li>
                  {vm.newShareAssignmentType === '제3자_배정' && (
                    <li>
                      <Radio
                        activeBackground
                        className={Qst.answerLabel}
                        value="신주발행_주주_통지"
                        checked={vm.issuingNewShareProcess === '신주발행_주주_통지'}
                        name="issuingNewShareProcess"
                        onChange={vm.setIssuingNewShareProcess}
                      >
                        주주에게 <span className={st.radioImportant}>신주발행통지</span>를 하고,{' '}
                        <span className={st.radioImportant}>14일</span>을 대기합니다.
                      </Radio>
                    </li>
                  )}
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      value="신주발행_홈페이지_신문사_공고"
                      checked={vm.issuingNewShareProcess === '신주발행_홈페이지_신문사_공고'}
                      name="issuingNewShareProcess"
                      onChange={vm.setIssuingNewShareProcess}
                    >
                      {vm.noticeType === '홈페이지' && '홈페이지'}
                      {vm.noticeType === '신문사' && '신문사'}에{' '}
                      <span className={st.radioImportant}>신주발행 공고</span>를 올리고,{' '}
                      <span className={st.radioImportant}>14일</span>을 대기합니다.
                    </Radio>
                  </li>
                </ul>
                <Error
                  name="issuingNewShareProcess"
                  value={vm.issuingNewShareProcess}
                  errorCase={{ required: `신주발행 절차를 선택해 주세요.` }}
                />
              </div>
              <div className={Qst.qnaSection}>
                <Qna tip="" className={Qst.qnaTitle}>
                  <div className={Qst.qnaBox}>
                    <QText>신주발행할 때 통지/공고가 왜 필요하나요?</QText>
                    <div className="answerBox">
                      <Answer className={Qst.answer}>상법에서 신주발행할 때 통지/공고를 하도록 정하고 있습니다.</Answer>
                      <p className={Qst.explain}>
                        상법의 원칙상 신주는 주주에게 발행해야 하고, 주주가 가진 주식수에 따라 신주를 배정받을 권리가
                        있습니다. (상법 제418조 제1항) 다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있습니다.
                      </p>
                      <p className={Qst.explain}>
                        신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 신주발행이
                        가능합니다. (상법 제418조 제2항) 이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는
                        공고해야 합니다.
                      </p>
                      <p className={Qst.explain}>
                        다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 됩니다. 통지/공고는 주주의
                        권리를 보호하기 위한 것이니, 주주들이 동의하면 절차를 생략할 수 있는 것입니다.
                      </p>
                    </div>
                  </div>
                </Qna>
              </div>
            </div>
          )}
        </ContentBox>
      </Question>
      {vm.existingShareholders.length !== 0 && vm.newShareAssignmentType && (
        <Question>
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>신주발행 절차를 선택하세요.</h2>
              </div>
              <ul className={Qst.answerList}>
                <li style={{ position: 'relative' }}>
                  <Radio
                    activeBackground
                    className={Qst.answerLabel}
                    value="주주_동의서_제출"
                    checked={vm.issuingNewShareProcess === '주주_동의서_제출'}
                    name="issuingNewShareProcess"
                    onChange={vm.setIssuingNewShareProcess}
                  >
                    <span className={st.radioImportant}>주주의 동의</span>를 받아,{' '}
                    <span className={st.radioImportant}>바로</span> 신주발행을 진행합니다.
                    <span className={st.radioTag}>추천</span>
                  </Radio>
                </li>
                {vm.newShareAssignmentType === '제3자_배정' && (
                  <li>
                    <Radio
                      activeBackground
                      className={Qst.answerLabel}
                      value="신주발행_주주_통지"
                      checked={vm.issuingNewShareProcess === '신주발행_주주_통지'}
                      name="issuingNewShareProcess"
                      onChange={vm.setIssuingNewShareProcess}
                    >
                      주주에게 <span className={st.radioImportant}>신주발행통지</span>를 하고,{' '}
                      <span className={st.radioImportant}>14일</span>을 대기합니다.
                    </Radio>
                  </li>
                )}
                <li>
                  <Radio
                    activeBackground
                    className={Qst.answerLabel}
                    value="신주발행_홈페이지_신문사_공고"
                    checked={vm.issuingNewShareProcess === '신주발행_홈페이지_신문사_공고'}
                    name="issuingNewShareProcess"
                    onChange={vm.setIssuingNewShareProcess}
                  >
                    {vm.noticeType === '홈페이지' && '홈페이지'}
                    {vm.noticeType === '신문사' && '신문사'}에 <span className={st.radioImportant}>신주발행 공고</span>
                    를 올리고, <span className={st.radioImportant}>14일</span>을 대기합니다.
                  </Radio>
                </li>
              </ul>
              <Error
                name="issuingNewShareProcess"
                value={vm.issuingNewShareProcess}
                errorCase={{ required: `신주발행 절차를 선택해 주세요.` }}
              />
            </div>
            <div className={Qst.qnaSection}>
              <Qna tip="" className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>신주발행할 때 통지/공고가 왜 필요하나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>상법에서 신주발행할 때 통지/공고를 하도록 정하고 있습니다.</Answer>
                    <p className={Qst.explain}>
                      상법의 원칙상 신주는 주주에게 발행해야 하고, 주주가 가진 주식수에 따라 신주를 배정받을 권리가
                      있습니다. (상법 제418조 제1항) 다만 정당한 목적이 있는 경우 제3자에게 발행할 수 있습니다.
                    </p>
                    <p className={Qst.explain}>
                      신기술 도입, 재무구조 개선 등 경영상 목적을 달성하기 위해 필요한 경우에는 제3자에게 신주발행이
                      가능합니다. (상법 제418조 제2항) 이 경우 주주에게 제3자에게 신주발행을 한다는 사실을 통지 또는
                      공고해야 합니다.
                    </p>
                    <p className={Qst.explain}>
                      다만 모든 주주가 동의하면 통지/공고를 생략하고 2주를 기다리지 않아도 됩니다. 통지/공고는 주주의
                      권리를 보호하기 위한 것이니, 주주들이 동의하면 절차를 생략할 수 있는 것입니다.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </ContentBox>
        </Question>
      )}
      {(vm.issuingNewShareProcess === '신주발행_주주_통지' ||
        vm.issuingNewShareProcess === '신주발행_홈페이지_신문사_공고') && (
        <Question>
          <ContentBox className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  {vm.issuingNewShareProcess === '신주발행_주주_통지' && '통지일'}
                  {vm.issuingNewShareProcess === '신주발행_홈페이지_신문사_공고' &&
                    '공고일'}은 언제인가요?
                </h2>
              </div>
              <div>
                <div className={Qst.birthWrap}>
                  <InputText
                    style={{ width: '130px' }}
                    placeholder="0000"
                    maxLength="4"
                    name="noticeDate.year"
                    value={vm.noticeDate.year}
                    onChange={vm.setNoticeDateYear}
                  />
                  <span className={Qst.birthText}>년</span>
                </div>
                <div className={Qst.birthWrap}>
                  <InputText
                    style={{ width: '108px' }}
                    placeholder="00"
                    maxLength="2"
                    name="noticeDate.month"
                    value={vm.noticeDate.month}
                    onChange={vm.setNoticeDateMonth}
                    onBlur={vm.noticeDateMonthBlur}
                  />
                  <span className={Qst.birthText}>월</span>
                </div>
                <div className={Qst.birthWrap}>
                  <InputText
                    style={{ width: '108px' }}
                    placeholder="00"
                    maxLength="2"
                    name="noticeDate.day"
                    value={vm.noticeDate.day}
                    onChange={vm.setNoticeDateDay}
                    onBlur={vm.noticeDateDayBlur}
                  />
                  <span className={Qst.birthText}>일</span>
                </div>
              </div>
              <div style={{ marginTop: '-27px' }}>
                <Error
                  name="noticeDate.year"
                  value={vm.noticeDate.year}
                  errorCase={{
                    required: '연도를 입력해 주세요.',
                    pattern: {
                      value: regExp.year().test(vm.noticeDate.year),
                      message: '올바른 연도를 입력해 주세요',
                    },
                  }}
                />
                <Error
                  name="noticeDate.month"
                  value={vm.noticeDate.month}
                  errorCase={{
                    required: vm.displayBirthErrorMessage('month') ? '월을 입력해 주세요.' : null,
                    pattern: {
                      value: vm.displayBirthErrorMessage('month') ? regExp.month().test(vm.noticeDate.month) : null,
                      message: '올바른 월을 입력해 주세요',
                    },
                  }}
                />
                <Error
                  name="noticeDate.day"
                  value={vm.noticeDate.day}
                  errorCase={{
                    required: vm.displayBirthErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                    pattern: {
                      value: vm.displayBirthErrorMessage('day') ? regExp.day().test(vm.noticeDate.day) : null,
                      message: '올바른 일(날짜)을 입력해 주세요.',
                    },
                  }}
                />
              </div>
            </div>
          </ContentBox>
        </Question>
      )}
    </form>
  ));
}

export default MethodOfIssuingNewShares;
