import { useObserver } from 'mobx-react';
import st from './Case2Modal.module.scss';
import clsx from 'clsx';
import vm from './StockholdersMeetingMusterNoticeVm';

function Case2Modal(props) {
  return useObserver(() => (
    <div className={st.modalContent}>
      <div className={st.title}>
        <span className={st.bold}>이메일, 카톡</span>으로 <span className={st.bold}>소집통지문</span> 발송하세요!
      </div>
      <div className={st.steps}>
        <div className={st.stepWrap}>
          <div className={st.step}>
            <div className={st.stepContent}>
              소집통지문을 <span className={st.bold}>자동으로</span>
              <br />
              <span className={st.bold}>작성</span>해 드립니다.
            </div>
          </div>
        </div>
        <div className={st.stepWrap}>
          <div className={st.step}>
            <div className={st.stepContent}>
              <span className={st.bold}>메일과 알림톡으로</span>
              <br />
              자동 <span className={st.bold}>발송</span>해 드립니다.
            </div>
          </div>
        </div>
        <div className={st.stepWrap}>
          <div className={st.step}>
            <div className={st.stepContent}>
              소집통지한 <span className={st.bold}>증거</span>가<br />
              <span className={st.bold}>자동 보관</span>됩니다.
            </div>
          </div>
        </div>
      </div>
      <div className={st.btnArea}>
        <button type="button" className={st.directBtn} onClick={() => vm.direct(props.formData)}>
          {props.noticeSendMethod === 'ONLINE' && (
            <span className={clsx(st.text, st.online)}>직접 작성해서 발송하겠습니다.</span>
          )}
          {props.noticeSendMethod === 'MAIL' && (
            <span className={clsx(st.text, st.mail)}>
              이번에는 직접
              <br />
              우편발송을 하겠습니다.
            </span>
          )}
        </button>
        <button type="button" className={st.serviceBtn} onClick={() => vm.service(props.formData)}>
          <span className={st.text}>
            <span className={st.bold}>이메일, 카톡 자동발송 서비스</span>를<br />
            이용해 보겠습니다.
          </span>
          <span className={st.tag}>추천</span>
          <span className={st.explain}>※ 서비스 이용시 별도 회원가입이 필요해요.</span>
        </button>
      </div>
    </div>
  ));
}

export default Case2Modal;
