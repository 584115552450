import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';

function Kssn(props) {
  return useObserver(() => (
    <div className={Qst.spaceBetween}>
      <div className={Qst.infoSection}>
        <div className={Qst.titleWrap}>
          <h2 className={Qst.title}>
            <span className={Qst.bold}>{props.item.koreanName}님</span>의 <span className={Qst.bold}>주민등록번호</span>
            를 입력해 주세요.
          </h2>
          <div className={Qst.titleExplain}>등기를 위해서는 주민등록번호가 필요합니다.</div>
          <div className={Qst.titleExplain}>주민등록번호는 헬프미 스마트 보안 시스템에 의하여 안전하게 보관됩니다.</div>
          {props.item.changeType === '사임_퇴임' && (
            <div className={Qst.titleExplain}>
              사임일자는 과태료가 나오지 않거나, 최대한 적게 나올 수 있는 날짜로 지정해드립니다. 특정일자를 희망한다면,
              채팅상담으로 알려주세요.
            </div>
          )}
        </div>
        <InputText
          placeholder="111111-1111111"
          style={{ width: '316px' }}
          textType="kssn"
          value={props.item.kssn}
          onChange={(e) => props.parentVm.setKssn(e, props.idx)}
          name={`${props.name}[${props.idx}].kssn`}
          errorText={
            <Error
              name={`${props.name}[${props.idx}].kssn`}
              value={props.item.kssn}
              errorCase={{
                required: '주민등록번호를 입력해 주세요.',
                validate: {
                  func: regExp.kssn(props.item.kssn),
                  message: '올바른 주민등록번호를 입력해 주세요.',
                },
              }}
            />
          }
        />
      </div>
    </div>
  ));
}

export default Kssn;
