import { observable, runInAction } from 'mobx';

const StandbyParValueDivisionStepModel = observable({
  parValueOnRegister: null,
  newParValue: null,
  keepGoingYn: null,
  parValueOnRegister: null,
  setStandbyParValueDivisionCheck(data) {
    this.parValueOnRegister = data?.parValueOnRegister || null;
    this.newParValue = data?.newParValue || null;
    this.keepGoingYn = data?.keepGoingYn ?? null;
  },
  setStandbyParValueDivision(data) {
    this.newParValue = data?.newParValue || null;
    this.parValueOnRegister = data?.parValueOnRegister || null;
  },
});

export default StandbyParValueDivisionStepModel;
