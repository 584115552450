import finalSubmissionModel from '@model/establish/finalSubmissionModel';
import smartformAppModel from '@model/smartformAppModel';
import st from './FinalUpload.module.scss';
import { addCommas } from '@common/module/replaceNumber';

class viewModel {
  companySize() {
    //참여 인원
    const companySize = finalSubmissionModel.corp.companySize;
    const shareholderCnt = finalSubmissionModel.shareholder.shareholders.length; //주주 인원
    const executiveCnt = finalSubmissionModel.executive.executives.length; //임원 인원
    let shareholder_executive = 0; //주주+임원 인원
    finalSubmissionModel.executive.executives.forEach((executive, idx) => {
      if (executive.isShareholder) {
        shareholder_executive++;
      }
    });
    if (companySize === 'TWO') {
      return '2명';
    } else if (companySize === 'OTHER') {
      return shareholderCnt + executiveCnt - shareholder_executive + '명';
    }
  }
  initiatorRole() {
    //참여자 역할
    const initiatorRole = finalSubmissionModel.corp.initiatorRole;
    if (initiatorRole === 'REPRESENTATIVE_DIRECTOR') {
      return '대표(지분 100%) + 이사(지분 0%)';
    } else if (initiatorRole === 'REPRESENTATIVE_AUDITOR') {
      return '대표(지분 100%) + 감사(지분 0%)';
    } else if (initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS') {
      return '대표(지분 0%) + 주주(지분 100%)';
    }
  }
  get koreanCorpName() {
    //한글 회사명
    return finalSubmissionModel.corp.koreanCorpName;
  }
  get englishCorpName() {
    //영문 회사명
    return finalSubmissionModel.corp.englishCorpName;
  }
  get corpAddress() {
    //회사 주소
    return finalSubmissionModel.corp.corpAddress;
  }
  get deliveryAddress() {
    //우편물 받을 주소
    const corpAddress = finalSubmissionModel.corp.corpAddress;
    const deliveryAddress = finalSubmissionModel.corp.deliveryAddress;
    if (corpAddress === deliveryAddress) {
      return '회사주소와 같음';
    } else {
      return finalSubmissionModel.corp.deliveryAddress;
    }
  }
  get parValue() {
    //1주당 금액
    return String(finalSubmissionModel.shareholder.parValue);
  }
  get capital() {
    //자본금
    return String(finalSubmissionModel.shareholder.capital);
  }
  //주주정보
  get shareholders() {
    return finalSubmissionModel.shareholder.shareholders;
  }
  investmentPercent(idx) {
    const capital = finalSubmissionModel.shareholder.capital;
    const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
    return (investment / capital) * 100;
  }
  stock(idx) {
    const parValue = finalSubmissionModel.shareholder.parValue;
    const investment = finalSubmissionModel.shareholder.shareholders[idx].investment;
    return investment / parValue;
  }
  get executives() {
    //임원정보
    return finalSubmissionModel.executive.executives;
  }
  isShareholder(idx) {
    //주주+임원 여부
    return finalSubmissionModel.executive.executives[idx].isShareholder;
  }
  positionInfoDisplay(idx) {
    //사내이사(대표), 이사(대표), 업무집행자(대표)일 경우, 안내 문구 추가
    if (this.executives[idx].position === '대표') {
      if (this.smartFormType() === '유한책임회사') {
        //유한책임회사인 경우
        return 'limitedLiabilityCompany';
      } else {
        //유한책임회사가 아닌 경우_주식회사, 유한회사
        return 'unLimitedLiabilityCompany';
      }
    }
  }
  get purposes() {
    //사업목적
    return finalSubmissionModel.etc.purposes;
  }
  stockOptionYn() {
    //스톡옵션
    const stockOptionYn = finalSubmissionModel.etc.stockOptionYn;
    if (stockOptionYn) {
      return '추가함';
    } else {
      return '추가하지 않음';
    }
  }
  shareTransferRestrictionsYn() {
    //주식양도제한
    const shareTransferRestrictionsYn = finalSubmissionModel.etc.shareTransferRestrictionsYn;
    if (shareTransferRestrictionsYn) {
      return '추가함';
    } else {
      return '추가하지 않음';
    }
  }
  get announcementMethod() {
    //공고방법
    return finalSubmissionModel.etc.announcementMethod;
  }
  prospectiveSocialEnterpriseYn() {
    //예비 사회적 기업 여부
    const prospectiveSocialEnterpriseYn = finalSubmissionModel.etc.prospectiveSocialEnterpriseYn;
    if (prospectiveSocialEnterpriseYn) {
      return '예비 사회적 기업으로 설립';
    } else {
      return '추가하지 않음';
    }
  }
  corpSeals() {
    //법인인감
    let corpSealsList = finalSubmissionModel.etc.corpSeals.map((corpSeal, idx) => {
      if (idx === 0) {
        return (
          <div key={idx}>
            {corpSeal.name} 대표님 : {corpSeal.type === '기본형' ? '기본형 무료제공' : '고급형 선택 (+19,800원)'}
          </div>
        );
      } else {
        return (
          <div key={idx} className={st.stampData}>
            {corpSeal.name} 대표님 :{' '}
            {corpSeal.type === '기본형' ? '기본형 선택 (+14,300원)' : '고급형 선택 (+34,100원)'}
          </div>
        );
      }
    });
    return corpSealsList;
  }
  usageSeal() {
    //사용인감
    const normal = finalSubmissionModel.etc.usageSeal.normalSealCount;
    const premium = finalSubmissionModel.etc.usageSeal.premiumSealCount;
    if (normal && premium) {
      return `기본형 도장 ${normal}개 추가, 고급형 도장 ${premium}개 추가 (+${addCommas(String(normal * 14300 + premium * 34100))}원)`;
    } else if (normal !== 0 && premium === 0) {
      return `기본형 도장 ${normal}개 추가 (+${addCommas(String(normal * 14300))}원)`;
    } else if (normal === 0 && premium !== 0) {
      return `고급형 도장 ${premium}개 추가 (+${addCommas(String(premium * 34100))}원)`;
    } else if (normal === 0 || premium === 0) {
      return '추가하지 않음';
    }
  }
  canUseYn() {
    //공인인증서
    const canUseYn = finalSubmissionModel.etc.canUseYn;
    if (canUseYn) {
      return '모두 사용가능 (전자등기로 진행)';
    } else if (canUseYn === false) {
      return '사용하지 않음 (서류등기로 진행)';
    } else {
      return canUseYn; //공인인증서 페이지가 없는 경우
    }
  }
  freeLicenseRegistrationYn() {
    //무료사업자등록
    const freeLicenseRegistrationYn = finalSubmissionModel.etc.freeLicenseRegistrationYn;
    if (freeLicenseRegistrationYn) {
      return '신청함';
    } else {
      return '신청하지 않음';
    }
  }
  noticeOfEstablishmentYn() {
    //설립통지 여부
    const noticeOfEstablishmentYn = finalSubmissionModel.etc.noticeOfEstablishmentYn;
    if (noticeOfEstablishmentYn) {
      return '확인 완료';
    } else {
      return '';
    }
  }
  smartFormType() {
    //스마트폼 타입
    const type = smartformAppModel.smartFormType;
    switch (type) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return '주식회사';
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return '농업회사법인';
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return '어업회사법인';
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return '유한회사';
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return '유한책임회사';
    }
  }
  name() {
    const type = smartformAppModel.smartFormType;
    switch (type) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return '출자자';
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return '출자자';
    }
  }
}
export default new viewModel();
