import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './Option.module.scss';
import clsx from 'clsx';
import Check from '@comComponents/atoms/Input/Check/Check';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Select from '@comComponents/atoms/Input/Select/Select';
import OptionVm from './OptionVm';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import regExp from '@common/module/regExp';
import AiChat from '@pages/Establish/AiChat/AiChat';

function Option() {
  return useObserver(() => (
    <form onSubmit={(e) => OptionVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={st.between}>
            <h2 className={st.title}>
              <span className={st.bold} style={{ color: '#006ac3' }}>
                추가할 옵션
              </span>
              을 <br /> 선택해 주세요.
            </h2>
            <AiChat showTitle={false} />
          </div>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <label className={clsx(st.checkWrap, OptionVm.stockOptionYn ? st.active : null)}>
                <Check
                  className={st.checkBox}
                  style={{ fontSize: '22px' }}
                  boxType="circle"
                  name="stockOptionYn"
                  value={OptionVm.stockOptionYn}
                  checked={OptionVm.stockOptionYn === true}
                  onChange={(e) => OptionVm.setStockOptionYn(e)}
                >
                  스톡옵션 규정 추가
                </Check>
                {/* <div className={st.option}>무료제공</div> */}
              </label>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.qnaTitle}>
                <div className={Qst.qnaBox}>
                  <QText>스톡옵션이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      스톡옵션은 임직원 등이 일정 기간이 지난 후, 회사 주식을 미리 정해둔 저렴한 가격으로 살 수 있는
                      권리를 말합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      스톡옵션은 주식을 살 수 있는 권리를 주는 것이므로, 주식 자체를 주는 것은 아닙니다. 스톡옵션은
                      발행하려면 등기부와 정관에 근거규정이 있어야 합니다. 설립할 때, 스톡옵션 규정을 등기부에 넣는 것은
                      무료이지만, 나중에 추가하는 경우 세금, 수수료가 추가 됩니다. 따라서 향후 스톡옵션을 부여할 계획이
                      있다면, 미리 설립할 때 추가하는 편을 추천드립니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>가족기업인데, 스톡옵션 규정을 넣어야 할까요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>가족기업이라면 스톡옵션 규정을 넣지 않는 편이 좋습니다.</Answer>
                    <p className={Qst.explain}>
                      스톡옵션 규정은 임직원에게 회사성장에 따른 수익을 나중에 나누어줄 수 있는 제도입니다. 따라서
                      가족기업이거나, 소규모 기업이라면 스톡옵션 규정을 넣지 않는 편이 좋습니다. 스톡옵션 규정을 넣게
                      되면, 등기부가 매우 길어지는 단점이 있습니다.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </div>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <label className={clsx(st.checkWrap, OptionVm.shareTransferRestrictionsYn ? st.active : null)}>
                <Check
                  className={st.checkBox}
                  style={{ fontSize: '22px' }}
                  boxType="circle"
                  name="shareTransferRestrictionsYn"
                  value={OptionVm.shareTransferRestrictionsYn}
                  checked={OptionVm.shareTransferRestrictionsYn === true}
                  onChange={(e) => OptionVm.setShareTransferRestrictionsYn(e)}
                >
                  주식양도제한 규정 추가
                </Check>
                {/* <div className={st.option}>무료제공</div> */}
              </label>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.noTitle}>
                <div className={Qst.qnaBox}>
                  <QText>주식양도제한 규정이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      주식양도제한 규정이란 주주들이 주주총회 또는 이사회의 동의를 얻어야만 주식을 제3자에게 양도할 수
                      있는 규정을 말합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      주식양도제한 규정은 미리 등기부와 정관에 정해두어야만, 누구에게나 그 효력을 주장할 수 있습니다.
                      설립할 때, 주식양도제한 규정을 등기부에 넣는 것은 무료이지만, 나중에 추가하는 경우 세금, 수수료가
                      추가됩니다. 보통 투자자들은 주식양도제한규정을 없앨 것을 요구하므로, 향후 투자받을 계획이 있다면
                      이 옵션은 선택하지 않는 편을 추천드립니다.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </div>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <label className={clsx(st.checkWrap, OptionVm.announcementMethodChangeYn ? st.announceActive : null)}>
                <Check
                  className={st.checkBox}
                  style={{ fontSize: '22px' }}
                  boxType="circle"
                  name="announcementMethodChangeYn"
                  value={OptionVm.announcementMethodChangeYn}
                  checked={OptionVm.announcementMethodChangeYn === true}
                  onChange={(e) => OptionVm.setAnnouncementMethodChangeYn(e)}
                >
                  공고방법 직접 지정
                </Check>
                {/* <div className={st.option}>무료제공</div> */}
              </label>
              {OptionVm.announcementMethodChangeYn === true && (
                <div className={clsx(st.announce, OptionVm.announcementMethodChangeYn ? st.announceActive : null)}>
                  <div className={st.content}>
                    <div className={st.title}>
                      <span className={st.bold}>공고방법을 선택</span>해 주세요.
                    </div>
                    <Radio
                      className={st.radio}
                      style={{ fontSize: '18px', position: 'relative', top: '2px', lineHeight: '1.17' }}
                      name="homepageAnnouncementYn"
                      value={true}
                      checked={OptionVm.homepageAnnouncementYn === true}
                      onChange={(e) => OptionVm.setHomepageAnnouncementYn(e)}
                    >
                      홈페이지 공고방법 (현재 접속가능한 단독 홈페이지만 가능)
                    </Radio>
                    <Radio
                      className={st.radio}
                      style={{ fontSize: '18px', position: 'relative', top: '2px', lineHeight: '1.17' }}
                      name="homepageAnnouncementYn"
                      value={false}
                      checked={OptionVm.homepageAnnouncementYn === false}
                      onChange={(e) => OptionVm.setHomepageAnnouncementYn(e)}
                    >
                      신문사 공고방법
                    </Radio>
                    <div style={{ marginTop: '5px' }}>
                      <Error
                        name="homepageAnnouncementYn"
                        value={OptionVm.homepageAnnouncementYn}
                        errorCase={{ required: '공고방법을 선택해 주세요.' }}
                      />
                    </div>
                  </div>
                  {OptionVm.homepageAnnouncementYn === true && (
                    <>
                      <div className={st.content}>
                        <div className={st.title}>
                          <span className={st.bold}>공고할 단독 홈페이지 주소를 입력</span>하고, 접속확인을 눌러주세요.
                          <div className={st.titleExplain}>
                            네이버카페, 블로그, 공동 홈페이지 등은 홈페이지 공고방법으로 사용할 수 없습니다.
                          </div>
                        </div>
                        <InputText
                          className={'homepageAddress'}
                          style={{ width: '378px', marginRight: '6px' }}
                          name="homepageAddress"
                          value={OptionVm.homepageAddress}
                          onChange={(e) => OptionVm.setHomepageAddress(e)}
                          errorText={
                            <Error
                              name="homepageAddress"
                              value={OptionVm.homepageAddress}
                              style={{ width: '495px' }}
                              errorCase={{
                                required: '홈페이지 주소를 입력해 주세요.',
                                pattern: {
                                  value: regExp.urlCheck().test(OptionVm.homepageAddress),
                                  message: 'https:// 또는 http://을 제외하고 입력해주세요.',
                                },
                              }}
                            />
                          }
                        />
                        <SquareBtn
                          onClick={() => OptionVm.validUrlCheckType()}
                          disabled={OptionVm.state.homepageAddressDisabledYn}
                        >
                          접속확인
                        </SquareBtn>
                      </div>
                      {OptionVm.optionInfo.validUrlCheckType === 'CAN_ACCESS' && (
                        <ErrorText>
                          <span style={{ color: '#006ac3' }}>접속가능한 홈페이지 주소입니다.</span>
                          <br />
                          (다만 접속가능한 홈페이지라고 하더라도, 네이버 블로그, 카페, 도메인판매 회사 사이트 등은
                          등기소에서 허용하지 않으므로 주의 부탁드립니다.)
                        </ErrorText>
                      )}
                      {OptionVm.optionInfo.validUrlCheckType === 'CANNOT_ACCESS' && (
                        <ErrorText>접속 불가능한 홈페이지 주소입니다. 주소를 다시 입력해주세요.</ErrorText>
                      )}
                      {OptionVm.optionInfo.validUrlCheckType === 'CONNECTION_ERROR ' && (
                        <ErrorText>
                          <span style={{ color: '#006ac3' }}>접속가능한 홈페이지 주소입니다.</span>
                          <br />
                          다만 접속가능한 홈페이지라고 하더라도, 네이버 블로그, 카페, 도메인판매 회사 사이트 등은
                          등기소에서 허용하지 않으므로 주의 부탁드립니다.
                        </ErrorText>
                      )}
                    </>
                  )}
                  {(OptionVm.homepageAnnouncementYn === true || OptionVm.homepageAnnouncementYn === false) && (
                    <div className={st.content}>
                      <div className={st.title}>
                        <span className={st.bold}>공고할 신문사를 선택</span>해 주세요.
                        <div className={st.titleExplain}>
                          홈페이지 공고를 선택할 경우에도, 홈페이지 접속이 불가능한 경우를 대비해서 공고할 신문사를 미리
                          정해두어야 합니다.
                        </div>
                      </div>
                      <Select
                        className={Qst.select}
                        style={{ width: '317px' }}
                        name="newspaperCompany"
                        value={OptionVm.newspaperCompany}
                        onChange={(e) => OptionVm.setNewspaperCompany(e)}
                        errorText={
                          <Error
                            name="newspaperCompany"
                            value={OptionVm.newspaperCompany}
                            errorCase={{ required: '신문사를 선택해 주세요.' }}
                          />
                        }
                      >
                        <option value="">-</option>
                        <option value="동아일보">동아일보</option>
                        <option value="매일경제">매일경제</option>
                        <option value="문화일보">문화일보</option>
                        <option value="아시아경제">아시아경제</option>
                        <option value="조선일보">조선일보</option>
                        <option value="중앙일보">중앙일보</option>
                        <option value="한겨레일보">한겨레일보</option>
                        <option value="한국경제">한국경제</option>
                        <option value="한국일보">한국일보</option>
                        <option value="선경일보">선경일보</option>
                        <option value="헤럴드경제">헤럴드경제</option>
                      </Select>
                    </div>
                  )}
                </div>
              )}
              <div className={st.announceExplain}>
                ※ 변경하지 않을 경우, 공고비용이 저렴한 아시아경제신문이 기본선택됩니다.
              </div>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.noTitle}>
                <div className={Qst.qnaBox}>
                  <QText>공고방법이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      공고방법이란 주주나 채권자에게 중대한 영향을 미치는 사항을 공고하는 방법을 말합니다. (설립시
                      공고할 필요가 없습니다)
                    </Answer>
                    <p className={Qst.explain}>
                      공고방법은 설립시 등기부에 반드시 기재해야 하는 사항이지만, 실제로 공고를 해야 하는 경우는 많지
                      않습니다. 설립을 할 때에는 공고를 할 필요가 없고, 회사를 청산하거나, 합병을 하거나, 주주배정
                      신주발행을 하는 등의 상황에서만 공고가 필수입니다. 헬프미 에서는 공고비용이 저렴한 아시아
                      경제신문을 기본적으로 선택 해드립니다. 다른 신문사로 변경을 원하거나, 홈페이지 공고를 추가하고
                      싶은 경우 추가하기를 선택해 주세요.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>신문공고와 홈페이지 공고는 무엇이 다른가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      신문공고는 일간 신문사에 공고문을 올리는 것이고, 홈페이지 공고는 단독 홈페이지에 공고문을 올리는
                      것입니다.
                    </Answer>
                    <p className={Qst.explain}>
                      홈페이지 공고를 선택하는 경우, 홈페이지 공고가 불가능한 경우를 대비하여, 미리 공고할 신문사를
                      아울러 정해두어야 합니다.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>나중에 만들 홈페이지 주소를 적어도 되나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>단독 홈페이지 주소는 현재 접속가능해야 합니다.</Answer>
                    <p className={Qst.explain}>
                      등기관이 심사를 할 때, 현재 접속이 불가능하다면, 법인설립을 허용해주지 않습니다. 나중에 홈페이지를
                      만들 예정이라면, 신문사 공고방법을 선택한 후, 홈페이지 개설 이후에 변경등기를 진행해 주세요.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>신문사 별로 공고방법은 어떤 차이가 있나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>신문사 별로 공고방법의 효력상 차이는 없고, 비용만 다릅니다.</Answer>
                    <p className={Qst.explain}>
                      예를 들면 아시아경제 신문의 1회당 공고비용은 11만 원이고, 조선일보의 1회당 공고비용은 40만 원
                      입니다. 신문사 공고를 할 일이 거의 없기는 하지만, 만일을 대비하여 공고방법이 저렴한 아시아경제
                      신문을 추천드립니다.
                    </p>
                  </div>
                </div>
              </Qna>
            </div>
          </div>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <label className={clsx(st.checkWrap, OptionVm.prospectiveSocialEnterpriseYn ? st.active : null)}>
                <Check
                  className={st.checkBox}
                  style={{ fontSize: '22px' }}
                  boxType="circle"
                  name="prospectiveSocialEnterpriseYn"
                  value={OptionVm.prospectiveSocialEnterpriseYn}
                  checked={OptionVm.prospectiveSocialEnterpriseYn === true}
                  onChange={(e) => OptionVm.setProspectiveSocialEnterpriseYn(e)}
                >
                  (예비) 사회적기업 정관으로 설립
                </Check>
                <div className={st.option}>141,900원</div>
              </label>
            </div>
            <div className={Qst.qnaSection}>
              <Qna className={Qst.noTitle}>
                <div className={Qst.qnaBox}>
                  <QText>사회적기업이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      사회적 목적을 우선적으로 추구하면서 영업활동을 수행하는 기업을 의미합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      사회적기업이란 영리기업과 비영리기업의 중간 형태로, 사회적 목적을 우선적으로 추구하면서
                      재화&middot;서비스의 생산&middot;판매 등 영업활동을 수행하는 기업을 말합니다. 사회적기업은
                      여러가지 혜택을 받을 수 있습니다. 사회적기업이 되기 위해서는 사회적기업 육성법에 따라 고용노동부
                      장관의 인증을 받아야 합니다.
                    </p>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://info.help-me.kr/hc/ko/articles/360029145052"
                      target="_blank"
                    >
                      사회적 기업 자세히 알아보기
                    </LikeBtn>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>예비사회적기업이란 무엇인가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      예비사회적기업이란 사회적기업 인증을 위한 최소한의 요건을 갖추고 있으나, 아직 일부 요건을 충족하지
                      못하고 있는 기업을 의미합니다.
                    </Answer>
                    <p className={Qst.explain}>
                      사회적기업은 여러가지 혜택을 받을 수 있지만, 고용노동부 장관의 인증을 받아야 합니다. 하지만
                      사회적기업의 인증 요건은 비교적 까다롭기 때문에 설립 즉시 사회적기업이 될 수는 없습니다. 그래서
                      최소한의 요건을 갖추고 있지만 아직 일부 요건을 충족하지 못하고 있는 기업을 지방자치단체장 또는
                      중앙부처장이 예비사회적기업으로 지정합니다. 예비사회적기업 역시 여러가지 혜택을 받을 수 있습니다.
                      예비사회적기업 지정은 최대 3년까지 유효합니다.
                    </p>
                    <LikeBtn
                      className={Qst.likeBtn}
                      href="https://info.help-me.kr/hc/ko/articles/360029153132"
                      target="_blank"
                    >
                      예비 사회적기업 자세히 알아보기
                    </LikeBtn>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>예비사회적기업 지정 또는 사회적기업 인증 절차를 대행해주나요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>아쉽게도 지정 및 인증 절차를 대행해드리지 않고 있습니다.</Answer>
                    <p className={Qst.explain}>
                      예비사회적기업 지정 및 사회적기업 인증 절차는 위의 안내링크를 참고해 주세요.
                    </p>
                  </div>
                </div>
                <div className={Qst.qnaBox}>
                  <QText>(예비)사회적기업 정관은 일반 주식회사랑 무엇이 다른가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      (예비)사회적기업 지정&middot;인증을 받기 위해서는 정관 상 명시해야 하는 사항이 있습니다. 명시해야
                      하는 사항은 예비사회적기업과 사회적기업 모두 동일하고, 헬프미에서는 해당 사항을 반영하여 정관을
                      작성해드립니다.
                    </Answer>
                  </div>
                </div>
              </Qna>
            </div>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default Option;
