import st from './ShareholderInvestment.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title}>
        자본금을 <span className={st.bold}>10억 원 이상</span>으로 입력하였습니다.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>
          자본금 10억 원 이상인 경우, 이사 3인 이상, 감사 1인 이상으로 구성해야 하므로 2인으로는 법인을 설립할 수
          없습니다. 자본금을 10억 원 미만으로 설정하거나, 처음 화면으로 돌아가서 회사에 참여하는 인원을 3인 이상으로
          선택해주세요.
        </p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
