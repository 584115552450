import { useObserver } from 'mobx-react';
import Trademark from './Trademark/Trademark';
import { Route } from 'react-router-dom';

function TermsAndConditionsAgree() {
  return useObserver(() => (
    <>
      {/* 상표등록 */}
      <Route path="/step/establish/TrademarkAgree/Trademark" component={Trademark}></Route>
    </>
  ));
}

export default TermsAndConditionsAgree;
