class AnnounceMethodStepVm {
  공고방법Text(step) {
    if (step.homepageUrl) {
      //홈페이지 주소가 있는 경우
      return `본 회사의 공고방법은 회사의 인터넷 홈페이지 ${step.homepageUrl}에 전자공고로 한다. 다만 전산장애 또는 그밖의 사유로 전자공고 방법에 의한 공고를 하는 것이 불가능한 경우에는 서울특별시내에서 발행하는 일간 ${step.newspaperCompany}에 게재한다. `;
    } else {
      return `본 회사의 공고는 서울특별시내에서 발행하는 일간 ${step.newspaperCompany}에 게재한다.`;
    }
  }
}

export default new AnnounceMethodStepVm();
