import RepresentativeCheckModel from '@model/changeRegistration/RepresentativeCheckModel';
import pageStepModel from '@model/pageStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import { _alert, _confirm } from '@model/dialogModel';

class RepresentativeCheckVm {
  constructor() {
    this.state = observable({
      addressModal: false,
      addressIdx: null,
      newAddressIdx: null,
    });
  }
  get nowStep() {
    return pageStepModel.nowStep;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target).error === false) {
        let param = formDataToJson(e.target).data;
        param.representatives.map((representative, idx) => {
          if (representative.newAddresses && representative.newAddresses.length !== 0) {
            representative.newAddresses.map((newAddress, idx2) => {
              param.representatives[idx].newAddresses[idx2].newAddress =
                RepresentativeCheckModel.representatives[idx].newAddresses[idx2].newAddress;
            });
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  get representatives() {
    return RepresentativeCheckModel.representatives;
  }
  setKssn(e, idx) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].kssn = e.target.value;
    });
  }
  setAddressSameYn(e, idx) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].addressSameYn = e.target.value === 'true';
      if (RepresentativeCheckModel.representatives[idx].newAddresses.length === 0) {
        RepresentativeCheckModel.representatives[idx].newAddresses.push({
          causeDate: {
            year: '',
            month: '',
            day: '',
          },
          detailAddress: '',
          nameOfBuilding: '',
          newAddress: {},
        });
      }
    });
  }
  get addressModal() {
    return this.state.addressModal;
  }
  //도로명 주소
  openAddressModal(openYn, idx, idx2) {
    runInAction(() => {
      this.state.addressIdx = idx;
      this.state.newAddressIdx = idx2;
      this.state.addressModal = openYn;
    });
  }
  addressComplate(address) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[this.state.addressIdx].newAddresses[
        this.state.newAddressIdx
      ].newAddress = address;
      RepresentativeCheckModel.representatives[this.state.addressIdx].newAddresses[
        this.state.newAddressIdx
      ].detailAddress = '';
      RepresentativeCheckModel.representatives[this.state.addressIdx].newAddresses[
        this.state.newAddressIdx
      ].nameOfBuilding = address.buildingName;
    });
    this.openAddressModal(false, this.state.addressIdx, this.state.newAddressIdx);
  }
  setDetailAddress(e, idx, idx2) {
    //상세주소
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].detailAddress = e.target.value;
    });
  }
  setNameOfBuilding(e, idx, idx2) {
    //건물명
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].nameOfBuilding = e.target.value;
    });
  }
  bnameFontSizeChange(idx, idx2) {
    const bName = RepresentativeCheckModel.representatives[idx].newAddresses[idx2].newAddress?.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
  //외국인 생년월일
  setYear(e, idx, idx2) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].causeDate.year = onlyNumber(e.target.value);
    });
  }
  setMonth(e, idx, idx2) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].causeDate.month = onlyNumber(e.target.value);
    });
  }
  setDay(e, idx, idx2) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].causeDate.day = onlyNumber(e.target.value);
    });
  }
  monthBlur(e, idx, idx2) {
    runInAction(() => {
      let month = e.target.value;
      if (month && month.length === 1) {
        e.target.value = '0' + month;
      }
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].causeDate.month = e.target.value;
    });
  }
  dayBlur(e, idx, idx2) {
    runInAction(() => {
      let day = e.target.value;
      if (day && day.length === 1) {
        e.target.value = '0' + day;
      }
      RepresentativeCheckModel.representatives[idx].newAddresses[idx2].causeDate.day = e.target.value;
    });
  }
  displayBirthErrorMessage(idx, idx2, type) {
    const year = this.representatives[idx].newAddresses[idx2].causeDate.year;
    const month = this.representatives[idx].newAddresses[idx2].causeDate.month;
    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  addNewAddress(idx) {
    runInAction(() => {
      RepresentativeCheckModel.representatives[idx].newAddresses.push({
        causeDate: {
          year: '',
          month: '',
          day: '',
        },
        detailAddress: '',
        nameOfBuilding: '',
        newAddress: {},
      });
    });
  }
  async deleteNewAddress(idx, idx2) {
    const conf = await _confirm('입력하신 변동내역 정보를 삭제하시겠습니까?');
    if (conf) {
      return RepresentativeCheckModel.representatives[idx].newAddresses.splice(idx2, 1);
    } else {
      return;
    }
  }
}

export default new RepresentativeCheckVm();
