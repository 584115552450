import { useObserver } from 'mobx-react';
import FreeIssueOfNewSharesVm from './FreeIssueOfNewSharesVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../FreeIssueOfNewShares/FreeIssueOfNewShares.module.scss';
import clsx from 'clsx';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Check from '@comComponents/atoms/Input/Check/Check';
import TextBtn from '@comComponents/atoms/Button/TextBtn/TextBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import { addCommas } from '@common/module/replaceNumber';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import { Fragment } from 'react';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';

function FreeIssueOfNewShares() {
  return useObserver(() => (
    <form onSubmit={(e) => FreeIssueOfNewSharesVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>무상증자의 재원</span>은 어떤 것인가요?
              </h2>
              <div className={Qst.titleExplain}>복수선택 가능합니다.</div>
            </div>
            <div className={st.checkHeader}>
              <div>
                <Check
                  className={st.checkAll}
                  style={{ fontSize: '17px', fontWeight: '500', top: '1px', whiteSpace: 'pre' }}
                  checked={FreeIssueOfNewSharesVm.checkAll}
                  onChange={(e) => FreeIssueOfNewSharesVm.setCheckAll(e)}
                >
                  전체선택({FreeIssueOfNewSharesVm.checkCount}/{FreeIssueOfNewSharesVm.resourceTypes.length})
                </Check>
              </div>
              <div>
                <TextBtn className={st.unCheckAll} onClick={() => FreeIssueOfNewSharesVm.setCheckAll(false)}>
                  선택해제
                </TextBtn>
              </div>
            </div>
            <ul className={st.resourceInfoList}>
              {FreeIssueOfNewSharesVm.resourceTypes.map((item, idx) => {
                return (
                  <Fragment key={idx}>
                    <li className={item.checkedYn ? st.checkLi : null}>
                      <input type="hidden" name={`resourceTypes[${idx}].resourceType`} value={item.resourceType} />
                      <Check
                        className={st.check}
                        style={{ top: '-3px', fontSize: '18px' }}
                        name={`resourceTypes[${idx}].checkedYn`}
                        value={item.checkedYn}
                        checked={item.checkedYn === true}
                        onChange={(e) => FreeIssueOfNewSharesVm.setCheckedYn(e, idx)}
                      >
                        <div>{item.resourceType}</div>
                      </Check>
                    </li>
                  </Fragment>
                );
              })}
            </ul>
            {FreeIssueOfNewSharesVm.checkedYnError() && (
              <div>
                <ErrorText>무상증자 재원을 선택해 주세요.</ErrorText>
              </div>
            )}
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="무상증자" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>언제 무상증자를 할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer} iconText="A1">
                    자본준비금이 있는 경우 무상증자를 할 수 있습니다.
                  </Answer>
                  <div className={Qst.explain}>
                    자본준비금의 대표적인 예는 주식발행초과금입니다. 유상증자 시 발행가액을 액면가보다 높게 잡아
                    할증발행을 한 경우 액면가와 차액만큼 발생한 금액을 주식발행초과금이라고 부르고, 이 금액으로
                    무상증자를 할 수 있습니다.
                  </div>
                  <Answer className={Qst.answer} iconText="A2">
                    이익준비금이 있는 경우 무상증자를 할 수 있습니다.
                  </Answer>
                  <div className={Qst.explain}>
                    이익준비금은 이익잉여금과는 다릅니다. 이익잉여금이란 아직 처분이 결정되지 않은 영업이익을 말합니다.
                    상법상 이익잉여금으로 무상증자를 바로 할 수는 없고, 별도의 절차가 필요합니다.
                  </div>
                  <div className={st.freeIssuedOfNewShares}>
                    <div className={st.freeIssuedOfNewSharesTitle}>1. 정기주총에서 이익준비금으로 넣는 방법</div>
                    <div className={st.freeIssuedOfNewSharesContent}>
                      정기주주총회에서 재무제표를 승인할 때 이익잉여금을 이익준비금으로 처분(변경)하고 무상증자를 할 수
                      있습니다. 따라서 이 경우 미리 이익잉여금을 이익준비금으로 변경한 재무제표를 준비해주셔야 합니다.
                    </div>
                  </div>
                  <div className={st.freeIssuedOfNewShares}>
                    <div className={st.freeIssuedOfNewSharesTitle}>2. 정기주총에서 주식배당을 결의하는 방법</div>
                    <div className={st.freeIssuedOfNewSharesContent}>
                      정기주주총회에서 이익잉여금으로 주식배당을 하면 무상증자와 동일한 효과가 있습니다. 다만 주식배당은
                      전체 배당액의 2분의 1을 초과하지 못하므로 증자 금액이 소액인 경우 실익이 적습니다.
                    </div>
                  </div>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>무상증자할 총 금액</span>을 입력해 주세요.
              </h2>
            </div>
            <div className={st.freeIssuedOfNewSharesAmountAndNumberBox}>
              <img src="/images/freeIssue_of_new_shares.png" className={st.freeIssuedOfNewSharesAmountAndNumberImg} />
            </div>
            <div className={st.freeIssuedOfNewSharesAmountAndNumberInputBox}>
              <div className={st.inputBox}>
                <div className={st.amountBox}>
                  <div className={st.title}>무상증자할 금액</div>
                  <div>
                    <InputText
                      className={st.input}
                      style={{ width: '160px' }}
                      placeholder="0"
                      placeholderAlign="right"
                      name="amount"
                      value={addCommas(FreeIssueOfNewSharesVm.amount)}
                      onChange={(e) => FreeIssueOfNewSharesVm.setAmount(e)}
                      errorIconNon
                      errorText={
                        <Error
                          name="amount"
                          value={FreeIssueOfNewSharesVm.amount}
                          style={
                            FreeIssueOfNewSharesVm.amountIssuePriceValidateFunc().numberCheckYn
                              ? { width: '175px' }
                              : { width: '560px' }
                          }
                          errorCase={{
                            required: '무상증자할 금액을 입력해 주세요.',
                            validate: {
                              func: FreeIssueOfNewSharesVm.amountIssuePriceValidateFunc().validation,
                              message: FreeIssueOfNewSharesVm.amountIssuePriceValidateFunc().message,
                            },
                          }}
                        />
                      }
                    />
                    <span className={st.won}>원</span>
                  </div>
                </div>
                <div className={st.numberBox}>
                  <div className={st.title}>발행할 주식수</div>
                  <div>
                    <InputText
                      className={st.input}
                      style={{ width: '160px' }}
                      placeholder="0"
                      placeholderAlign="right"
                      name="number"
                      value={addCommas(FreeIssueOfNewSharesVm.number)}
                      onChange={(e) => FreeIssueOfNewSharesVm.setNumber(e)}
                      errorIconNon
                      errorText={
                        <Error
                          name="number"
                          value={FreeIssueOfNewSharesVm.number}
                          errorCase={{
                            required: '발행할 주식수를 입력해 주세요.',
                            validate: {
                              func: FreeIssueOfNewSharesVm.numberIssuePriceValidateFunc().validation,
                              message: FreeIssueOfNewSharesVm.numberIssuePriceValidateFunc().message,
                            },
                          }}
                        />
                      }
                    />
                    <span className={st.won}>주</span>
                  </div>
                </div>
              </div>
              <div className={st.countBox}>
                <div className={st.title}>발행가액(자동계산)</div>
                <div className={st.count}>
                  {FreeIssueOfNewSharesVm.issuePrice()?.point ? (
                    <span>
                      {addCommas(FreeIssueOfNewSharesVm.issuePrice().issuePrice)}
                      <span className={st.issuePriceError}>
                        .{addCommas(FreeIssueOfNewSharesVm.issuePrice().point)}
                      </span>
                    </span>
                  ) : (
                    addCommas(FreeIssueOfNewSharesVm.issuePrice())
                  )}
                  <span className={st.won}>원</span>
                  {FreeIssueOfNewSharesVm.display액면금() && <div className={st.successPrice}>액면가</div>}
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>배정기준일</span>을 입력해 주세요.
              </h2>
            </div>
            <Badge className={st.recordDateBadge} title="예시" type="example" border={false}>
              <div className={st.recordDateBadgeBox}>
                <img className={st.recordDateBadgeImg} src="/images/registration_date.png" alt="배정기준일 예시" />
                <div className={st.recordDateBadgeTextBox}>
                  <div className={st.recordDateBadgeText}>
                    <div className={st.text}>
                      <span className={st.bold}>배정기준일부터 등기접수가 가능</span>합니다.
                    </div>
                  </div>
                  <div className={st.recordDateBadgeText}>
                    <div className={st.text}>
                      배정기준일로부터 2주 내에 등기를 접수해야 과태료가 발생하지 아니하므로, 안정적으로 등기신청하기
                      위해 배정기준일을{' '}
                      <span className={st.bold}>오늘(질문지 제출일)을 기준으로, 1주전 ~ 3주 이내</span>로 입력하는 것을
                      추천드립니다.
                    </div>
                    <div className={clsx(st.text, st.colorChange)}>
                      <span className={st.bold}>예시) 오늘(질문 제출일)이 3월 23일</span>이라면,{' '}
                      <span className={st.bold}>안정적으로 등기 가능한 배정기준일은 3월 16일 ~ 4월 12일</span> 입니다.
                    </div>
                  </div>
                  <div className={st.recordDateBadgeTextExplain}>
                    특별한 사정이 없다면, <span className={st.bold}>오늘 날짜(질문 제출일)로 입력하는 것을 추천</span>
                    드립니다.
                    <br />
                    입력한 배정기준일로 부터 2주 이내 접수해야 과태료가 부가되지 않습니다.
                  </div>
                </div>
              </div>
            </Badge>
            <div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '130px' }}
                  placeholder="0000"
                  name="recordDateForNewSharesAllotment.year"
                  value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.year}
                  onChange={(e) => FreeIssueOfNewSharesVm.setYear(e)}
                  maxLength="4"
                />
                <span className={Qst.birthText}>년</span>
              </div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '108px' }}
                  placeholder="00"
                  name="recordDateForNewSharesAllotment.month"
                  value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.month}
                  onChange={(e) => FreeIssueOfNewSharesVm.setMonth(e)}
                  maxLength="2"
                  onBlur={(e) => FreeIssueOfNewSharesVm.dateBlur(e, 'month')}
                />
                <span className={Qst.birthText}>월</span>
              </div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '108px' }}
                  placeholder="00"
                  name="recordDateForNewSharesAllotment.day"
                  value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.day}
                  onChange={(e) => FreeIssueOfNewSharesVm.setDay(e)}
                  maxLength="2"
                  onBlur={(e) => FreeIssueOfNewSharesVm.dateBlur(e, 'day')}
                />
                <span className={Qst.birthText}>일</span>
              </div>
            </div>
            <div>
              <Error
                style={{ marginTop: '-27px' }}
                name="recordDateForNewSharesAllotment.year"
                value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.year}
                errorCase={{
                  required: '연도를 입력해 주세요.',
                  pattern: {
                    value: regExp.year().test(FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.year),
                    message: '올바른 연도를 입력해 주세요',
                  },
                }}
              />
              <Error
                style={{ marginTop: '-27px' }}
                name="recordDateForNewSharesAllotment.month"
                value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.month}
                errorCase={{
                  required: FreeIssueOfNewSharesVm.displayBirthErrorMessage('month') ? '월을 입력해 주세요.' : null,
                  pattern: {
                    value: FreeIssueOfNewSharesVm.displayBirthErrorMessage('month')
                      ? regExp.month().test(FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.month)
                      : null,
                    message: '올바른 월을 입력해 주세요',
                  },
                }}
              />
              <Error
                style={{ marginTop: '-27px' }}
                name="recordDateForNewSharesAllotment.day"
                value={FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.day}
                errorCase={{
                  required: FreeIssueOfNewSharesVm.displayBirthErrorMessage('day') ? '일(날짜)을 입력해 주세요.' : null,
                  pattern: {
                    value: FreeIssueOfNewSharesVm.displayBirthErrorMessage('day')
                      ? regExp.day().test(FreeIssueOfNewSharesVm.recordDateForNewSharesAllotment.day)
                      : null,
                    message: '올바른 일(날짜)을 입력해 주세요.',
                  },
                }}
              />
            </div>
          </div>
          <div className={Qst.qnaSection}>
            <Qna className={Qst.noTitle}>
              <div className={Qst.qnaBox}>
                <QText>배정기준일이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    배정기준일이란 무상증자된 주식을 받을 주주를 확정하는 날입니다.
                  </Answer>
                  <div className={Qst.explain}>
                    무상증자를 하면 주주들이 무상으로 주식을 얻게 되는데, 주식은 언제든 양도할 수 있으므로, 무상으로
                    주식을 얻게될 주주들을 확정하는 날이 필요합니다. 배정기준일을 정한 경우, 2주 전에 공고가 필요합니다.
                  </div>
                  <div className={Qst.explain}>
                    또한 배정기준일에 신주발행의 효과가 발생하고, 배정기준일부터 등기접수가 가능합니다. 미래의 날짜를
                    배정기준일로 정할 수 있지만, 그 날짜가 되기 전에는 등기접수를 할 수 없다는 점 유의 부탁드립니다.
                  </div>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default FreeIssueOfNewShares;
