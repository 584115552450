import Check from '@common/components/atoms/Input/Check/Check';
import { observer } from 'mobx-react';

import st from './style.module.scss';

const CheckBox = observer((props) => {
  return (
    <Check className={st.checkBox} onlyCheckBox {...props}>
      {''}
    </Check>
  );
});

export default CheckBox;
