import { useObserver } from 'mobx-react';
import vm from './ConvertibleBondAcquisitionAmountVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ConvertibleBondAcquisitionAmount.module.scss';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import { addCommas } from '@common/module/replaceNumber';
import InputText from '@comComponents/atoms/Input/InputText/InputText';

function ConvertibleBondAcquisitionAmount() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>전환사채 인수금액</span>을 입력해주세요.
              </h2>
            </div>
            <table className={st.table}>
              <colgroup>
                <col width="218" />
                <col width="100" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>전환사채인수인</th>
                  <th>자격</th>
                  <th>인수금액(=투자금)</th>
                </tr>
              </thead>
              <tbody>
                {vm.convertibleBondAcquisitionAmountInfos?.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      <td>
                        <span className={st.index}>{idx + 1}</span>
                        {data.name}
                      </td>
                      <td>{data.existingShareholdersYn ? '주주' : '제3자'}</td>
                      <td>
                        <input
                          type="hidden"
                          name={`convertibleBondAcquisitionAmountInfos[${idx}].id`}
                          value={data.id}
                        />
                        <InputText
                          style={{ width: '160px', height: '52px', fontSize: '16px' }}
                          size="small"
                          value={addCommas(data.acquisitionAmount)}
                          onChange={(e) => vm.setAcquisitionAmount(e, idx)}
                          name={`convertibleBondAcquisitionAmountInfos[${idx}].acquisitionAmount`}
                          errorText={
                            <Error
                              name={`convertibleBondAcquisitionAmountInfos[${idx}].acquisitionAmount`}
                              value={addCommas(data.acquisitionAmount)}
                              style={{ width: '375px', textAlign: 'left' }}
                              errorCase={{
                                required: '인수금액을 입력해 주세요.',
                              }}
                            />
                          }
                        />
                        <span className={st.text}>원</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>합계</td>
                  <td colSpan={2}>{addCommas(vm.sum)} 원</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default ConvertibleBondAcquisitionAmount;
