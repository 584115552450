import { runInAction, observable, toJS } from 'mobx';
import TCAgreeAndDeliveryAddressStepModel from '@model/changeRegistration/TCAgreeAndDeliveryAddressStepModel';
import portalModel from '@model/portalModel';
import RequiredModal from '@pages/ModalContent/changeRegistration/TCAgreeAndDeliveryAddress/RequiredModal';
import UnRequiredModal from '@pages/ModalContent/changeRegistration/TCAgreeAndDeliveryAddress/UnRequiredModal';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';

class TCAgreeAndDeliveryAddressVm {
  constructor() {
    this.state = observable({
      addressModal: false,
    });
  }
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      runInAction(() => {
        let param = formDataToJson(e.target).data;
        if (param.deliveryAddress) {
          param.deliveryAddress = TCAgreeAndDeliveryAddressStepModel.address;
        }
        stepService.saveStep(param);
      });
    }
  }
  openModal(type) {
    let modal = {};
    let content = '';
    if (type === 'required') {
      modal = RequiredModal;
      content = TCAgreeAndDeliveryAddressStepModel.tcagreements[0].content;
    } else if (type === 'unRequired') {
      modal = UnRequiredModal;
      content = TCAgreeAndDeliveryAddressStepModel.tcagreements[1].content;
    }
    runInAction(() => {
      portalModel.modalContent = modal.modalContent(content);
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get tcagreements() {
    return TCAgreeAndDeliveryAddressStepModel.tcagreements;
  }
  setAgreeYn(e, idx) {
    if (TCAgreeAndDeliveryAddressStepModel.tcagreements[idx].agreeYn) {
      TCAgreeAndDeliveryAddressStepModel.tcagreements[idx].agreeYn = false;
    } else {
      TCAgreeAndDeliveryAddressStepModel.tcagreements[idx].agreeYn = true;
    }
  }
  get agreeAll() {
    let isCheckAll = true;
    let agreeCnt = 0;
    TCAgreeAndDeliveryAddressStepModel.tcagreements.forEach((tcagreements) => {
      if (tcagreements.agreeYn) {
        agreeCnt++;
      } else if (!tcagreements.agreeYn) {
        isCheckAll = false;
      }
    });
    if (agreeCnt === 0) {
      return false;
    }
    return isCheckAll;
  }
  setAgreeAll(e) {
    const value = e.target.checked;
    runInAction(() => {
      TCAgreeAndDeliveryAddressStepModel.tcagreements.forEach((tcagreements) => {
        tcagreements.agreeYn = value;
      });
    });
  }
  get originAddress() {
    return TCAgreeAndDeliveryAddressStepModel.originAddress;
  }
  get deliveryToCompanyAddressYn() {
    return TCAgreeAndDeliveryAddressStepModel.deliveryToCompanyAddressYn;
  }
  setDeliveryToCompanyAddressYn(e) {
    runInAction(() => {
      TCAgreeAndDeliveryAddressStepModel.deliveryToCompanyAddressYn = e.target.value === 'true';
    });
  }
  get address() {
    return TCAgreeAndDeliveryAddressStepModel.address;
  }
  openAddressModal(openYn) {
    this.state.addressModal = openYn;
  }
  addressComplate(address) {
    runInAction(() => {
      TCAgreeAndDeliveryAddressStepModel.address = address;
      TCAgreeAndDeliveryAddressStepModel.detailAddress = '';
      TCAgreeAndDeliveryAddressStepModel.nameOfBuilding = address.buildingName;
    });
    this.openAddressModal(false);
  }
  get detailAddress() {
    return TCAgreeAndDeliveryAddressStepModel.detailAddress;
  }
  setDetailAddress(e) {
    runInAction(() => {
      TCAgreeAndDeliveryAddressStepModel.detailAddress = e.target.value;
    });
  }
  get nameOfBuilding() {
    return TCAgreeAndDeliveryAddressStepModel.nameOfBuilding;
  }
  setNameOfBuilding(e) {
    runInAction(() => {
      TCAgreeAndDeliveryAddressStepModel.nameOfBuilding = e.target.value;
    });
  }
  get bnameFontSizeChange() {
    const bName = TCAgreeAndDeliveryAddressStepModel.address?.bname;
    if (bName) {
      if (bName.length > 3)
        return true; //bname이 3글자보다 길면 true
      else return false;
    } else {
      return false;
    }
  }
}

export default new TCAgreeAndDeliveryAddressVm();
