import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import CorpNameStepVm from './CorpNameStepVm';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './CorpNameStep.module.scss';
import clsx from 'clsx';

function CorpNameStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>한글 회사명</div>
        <div className={Cst.data}>
          <span className={props.step.changeKoreanCorpNameYn ? null : st.noChange}>
            {CorpNameStepVm.koreanName(props.step)}
          </span>
        </div>
      </div>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>영문 회사명</div>
        <div className={Cst.data}>
          <span className={props.step.changeEnglishCorpNameYn ? null : st.noChange}>
            {CorpNameStepVm.englishName(props.step)}
          </span>
        </div>
      </div>
    </>
  ));
}

export default CorpNameStep;
