import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import NewAddressVm from './NewAddressVm';

function NewAddress(props) {
  return useObserver(() => (
    <>
      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
        <div className={Qst.infoSection}>
          {props.item.foreignerYn ? (
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>{NewAddressVm.displayKoreanName(props.item, props.origin)}</span>님의{' '}
                <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해 주세요.
              </h2>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
              </div>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 법인설립이 거절됩니다. (5~7일 더 걸림)
              </div>
            </div>
          ) : (
            <>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>{NewAddressVm.displayKoreanName(props.item, props.origin)}</span>님의{' '}
                  <span className={Qst.bold}>주민등록등본상 신규 주소</span>를 입력해 주세요.
                </h2>
              </div>
              <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                <img
                  className={Qst.addressBadgeImg}
                  src="/images/person_address_example.png"
                  alt="주민등록상 도로명주소 예시"
                />
              </Badge>
            </>
          )}
          {props.origin ? (
            <>
              {/* 기존임원 */}
              {props.item.newAddresses.map((item2, idx2) => {
                return (
                  <div key={idx2}>
                    <div className={Qst.rowWrap}>
                      <InputText
                        className={Qst.inputText}
                        style={{ width: '380px' }}
                        explain="도로명 주소"
                        value={item2?.newAddress?.address || ''}
                        readOnly
                        onClick={() => props.parentVm.openAddressModal(true, props.idx, idx2)}
                        name={`${props.name}[${props.idx}].newAddresses[${idx2}].newAddress.address`}
                        errorText={
                          <Error
                            name={`${props.name}[${props.idx}].newAddresses[${idx2}].newAddress.address`}
                            value={item2?.newAddress?.address}
                            errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                          />
                        }
                      />
                      <SquareBtn onClick={() => props.parentVm.openAddressModal(true, props.idx, idx2)}>
                        도로명 주소 찾기
                      </SquareBtn>
                    </div>
                    <div className={Qst.rowWrap}>
                      <InputText
                        className={Qst.inputText}
                        style={{ width: '271px' }}
                        explain="상세 주소"
                        placeholder="상세주소 없을 시, [없음] 입력"
                        value={item2.detailAddress}
                        onChange={(e) => props.parentVm.setDetailAddress(e, props.idx, idx2)}
                        name={`${props.name}[${props.idx}].newAddresses[${idx2}].detailAddress`}
                        errorText={
                          <Error
                            name={`${props.name}[${props.idx}].newAddresses[${idx2}].detailAddress`}
                            value={item2.detailAddress}
                            errorCase={{
                              required: '상세 주소를 입력해 주세요.',
                              pattern: {
                                value: regExp.onlyKorNum_hyphen_comma().test(item2.detailAddress),
                                message: (
                                  <span>
                                    임대차 계약서상 영문이 있다면, <br />
                                    한글로 옮겨적어주세요.
                                    <br />
                                    EX) B동 &gt; 비동
                                  </span>
                                ),
                              },
                            }}
                          />
                        }
                      />
                      <div className={Qst.symbol}>
                        <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                        <span
                          className={clsx(
                            Qst.symbolText,
                            NewAddressVm.bnameFontSizeChange(props.item, idx2, props.origin) ? Qst.fontSize : null,
                          )}
                        >
                          {item2?.newAddress?.bname || '법정동'},&nbsp;
                        </span>
                        <InputText
                          style={{ width: '200px' }}
                          explain="건물명"
                          placeholder="없을 시, [없음] 입력"
                          value={item2.nameOfBuilding}
                          onChange={(e) => props.parentVm.setNameOfBuilding(e, props.idx, idx2)}
                          name={`${props.name}[${props.idx}].newAddresses[${idx2}].nameOfBuilding`}
                          errorText={
                            <Error
                              name={`${props.name}[${props.idx}].newAddresses[${idx2}].nameOfBuilding`}
                              value={item2.nameOfBuilding}
                              errorCase={{
                                required: '건물명을 입력해 주세요.',
                                pattern: {
                                  value: regExp.onlyKorNum_hyphen_comma().test(item2.nameOfBuilding),
                                  message: (
                                    <span>
                                      건물명이 영문인 경우, 한글
                                      <br />
                                      발음으로 옮겨적어 주세요. <br />
                                      EX) AB타워 &gt; 에이비 타워
                                    </span>
                                  ),
                                },
                              }}
                            />
                          }
                        />
                        <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {/* 신규임원 */}
              <div className={Qst.rowWrap}>
                <InputText
                  className={Qst.inputText}
                  style={{ width: '380px' }}
                  explain="도로명 주소"
                  value={props.item?.newAddress?.address || ''}
                  readOnly
                  onClick={() => props.parentVm.openAddressModal(true, props.idx)}
                  name={`${props.name}[${props.idx}].newAddress.address`}
                  errorText={
                    <Error
                      name={`${props.name}[${props.idx}].newAddress.address`}
                      value={props.item?.newAddress?.address}
                      errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                    />
                  }
                />
                <SquareBtn onClick={() => props.parentVm.openAddressModal(true, props.idx)}>도로명 주소 찾기</SquareBtn>
              </div>
              <div className={Qst.rowWrap}>
                <InputText
                  className={Qst.inputText}
                  style={{ width: '271px' }}
                  explain="상세 주소"
                  placeholder="상세주소 없을 시, [없음] 입력"
                  value={props.item.detailAddress}
                  onChange={(e) => props.parentVm.setDetailAddress(e, props.idx)}
                  name={`${props.name}[${props.idx}].detailAddress`}
                  errorText={
                    <Error
                      name={`${props.name}[${props.idx}].detailAddress`}
                      value={props.item.detailAddress}
                      errorCase={{
                        required: '상세 주소를 입력해 주세요.',
                        pattern: {
                          value: regExp.onlyKorNum_hyphen_comma().test(props.item.detailAddress),
                          message: (
                            <span>
                              임대차 계약서상 영문이 있다면, <br />
                              한글로 옮겨적어주세요.
                              <br />
                              EX) B동 &gt; 비동
                            </span>
                          ),
                        },
                      }}
                    />
                  }
                />
                <div className={Qst.symbol}>
                  <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                  <span
                    className={clsx(
                      Qst.symbolText,
                      NewAddressVm.bnameFontSizeChange(props.item, null, props.orgin) ? Qst.fontSize : null,
                    )}
                  >
                    {props.item?.newAddress?.bname || '법정동'},&nbsp;
                  </span>
                  <InputText
                    style={{ width: '200px' }}
                    explain="건물명"
                    placeholder="없을 시, [없음] 입력"
                    value={props.item.nameOfBuilding}
                    onChange={(e) => props.parentVm.setNameOfBuilding(e, props.idx)}
                    name={`${props.name}[${props.idx}].nameOfBuilding`}
                    errorText={
                      <Error
                        name={`${props.name}[${props.idx}].nameOfBuilding`}
                        value={props.item.nameOfBuilding}
                        errorCase={{
                          required: '건물명을 입력해 주세요.',
                          pattern: {
                            value: regExp.onlyKorNum_hyphen_comma().test(props.item.nameOfBuilding),
                            message: (
                              <span>
                                건물명이 영문인 경우, 한글
                                <br />
                                발음으로 옮겨적어 주세요. <br />
                                EX) AB타워 &gt; 에이비 타워
                              </span>
                            ),
                          },
                        }}
                      />
                    }
                  />
                  <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  ));
}

export default NewAddress;
