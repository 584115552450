import { runInAction } from 'mobx';
import etcInfoModel from '@model/establish/etcInfoModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';

class viewModel {
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
  get noticeOfEstablishmentYn() {
    return etcInfoModel.noticeOfEstablishmentYn;
  }
  setNoticeOfEstablishmentYn(e) {
    etcInfoModel.noticeOfEstablishmentYn = e.target.value === 'true';
  }
}

export default new viewModel();
