import { observable, runInAction } from 'mobx';

const announceMethodStepModel = observable({
  customNewspaperCompany: '',
  hasHomepageYn: null,
  homepageUrl: '',
  newspaperCompany: '',
  newspaperCompanyList: [],
  validUrlCheckType: null,
  originAnnounceMethod: '',
  setAnnounceMethod(data) {
    runInAction(() => {
      this.customNewspaperCompany = data?.customNewspaperCompany || '';
      this.hasHomepageYn = data?.hasHomepageYn ?? null;
      this.homepageUrl = data?.homepageUrl || '';
      this.newspaperCompany = data?.newspaperCompany || '';
      this.newspaperCompanyList = data?.newspaperCompanyList || [];
      this.validUrlCheckType = data?.validUrlCheckType || null;
      this.originAnnounceMethod = data?.originAnnounceMethod || '';
    });
  },
});

export default announceMethodStepModel;
