import FileUploadStepModel from '@model/changeRegistration/FileUploadStepModel';
import { observable } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import changeRegistrationService from '@service/changeRegistrationService';
import { findPos } from '@common/module/submit';

class FileUploadVm {
  constructor() {
    this.state = observable({
      errorCheckYn: false,
    });
  }
  uploadValidation() {
    let errorFileList = [];
    let ruleEmptyYn = false;
    let exceptionEmptyYn = false;
    FileUploadStepModel.fileGroupTypes.forEach((item) => {
      item.details.forEach((detail) => {
        if (detail.fileType !== '사업자등록증') {
          //사업자등록은 비필수값이므로 제외
          if (detail.files.length === 0 && detail.uploadFiles.length === 0) {
            if (detail.fileType === '무상증자_최종_재무제표') {
              ruleEmptyYn = true;
            } else if (detail.fileType === '무상증자_예외') {
              exceptionEmptyYn = true;
            } else {
              errorFileList.push(detail.fileType);
            }
          }
          if (ruleEmptyYn && exceptionEmptyYn) {
            //무상증자_최종_재무제표, 무상증자_예외 둘 중 하나만 파일 업로드하면 됨. 둘다 파일 업로드가 안된 경우
            errorFileList.push('무상증자_최종_재무제표');
            errorFileList.push('무상증자_예외');
          }
        }
      });
    });
    if (errorFileList.length) {
      return errorFileList;
    } else {
      return true;
    }
  }
  displayErrorText(fileType) {
    if (this.uploadValidation() !== true) {
      let errorList = this.uploadValidation();
      if (errorList.includes(fileType)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  async upload(e) {
    e.preventDefault();
    if (this.uploadValidation() === true) {
      await changeRegistrationService.uploadFiles();
    } else {
      this.state.errorCheckYn = true;
      let errorList = this.uploadValidation();
      const fileCheck = setInterval(() => {
        clearInterval(fileCheck);
        let top = findPos(document.querySelector(`.${errorList[0]}`)) - 185;
        window.scroll(0, top);
      }, 30);
    }
  }
  get fileGroupTypes() {
    return FileUploadStepModel.fileGroupTypes;
  }
  get deleteIds() {
    return FileUploadStepModel.deleteIds;
  }
  files(idx, idx2) {
    return FileUploadStepModel.fileGroupTypes[idx].details[idx2].files;
  }
  uploadFiles(idx, idx2) {
    return FileUploadStepModel.fileGroupTypes[idx].details[idx2].uploadFiles;
  }
}
export default new FileUploadVm();
