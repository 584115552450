import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import ArrowRight from '@common/components/asset/icon/ico_arrow_right.svg';
import Plus from '@common/components/asset/icon/plus.svg';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import CorporationTypeExampleBox from './components/ corporationTypeExampleBox';
import { 농업회사법인, 어업회사법인 } from './constant';
import st from './style.module.scss';
import AiChat from '@pages/Establish/AiChat/AiChat';

const BusinessPurposeQna = observer(({ smartformType }) => {
  return (
    <Question>
      <h1 className={st.title}>사업목적 안내</h1>
      <div className={st.purposeBoxWrapper}>
        <div className={st.explainSelectPurposeBox}>
          <div className={st.titleContainer}>사업목적 선택방법</div>
          <div className={st.selectPurposeInfoBox} style={{ padding: '16px 8px 16px 16px' }}>
            <h3 className={st.titleWrapper}>
              <div className={st.numberContainer}>1</div>
              추천 목적을 선택해 보세요.
            </h3>
            <div className={st.bodyText} style={{ marginTop: '4px' }}>
              헬프미에서 30,000개 이상의 등기부를 분석하여, 분야별 가장 많이 사용된 목적을 정리했습니다. 반려 걱정없이
              간편하게 선택해 보세요.
            </div>
          </div>

          <div className={st.selectPurposeInfoBox} style={{ padding: '16px' }}>
            <h3 className={st.titleWrapper}>
              <div className={st.numberContainer}>2</div>
              직접 입력 시 아래내용을 참고하세요.
            </h3>
            <div className={st.bodyText}>
              <div className={st.customUl} /> [구체적인 사업분야] + [일반적인 사업종류]를 조합해 보세요.
            </div>
            <div className={st.exampleBoxWrapper}>
              <div className={st.exampleBox}>예시</div>
              <div className={st.exampleContentBox}>
                <div className={st.exampleContentWrapper}>
                  <span className={st.subTitle}>
                    구체적인 <br />
                    사업분야
                  </span>
                  <span className={st.content}>
                    건강식품, 의류, 화장품, 광고, 식자재, 식품, 전자기기, 자동차, <br /> 소프트웨어, 부동산, 인터넷,
                    캐릭터, 홍보 , ...
                  </span>
                </div>
                <img src={Plus} className={st.plusIcon} alt="plusIcon" />
                <div className={st.exampleContentWrapper}>
                  <span className={st.subTitle}>
                    일반적인 <br />
                    사업종류
                  </span>
                  <span className={st.content}>
                    도소매업, 연구개발업, 수출임업, 제조판매업, 판매업, 공급업, <br />
                    제조업, 서비스업, 유통업, 임대업, 유지 및 보수업 ...
                  </span>
                </div>
              </div>
            </div>
            <div className={st.bodyText}>
              <div className={st.customUl} /> 영어를 쓰신다면, 한글을 먼저 쓰고 괄호 안에 영어를 써주세요.
            </div>
            <div className={st.exampleBoxWrapper}>
              <div className={st.exampleBox}>예시</div>
              <div className={st.exampleContentBox}>
                <div className={st.exampleContentWrapper}>
                  <div className={st.exampleContentWrapper}>
                    <span className={st.lineThroughText}>It System 제공업</span>
                    <img src={ArrowRight} className={st.arrowRightIcon} alt="arrowRight" />
                    <span className={st.subTitle}>아이티 시스템(It System) 제공업</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={st.qnaContainer}>
          <AiChat />
          <Qna>
            <div className={clsx(Qst.qnaBox, st.qnaBoxTitle)}>
              <QText>사업목적이란 무엇인가요?</QText>
              <div className="answerBox">
                <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                  사업목적은 앞으로 회사가 운영할 사업입니다.
                </Answer>
                <p className={st.qnaBoxExplain}>
                  설립할 때, 당장 시작할 사업 뿐만 아니라, 장래에 운영할 가능성이 있는 사업목적까지 넣는 편을
                  추천드립니다. 설립 이후에 사업목적을 추가할 때, 세금과 수수료가 <br /> 들기 때문입니다.
                </p>
              </div>
            </div>
            {smartformType === 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' && ( //농업회사법인
              <div className={clsx(Qst.qnaBox, st.qnaBoxTitle)}>
                <QText>농업회사법인의 사업목적을 자유롭게 정할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                    아니요. 농업과 관련한 사업목적만 쓸 수 있습니다.
                  </Answer>
                  <p className={st.qnaBoxExplain}>
                    농업회사법인의 경우, 아래 목적사항만 추가할 수 있습니다. (농림축산식품부 입장) [농업·어업·임업{' '}
                    {'> '}
                    농업회사법인] 모음을 추가하시면 됩니다.
                  </p>
                  <CorporationTypeExampleBox title="농업회사법인" list={농업회사법인} />
                  <p className={st.qnaBoxExplain}>
                    농촌융복합산업은 농촌융복합산업 육성 및 지원에 관한 법률 제8조에 따라 인증받은 자를 의미하며, 인증을
                    받아야 등기부상 목적 기재가 가능한 점 참고해주세요. 인증을 받지 않으셨을 경우 제외하는 것을
                    권유드립니다.
                  </p>
                </div>
              </div>
            )}
            {smartformType === 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && ( //어업회사법인
              <div className={clsx(Qst.qnaBox, st.qnaBoxTitle)}>
                <QText>어업회사법인의 사업목적을 자유롭게 정할 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                    아니요. 어업과 관련한 사업목적만 쓸 수 있습니다.
                  </Answer>
                  <p className={st.qnaBoxExplain}>
                    어업회사법인의 경우, 아래 목적사항만 추가할 수 있습니다. (농림축산식품부 입장) [농업·어업·임업{' '}
                    {'> '}
                    어업회사법인] 모음을 추가하시면 됩니다.
                  </p>
                  <CorporationTypeExampleBox title="어업회사법인" list={어업회사법인} />
                </div>
              </div>
            )}
            {smartformType !== 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY' &&
              smartformType !== 'ESTABLISHMENT_FISHERY_STOCK_COMPANY' && (
                <>
                  <div className={clsx(Qst.qnaBox, st.qnaBoxTitle)}>
                    <QText>부동산 임대업을 목적에 넣어도 불이익이 없나요?</QText>
                    <div className="answerBox">
                      <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                        일반적으로 불이익이 없습니다. (일부 예외 존재)
                      </Answer>
                      <p className={st.qnaBoxExplain}>
                        특별한 불이익은 없으므로, 추천 목적의 [부동산 임대업] 선택을 추천드립니다. <br /> 목적에 넣은 후
                        반드시 사업자등록증에 포함시킬 필요가 없기 때문에, 나중에 부동산을 직접 매입해서 임대를 시작할
                        수 있는 상황이 되면 사업자등록증에 포함시키면 됩니다. 사업자등록증에 포함시키는 절차는 국세청
                        홈택스를 통해 직접 진행하시거나, 담당 세무사님을 통해 진행해주시면 됩니다.
                      </p>
                      <p className={clsx(st.qnaBoxExplain, st.qnaBoxExplainGap)}>
                        단, 특수한 목적을 가진 법인(부동산 중개법인, 대부업, 대부중개업 법인, 건축사사무소 법인 등)은
                        이러한 목적을 넣을 수 없습니다. 또한, 소프트웨어 개발 목적으로만 설립하는 경우, 설립시 납부하는
                        공과금을 감면받을 수 있는데, 부동산 임대업 목적을 넣으면 감면을 받을 수 없으므로, 이 부분도
                        주의가 필요합니다.
                      </p>
                    </div>
                  </div>
                  <div className={clsx(Qst.qnaBox, st.qnaBoxTitle)}>
                    <QText>부동산 중개 법인의 경우, 주의할 점이 있나요?</QText>
                    <div className="answerBox">
                      <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                        부동산 중개 법인은 부동산 임대업과 매매업을 넣을 수 없습니다.
                      </Answer>
                      <p className={st.qnaBoxExplain}>
                        법인인 공인중개사는 법률에 정해진 업무 외에 다른 업무를 할 수 없습니다. 따라서, 추천목적의
                        [부동산 중개] 선택을 추천드립니다.
                      </p>
                      <LikeBtn
                        className={clsx(Qst.likeBtn, st.qnaBoxExplainGap)}
                        href="https://blog.help-me.kr/2018/06/부동산중개법인/"
                        target="_blank"
                      >
                        부동산중개법인 더 알아보기
                      </LikeBtn>
                    </div>
                  </div>
                  <div className={Qst.qnaBox}>
                    <QText>대부업 · 대부중개업의 경우, 주의할 점이 있나요?</QText>
                    <div className="answerBox">
                      <Answer className={clsx(Qst.answer, st.qnaBoxSubTitle)}>
                        목적과 회사 이름에 대부, 대부중개를 꼭 넣어주세요.
                      </Answer>
                      <p className={st.qnaBoxExplain}>
                        목적에 대부업, 대부중개업을 넣을 뿐만 아니라, 회사 이름에도 넣어야 한다는 점 꼭 기억해주세요.
                      </p>
                      <LikeBtn
                        className={clsx(Qst.likeBtn, st.qnaBoxExplainGap)}
                        href="https://info.help-me.kr/hc/ko/articles/360029986411"
                        target="_blank"
                      >
                        대부업 법인 더 알아보기
                      </LikeBtn>
                      <br />
                      <LikeBtn
                        className={Qst.likeBtn}
                        style={{ marginTop: '10px' }}
                        href="https://info.help-me.kr/hc/ko/articles/360029651072"
                        target="_blank"
                      >
                        대부중개업 법인 더 알아보기
                      </LikeBtn>
                    </div>
                  </div>
                </>
              )}
          </Qna>
        </div>
      </div>
    </Question>
  );
});

export default BusinessPurposeQna;
