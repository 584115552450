import { useObserver } from 'mobx-react';
import FundAndInvestmentAssociationVm from './FundAndInvestmentAssociationVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from '../ConvertibleBondAcquirer.module.scss';
import clsx from 'clsx';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import Icon from '@comComponents/atoms/Icon/Icon';

function ForeignerCompany(props) {
  return useObserver(() => (
    <>
      <div className={Qst.spaceBetween}>
        <div className={Qst.infoSection}>
          <div className={Qst.titleWrap}>
            <h2 className={clsx(Qst.title, Qst.inside)}>
              <span className={Qst.bold}>펀드/투자조합의 이름을 입력</span>해 주세요.
            </h2>
            <div className={Qst.titleExplain}>고유번호증에 나오는 정식명칭을 입력해 주세요.</div>
          </div>
          <Badge className={st.FundInvestmentBadge} title="예시" type="example" border={false}>
            <img className={st.FundInvestmentBadgeImg} src="/images/fund_Investment.png" alt="고유번호증 예시" />
          </Badge>
          <InputText
            style={{ width: '316px' }}
            value={props.item.koreanName}
            onChange={(e) => FundAndInvestmentAssociationVm.setKoreanName(e, props.idx)}
            name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`}
            errorText={
              <Error
                name={`convertibleBondAcquirerInfos[${props.idx}].koreanName`}
                value={props.item.koreanName}
                errorCase={{ required: '성함을 입력해 주세요.' }}
              />
            }
          />
        </div>
      </div>
      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
        <div className={Qst.infoSection}>
          <div className={Qst.titleWrap}>
            <h2 className={clsx(Qst.title, Qst.inside)}>
              {props.item.koreanName ? props.item.koreanName : '전환사채 인수인'}님의{' '}
              <span className={Qst.bold}>도로명주소</span>를 입력해 주세요.
            </h2>
          </div>
          <InputText
            style={{ width: '560px' }}
            value={props.item.koreanAddress}
            onChange={(e) => FundAndInvestmentAssociationVm.setKoreanAddress(e, props.idx)}
            name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`}
            errorText={
              <Error
                name={`convertibleBondAcquirerInfos[${props.idx}].koreanAddress`}
                value={props.item.koreanAddress}
                errorCase={{ required: '주소를 입력해 주세요.' }}
              />
            }
          />
        </div>
      </div>
      <div className={Qst.spaceBetween}>
        <div className={Qst.infoSection}>
          <div className={Qst.titleWrap}>
            <h2 className={clsx(Qst.title, Qst.inside)}>
              {props.item.koreanName ? props.item.koreanName : '전환사채 인수인'}님의{' '}
              <span className={Qst.bold}>업무집행조합원 또는 대표자</span>를 입력해 주세요.
            </h2>
          </div>
          <Badge className={st.FundInvestmentBadge} title="예시" type="example" border={false}>
            <img className={st.FundInvestmentBadgeImg} src="/images/fund_Investment_2.png" alt="고유번호증 예시" />
          </Badge>
          {props.item.representatives.map((item, idx2) => {
            return (
              <div className={clsx(Qst.rowWrap, st.representativeRowWrap)} key={idx2 + props.item.representatives}>
                <input
                  type="hidden"
                  name={`convertibleBondAcquirerInfos[${props.idx}].representatives[${idx2}].id`}
                  value={item?.id || ''}
                />
                <InputText
                  className={Qst.inputText}
                  style={{ width: '536px' }}
                  explain={idx2 === 0 ? '이름' : null}
                  name={`convertibleBondAcquirerInfos[${props.idx}].representatives[${idx2}].name`}
                  value={item.name}
                  onChange={(e) => FundAndInvestmentAssociationVm.setRepresentativesName(e, props.idx, idx2)}
                  errorText={
                    <Error
                      name={`convertibleBondAcquirerInfos[${props.idx}].representatives[${idx2}].name`}
                      value={item.name}
                      errorCase={{ required: '업무집행조합원 또는 대표자를 입력해 주세요.' }}
                    />
                  }
                />
                {idx2 !== 0 && (
                  <CloseBtn
                    className={st.representativeCloseBtn}
                    onClick={() => FundAndInvestmentAssociationVm.setRepresentativesCloseBtn(props.idx, idx2)}
                  />
                )}
              </div>
            );
          })}
          <div className={clsx(Qst.rowWrap, st.representativeAddRowWrap)} style={{ width: '536px' }}>
            <button
              type="button"
              className={st.representativeAddBtn}
              onClick={() => FundAndInvestmentAssociationVm.addRepresentatives(props.idx)}
            >
              추가하기
              <Icon icon="addGray" className={st.addGray} />
            </button>
          </div>
        </div>
      </div>
    </>
  ));
}
export default ForeignerCompany;
