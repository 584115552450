import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import { runInAction, observable } from 'mobx';

class KoreanPersonVm {
  disabledNameYn(idx, reviseInfoListKoreanName) {
    let disabledYn = false;
    const isNewYn = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].isNewYn;
    if (isNewYn) {
      if (reviseInfoListKoreanName === false) {
        disabledYn = true;
      }
    } else {
      disabledYn = true;
    }
    return disabledYn;
  }
  setKoreanName(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName = e.target.value;
    });
  }
  setKssn(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].kssn = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress = e.target.value;
    });
  }
}

export default new KoreanPersonVm();
