import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import clsx from 'clsx';
import st from './StandByStockholderMeetingInfoStep.module.scss';

function StandByStockholderMeetingInfoStep(props) {
  return useObserver(() => (
    <>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>의장</div>
        <div className={Cst.data}>
          <span>{props.step.stockholderMeetingChairman}</span>
        </div>
      </div>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>
          출석, 찬성, 인감증
          <br />
          명서 제공할 주주
        </div>
        <div className={Cst.data}>
          <table className={st.table}>
            <colgroup>
              <col width="457px" />
              <col />
              <col width="106px" />
              <col width="106px" />
              <col width="110px" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>성함</th>
                <th style={{ textAlign: 'center' }}>구별</th>
                <th style={{ textAlign: 'center' }}>출석여부</th>
                <th style={{ textAlign: 'center' }}>찬성여부</th>
                <th style={{ textAlign: 'right' }}>
                  인감증명서
                  <br />
                  제공여부
                </th>
              </tr>
            </thead>
            <tbody>
              {props.step.stockholders.map((stockholder, idx) => (
                <tr key={idx}>
                  <td style={{ textAlign: 'left' }}>{stockholder.name}</td>
                  <td style={{ textAlign: 'center' }}>{stockholder.stockholderType}</td>
                  <td style={{ textAlign: 'center' }}>{stockholder.attendYn ? 'O' : 'X'}</td>
                  <td style={{ textAlign: 'center' }}>{stockholder.agreeYn ? 'O' : 'X'}</td>
                  <td style={{ textAlign: 'center' }}>{stockholder.sealProvidingYn ? 'O' : 'X'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  ));
}
export default StandByStockholderMeetingInfoStep;
