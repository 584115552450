import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './CompanyAddress.module.scss';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import Icon from '@comComponents/atoms/Icon/Icon';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import CompanyAddressVm from './CompanyAddressVm';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import LikeBtn from '@comComponents/atoms/HyperLink/LikeBtn/LikeBtn';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import { toJS } from 'mobx';
import AiChat from '@pages/Establish/AiChat/AiChat';

function CompanyAddress() {
  return useObserver(() => (
    <form onSubmit={(e) => CompanyAddressVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>회사의 임대차계약서 상 도로명 주소를 입력</span>해 주세요.
              </h2>
            </div>
            <Badge className={st.badge} title="예시" type="example" border={false}>
              <img
                className={st.badgeImg}
                src="/images/contract_example.png?1"
                alt="임대차계약서 예시"
                style={{ width: '544px' }}
              />
            </Badge>
            <div className={Qst.rowWrap}>
              <InputText
                className={Qst.inputText}
                style={{ width: '380px' }}
                explain="도로명 주소"
                value={CompanyAddressVm.companyAddress?.address?.address || ''}
                readOnly
                onClick={() => CompanyAddressVm.openCompanyAddressModal(true)}
                name="companyAddress.address"
                errorText={
                  <Error
                    name="companyAddress.address"
                    value={CompanyAddressVm.companyAddress?.address?.address || ''}
                    errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                  />
                }
              />
              <SquareBtn onClick={() => CompanyAddressVm.openCompanyAddressModal(true)}>도로명 주소 찾기</SquareBtn>
              {CompanyAddressVm.companyAddressModal && (
                <SearchAddress
                  onClose={() => CompanyAddressVm.openCompanyAddressModal(false)}
                  onComplete={(data) => CompanyAddressVm.companyAddressComplate(data)}
                ></SearchAddress>
              )}
            </div>
            <div className={Qst.rowWrap}>
              <InputText
                className={Qst.inputText}
                style={{ width: '273px' }}
                explain="상세 주소"
                errorText={
                  <Error
                    name="companyAddress.detailAddress"
                    value={CompanyAddressVm.companyAddress?.detailAddress}
                    errorCase={{
                      required: '상세 주소를 입력해 주세요.',
                      pattern: {
                        value: regExp.onlyKorNum_hyphen_comma().test(CompanyAddressVm.companyAddress?.detailAddress),
                        message: (
                          <span>
                            임대차 계약서상 영문이 있다면, <br />
                            한글로 옮겨적어주세요.
                            <br />
                            EX) B동 &gt; 비동
                          </span>
                        ),
                      },
                    }}
                  />
                }
                placeholder="상세주소 없을 시, [없음] 입력"
                value={CompanyAddressVm.companyAddress?.detailAddress}
                onChange={CompanyAddressVm.companyAddress_detailAddress}
                name="companyAddress.detailAddress"
              />
              <div className={Qst.symbol}>
                <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                <span
                  className={clsx(
                    Qst.symbolText,
                    CompanyAddressVm.companyAddress_bnameFontSizeChange ? Qst.fontSize : null,
                  )}
                >
                  {CompanyAddressVm.companyAddress?.address?.bname || '법정동'},&nbsp;
                </span>
                <InputText
                  style={{ width: '200px' }}
                  explain="건물명"
                  placeholder="없을 시, [없음] 입력"
                  value={CompanyAddressVm.companyAddress?.nameOfBuilding}
                  onChange={CompanyAddressVm.companyAddress_nameOfBuilding}
                  name="companyAddress.nameOfBuilding"
                  errorText={
                    <Error
                      name="companyAddress.nameOfBuilding"
                      value={CompanyAddressVm.companyAddress?.nameOfBuilding}
                      style={{ width: '260px' }}
                      errorCase={{
                        required: '건물명을 입력해 주세요.',
                        pattern: {
                          value: regExp.onlyKorNum_hyphen_comma().test(CompanyAddressVm.companyAddress?.nameOfBuilding),
                          message: (
                            <span>
                              건물명이 영문인 경우, 한글
                              <br />
                              발음으로 옮겨적어 주세요. <br />
                              EX) AB타워 &gt; 에이비 타워
                            </span>
                          ),
                        },
                      }}
                    />
                  }
                />
                <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
              </div>
            </div>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                등기 완료 후 <span className={Qst.bold}>우편물 받을 주소를 알려</span>주세요.
              </h2>
              <div className={Qst.titleExplain}>
                등기완료 후 헬프미 직원이 직접 등기소를 방문하여, 인감카드, 인감증명서를 발급받은 후, 등기부등본, 정관
                등과 함께 빠른 등기로 보내드립니다.
              </div>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={true}
                  activeBackground
                  checked={CompanyAddressVm.headOfficeDeliveryYn === true}
                  onChange={CompanyAddressVm.setHeadOfficeDeliveryYn}
                  name="headOfficeDeliveryYn"
                >
                  위에 입력한 회사주소
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={false}
                  activeBackground
                  checked={CompanyAddressVm.headOfficeDeliveryYn === false}
                  onChange={CompanyAddressVm.setHeadOfficeDeliveryYn}
                  name="headOfficeDeliveryYn"
                >
                  그 밖의 주소
                </Radio>
              </li>
            </ul>
            <Error
              name="headOfficeDeliveryYn"
              value={CompanyAddressVm.headOfficeDeliveryYn}
              errorCase={{ required: '우편물 받을 주소를 선택해 주세요.' }}
            />
            {CompanyAddressVm.headOfficeDeliveryYn === false && (
              <>
                <div className={Qst.rowWrap}>
                  <InputText
                    className={Qst.inputText}
                    style={{ width: '380px' }}
                    explain="도로명 주소"
                    value={CompanyAddressVm.deliveryAddress?.address?.address || ''}
                    readOnly
                    onClick={() => CompanyAddressVm.openPostAddressModal(true)}
                    name="deliveryAddress.address"
                    errorText={
                      <Error
                        name="deliveryAddress.address"
                        value={CompanyAddressVm.deliveryAddress?.address?.address}
                        errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                      />
                    }
                  />
                  <SquareBtn onClick={() => CompanyAddressVm.openPostAddressModal(true)}>도로명 주소 찾기</SquareBtn>
                  {CompanyAddressVm.postAddressModal && (
                    <SearchAddress
                      onClose={() => CompanyAddressVm.openPostAddressModal(false)}
                      onComplete={(data) => CompanyAddressVm.postAddressComplate(data)}
                    ></SearchAddress>
                  )}
                </div>
                <div className={Qst.rowWrap}>
                  <InputText
                    className={Qst.inputText}
                    style={{ width: '273px' }}
                    explain="상세 주소"
                    placeholder="상세주소 없을 시, [없음] 입력"
                    value={CompanyAddressVm.deliveryAddress?.detailAddress}
                    onChange={CompanyAddressVm.deliveryAddress_detailAddress}
                    name="deliveryAddress.detailAddress"
                    errorText={
                      <Error
                        name="deliveryAddress.detailAddress"
                        value={CompanyAddressVm.deliveryAddress?.detailAddress}
                        errorCase={{
                          required: '상세 주소를 입력해 주세요.',
                          pattern: {
                            value: regExp
                              .onlyKorNum_hyphen_comma()
                              .test(CompanyAddressVm.deliveryAddress?.detailAddress),
                            message: (
                              <span>
                                임대차 계약서상 영문이 있다면, <br />
                                한글로 옮겨적어주세요.
                                <br />
                                EX) B동 &gt; 비동
                              </span>
                            ),
                          },
                        }}
                      />
                    }
                  />
                  <div className={Qst.symbol}>
                    <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                    <span
                      className={clsx(
                        Qst.symbolText,
                        CompanyAddressVm.deliveryAddress_bnameFontSizeChange ? Qst.fontSize : null,
                      )}
                    >
                      {CompanyAddressVm.deliveryAddress?.address?.bname || '법정동'},&nbsp;
                    </span>
                    <InputText
                      style={{ width: '200px' }}
                      explain="건물명"
                      placeholder="없을 시, [없음] 입력"
                      value={CompanyAddressVm.deliveryAddress?.nameOfBuilding}
                      onChange={CompanyAddressVm.deliveryAddress_nameOfBuilding}
                      name="deliveryAddress.nameOfBuilding"
                      errorText={
                        <Error
                          name="deliveryAddress.nameOfBuilding"
                          value={CompanyAddressVm.deliveryAddress?.nameOfBuilding}
                          style={{ width: '260px' }}
                          errorCase={{
                            required: '건물명을 입력해 주세요.',
                            pattern: {
                              value: regExp
                                .onlyKorNum_hyphen_comma()
                                .test(CompanyAddressVm.deliveryAddress?.nameOfBuilding),
                              message: (
                                <span>
                                  건물명이 영문인 경우, 한글
                                  <br />
                                  발음으로 옮겨적어 주세요. <br />
                                  EX) AB타워 &gt; 에이비 타워
                                </span>
                              ),
                            },
                          }}
                        />
                      }
                    />
                    <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={Qst.qnaSection}>
            <AiChat />
            <Qna tip="주소 설정방법" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>지번주소를 어떻게 도로명 주소로 바꾸나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>도로명주소 안내시스템 사이트에서 바꿀 수 있습니다.</Answer>
                  <LikeBtn className={Qst.likeBtn} href="https://www.juso.go.kr/openIndexPage.do" target="_blank">
                    도로명주소 안내시스템 사이트 바로가기
                  </LikeBtn>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>주소에는 영어를 쓸 수 있나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>등기소에서는 주소를 적을 때, 한글 숫자만 허용합니다. (영어 X)</Answer>
                  <p className={Qst.explain}>임대차계약서상 영어가 들어가 있다면, 한글로 변환해 주세요.</p>
                  <TextBox className={Qst.textBox} title="예시">
                    <div className={Qst.textWrap}>
                      <span className={Qst.false}>B동, 202-1001</span>
                      <Icon icon="arrow" className={Qst.iconArrow} />
                      <span className={Qst.true}>비동, 202동 1001호</span>
                    </div>
                  </TextBox>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>임대차계약서를 사전에 준비해야 할까요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>임대차계약서를 미리 준비하는 것을 추천드립니다.</Answer>
                  <p className={Qst.explain}>
                    설립등기시에는 임대차계약서를 제출하지 않아도 되지만, 설립등기 직후 사업자등록을 할 때, 세무서에
                    제출해야 합니다. 이 때 임대차계약서의 주소와 등기부상 주소가 일치하지 않으면, 사업자등록이
                    거부됩니다.
                  </p>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>설립 전에 임대차계약서를 어떻게 작성하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer} iconText="A1">
                    설립 전에 임대차계약서를 작성한다면, 우선 개인 명의로 계약서를 써주세요.
                  </Answer>
                  <p className={Qst.explain}>
                    등기가 완료되기 전이므로 대표이사나 {CompanyAddressVm.qnaContent.name} 개인 명의로 우선
                    임대&middot;전대차계약서를 작성해 주세요. 이때 아래와 같은 특약사항을 기재해 주세요.
                  </p>
                  <TextBox className={Qst.textBox} style={{ padding: '18px 34px 18px 20px' }} title="특약사항">
                    <ol className={st.orderList}>
                      <li className={st.first}>
                        <span className={st.bold}>임차인은 현재 설립 중의 법인</span>으로서, 임차인의{' '}
                        <span className={st.bold}>
                          설립등기가 완료된 후, 회사명을 임차인으로 표시한 임대차계약서를 다시 작성
                        </span>
                        한다. 이때 임차인은 임대인에게 법인 등기부등본을 교부한다.
                      </li>
                      <li className={st.second}>
                        임차인은 현재 설립 중의 법인으로서,{' '}
                        <span className={st.bold}>법인인감등록이 완료되지 아니하였는바,</span> 본 계약서에는{' '}
                        <span className={st.bold}>홍길동(1900.00.00.생)의 인감도장을 대신 날인한다.</span>
                      </li>
                    </ol>
                  </TextBox>
                  <Answer className={Qst.answer} iconText="A2">
                    등기 후 회사 명의로 다시 계약서를 작성해 주세요.
                  </Answer>
                  <p className={Qst.explain}>
                    등기가 완료되면 특약사항에 따라 반드시 법인 명의로 계약서를 다시 작성해 주세요. 특약은 개인이 법인을
                    위해 계약을 체결했으므로 추후 계약서를 다시 작성한다는 취지에 불과합니다.
                  </p>
                </div>
              </div>
              {CompanyAddressVm.qnaContent.officeYn && (
                <div className={Qst.qnaBox}>
                  <QText>공유오피스 주소로 설립이 가능한가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>원칙적으로 공유오피스에서 설립할 수 있지만, 제한이 있습니다.</Answer>
                    <p className={Qst.explain}>
                      세무서는 일부 업종의 경우, 공유오피스에서 영업할 수 없다고 판단해서 사업자등록을 허용하지
                      않습니다. (가정집보다는 사업자등록이 되는 경우가 더 많습니다.) 그 판단은 세무서마다 다릅니다.
                      따라서 관할 세무서에 미리 문의한 후, 진행하는 것을 추천드립니다. 이때 계약기간은 최소 3개월로 해
                      주세요. 계약 기간이 1개월이면 세무서에서 기간이 짧다는 이유로 3개월 혹은 6개월 이상의 계약서를
                      다시 제출하라고 요구하는 경우가 있습니다.
                    </p>
                  </div>
                </div>
              )}
              <div className={Qst.qnaBox}>
                <QText>가정집 주소로 설립이 가능한가요?</QText>
                {CompanyAddressVm.qnaContent.houseEstablish && (
                  <div className="answerBox">
                    <Answer className={Qst.answer}>원칙적으로 가정집에서 설립할 수 있지만, 제한이 있습니다.</Answer>
                    <p className={Qst.explain}>
                      세무서는 일부 업종의 경우, 주거용 건물에서 영업할 수 없다고 판단해서 사업자등록을 허용하지
                      않습니다. 가정집에서 사업자등록이 어려운 업종의 경우, 다른 주소를 선택해 주세요.
                    </p>
                    <dl className={Qst.defList}>
                      <dt className={Qst.term}>가정집 설립이 어려운 업종</dt>
                      <dd className={Qst.def}>도매업, 소매업, 판매업, 음식점업, 공장업, 제조업, 창고업, 건설업 등</dd>
                      <dt className={Qst.term} style={{ marginTop: '10px' }}>
                        가정집 설립이 가능한 업종
                      </dt>
                      <dd className={Qst.def}>통신판매업, 소프트웨어 제작업, 컨설팅업 등</dd>
                    </dl>
                  </div>
                )}
                {!CompanyAddressVm.qnaContent.houseEstablish && (
                  <div className="answerBox">
                    <Answer className={Qst.answer}>
                      {CompanyAddressVm.qnaContent.smartFormTypeText}은 가정집에서 설립할 수 없습니다.
                    </Answer>
                    <p className={Qst.explain}>
                      일반 주식회사는 업종에 따라 가정집에서 설립할 수 있는 경우도 있지만, 세무서에서는{' '}
                      {CompanyAddressVm.qnaContent.smartFormTypeText}은 주거용 건물에서 영업할 수 없다고 판단해서
                      사업자등록을 허용하지 않습니다.
                    </p>
                  </div>
                )}
              </div>
              {CompanyAddressVm.qnaContent.houseRental && (
                <div className={Qst.qnaBox}>
                  <QText>전세집 주소로 설립이 가능한가요?</QText>
                  <div className="answerBox">
                    <Answer className={Qst.answer}>원칙적으로 전세집에서도 설립할 수 있지만, 제한이 있습니다.</Answer>
                    <p className={Qst.explain}>
                      가정집 설립이 가능한 업종이어야 할 뿐만 아니라, 집주인으로부터 전대차동의서를 미리 받아두어야
                      합니다. 나중에 설립 후 집주인이 전대차동의서를 써주지 않는다면, 사업자등록을 할 수 없습니다. 이
                      경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요. 전대차동의서 양식이 필요하다면
                      채팅상담으로 요청해 주세요.
                    </p>
                  </div>
                </div>
              )}
              <div className={Qst.qnaBox}>
                <QText>임대차와 전대차 무엇이 다른가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer} iconText="A1">
                    임대차는 회사가 건물주에게 직접 장소를 임차한 경우입니다.
                  </Answer>
                  <Answer className={Qst.answer} iconText="A2">
                    전대차는 회사가 건물주가 아닌 기존 임차인으로부터 다시 임차한 경우입니다.
                  </Answer>
                  <p className={Qst.explain}>
                    임대인과 신규 회사가 계약 당사자가 되어 전대차계약서를 작성합니다. 이 경우 반드시 건물주의 전대차
                    동의서를 첨부해야 합니다. 나중에 건물주가 전대차 동의서를 써주지 않는다면 사업자등록을 할 수
                    없습니다. 이 경우 비용을 들여 주소이전을 해야 하므로, 꼭 주의해 주세요.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default CompanyAddress;
