import { useObserver } from 'mobx-react';
import StockholdersMeetingMusterNotice from './StockholdersMeetingMusterNotice/StockholdersMeetingMusterNotice';
import StockholdersMeetingSealProvidingStockholdersCaseOne from './StockholdersMeetingSealProvidingStockholdersCaseOne/StockholdersMeetingSealProvidingStockholdersCaseOne';
import StockholdersMeetingAttendStockholders from './StockholdersMeetingAttendStockholders/StockholdersMeetingAttendStockholders';
import StockholdersMeetingSealProvidingStockholdersCaseTwo from './StockholdersMeetingSealProvidingStockholdersCaseTwo/StockholdersMeetingSealProvidingStockholdersCaseTwo';
import { Route } from 'react-router-dom';

function StockCompanySealStep() {
  return useObserver(() => (
    <>
      {/* 주주총회첫페이지 소집통지를 어떻게 진행하나요? */}
      <Route
        path="/step/changeRegistration/StockCompanySealStep/StockholdersMeetingMusterNotice"
        component={StockholdersMeetingMusterNotice}
      ></Route>
      {/* 인감증명서를 제공할 주주님의 정보를 입력해주세요. page2 */}
      <Route
        path="/step/changeRegistration/StockCompanySealStep/StockholdersMeetingSealProvidingStockholdersCaseOne"
        component={StockholdersMeetingSealProvidingStockholdersCaseOne}
      ></Route>
      {/* 주주총회에 참석할 주주분의 정보를 입력해주세요. page2 */}
      <Route
        path="/step/changeRegistration/StockCompanySealStep/StockholdersMeetingAttendStockholders"
        component={StockholdersMeetingAttendStockholders}
      ></Route>
      {/* 인감증명서를 제공할 주주님의 정보를 입력해주세요. page3 */}
      <Route
        path="/step/changeRegistration/StockCompanySealStep/StockholdersMeetingSealProvidingStockholdersCaseTwo"
        component={StockholdersMeetingSealProvidingStockholdersCaseTwo}
      ></Route>
    </>
  ));
}

export default StockCompanySealStep;
