import { observer } from 'mobx-react';

import CheckBox from '../../ui/CheckBox';
import GroupListItem from '../../ui/GroupListItem';
import Group from './components/Group';
import GroupForContent from './components/GroupForContent';
import st from './style.module.scss';
import useSearchResult from './useSearchResult';

const SearchResult = observer(({ searchMob, searchResult, selectedRecommendedPurposesMob }) => {
  const { searchKeyword } = searchMob;

  const { searchResultMob } = useSearchResult({
    searchMob,
    searchResult,
    selectedRecommendedPurposesMob,
  });

  return (
    <div className={st.container}>
      <div className={st.content}>
        {searchResult.purposeGroupsForGroupName.map((group) => (
          <Group
            key={group.groupNames.join()}
            purposeGroup={group}
            highlightText={searchKeyword}
            searchResultMob={searchResultMob}
          />
        ))}
        {searchResult.purposeGroupsForContent.map((group) => (
          <GroupForContent
            key={group.groupNames.join()}
            purposeGroup={group}
            defaultOpen
            highlightText={searchKeyword}
            searchResultMob={searchResultMob}
          />
        ))}
        {searchResult.purposes.map((purpose) => (
          <div key={purpose.recommendedPurposeId} className={st.wrap}>
            <CheckBox
              checked={searchResultMob.checkSelected(purpose.recommendedPurposeId)}
              onChange={() => searchResultMob.togglePurpose(purpose.recommendedPurposeId)}
            />
            <GroupListItem.PurposeRow size={'medium'} content={purpose.content} highlightText={searchKeyword} />
          </div>
        ))}
      </div>
      <div className={st.footer}>
        <div className={st.direct} style={{ display: searchResultMob.hasExactMatch ? 'none' : 'flex' }}>
          <div>찾으시는 목적이 없으신가요?</div>
          <div className={st.checkWrap}>
            <CheckBox
              checked={!!searchResultMob.directPurposeContent}
              onChange={(e) => (searchResultMob.directPurposeContent = e.target.checked ? searchKeyword : null)}
            />
            <div className={st.label}>
              <span>{searchKeyword}</span>(으)로 직접 입력하기
            </div>
          </div>
        </div>
        <button type="button" onClick={() => searchResultMob.submitSelectedPurposes()}>
          선택 완료
        </button>
      </div>
    </div>
  );
});

export default SearchResult;
