import etcInfoModel from '@model/establish/etcInfoModel';

import { purposeType } from '../../../constant';
import { 목적_추가금액_갯수_condition } from '../../../store';

const useChargeGuide = () => {
  const recommendedTypeCounts = etcInfoModel.businessPurposes.filter(
    (item) => item.purposeType === purposeType.RECOMMENDED,
  ).length;

  const directTypeCounts = etcInfoModel.businessPurposes.filter(
    (item) => item.purposeType === purposeType.DIRECT,
  ).length;

  const exceedRecommendTypeCounts = Math.max(
    0,
    recommendedTypeCounts - 목적_추가금액_갯수_condition[purposeType.RECOMMENDED],
  );

  const exceedDirectTypeCounts = Math.max(0, directTypeCounts - 목적_추가금액_갯수_condition[purposeType.DIRECT]);

  const exceedTotalCounts = exceedRecommendTypeCounts + exceedDirectTypeCounts;

  const calculateCharge = (purposeCount) => {
    return purposeCount * 5500;
  };

  return {
    recommendedTypeCounts,
    directTypeCounts,
    exceedRecommendTypeCounts,
    exceedDirectTypeCounts,
    exceedTotalCounts,
    calculateCharge,
  };
};

export default useChargeGuide;
