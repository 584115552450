import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import etcInfoModel from '@model/establish/etcInfoModel';
import portalModel from '@model/portalModel';
import smartformAppModel from '@model/smartformAppModel';
import stepService from '@service/stepService';
import { runInAction, when } from 'mobx';
import { useCallback, useEffect, useRef } from 'react';

import CheckAdditionalChargeModal from './components/CheckAdditionalChargeModal';
import CheckNoteModal from './components/CheckNoteModal';
import { purposeType } from './constant';
import { 목적_추가금액_갯수_condition } from './store';

const useBusinessPurposes = () => {
  const smartformType = smartformAppModel.smartFormType;
  const businessPurposes = etcInfoModel.businessPurposes;

  const submitFlowRef = useRef({
    is추가금액확인: false,
    is유의사항확인: false,
  });

  const open유의사항_확인_모달 = useCallback(async (purposesWithNote) => {
    const onClickNext = () => {
      runInAction(() => {
        submitFlowRef.current.is유의사항확인 = true;
        portalModel.setModalClose();
      });
    };

    runInAction(() => {
      portalModel.modalContent = <CheckNoteModal onClickNext={onClickNext} purposesWithNote={purposesWithNote} />;
      portalModel.closeBtn = true;
    });

    await when(() => !portalModel.modalContent);

    return submitFlowRef.current.is유의사항확인;
  }, []);

  const open추가금액_확인_모달 = useCallback(async () => {
    const onClickNext = () => {
      runInAction(() => {
        submitFlowRef.current.is추가금액확인 = true;
        portalModel.setModalClose();
      });
    };

    runInAction(() => {
      portalModel.modalContent = <CheckAdditionalChargeModal onClickNext={onClickNext} />;
      portalModel.closeBtn = true;
    });

    await when(() => !portalModel.modalContent);

    return submitFlowRef.current.is추가금액확인;
  }, []);

  const submit = useCallback(async (e) => {
    e.preventDefault();

    // 확인 모달 플로우 초기화
    submitFlowRef.current.is추가금액확인 = false;
    submitFlowRef.current.is유의사항확인 = false;

    // 폼벨리데이션 테스트전 전체 선택한 목적 재랜더링 트리거
    etcInfoModel.businessPurposes = [...etcInfoModel.businessPurposes];

    //폼벨리데이션 테스트
    if (formDataToJson(e.target).error) {
      return;
    }

    const selectedPurposes = etcInfoModel.businessPurposes;

    if (selectedPurposes.length === 0) {
      return _alert('사업목적을 추가해 주세요.');
    }

    //목적 정규식 테스트
    const regExp = /([^가-힣A-Za-z0-9\s.,\[\]{}()%$:\n-])+/;

    for (const purpose of selectedPurposes) {
      if (regExp.test(purpose.content)) {
        return _alert(
          '목적에는 정해진 문자 이외에는 사용할 수 없습니다.\n정해진 문자: 한글/영문/숫자/하이픈/구두점/쉼표/스페이스/대괄호/중괄호/소괄호/퍼센트/달러/콜론/Enter',
        );
      }
    }

    const notedPurposes = selectedPurposes.filter((item) => item.noteYn);

    const isCheck유의사항 = notedPurposes.length > 0;
    const isCheck추가금액 =
      selectedPurposes.filter((item) => item.purposeType === purposeType.DIRECT).length >
        목적_추가금액_갯수_condition[purposeType.DIRECT] ||
      selectedPurposes.filter((item) => item.purposeType === purposeType.RECOMMENDED).length >
        목적_추가금액_갯수_condition[purposeType.RECOMMENDED];

    if (isCheck유의사항 && !(await open유의사항_확인_모달(notedPurposes))) {
      return;
    }
    if (isCheck추가금액 && !(await open추가금액_확인_모달())) {
      return;
    }

    const param = {
      businessPurposes: selectedPurposes.map((item) => ({
        id: item.id ? Number(item.id) : null, // 고유키
        // 추천 목적에 대한 키 (직접입력시 null)
        recommendedPurposeId: item.purposeType === purposeType.RECOMMENDED ? Number(item.recommendedPurposeId) : null,
        // 직접입력 목적 (추천목적이면 null)
        directContent: item.purposeType === purposeType.DIRECT ? item.content.trim() : null,
        purposeType: item.purposeType,
      })),
    };

    stepService.saveStep(param);
  });

  useEffect(() => {
    목적_추가금액_갯수_condition.setConditionByPurposeVersion(etcInfoModel.purposeVersion);
  }, [etcInfoModel.purposeVersion]);

  return { smartformType, businessPurposes, submit };
};

export default useBusinessPurposes;
