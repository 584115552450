import etcInfoModel from '@model/establish/etcInfoModel';
import formDataToJson from '@common/module/submit';
import stepService from '@service/stepService';
import { runInAction } from 'mobx';

class viewModel {
  get trademarkAgreeYn() {
    return etcInfoModel.trademarkAgreeYn;
  }
  setTrademarkAgreeYn(e) {
    runInAction(() => {
      etcInfoModel.trademarkAgreeYn = e.target.value === 'true';
    });
  }
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error === false) {
      let param = formDataToJson(e.target).data;
      stepService.saveStep(param);
    }
  }
}
export default new viewModel();
