import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './FisheryNoticeOfEstablishment.module.scss';
import clsx from 'clsx';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import FisheryNoticeOfEstablishmentVm from './FisheryNoticeOfEstablishmentVm';
import ErrorText from '@comComponents/atoms/Text/ErrorText/ErrorText';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import Ul from '@comComponents/atoms/Text/UnorderListText/UnorderList';
import TextBox from '@comComponents/atoms/Text/TextBox/TextBox';
import HyperText from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';

function FisheryNoticeOfEstablishment() {
  return useObserver(() => (
    <form onSubmit={(e) => FisheryNoticeOfEstablishmentVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                설립을 마친 후,<span className={Qst.bold}> 직접 설립통지</span>를 진행해 주세요.
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  name="noticeOfEstablishmentYn"
                  value={true}
                  activeBackground
                  checked={FisheryNoticeOfEstablishmentVm.noticeOfEstablishmentYn === true}
                  onChange={(e) => FisheryNoticeOfEstablishmentVm.setNoticeOfEstablishmentYn(e)}
                >
                  네, 확인했습니다.
                </Radio>
              </li>
            </ul>
            <Error
              name="noticeOfEstablishmentYn"
              value={FisheryNoticeOfEstablishmentVm.noticeOfEstablishmentYn}
              errorCase={{ required: '직접 설립통지 진행 확인 여부를 선택해 주세요.' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="설립통지" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>어업회사법인 설립통지는 어떻게 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    설립등기를 마친 후, 30일 내에 관할구청에 서류를 제출하면 됩니다.
                  </Answer>
                  <div className={Qst.explain}>
                    <Ul>
                      <li>
                        어업회사법인은 세제혜택이 있는 대신, 일반 주식회사와 달리 설립등기를 마친 후 관할 구청(또는
                        시청, 군청)에 아래 서류를 제출해서 설립통지를 해야 합니다. (관련법규)
                        <TextBox className={clsx(Qst.textBox, st.textBox)} title="제출서류">
                          <ol className={st.document}>
                            <li>
                              통지서 양식에 내용을 기입한 것 ({' '}
                              <HyperText
                                title="통지서 양식 다운로드"
                                href="http://sc.help-me.kr/forms/%EC%96%B4%EC%97%85%ED%9A%8C%EC%82%AC%EB%B2%95%EC%9D%B8%20%EC%84%A4%EB%A6%BD%EB%93%B1%EA%B8%B0%ED%86%B5%EC%A7%80%EC%84%9C.hwp"
                                target="_blank"
                              >
                                통지서 양식 다운로드 받기
                              </HyperText>{' '}
                              )
                            </li>
                            <li>정관 (헬프미 제공)</li>
                            <li>등기부등본 (헬프미 제공)</li>
                            <li>발기인회의사록 (헬프미 제공)</li>
                          </ol>
                        </TextBox>
                      </li>
                      <li>설립통지는 사업자등록과 별개이며, 고객님께서 직접 진행해주시면 됩니다.</li>
                    </Ul>
                  </div>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default FisheryNoticeOfEstablishment;
