import { useObserver } from 'mobx-react';
import BeforeFinalCheck from './FinalCheck/BeforeFinalCheck/BeforeFinalCheck';
import AfterFinalCheck from './FinalCheck/AfterFinalCheck/AfterFinalCheck';
import FinalUpload from './FinalUpload/FinalUpload';
import { Route } from 'react-router-dom';
import { useEffect } from 'react';
import FinalSubmissionVm from './FinalSubmissionVm';

function FinalSubmission() {
  useEffect(() => {
    FinalSubmissionVm.loadFinalSubmission();
  }, []);
  return useObserver(() => (
    <>
      {/* 최종제출 전 내용 확인 */}
      <Route path="/establish/BeforeFinalCheck" component={BeforeFinalCheck}></Route>
      {/* 최종제출 후 내용 확인 */}
      <Route path="/establish/AfterFinalCheck" component={AfterFinalCheck}></Route>
      {/* 업로드 후 내용 확인 */}
      <Route path="/establish/FinalUpload" component={FinalUpload}></Route>
    </>
  ));
}

export default FinalSubmission;
