import { get } from '@common/module/httpRequest';
import smartformAppModel from '@model/smartformAppModel';
import { runInAction } from 'mobx';

class moduleService {
  async corpNameDuplicationCheck(companyName) {
    //회사명 중복체크 (첫번째 파라미터로 조회할 회사명)
    const res = await get(
      `/smartform/${smartformAppModel.smartformId}/corpNameDuplicationCheck`,
      {
        companyName: companyName,
      },
      {},
    );
    const data = res.data;
    return data.duplicationCheckType;
  }
  async corpNameDuplicationCheckWithAddress(companyName, 광역자치단체, 시군구) {
    //회사명 중복체크 (첫번째 파라미터로 조회할 회사명)
    const res = await get(
      `/smartform/${smartformAppModel.smartformId}/corpNameDuplicationCheckWithAddress`,
      {
        companyName: companyName,
        광역자치단체: 광역자치단체,
        시군구: 시군구 || null,
      },
      {},
    );
    const data = res.data;
    return data.duplicationCheckType;
  }
  async homepageAddressValidationCheck(homepageAddress) {
    //홈페이지 주소 접속체크
    const res = await get(
      `/smartform/isValidUrl`,
      {
        url: homepageAddress,
      },
      {},
    );
    const data = res.data;
    return data;
  }
}
export default new moduleService();
