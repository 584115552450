import { usePopularPurposesQuery } from '@pages/Establish/EtcInfo/BusinessPurpose/queries';
import { observer } from 'mobx-react';

import PopularPurposeGroupList from './components/PopularPurposeGroupList';
import SearchField from './components/SearchField';
import SearchResult from './components/SearchResult';
import useSearchPurpose from './hook/useSearchPurpose';
import useSelectedRecommendedPurposes from './hook/useSelectedRecommendedPurposes';
import st from './style.module.scss';

const PurposeSearch = observer(() => {
  const {
    data: { purposeGroups: popularPurposeGroups },
  } = usePopularPurposesQuery();
  const { searchResult, searchMob } = useSearchPurpose();
  const { selectedRecommendedPurposesMob } = useSelectedRecommendedPurposes();

  return (
    <div className={st.container}>
      <SearchField searchMob={searchMob} />
      {searchMob.searchKeyword ? (
        <SearchResult
          searchMob={searchMob}
          searchResult={searchResult}
          selectedRecommendedPurposesMob={selectedRecommendedPurposesMob}
        />
      ) : (
        <PopularPurposeGroupList
          popularPurposeGroups={popularPurposeGroups}
          selectedRecommendedPurposesMob={selectedRecommendedPurposesMob}
        />
      )}
    </div>
  );
});

export default PurposeSearch;
