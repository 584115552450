import { useObserver } from 'mobx-react';
import BranchOfficeRelocate from './BranchOfficeRelocate/BranchOfficeRelocate';
import { Route } from 'react-router-dom';

function BranchOfficeRelocateStep() {
  return useObserver(() => (
    <>
      {/* 지점이전 */}
      <Route
        path="/step/changeRegistration/BranchOfficeRelocateStep/BranchOfficeRelocate"
        component={BranchOfficeRelocate}
      ></Route>
    </>
  ));
}

export default BranchOfficeRelocateStep;
