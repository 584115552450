import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import vm from './StandByBoardMeetingBasicInfoVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandByBoardMeetingBasicInfo.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Check from '@comComponents/atoms/Input/Check/Check';
import clsx from 'clsx';

function StandByBoardMeetingBasicInfo() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>이사회 의장을 선택하세요.</h2>
                <div className={Qst.titleExplain}>대표님 중 한 분을 의장으로 선택하세요.</div>
                <div className={Qst.titleExplain}>
                  대표님이 아닌 분이 의장이 되려면 추가적인 이사회 결의를 해야하는 경우가 많아요.
                </div>
              </div>
              <ul className={Qst.answerList}>
                {vm.chairmanCandidates?.map((chairman, idx) => {
                  return (
                    <li key={idx} className={st.chairman}>
                      <Radio
                        className={Qst.answerLabel}
                        value={chairman.id}
                        activeBackground
                        checked={vm.chairmanId === chairman.id}
                        onChange={(e) => vm.setChairmanId(e)}
                        name="이사회_의장_id"
                      >
                        {chairman.name} {chairman.position}
                      </Radio>
                      {chairman.representativeYn && <span className={st.tag}>추천</span>}
                    </li>
                  );
                })}
              </ul>
              {vm.chairmanCandidates.length !== 0 && (
                <Error name="이사회_의장_id" value={vm.chairmanId} errorCase={{ required: '의장을 선택하세요.' }} />
              )}
            </div>
          </div>
        </ContentBox>
      </Question>
      {vm.chairmanId && (
        <Question>
          <ContentBox>
            <div className={Qst.spaceBetween}>
              <div className={Qst.infoSection}>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>임원의 출석 여부를 선택하세요.</h2>
                </div>
                <table className={st.table}>
                  <colgroup>
                    <col width="75" />
                    <col />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>
                        <Check
                          className={st.check}
                          style={{
                            paddingLeft: '30px',
                            fontSize: '16px',
                            fontWeight: '500',
                            letterSpacing: '-0.48px',
                            whiteSpace: 'pre',
                          }}
                          top="0"
                          checked={vm.allChecked}
                          onChange={(e) => vm.setCheckedAll(e)}
                        >
                          출석
                        </Check>
                      </th>
                      <th>이름</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vm.executiveMembers?.map((executiveMember, idx) => {
                      return (
                        <tr key={idx}>
                          <td>
                            <Check
                              onlyCheckBox
                              className={st.check}
                              checked={executiveMember.attendYn}
                              value={executiveMember.id}
                              onChange={(e) => vm.setChecked(e, idx)}
                              name={`participants`}
                            />
                          </td>
                          <td>
                            <span className={st.index}>{idx + 1}</span>
                            <span className={st.name}>{executiveMember.name}</span>
                            <span className={st.position}>{executiveMember.position}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={2}>
                        <div className={st.checkState}>
                          {vm.checkState === '충분' && <div className={st.enough}>충분</div>}
                          {vm.checkState === '부족' && <div className={st.lack}>부족</div>}
                          <div className={st.explain}>
                            <div className={st.bold}>의사정족수 자동계산</div>
                            <div>이사를 선택하면, 상법에 맞는지 자동으로 계산해드려요.</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </ContentBox>
        </Question>
      )}
    </form>
  ));
}
export default StandByBoardMeetingBasicInfo;
