import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './LiquidationStep.module.scss';
import clsx from 'clsx';

function LiquidationStep(props) {
  return useObserver(() => (
    <>
      <div className={Cst.row}>
        <div className={Cst.dName}>청산인</div>
        <div className={Cst.data}>
          {props.step.directors.map((item, idx) => {
            return (
              <Fragment key={idx}>
                {item.selectedYn && (
                  <div className={st.liquidationInfo}>
                    <div className={st.liquidationNamePosition}>
                      {item.koreanName} {item.position}님{item.englishName && <div>({item.englishName})</div>}
                    </div>
                    <div className={st.liquidationDetail}>
                      <div>
                        {item.foreignerYn ? '생년월일' : '주민등록번호'} : {item.birth}
                      </div>
                      {item.foreignerYn && <div>국적 : {item.nationality}</div>}
                      <div>주소 : {item.address}</div>
                      {item.addressSameYn === false && <div>전입신고일자 : {item.addressChangeDate}</div>}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      {props.step.liquidationStepType === 'CASE2' && (
        <div className={Cst.row}>
          <div className={Cst.dName}>등기부상 이사</div>
          <div className={Cst.data}>
            {props.step.directors.map((item, idx) => {
              return (
                <Fragment key={idx}>
                  {item.selectedYn === false && (
                    <div className={st.executiveInfo}>
                      <div className={st.executiveNamePosition}>
                        {item.koreanName} {item.position}님{item.englishName && <div>({item.englishName})</div>}
                      </div>
                      <div className={st.executiveDetail}>
                        {item.foreignerYn === false && <div>주민등록번호 : {item.birth}</div>}
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
      <div className={Cst.row}>
        <div className={Cst.dName}>해산일자</div>
        <div className={Cst.data}>{props.step.liquidationDate || '-'}</div>
      </div>
    </>
  ));
}
export default LiquidationStep;
