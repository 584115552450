import { useObserver } from 'mobx-react';
import BranchOfficeChange from './BranchOfficeChange/BranchOfficeChange';
import { Route } from 'react-router-dom';

function BranchOfficeChangeStep() {
  return useObserver(() => (
    <>
      {/* 지점변경 */}
      <Route
        path="/step/changeRegistration/BranchOfficeChangeStep/BranchOfficeChange"
        component={BranchOfficeChange}
      ></Route>
    </>
  ));
}

export default BranchOfficeChangeStep;
