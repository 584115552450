import { observable } from 'mobx';

const StandbyFreeIssueOfNewSharesStepModel = observable({
  capital: null,
  freeIssueOfNewSharesCapital: null,
  freeIssueOfNewSharesType: '',
  keepGoingYn: null,
  standbyFreeIssueOfNewSharesAllotmentInfos: [],
  amount: null,
  number: null,
  parValue: null,
  recordDateForNewSharesAllotment: {
    year: '',
    month: '',
    day: '',
  },
  resourceTypes: [],
  setStandbyFreeIssueOfNewSharesCheck(data) {
    this.capital = data?.capital || null;
    this.freeIssueOfNewSharesCapital = data?.freeIssueOfNewSharesCapital || null;
    this.freeIssueOfNewSharesType = data?.freeIssueOfNewSharesType || null;
    this.keepGoingYn = data?.keepGoingYn ?? null;
    if (data.standbyFreeIssueOfNewSharesAllotmentInfos && data.standbyFreeIssueOfNewSharesAllotmentInfos.length !== 0) {
      this.standbyFreeIssueOfNewSharesAllotmentInfos = data.standbyFreeIssueOfNewSharesAllotmentInfos.map((data) => {
        return {
          id: data.id || null,
          name: data.name || '',
          newStockAmount: data.newStockAmount || null,
          shareRatio: data.shareRatio || null,
        };
      });
    } else {
      this.standbyFreeIssueOfNewSharesAllotmentInfos = [];
    }
  },
  setStandbyFreeIssueOfNewShares(data) {
    this.amount = data?.amount || null;
    this.number = data?.number || null;
    this.parValue = data?.parValue || null;
    this.recordDateForNewSharesAllotment = data?.recordDateForNewSharesAllotment || {
      year: '',
      month: '',
      day: '',
    };
    this.resourceTypes = data?.resourceTypes || [
      {
        resoureceType: '',
        checkedYn: false,
      },
    ];
  },
});

export default StandbyFreeIssueOfNewSharesStepModel;
