import { observable } from 'mobx';

const balanceCertificateModel = observable({
  fileName: '',
  fileSize: null,
  id: null,
  url: '',
  uploadFiles: null, //파일 업로드
  setBalanceCertificate(balanceCertificate) {
    this.fileName = balanceCertificate?.fileName || '';
    this.fileSize = balanceCertificate?.fileSize || null;
    this.id = balanceCertificate?.id || null;
    this.url = balanceCertificate?.url || '';
  },
});

export default balanceCertificateModel;
