import { runInAction, toJS } from 'mobx';
import smartformAppModel from '@model/smartformAppModel';
import companyInfoModel from '@model/establish/companyInfoModel';
import stepService from '@service/stepService';
import portalModel from '@model/portalModel';
import CompanySizeModal from '@pages/ModalContent/establish/CompanySize/CompanySizeAlertModal';
import FarmerModal from '@pages/ModalContent/establish/CompanySize/FarmerModal';
import FisherManModal from '@pages/ModalContent/establish/CompanySize/FisherManModal';
import formDataToJson from '@common/module/submit';
import CompanySizeChangeModal from '@pages/ModalContent/establish/CompanySize/CompanySizeChangeModal';

class viewModel {
  constructor() {
    this.beforeData = {
      companySize: null,
      initiatorRole: null,
    };
  }
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      if (param.companySize === 'ONE') {
        runInAction(() => {
          portalModel.modalContent = CompanySizeModal.modalContent;
          portalModel.btn = CompanySizeModal.btn ?? null;
          portalModel.closeBtn = CompanySizeModal.closeBtn ?? true;
        });
        return;
      }
      if (this.beforeData.companySize) {
        //제출한 데이터를 수정한 경우
        //회사 규모를 수정한 경우
        if (this.beforeData.companySize !== this.companySize) {
          if (this.companySize === 'OTHER') {
            //2명에서 3명으로 변경
            runInAction(() => {
              portalModel.modalContent = CompanySizeChangeModal.modalContent(this.companySize);
              portalModel.btn = CompanySizeChangeModal.btn(param) ?? null;
              portalModel.closeBtn = CompanySizeChangeModal.closeBtn ?? true;
            });
          } else if (this.companySize === 'TWO') {
            //3명에서 2명으로 변경
            runInAction(() => {
              portalModel.modalContent = CompanySizeChangeModal.modalContent(this.companySize);
              portalModel.btn = CompanySizeChangeModal.btn(param) ?? null;
              portalModel.closeBtn = CompanySizeChangeModal.closeBtn ?? true;
            });
          }
        }
        //설립 인원이 2명일 때, 역할을 변경한 경우
        else if (this.beforeData.initiatorRole !== this.initiatorRole) {
          let initiatorRoleChangeAlert = false;

          //그룹1 (대표100 + 이사0, 대표100 + 감사0)
          //기존 역할 (대표100 + 이사0)
          if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS'
          )
            initiatorRoleChangeAlert = true; //대표0+주주100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표0+이사100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표0+감사100 로 변경
          //기존 역할 (대표100 + 감사0)
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS'
          )
            initiatorRoleChangeAlert = true; //대표0+주주100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표0+이사100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표0+감사100 로 변경
          //그룹2 (대표0 + 주주100)
          //기존 역할 (대표0 + 주주100)
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS' &&
            this.initiatorRole === 'REPRESENTATIVE_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표100+이사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS' &&
            this.initiatorRole === 'REPRESENTATIVE_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표100+감사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표0+이사100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표0+감사100 로 변경
          //그룹3 (대표0 + 이사100, 대표0 + 감사100)
          //기존 역할 (대표0 + 이사100)
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표100+이사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표100+감사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS'
          )
            initiatorRoleChangeAlert = true; //대표0+주주0 로 변경
          //기존역할 (대표0 + 감사100)
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_DIRECTOR'
          )
            initiatorRoleChangeAlert = true; //대표100+이사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_AUDITOR'
          )
            initiatorRoleChangeAlert = true; //대표100+감사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_SHAREHOLDERS'
          )
            initiatorRoleChangeAlert = true; //대표0+주주0 로 변경
          //그룹 내 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_AUDITOR'
          )
            initiatorRoleChangeAlert = false; //대표100+이사0 -> 대표100+감사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_DIRECTOR'
          )
            initiatorRoleChangeAlert = false; //대표100+감사0 -> 대표100+이사0 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR'
          )
            initiatorRoleChangeAlert = false; //대표0+이사100 -> 대표0+감사100 로 변경
          else if (
            this.beforeData.initiatorRole === 'REPRESENTATIVE_STOCKED_AUDITOR' &&
            this.initiatorRole === 'REPRESENTATIVE_STOCKED_DIRECTOR'
          )
            initiatorRoleChangeAlert = false; //대표0+감사100 -> 대표0+이사100 로 변경

          if (initiatorRoleChangeAlert) {
            //대표+이사 <-> 대표+주주 or 대표+감사 <-> 대표+주주 로 변경한 경우
            runInAction(() => {
              portalModel.modalContent = CompanySizeChangeModal.modalContent('initiatorRoleChange');
              portalModel.btn = CompanySizeChangeModal.btn(param) ?? null;
              portalModel.closeBtn = CompanySizeChangeModal.closeBtn ?? true;
            });
          } else {
            //대표+이사 <-> 대표+감사 로 변경한 경우
            stepService.saveStep(param);
          }
        } else {
          //데이터를 수정하지 않고 제출하는 경우
          stepService.saveStep(param);
        }
      } else {
        //처음 데이터를 제출하는 경우
        stepService.saveStep(param);
      }
    }
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get companySize() {
    return companyInfoModel.companySize;
  }
  get initiatorRole() {
    return companyInfoModel.initiatorRole;
  }
  setInitiatorRole(e) {
    companyInfoModel.initiatorRole = e.target.value;
  }
  setCompanySize(e) {
    companyInfoModel.companySize = e.target.value;
  }
  get allFarmWorkersYn() {
    return companyInfoModel.allFarmWorkersYn;
  }
  setAllFarmWorkersYn(e) {
    companyInfoModel.allFarmWorkersYn = e.target.value === 'true';
  }
  get checkPreReportingSystemYn() {
    return companyInfoModel.checkPreReportingSystemYn;
  }
  setCheckPreReportingSystemYn(e) {
    companyInfoModel.checkPreReportingSystemYn = e.target.value === 'true';
  }
  get allFishermenYn() {
    return companyInfoModel.allFishermenYn;
  }
  setAllFishermenYn(e) {
    companyInfoModel.allFishermenYn = e.target.value === 'true';
  }
  openModal(content) {
    let modal = {};
    if (content === 'Farmer') {
      modal = FarmerModal;
    } else if (content === 'Fisher') {
      modal = FisherManModal;
    }
    runInAction(() => {
      portalModel.modalContent = modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
}
export default new viewModel();
