import { observable } from 'mobx';

import { purposeType } from './constant';

export const 목적_추가금액_갯수_condition = observable({
  [purposeType.RECOMMENDED]: 50,
  [purposeType.DIRECT]: 10,
  setConditionByPurposeVersion(purposeVersion) {
    this[purposeType.DIRECT] = Number(purposeVersion) === 1 ? 20 : 10;
  },
});
