import { observable, runInAction } from 'mobx';
import stepService from '@service/stepService';
import companyInfoModel from '@model/establish/companyInfoModel';
import moduleService from '@service/moduleService';
import smartformAppModel from '@model/smartformAppModel';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import regExp from '@common/module/regExp';

class viewModel {
  constructor() {
    this.state = observable({
      companyNameDisabledYn: false,
    });
  }
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      if (companyInfoModel.companyName.companyNameCheck === 'CANNOT_USE') {
        return _alert('회사이름이 중복입니다.');
      }
      if (!companyInfoModel.companyName.companyNameCheck) {
        return _alert('회사이름 중복확인을 해주세요.');
      }
      param.corpNameDuplicationCheckType = companyInfoModel.companyName.companyNameCheck;
      stepService.saveStep(param);
    }
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get companyName() {
    return companyInfoModel.companyName;
  }
  koreanName(e) {
    runInAction(() => {
      companyInfoModel.companyName.koreanName = e.target.value.trim();
      companyInfoModel.companyName.companyNameCheck = null;
    });
  }
  async companyNameCheck() {
    if (companyInfoModel.companyName.koreanName) {
      if (!regExp.onlyKorNum_noSpacing().test(companyInfoModel.companyName.koreanName)) {
        return await _alert('한글 회사이름에는 한글, 숫자만 사용 가능합니다. 띄어쓰기는 불가능합니다.');
      } else {
        this.state.companyNameDisabledYn = true;
        companyInfoModel.companyName.companyNameCheck = await moduleService.corpNameDuplicationCheck(
          companyInfoModel.companyName.koreanName,
        );
        this.state.companyNameDisabledYn = false;
      }
    } else {
      await _alert('회사이름을 입력해 주세요.');
    }
  }
  prefixed(e) {
    companyInfoModel.companyName.prefixed = e.target.value === 'true';
  }
  englishNameAddYn(e) {
    companyInfoModel.companyName.englishNameAddYn = e.target.value === 'true';
  }
  englishName(e) {
    companyInfoModel.companyName.englishName = e.target.value;
  }
  englishNotation(e) {
    companyInfoModel.companyName.englishNotation = e.target.value;
  }
  get text() {
    //폼타입별 문구
    const formType = this.smartFormType;
    switch (formType) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return { formType: '주식회사' };
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return { formType: '농업회사법인 주식회사' };
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return { formType: '어업회사법인 주식회사' };
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return { formType: '유한회사' };
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return { formType: '유한책임회사' };
    }
  }
  get englishNotationSelectList() {
    //표기법
    const formType = this.smartFormType;
    switch (formType) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return [
          { text: 'Co., Ltd (영국식 표현)', value: 'CO_LTD' },
          { text: 'Corp.', value: 'CORP' },
          { text: 'Inc.', value: 'INC' },
          { text: '표기안함', value: 'NONE' },
        ];
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return [
          { text: 'Co., Ltd (영국식 표현)', value: 'CO_LTD' },
          { text: 'Corp.', value: 'CORP' },
          { text: 'Inc.', value: 'INC' },
          { text: '표기안함', value: 'NONE' },
        ];
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return [
          { text: 'Co., Ltd (영국식 표현)', value: 'CO_LTD' },
          { text: 'Corp.', value: 'CORP' },
          { text: 'Inc.', value: 'INC' },
          { text: '표기안함', value: 'NONE' },
        ];
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return [
          { text: 'Ltd.', value: 'LTD' },
          { text: '표기안함', value: 'NONE' },
        ];
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return [
          { text: 'LLC.', value: 'LLC' },
          { text: '표기안함', value: 'NONE' },
        ];
    }
  }
  get validateFunc() {
    const companyName = this.companyName.koreanName;
    if (companyName.includes('주식회사')) {
      return { includeYn: false, message: '[주식회사] 문구는 제외하고 입력해 주세요.' };
    } else if (companyName.includes('농업회사법인')) {
      return { includeYn: false, message: '[농업회사법인] 문구는 제외하고 입력해 주세요.' };
    } else if (companyName.includes('어업회사법인')) {
      return { includeYn: false, message: '[어업회사법인] 문구는 제외하고 입력해 주세요.' };
    } else if (companyName.includes('유한회사')) {
      return { includeYn: false, message: '[유한회사] 문구는 제외하고 입력해 주세요.' };
    } else if (companyName.includes('유한책임회사')) {
      return { includeYn: false, message: '[유한책임회사] 문구는 제외하고 입력해 주세요.' };
    } else {
      return { includeYn: true, message: null };
    }
  }
}
export default new viewModel();
