import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './BoardMeetingSealProvidingStep.module.scss';
import clsx from 'clsx';

function BoardMeetingSealProvidingStep(props) {
  return useObserver(() => (
    <>
      <div className={Cst.row}>
        <div className={Cst.dName} style={{ width: '370px' }}>
          이사회 공증용 인감증명서를 제공할 이사님
        </div>
        <div className={Cst.data} style={{ marginLeft: '20px' }}>
          {props.step.directors.map((item, idx) => {
            return (
              <span key={idx} className={st.director}>
                {idx + 1}. {item}
              </span>
            );
          })}
        </div>
      </div>
    </>
  ));
}
export default BoardMeetingSealProvidingStep;
