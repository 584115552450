import etcInfoModel from '@model/establish/etcInfoModel';
import { purposeType } from '@pages/Establish/EtcInfo/BusinessPurpose/constant';
import { runInAction } from 'mobx';
import { useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';

const useSearchResult = ({ searchMob, searchResult, selectedRecommendedPurposesMob }) => {
  const searchResultMob = useLocalObservable(() => ({
    selectablePurposeMap: new Map(),
    selectedPurposeIdSet: new Set(),
    // 직접입력 사업 목적 추가하고 싶은지
    directPurposeContent: null,
    // 정확히 검색 내용과 동일한 사업목적 혹은 그룹명이 있는지
    hasExactMatch: false,
    initSelectedPurposes(recommendedPurposes = []) {
      runInAction(() => {
        searchResultMob.reset();

        // 이미 선택된 추천 사업목적들 모두 반영
        selectedRecommendedPurposesMob.selectedPurposeMap.forEach((purpose) =>
          searchResultMob.selectPurpose(purpose.recommendedPurposeId),
        );

        // 검색 결과로 선택가능한 사업목적들 모두 반영
        recommendedPurposes.forEach((purpose) => {
          if (searchResultMob.selectablePurposeMap.has(purpose.recommendedPurposeId)) {
            return;
          }

          searchResultMob.selectablePurposeMap.set(Number(purpose.recommendedPurposeId), purpose);
        });
      });
    },
    reset() {
      runInAction(() => {
        searchResultMob.hasExactMatch = false;
        searchResultMob.directPurposeContent = null;
        searchResultMob.selectablePurposeMap.clear();
        searchResultMob.selectedPurposeIdSet.clear();
      });
    },
    checkSelected(purposeId) {
      return searchResultMob.selectedPurposeIdSet.has(Number(purposeId));
    },
    togglePurpose(purposeId) {
      if (searchResultMob.checkSelected(purposeId)) {
        searchResultMob.unselectPurpose(purposeId);
      } else {
        searchResultMob.selectPurpose(purposeId);
      }
    },
    selectPurpose(purposeId) {
      searchResultMob.selectedPurposeIdSet.add(Number(purposeId));
    },
    unselectPurpose(purposeId) {
      searchResultMob.selectedPurposeIdSet.delete(Number(purposeId));
    },
    /**
     * 선택 완료 시 반영
     */
    submitSelectedPurposes() {
      runInAction(() => {
        /**
         * 선택한 사업목적들 중 검색 결과에서 선택가능한 목적들만 선택한 목적 목록에 추가한다.
         */
        searchResultMob.selectedPurposeIdSet.forEach((selectedPurposeId) => {
          if (!searchResultMob.selectablePurposeMap.has(selectedPurposeId)) {
            return;
          }

          selectedRecommendedPurposesMob.selectPurpose(searchResultMob.selectablePurposeMap.get(selectedPurposeId));
        });

        // 검색 결과에서 선택 해제된 목적들은 제거
        selectedRecommendedPurposesMob.selectedPurposeMap.forEach((purpose) => {
          if (!searchResultMob.selectedPurposeIdSet.has(purpose.recommendedPurposeId)) {
            selectedRecommendedPurposesMob.unselectPurpose(purpose);
          }
        });

        // 직접입력하기 선택시 이것도 반영
        if (
          searchResultMob.directPurposeContent &&
          // 중복된 content는 제외
          !etcInfoModel.businessPurposes.some((item) => item.content === searchResultMob.directPurposeContent)
        ) {
          etcInfoModel.businessPurposes.push({
            id: null,
            content: searchResultMob.directPurposeContent,
            purposeType: purposeType.DIRECT,
            noteYn: false,
            note: null,
          });
        }

        searchMob.reset();
        searchResultMob.reset();
      });
    },
  }));

  useEffect(() => {
    const purposeList = [
      ...searchResult.purposes,
      ...searchResult.purposeGroupsForGroupName.map((group) => group.purposes).flat(1),
      ...searchResult.purposeGroupsForContent.map((group) => group.purposes).flat(1),
    ];

    searchResultMob.initSelectedPurposes(purposeList);

    searchResultMob.hasExactMatch =
      purposeList.some((item) => item.content === searchMob.searchKeyword) ||
      searchResult.purposeGroupsForGroupName.some((group) =>
        group.groupNames.some((groupName) => groupName === searchMob.searchKeyword),
      );

    return () => {
      searchResultMob.reset();
    };
  }, [searchResult]);

  return {
    searchResultMob,
  };
};

export default useSearchResult;
