import { observable } from 'mobx';

const companyInfoModel = observable({
  companySize: '', //회사설립 참여 인원수
  initiatorRole: '', //설립발기인 역할
  companyAddress: {
    // 회사주소
    address: null,
    detailAddress: '',
    nameOfBuilding: '',
  },
  headOfficeDeliveryYn: null, //우편받을주소 본점주소를 사용할 지
  deliveryAddress: {
    // 우편받을주소
    address: null,
    detailAddress: '',
    nameOfBuilding: '',
  },
  companyName: {
    companyNameCheck: null, //회사이름 중복체크 (체크아직안한상태 : null, 체크성공 : CAN_USE, api에러로 실패 : API_ERROR, 중복 : CANNOT_USE, SKIP||CHECKED : 인터넷등기소에서 중복체크 불가능한 시간)
    koreanName: '', //회사명
    prefixed: null, //주식회사문구 앞/뒤 bool
    englishNameAddYn: null, //회사 영어이름 추가여부 bool
    englishName: '', //회사 영어이름
    englishNotation: '', // 영문 회사이름 표기법 (BRITISH_EXPRESSION : CO., Ltd, AMERICAN_EXPRESSION_1 : Corp, AMERICAN_EXPRESSION_2 : Inc.. NONE: 표기안함)
  },
  allFarmWorkersYn: null, //설립인 전원 농업인 여부
  allFishermenYn: null, //설립인 전원 어업인 여부
  checkPreReportingSystemYn: null, //사전신고제도 여부
  setCompanySize(companySize) {
    this.companySize = companySize?.companySize || '';
    this.initiatorRole = companySize?.initiatorRole || '';
  },
  setCompanyAddress(companyAddress) {
    this.headOfficeDeliveryYn = companyAddress?.headOfficeDeliveryYn ?? null;
    if (companyAddress?.companyAddress) {
      this.companyAddress.address = companyAddress?.companyAddress?.address || null;
      this.companyAddress.detailAddress = companyAddress?.companyAddress?.detailAddress || '';
      this.companyAddress.nameOfBuilding = companyAddress?.companyAddress?.nameOfBuilding || '';
    } else {
      this.companyAddress = {
        // 회사주소
        address: null,
        detailAddress: '',
        nameOfBuilding: '',
      };
    }
    if (companyAddress?.deliveryAddress) {
      this.deliveryAddress.address = companyAddress?.deliveryAddress?.address || null;
      this.deliveryAddress.detailAddress = companyAddress?.deliveryAddress?.detailAddress || '';
      this.deliveryAddress.nameOfBuilding = companyAddress?.deliveryAddress?.nameOfBuilding || '';
    } else {
      this.deliveryAddress = {
        address: null,
        detailAddress: '',
        nameOfBuilding: '',
      };
    }
  },
  setCompanyName(companyName) {
    this.companyName.englishName = companyName?.englishName || '';
    this.companyName.englishNameAddYn = companyName?.englishNameAddYn ?? null;
    this.companyName.koreanName = companyName?.koreanName || '';
    this.companyName.prefixed = companyName?.prefixed ?? null;
    this.companyName.companyNameCheck = companyName?.corpNameDuplicationCheckType || null;
    this.companyName.englishNotation = companyName?.englishNotation || '';
  },
  setFarmerCheck(farmerCheck) {
    this.allFarmWorkersYn = farmerCheck?.allFarmWorkersYn || null;
  },
  setFishermanCheck(fishermanCheck) {
    this.allFishermenYn = fishermanCheck?.allFishermenYn || null;
  },
  setCheckPreReportingSystemYn(checkPreReportingSystemYn) {
    this.checkPreReportingSystemYn = checkPreReportingSystemYn || null;
  },
});

export default companyInfoModel;
