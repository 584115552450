import BoardMeetingSealProvidingStepModel from '@model/changeRegistration/BoardMeetingSealProvidingStepModel';
import { runInAction, observable } from 'mobx';
import stepService from '@service/stepService';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import BoardMeetingSealProvidingModal from '@pages/ModalContent/changeRegistration/BoardMeetingSealProviding/BoardMeetingSealProvidingModal';

class BoardMeetingSealProvidingVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        this.state.errorArr = [];
        this.state.openIdx = null;
        if (BoardMeetingSealProvidingStepModel.directors.length < 2) {
          //한명만 입력하고 넘어가는 경우
          return this.openModal();
        } else {
          //올바를 데이터를 입력한 경우
          let param = formDataToJson(e.target).data;
          stepService.saveStep(param);
        }
      }
    });
  }
  openModal() {
    const modal = BoardMeetingSealProvidingModal;
    runInAction(() => {
      portalModel.modalContent = modal.modalContent;
      portalModel.btn = modal.btn ?? null;
      portalModel.closeBtn = modal.closeBtn ?? true;
    });
  }
  get directorsDefaultValue() {
    const val = {
      id: '',
      name: '',
      sealProviderType: '',
    };
    return val;
  }
  get directors() {
    return BoardMeetingSealProvidingStepModel.directors;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setTitle(idx) {
    let name = this.directors[idx].name;
    let type = this.directors[idx].sealProviderType;
    if (type === '외국인_거주등록') {
      type = '외국인';
    }
    let title = '';
    if (name && type) {
      title = name + ' 이사님(' + type + ')';
    } else if (!name) {
      title = '인감증명서를 제공할 이사분의 정보를 입력해 주세요.';
    }
    return title;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  async setCloseBtn(idx) {
    const directors = BoardMeetingSealProvidingStepModel.directors;
    let name = directors[idx].name;
    if (!name) {
      name = '해당 이사';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (directors.length === 1) {
      if (conf) {
        runInAction(() => {
          BoardMeetingSealProvidingStepModel.directors = [];
          BoardMeetingSealProvidingStepModel.directors.push(this.directorsDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return directors.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  addDirectors() {
    runInAction(() => {
      BoardMeetingSealProvidingStepModel.directors.push(this.directorsDefaultValue);
      this.state.openIdx = this.directors.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  setSealProviderType(e, idx) {
    runInAction(() => {
      BoardMeetingSealProvidingStepModel.directors[idx].sealProviderType = e.target.value;
    });
  }
  setName(e, idx) {
    runInAction(() => {
      BoardMeetingSealProvidingStepModel.directors[idx].name = e.target.value;
    });
  }
}
export default new BoardMeetingSealProvidingVm();
