import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './AddStockOptionStep.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import AddStockOptionStepVm from './AddStockOptionStepVm';

function AddStockOptionStep(props) {
  return useObserver(() => (
    <>
      <div className={Ast.row}>
        <div className={Ast.dName}>벤처기업인증</div>
        <div className={Ast.data}>
          {props.step.ventureYn !== null ? <>인증 {props.step.ventureYn ? 'O' : 'X'}</> : '-'}
        </div>
      </div>
      <div className={Ast.row}>
        <div className={Ast.dName}>규정</div>
        <div className={Ast.data}>
          {props?.step?.helpmeRegulationYn !== null ? (
            <>
              {props?.step?.helpmeRegulationYn === true && <span>헬프미 표준규정</span>}
              {props?.step?.helpmeRegulationYn === false && (
                <>
                  <span>자체규정</span>
                  <div className={st.regulation}>{props?.step?.regulation}</div>
                </>
              )}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={Ast.row}>
        <div className={Ast.dName}></div>
        <div className={Ast.data}>
          <CopyBtn
            onClick={() => copyText(AddStockOptionStepVm.스톡옵션Text(1, props.step.ventureYn))}
            className={Ast.stockOptionCopyBtn}
            style={{ marginLeft: '0' }}
          >
            [1]
          </CopyBtn>
          <CopyBtn
            onClick={() => copyText(AddStockOptionStepVm.스톡옵션Text(2, props.step.ventureYn))}
            className={Ast.stockOptionCopyBtn}
          >
            [2]
          </CopyBtn>
          <CopyBtn
            onClick={() => copyText(AddStockOptionStepVm.스톡옵션Text(3, props.step.ventureYn))}
            className={Ast.stockOptionCopyBtn}
          >
            [3]
          </CopyBtn>
          <CopyBtn
            onClick={() => copyText(AddStockOptionStepVm.스톡옵션Text(4, props.step.ventureYn))}
            className={Ast.stockOptionCopyBtn}
          >
            [4]
          </CopyBtn>
          <CopyBtn
            onClick={() => copyText(AddStockOptionStepVm.스톡옵션Text(5, props.step.ventureYn))}
            className={Ast.stockOptionCopyBtn}
          >
            [5]
          </CopyBtn>
        </div>
      </div>
    </>
  ));
}
export default AddStockOptionStep;
