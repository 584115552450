import StockCompanySealStepModel from '@model/changeRegistration/StockCompanySealStepModel';
import { runInAction, observable, toJS } from 'mobx';
import formDataToJson, { errorFocus, findPos, objectToFormData } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import stepService from '@service/stepService';

class StockholdersMeetingSealProvidingStockholdersCaseOneVm {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
    });
  }
  get pageModel() {
    return StockCompanySealStepModel.StockholdersMeetingSealProvidingStockholdersCaseOne;
  }
  get stockholders() {
    return this.pageModel.stockholders;
  }
  get stockholderMeetingType() {
    return this.pageModel.stockholderMeetingType;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setTitle(idx) {
    if (this.stockholders[idx].name) {
      return this.stockholders[idx].name + ' 주주님';
    } else {
      return '인감증명서를 제공할 주주님의 정보를 입력해주세요.';
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    this.stockholders.forEach((stockholder, index) => {
      if (
        stockholder.sealProviderType &&
        stockholder.sealProviderType === '펀드_투자조합' &&
        !stockholder.uploadFiles.length &&
        !stockholder.files.length
      ) {
        errorIdx.push(index);
      }
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  setSealProviderType(e, idx) {
    this.stockholders[idx].sealProviderType = e.target.value;
  }
  setName(e, idx) {
    this.stockholders[idx].name = e.target.value;
  }
  setEnglishName(e, idx) {
    this.stockholders[idx].englishName = e.target.value;
  }
  setNationalityType(e, idx) {
    this.stockholders[idx].nationalityType = e.target.value;
  }
  setNationalityOthers(e, idx) {
    this.stockholders[idx].nationalityOthers = e.target.value;
  }
  get stockholdersDefaultValue() {
    return {
      englishName: '',
      id: '',
      name: '',
      nationalityOthers: '',
      nationalityType: '',
      sealProviderType: '',
      uploadFiles: [],
      files: [],
      deleteFileIds: [],
      filesSubmit: false,
    };
  }
  addStockholder() {
    runInAction(() => {
      this.stockholders.push(this.stockholdersDefaultValue);
      this.state.openIdx = this.stockholders.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 155;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  async setCloseBtn(idx) {
    let name = this.stockholders[idx].name;
    if (!name) {
      name = '해당 주주';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (this.stockholders.length === 1) {
      if (conf) {
        runInAction(() => {
          this.pageModel.stockholders = [];
          this.pageModel.stockholders.push(this.stockholdersDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return this.stockholders.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  fileErrorCheck() {
    let errorYn = false;
    this.stockholders.forEach((stockholder, index) => {
      stockholder.filesSubmit = true;
      if (
        stockholder.sealProviderType &&
        stockholder.sealProviderType === '펀드_투자조합' &&
        !stockholder.uploadFiles.length &&
        !stockholder.files.length
      ) {
        errorYn = true;
      }
    });
    return errorYn;
  }
  submit(e) {
    e.preventDefault();
    runInAction(() => {
      const fileError = this.fileErrorCheck();
      if (formDataToJson(e.target, false).error || fileError) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        let param = formDataToJson(e.target).data;
        param.stockholders.forEach((stockholder, index) => {
          if (stockholder.sealProviderType === '펀드_투자조합') {
            stockholder.deleteFileIds = toJS(this.stockholders[index].deleteFileIds);
          }
        });
        const formData = new FormData();
        objectToFormData(formData, param);
        param.stockholders.forEach((stockholder, index) => {
          if (stockholder.sealProviderType === '펀드_투자조합') {
            this.stockholders[index].uploadFiles.forEach((file, index2) => {
              formData.append(`stockholders[${index}].files[${index2}]`, file);
            });
          }
        });
        stepService.saveStep(formData);
      }
    });
  }
}
export default new StockholdersMeetingSealProvidingStockholdersCaseOneVm();
