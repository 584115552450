export const highlightUrls = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+(?:\([^\s]+\)|\S*))/g;
  const texts = text.split(urlRegex);

  return texts.map((part, idx) => {
    if (urlRegex.test(part)) {
      const displayText = part.replace(/%20/g, ' ');

      return (
        <a key={idx} href={part} target="_blank" rel="noopener noreferrer" style={{ color: '#00B4E3' }}>
          {displayText}
        </a>
      );
    }

    return part;
  });
};
