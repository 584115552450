import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import { runInAction } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';

class KoreanPersonVm {
  setKoreanName(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanName = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanAddress = e.target.value;
    });
  }
  addKoreanCompanyRepresentatives(idx) {
    if (ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives[0].position) {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives[0].position,
      });
    } else {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: '',
      });
    }
  }
  koreanCompanyRepresentatives_position(e, idx) {
    //직책
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives.forEach((item, idx2) => {
        ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives[idx2].position =
          e.target.value;
      });
    });
  }
  koreanCompanyRepresentatives_name(e, idx, idx2) {
    //성함
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives[idx2].name =
        e.target.value;
    });
  }
  async setKoreanCompanyRepresentativesCloseBtn(idx, idx2) {
    const koreanCompanyRepresentatives =
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanCompanyRepresentatives;
    let name = koreanCompanyRepresentatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return koreanCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  disabledRepresentativePositionYn(idx, idx2) {
    let disabledYn = false;
    if (idx2 !== 0) {
      disabledYn = true;
    }
    return disabledYn;
  }
}

export default new KoreanPersonVm();
