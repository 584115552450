import { observable } from 'mobx';

const smartformAppModel = observable({
  smartformId: null,
  smartFormType: null,
  isLoadData: false,
  userToken: null,
  corpId: null,
  isEstablishment: null,
  userId: null,
  authority: null,
  isSubmitData: true,
  standbyYn: null,
  setSmartformId(smartformId) {
    this.smartformId = smartformId;
  },
  setSmartFormType(smartFormType) {
    this.smartFormType = smartFormType;
  },
  setIsLoadData(isLoadData) {
    this.isLoadData = isLoadData;
  },
  setIsSubmitData(isSubmitData) {
    this.isSubmitData = isSubmitData;
  },
  setIsEstablishment(isEstablishment) {
    this.isEstablishment = isEstablishment;
  },
  setUserId(userId) {
    this.userId = userId;
  },
  setUserToken(userToken) {
    this.userToken = userToken;
  },
  setCorpId(corpId) {
    this.corpId = corpId;
  },
  setAuthority(authority) {
    this.authority = authority;
  },
  setStandbyYn(standbyYn) {
    this.standbyYn = standbyYn;
  },
});

export default smartformAppModel;
