import st from './TCAgreeModal.module.scss';
import clsx from 'clsx';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: function (data) {
    const content = () => {
      return { __html: data };
    };
    return (
      <article className={st.Modal}>
        <h1 className={st.title}>
          위임계약 약관 <span className={st.required}>(필수)</span>
        </h1>
        <div className={clsx(st.modalContent, st.termsBox, st.termsRequired)}>
          <h2 className={st.terms}>총칙</h2>
          <div dangerouslySetInnerHTML={content()} />
        </div>
      </article>
    );
  },
  btn: [
    {
      title: '위임계약 약관 확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
