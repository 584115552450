class BranchOfficeChangeStepVm {
  managerChangeType(type) {
    switch (type) {
      case 'ADD_NEW':
        return <span>지배인 추가</span>;
      case 'CHANGE_MANAGER':
        return (
          <span>
            기존 지배인을 새로운
            <br />
            사람으로 변경
          </span>
        );
      case 'CHANGE_ADDRESS':
        return <span>지배인 집주소 변경</span>;
      case 'DELETE':
        return <span>지배인 폐지</span>;
      case 'NONE':
        return <span>변경없음</span>;
    }
  }
  displayAddressYn(type) {
    if (type === 'DELETE') {
      return false;
    } else {
      return true;
    }
  }
  displayManagerChangeDateYn(type) {
    if (type === 'ADD_NEW' || type === 'CHANGE_MANAGER') {
      return true;
    } else {
      return false;
    }
  }
  displayManagerCauseDateYn(type) {
    if (type === 'CHANGE_ADDRESS' || type === 'DELETE') {
      return true;
    } else {
      return false;
    }
  }
}
export default new BranchOfficeChangeStepVm();
