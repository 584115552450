import { observable } from 'mobx';

const portalModel = observable({
  modalContent: null,
  btn: null,
  closeBtn: true,
  modalClassName: null,
  onClosePreCallback: null,
  setModalClose() {
    portalModel.modalContent = null;
    portalModel.btn = null;
    portalModel.closeBtn = true;
    portalModel.modalClassName = null;
    portalModel.onClosePreCallback = null;
  },
});

export default portalModel;
