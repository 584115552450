import NumOfSharesToBeIssuedStepModel from '@model/changeRegistration/NumOfSharesToBeIssuedStepModel';
import { observable, runInAction, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import stepService from '@service/stepService';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class NumOfSharesToBeIssuedVm {
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target).error === false) {
        let param = formDataToJson(e.target).data;
        if (param.sharesToBeIssuedType === 'DIRECT') {
          param.newNumOfSharesToBeIssued = removeCommas(param.newNumOfSharesToBeIssued);
        }
        stepService.saveStep(param);
      }
    });
  }
  get case() {
    return NumOfSharesToBeIssuedStepModel.sharesToBeIssuedCase;
  }
  get numOfSharesOnRegister() {
    //등기부상 이미 발행된 주식수
    return NumOfSharesToBeIssuedStepModel.numOfSharesOnRegister;
  }
  get numOfNewShares() {
    //이번에 발행할 신주식수
    return NumOfSharesToBeIssuedStepModel.numOfNewShares;
  }
  get numOfSharesToBeIssuedOnRegister() {
    //등기부상 발행예정 주식수
    return NumOfSharesToBeIssuedStepModel.numOfSharesToBeIssuedOnRegister;
  }
  sumShares() {
    const _numOfSharesOnRegister = this.numOfSharesOnRegister;
    const _numOfNewShares = this.numOfNewShares;

    return _numOfSharesOnRegister + _numOfNewShares;
  }
  sharesCalc() {
    const _sumShares = this.sumShares();
    const _numOfSharesToBeIssuedOnRegister = this.numOfSharesToBeIssuedOnRegister;

    return _sumShares - _numOfSharesToBeIssuedOnRegister;
  }
  get sharesToBeIssuedType() {
    return NumOfSharesToBeIssuedStepModel.sharesToBeIssuedType;
  }
  setSharesToBeIssuedType(e) {
    runInAction(() => {
      NumOfSharesToBeIssuedStepModel.sharesToBeIssuedType = e.target.value;
    });
  }
  get newNumOfSharesToBeIssued() {
    //발행예정주식수 직접입력 값
    return NumOfSharesToBeIssuedStepModel.newNumOfSharesToBeIssued;
  }
  setNewNumOfSharesToBeIssued(e) {
    runInAction(() => {
      NumOfSharesToBeIssuedStepModel.newNumOfSharesToBeIssued = onlyNumber(removeCommas(e.target.value));
    });
  }
  newNumOfSharesToBeIssuedValidation(data) {
    const numOfSharesToBeIssued = Number(data);
    if (numOfSharesToBeIssued > 0) {
      return true;
    } else {
      return false;
    }
  }
}

export default new NumOfSharesToBeIssuedVm();
