import React, { useEffect } from 'react';
import styled from 'styled-components';
import loading from '../../asset/icon/loading.gif';
import PropTypes from 'prop-types';

const Wrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 99999;
`;
const ModalWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const ModalContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
`;

function Loading(props) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  });
  return (
    <Wrap>
      <ModalWrap>
        <ModalContainer width={props.width} height={props.height}>
          <img src={loading}></img>
        </ModalContainer>
      </ModalWrap>
    </Wrap>
  );
}
Loading.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
Loading.defaultProps = {
  width: 'auto',
  height: 'auto',
};
export default Loading;
