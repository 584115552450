import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './BranchOfficeDeleteStep.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function BranchOfficeDeleteStep(props) {
  return useObserver(() => (
    <>
      <div className={Ast.row}>
        <div className={Ast.dName}>폐지할 지점</div>
        <div className={Ast.data}>
          {props.step.branches.length === 0 && '-'}
          {props.step.branches.map((item, idx) => {
            return (
              <div key={idx} className={st.address}>
                {idx + 1}. {item.address}
                <CopyBtn onClick={() => copyText(item.address)} className={Ast.addressCopyAllBtn}>
                  <Icon icon="copy" className={Ast.copyIcon} />
                </CopyBtn>
              </div>
            );
          })}
        </div>
      </div>
    </>
  ));
}
export default BranchOfficeDeleteStep;
