import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import StandbyParValueDivisionVm from './StandbyParValueDivisionVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import SearchAddress from '@comComponents/atoms/SearchAddress/SearchAddress';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandbyParValueDivision.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import clsx from 'clsx';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import { addCommas } from '@common/module/replaceNumber';

function StandbyParValueDivision() {
  return useObserver(() => (
    <form onSubmit={(e) => StandbyParValueDivisionVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox className={Qst.spaceBetween}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>기존</span> 회사의 <span className={Qst.bold}>액면금</span>을 확인해 주세요.
              </h2>
            </div>
            <div className={st.parValueOnRegisterBox}>
              <div className={st.title}>액면금</div>
              <div className={st.parValueOnRegister}>
                {addCommas(StandbyParValueDivisionVm.parValueOnRegister)}
                <span className={st.parValueOnRegisterText}>원</span>
              </div>
            </div>
            <div className={Qst.titleWrap} style={{ marginTop: '120px' }}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>변경할 액면금</span>을 입력해 주세요.
              </h2>
              <div className={Qst.titleExplain}>최소금액은 100원이고, 100원 단위로 입력 가능합니다.</div>
            </div>
            <div>
              <InputText
                className={Qst.inputText}
                placeholder="0"
                placeholderAlign="right"
                style={{ width: '383px' }}
                name="newParValue"
                value={addCommas(StandbyParValueDivisionVm.newParValue)}
                onChange={(e) => StandbyParValueDivisionVm.setNewParValue(e)}
                errorText={
                  <Error
                    name="newParValue"
                    value={StandbyParValueDivisionVm.newParValue}
                    errorCase={{
                      required: '액면금을 입력해 주세요.',
                      validate: {
                        func: regExp.priceCheck().test(addCommas(String(StandbyParValueDivisionVm.newParValue))),
                        message: '100원 단위로 입력해 주세요.',
                      },
                    }}
                  />
                }
              />
              <span className={st.newParValueText}>원</span>
            </div>
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="액면금 변경" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>액면금이란 무엇인가요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>액면금이란 등기부에 기재되는 1주의 명목상 금액입니다.</Answer>
                  <div className={Qst.explain}>
                    액면금은 회사 주식의 실제가치와는 다르며, 등기부에 기재되는 1주의 최소단위를 말합니다.
                  </div>
                  <div className={Qst.explain}>
                    주식을 새로 발행할 때에는 발행가액이 액면금 이상이어야 하고, 액면금에 미치지 못하는 발행가액을
                    책정할 경우, 법원의 허가를 받아야 합니다.
                  </div>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>액면금을 얼마로 변경하면 좋을까요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    액면분할을 하는 경우라면 100원을 추천드리고, 액면병합을 하는 경우라면 상황에 따라 다릅니다.
                  </Answer>
                  <div className={Qst.explain}>
                    액면분할을 하는 경우는 대부분 1주의 가치가 너무 높아져서, 이를 쪼개서 팔기 쉽도록 1주의 가격을
                    낮추기 위해서 진행합니다. 따라서 이 경우에는 상법상 최소단위인 100원을 추천드립니다.
                  </div>
                  <div className={Qst.explain}>
                    액면병합을 하는 경우는 다양한 목적이 있기 때문에, 고객님의 사정에 맞는 금액을 정하시면 됩니다.
                  </div>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>액면금을 100원보다 더 낮게 할 수 없나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>액면금을 100원보다 더 낮게 할 수는 없습니다.</Answer>
                  <div className={Qst.explain}>
                    상법상 액면금의 최소단위가 100원이기 때문에, 100원 이상으로 설정해주셔야 합니다.
                  </div>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default StandbyParValueDivision;
