import { observer } from 'mobx-react';
import React, { useCallback, useState } from 'react';

import CheckBox from '../../../../ui/CheckBox';
import GroupListItem from '../../../../ui/GroupListItem';
import st from './style.module.scss';

const Group = observer(({ purposeGroup = {}, defaultOpen = false, highlightText, searchResultMob }) => {
  const [openDropDown, setOpenDropDown] = useState(defaultOpen);

  const { groupNames = [], purposes = [] } = purposeGroup;

  const isGroupSelected = purposes.every((item) => searchResultMob.checkSelected(item.recommendedPurposeId));

  const toggleGroup = useCallback((e) => {
    e.stopPropagation();

    const checked = e.target.checked;

    if (checked) {
      purposes.forEach((item) => searchResultMob.selectPurpose(item.recommendedPurposeId));
      setOpenDropDown(true);
    } else {
      purposes.forEach((item) => searchResultMob.unselectPurpose(item.recommendedPurposeId));
      setOpenDropDown(false);
    }
  }, []);

  return (
    <div className={st.container}>
      <div className={st.group}>
        <CheckBox checked={isGroupSelected} onChange={toggleGroup} />
        <GroupListItem.GroupTitle
          groupNames={groupNames}
          contentLength={purposes.length}
          isDropDownOpened={openDropDown}
          onClickOpenDropDown={() => setOpenDropDown(!openDropDown)}
          highlightText={highlightText}
        />
      </div>
      {openDropDown && (
        <div className={st.dropDown}>
          {purposes.map((item, index) => (
            <React.Fragment key={item.recommendedPurposeId}>
              {index === 0 && <GroupListItem.Divider />}
              <div className={st.purposeWrap}>
                <CheckBox
                  checked={searchResultMob.checkSelected(item.recommendedPurposeId)}
                  onChange={() => searchResultMob.togglePurpose(item.recommendedPurposeId)}
                />
                <GroupListItem.PurposeRow content={item.content} highlightText={highlightText} />
              </div>
              <GroupListItem.Divider />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
});

export default Group;
