import { _alert, _confirm } from '@model/dialogModel';
import minorDocumentUploadModel from '@model/minorDocumentUploadModel';
import { pageRouter } from '@model/pageStepModel';
import smartformAppModel from '@model/smartformAppModel';
import pageService from '@service/pageService';
import { observable, runInAction } from 'mobx';

class viewModel {
  constructor() {
    this.state = observable({
      submitBtnDisabledYn: null,
    });
  }
  loadMinorShareholderDocuments() {
    runInAction(() => {
      minorDocumentUploadModel.familyRelationshipCertificateUploadFiles = [];
      minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles = [];
    });
    pageService.loadMinorShareholderDocuments();
  }
  pre() {
    pageRouter.replace('/MinorDocumentUpload/BasicCertificate');
  }
  get familyRelationshipCertificate() {
    return minorDocumentUploadModel.familyRelationshipCertificate;
  }
  get familyRelationshipCertificateUploadFiles() {
    return minorDocumentUploadModel.familyRelationshipCertificateUploadFiles;
  }
  get familyRelationshipCertificateDeleteFiles() {
    return minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles;
  }
  async upload() {
    if (
      minorDocumentUploadModel.familyRelationshipCertificateUploadFiles.length === 0 &&
      minorDocumentUploadModel.familyRelationshipCertificate.length === 0
    ) {
      //파일 업로드를 하지 않는 경우
      await _alert('파일을 업로드해 주세요.');

      return;
    }

    if (
      minorDocumentUploadModel.familyRelationshipCertificateUploadFiles.length !== 0 ||
      minorDocumentUploadModel.familyRelationshipCertificateDeleteFiles.length !== 0
    ) {
      //업로드할 파일이 있는 경우(처음 파일을 업로드 하는 경우, 기존 파일을 변경한 경우)
      this.state.submitBtnDisabledYn = true;

      await pageService.uploadMinorShareholderDocumentsFamilyRelationship();
      await _alert('파일을 업로드 하였습니다.');
      this.state.submitBtnDisabledYn = false;

      if (smartformAppModel.corpId) {
        //대시보드에서 열었을 때(corpId가 있을 때)
        if (window.opener) {
          window.opener.close();
        }

        sessionStorage.clear();
        window.open(
          `${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`,
          '_blank',
        );
        window.close();
      } else {
        //어드민에서 열었을 때(corpId가 없을 때)
        sessionStorage.clear();
        window.close();
      }

      return;
    }

    //기존 파일을 변경 없이 그대로 업로드 하는 경우
    if (await _confirm('기존 파일 그대로 업로드하시겠습니까?')) {
      //대시보드에서 열었을 때(corpId가 있을 때)
      if (smartformAppModel.corpId) {
        try {
          this.state.submitBtnDisabledYn = true;
          // 미성년자 파일 제출 1에서 새로운 파일을 업로드 했을 수 있음으로 form 제출 처리 API 호출
          await pageService.submitMinorShareholderDocumentForm();
          await _alert('기존 파일을 업로드 하였습니다.');
        } finally {
          this.state.submitBtnDisabledYn = false;
        }

        if (window.opener) {
          window.opener.close();
        }

        sessionStorage.clear();
        window.open(
          `${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`,
          '_blank',
        );
        window.close();
      } else {
        await _alert('기존 파일을 업로드 하였습니다.');
        //어드민에서 열었을 때(corpId가 없을 때)
        sessionStorage.clear();
        window.close();
      }
    }
  }
  name() {
    const type = smartformAppModel.smartFormType;

    switch (type) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return '주주/임원';
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return '주주/임원';
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return '주주/임원';
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return '출자자/임원';
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return '출자자/임원';
    }
  }
}

export default new viewModel();
