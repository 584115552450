import { toJS, runInAction, observable } from 'mobx';
import changeRegistrationService from '@service/changeRegistrationService';
import changeRegistrationModel from '@model/changeRegistrationModel';
import smartformAppModel from '@model/smartformAppModel';
import passwordModel from '@model/changeRegistration/passwordModel';
import pageStepModel from '@model/pageStepModel';

class viewModel {
  constructor() {
    this.state = observable({
      mainContentTop: 217,
    });
  }
  get smartformId() {
    return smartformAppModel.smartformId;
  }
  async lunchChangeRegistrationService() {
    if (this.smartformId && passwordModel.hasPassword === null) {
      //최초 한번만 실행하기 위해서 hasPassword가 null일때만 실행함
      await changeRegistrationService.loadChangeRegistrationBasic(this.smartformId); //변경등기 기본공통데이터 로드 후 가야할 페이지로 redirect
    }
  }
  get userToken() {
    return smartformAppModel.userToken;
  }
  get isStep() {
    return pageStepModel.isStep;
  }
  get isLoadData() {
    return smartformAppModel.isLoadData;
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  mainContentTop() {
    const interval = setInterval(() => {
      if (document.getElementById('headerNav')) {
        this.state.mainContentTop =
          document.getElementById('headerNav').clientHeight + document.getElementById('headerNav').offsetTop + 1;
        clearInterval(interval);
      }
    }, 30);
  }
}

export default new viewModel();
