import { runInAction } from 'mobx';
import shareholderAndExecutiveInfoModel from '@model/establish/shareholderAndExecutiveInfoModel';
import stepService from '@service/stepService';
import { removeCommas, addCommas, onlyNumber } from '@common/module/replaceNumber';
import smartformAppModel from '@model/smartformAppModel';
import formDataToJson from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import companyInfoModel from '@model/establish/companyInfoModel';
import ShareholderInvestmentModal from '@pages/ModalContent/establish/ShareholderInvestment/ShareholderInvestmentModal';
import portalModel from '@model/portalModel';

class viewModel {
  submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      param.investments.map((investments) => {
        investments.investment = removeCommas(investments.investment);
      });
      if (companyInfoModel.companySize === 'TWO' && this.establishInvestment >= 1000000000) {
        runInAction(() => {
          portalModel.modalContent = ShareholderInvestmentModal.modalContent;
          portalModel.btn = ShareholderInvestmentModal.btn ?? null;
          portalModel.closeBtn = ShareholderInvestmentModal.closeBtn ?? true;
        });
        return;
      }
      stepService.saveStep(param);
    }
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get parValue() {
    return shareholderAndExecutiveInfoModel.parValue;
  }
  get shareholders() {
    return shareholderAndExecutiveInfoModel.shareholders;
  }
  get investments() {
    return shareholderAndExecutiveInfoModel.investments;
  }
  shareholdersName(idx) {
    let koreanName = '';
    this.shareholders.forEach((shareholders) => {
      if (shareholders.id === this.investments[idx].id) {
        koreanName = shareholders.koreanName;
      }
    });
    return koreanName;
  }
  setInvestment(e, idx) {
    shareholderAndExecutiveInfoModel.investments[idx].investment = onlyNumber(removeCommas(e.target.value));
  }
  get establishInvestment() {
    let sum = 0;
    runInAction(() => {
      shareholderAndExecutiveInfoModel.investments.forEach((investments, idx) => {
        const investment = investments.investment ? removeCommas(String(investments.investment)) : 0;
        sum = sum + Number(investment);
      });
    });
    return sum;
  }
  investmentPercent(idx) {
    let sum = this.establishInvestment;
    let investment = shareholderAndExecutiveInfoModel.investments[idx].investment
      ? removeCommas(String(shareholderAndExecutiveInfoModel.investments[idx].investment))
      : 0;
    if (investment === 0) {
      return investment;
    }
    if (sum === 0) {
      return sum;
    }
    return (Number(investment) / sum) * 100;
  }
  get text() {
    const formType = this.smartFormType;
    switch (formType) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return { name: '주주', parValue: '액면금' };
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return { name: '주주', parValue: '액면금' };
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return { name: '주주', parValue: '액면금' };
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return { name: '출자자(=사원)', parValue: '출자 1좌의 금액' };
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return { name: '출자자(=사원)' };
    }
  }
  validateFunc(idx) {
    const value = shareholderAndExecutiveInfoModel.investments[idx].investment;
    const parValue = this.parValue;
    if (value % parValue === 0) {
      return true;
    } else {
      return false;
    }
  }
}

export default new viewModel();
