import { observable, runInAction } from 'mobx';

const BoardMeetingSealProvidingStepModel = observable({
  directors: [],
  setBoardMeetingSealProviding(data) {
    runInAction(() => {
      if (data.directors && data.directors.length) {
        this.directors = data.directors.map((director, idx) => {
          return {
            id: director.id || '',
            name: director.name || '',
            sealProviderType: director.sealProviderType || '',
          };
        });
      } else {
        this.directors = [];
      }
    });
  },
});

export default BoardMeetingSealProvidingStepModel;
