import { addCommas } from '@common/module/replaceNumber';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import { observer } from 'mobx-react';

import { purposeType } from '../../constant';
import { 목적_추가금액_갯수_condition } from '../../store';
import useChargeGuide from './hook/useChargeGuide';
import st from './style.module.scss';

const AdditionalChargeGuide = observer(() => {
  const {
    recommendedTypeCounts,
    directTypeCounts,
    exceedRecommendTypeCounts,
    exceedDirectTypeCounts,
    exceedTotalCounts,
    calculateCharge,
  } = useChargeGuide();

  return (
    <Question>
      <ContentBox className={Qst.spaceBetween}>
        <div className={st.Modal}>
          <div className={st.title}>
            <span>목적 추가금액</span> 안내
          </div>
          <div className={st.text}>
            <p>
              ※ 추천 목적 {목적_추가금액_갯수_condition[purposeType.RECOMMENDED]}개, 직접입력 목적{' '}
              {목적_추가금액_갯수_condition[purposeType.DIRECT]}개 각각 초과 시 개당 5,500원의 추가비용이 발생합니다.
              <br />※ 법인설립 금액과 합산되어 마지막에 결제됩니다.
            </p>
          </div>
          <div className={st.chargeGuideWrapper}>
            <table>
              <thead>
                <tr>
                  <th className={st.leftTr}>분류</th>
                  <th>총 개수</th>
                  <th>초과 개수</th>
                  <th>개당 금액</th>
                  <th className={st.rightTr}>목적 추가금액</th>
                </tr>
              </thead>
              <tbody>
                {exceedRecommendTypeCounts > 0 && (
                  <tr>
                    <td className={st.purpose}>추천 목적</td>
                    <td className={st.exceedPurposeCounts}>
                      {recommendedTypeCounts}
                      <span className={st.suffix}>개</span>
                    </td>
                    <td className={st.exceedPurposeCounts}>
                      {exceedRecommendTypeCounts}
                      <span className={st.suffix}>개</span>
                    </td>
                    <td className={st.purposeCharge}>
                      {addCommas(5500)}
                      <span className={st.suffix}>원</span>
                    </td>
                    <td className={st.totalPurposeCharge}>
                      {addCommas(calculateCharge(exceedRecommendTypeCounts))}
                      <span className={st.suffix}>원</span>
                    </td>
                  </tr>
                )}
                {exceedDirectTypeCounts > 0 && (
                  <tr>
                    <td className={st.purpose}>직접입력 목적</td>
                    <td className={st.exceedPurposeCounts}>
                      {directTypeCounts}
                      <span className={st.suffix}>개</span>
                    </td>
                    <td className={st.exceedPurposeCounts}>
                      {exceedDirectTypeCounts}
                      <span className={st.suffix}>개</span>
                    </td>
                    <td className={st.purposeCharge}>
                      {addCommas(5500)}
                      <span className={st.suffix}>원</span>
                    </td>
                    <td className={st.totalPurposeCharge}>
                      {addCommas(calculateCharge(exceedDirectTypeCounts))}
                      <span className={st.suffix}>원</span>
                    </td>
                  </tr>
                )}
                <tr className={st.blueText}>
                  <td className={clsx(st.blueText, st.leftTr)} style={{ fontSize: '20px' }}>
                    합계
                  </td>
                  <td></td>
                  <td className={st.blueText}>
                    {exceedTotalCounts}
                    <span className={st.suffix}>개</span>
                  </td>
                  <td className={st.blueText}></td>
                  <td className={clsx(st.blueText, st.rightTr)}>
                    {addCommas(calculateCharge(exceedTotalCounts))}
                    <span className={st.suffix}>원</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ContentBox>
    </Question>
  );
});

export default AdditionalChargeGuide;
