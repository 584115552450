import { useObserver } from 'mobx-react';
import TextAnimation from 'react-animate-text';
import { Link } from 'react-router-dom';

import st from './FocusBox.module.scss';

function FocusBox(props) {
  const key = props.page.key;
  const question = props.page.question;
  const link = props.page.link;
  const checkPage = props.checkPage;

  let displayYn = false;

  if (key === checkPage) {
    displayYn = true;
  }

  return useObserver(() => (
    <>
      {displayYn ? (
        <>
          <div className={st.question}>
            <div className={st.left}>
              <img className={st.helpme} src="/images/pro_helpme.png" alt="헬프미 로고 이미지" />
              <TextAnimation charInterval="30">
                <div className={st.qText}>{question}</div>
              </TextAnimation>
            </div>
            <div className={st.right}>
              <button className={st.btnYes} onClick={props.btnYes}>
                예
              </button>
              {checkPage === 'start' ? (
                <button className={st.btnNo} onClick={props.btnSubmit} disabled={props.disabled}>
                  아니오, 확인 없이 곧바로 최종 제출하겠습니다.
                </button>
              ) : (
                <Link to={link} onClick={props.btnEdit}>
                  <button className={st.btnNo}>아니오, 수정하겠습니다.</button>
                </Link>
              )}
            </div>
          </div>
          {checkPage !== 'start' && <button className={st.btnBack} onClick={props.btnBack} />}
        </>
      ) : null}
    </>
  ));
}

export default FocusBox;
