import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import AddressVm from './AddressVm';
import Icon from '@comComponents/atoms/Icon/Icon';
import CloseBtn from '@comComponents/atoms/Button/CloseBtn/CloseBtn';

function Address(props) {
  return useObserver(() => (
    <>
      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
        <div className={Qst.infoSection}>
          {props.item.foreignerYn ? (
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>{AddressVm.displayKoreanName(props.item, props.origin)}</span>님의{' '}
                <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>와{' '}
                <span className={Qst.bold}>주소변경 일자</span>를 입력해 주세요.
              </h2>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
              </div>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 등기신청이 거절됩니다. (5~7일 더 걸림)
              </div>
            </div>
          ) : (
            <>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>{AddressVm.displayKoreanName(props.item, props.origin)}</span>님의{' '}
                  <span className={Qst.bold}>주민등록등본상 신규 주소</span>와{' '}
                  <span className={Qst.bold}>전입신고 일자</span>를 입력해 주세요.
                </h2>
              </div>
              <div className={Qst.addressBadgeTitle}>주민등록상 주소 확인방법</div>
              <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                <img
                  className={Qst.addressBadgeImg}
                  src="/images/person_address_example.png"
                  alt="주민등록상 도로명주소 예시"
                />
              </Badge>
              <div className={Qst.reportDateBadgeTitle}>전입신고 일자 확인방법</div>
              <Badge className={Qst.reportDateBadge} title="예시" type="example" border={false}>
                <img
                  className={Qst.reportDateBadgeImg}
                  src="/images/causedate_example.png"
                  alt="주민등록상 전입신고일자 예시"
                />
              </Badge>
              <div className={Qst.newAddressWrap}>
                <div className={Qst.title}>
                  법인등기부상 {AddressVm.displayKoreanName(props.item, props.origin)}님의 집주소 및 전입신고 일자
                </div>
                <div className={Qst.newAddressTable}>
                  <div className={Qst.address}>
                    <div className={Qst.addressTitle}>주민등록상 주소</div>
                    <div className={Qst.originAddress}>
                      <div>{props.item.originAddress}</div>
                      <div className={Qst.orignAddressExplain}>
                        법인등기부상 {AddressVm.displayKoreanName(props.item, props.origin)}님의 집주소
                      </div>
                    </div>
                  </div>
                  <div className={Qst.date}>
                    <div className={Qst.dateTitle}>전입신고 일자</div>
                    <div className={Qst.originCauseDate}>{props.item.originCauseDate} 전입</div>
                  </div>
                </div>
                <div className={Qst.newAddressExplain}>
                  {AddressVm.displayKoreanName(props.item, props.origin)}님의 주민등록상 주소가 마지막 등기된 때로부터{' '}
                  <span className={Qst.bold}>여러번 바뀌었다면 변동내역을 모두 입력</span>해 주세요. 법인등기부에는
                  대표님의 과거 주민등록상 집주소 변동내역이 모두 등기되어야 합니다. (최신주소만 입력해서 등기신청을
                  하는 경우, 보정명령이 내려짐)
                </div>
              </div>
            </>
          )}
          {props.item.newAddresses.map((item2, idx2) => {
            return (
              <div className={Qst.newAddressList} key={idx2}>
                <div className={Qst.newAddressTitle}>변동내역 {idx2 + 1}</div>
                <div className={Qst.newAddressBox}>
                  <div className={Qst.rowWrap}>
                    <InputText
                      className={Qst.inputText}
                      style={{ width: '380px' }}
                      explain="도로명 주소"
                      value={item2?.newAddress?.address || ''}
                      readOnly
                      onClick={() => props.parentVm.openAddressModal(true, props.idx, idx2)}
                      name={`${props.name}[${props.idx}].newAddresses[${idx2}].newAddress.address`}
                      errorText={
                        <Error
                          name={`${props.name}[${props.idx}].newAddresses[${idx2}].newAddress.address`}
                          value={item2?.newAddress?.address}
                          errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                        />
                      }
                    />
                    <SquareBtn onClick={() => props.parentVm.openAddressModal(true, props.idx, idx2)}>
                      도로명 주소 찾기
                    </SquareBtn>
                  </div>
                  <div className={Qst.rowWrap}>
                    <InputText
                      className={Qst.inputText}
                      style={{ width: '271px' }}
                      explain="상세 주소"
                      placeholder="상세주소 없을 시, [없음] 입력"
                      value={item2.detailAddress}
                      onChange={(e) => props.parentVm.setDetailAddress(e, props.idx, idx2)}
                      name={`${props.name}[${props.idx}].newAddresses[${idx2}].detailAddress`}
                      errorText={
                        <Error
                          name={`${props.name}[${props.idx}].newAddresses[${idx2}].detailAddress`}
                          value={item2.detailAddress}
                          errorCase={{
                            required: '상세 주소를 입력해 주세요.',
                            pattern: {
                              value: regExp.onlyKorNum_hyphen_comma().test(item2.detailAddress),
                              message: (
                                <span>
                                  임대차 계약서상 영문이 있다면, <br />
                                  한글로 옮겨적어주세요.
                                  <br />
                                  EX) B동 &gt; 비동
                                </span>
                              ),
                            },
                          }}
                        />
                      }
                    />
                    <div className={Qst.symbol}>
                      <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
                      <span
                        className={clsx(
                          Qst.symbolText,
                          AddressVm.bnameFontSizeChange(props.item, idx2) ? Qst.fontSize : null,
                        )}
                      >
                        {item2?.newAddress?.bname || '법정동'},&nbsp;
                      </span>
                      <InputText
                        style={{ width: '200px' }}
                        explain="건물명"
                        placeholder="없을 시, [없음] 입력"
                        value={item2.nameOfBuilding}
                        onChange={(e) => props.parentVm.setNameOfBuilding(e, props.idx, idx2)}
                        name={`${props.name}[${props.idx}].newAddresses[${idx2}].nameOfBuilding`}
                        errorText={
                          <Error
                            name={`${props.name}[${props.idx}].newAddresses[${idx2}].nameOfBuilding`}
                            value={item2.nameOfBuilding}
                            errorCase={{
                              required: '건물명을 입력해 주세요.',
                              pattern: {
                                value: regExp.onlyKorNum_hyphen_comma().test(item2.nameOfBuilding),
                                message: (
                                  <span>
                                    건물명이 영문인 경우, 한글
                                    <br />
                                    발음으로 옮겨적어 주세요. <br />
                                    EX) AB타워 &gt; 에이비 타워
                                  </span>
                                ),
                              },
                            }}
                          />
                        }
                      />
                      <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
                    </div>
                  </div>
                </div>
                <div className={Qst.causeDateBox}>
                  <div className={Qst.causeDateTitle}>{props.item.foreignerYn ? '주소변경 일자' : '전입신고 일자'}</div>
                  <div className={Qst.birthWrap} style={{ marginLeft: '0' }}>
                    <InputText
                      style={{ width: '130px' }}
                      placeholder="0000"
                      name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.year`}
                      value={item2.causeDate?.year}
                      onChange={(e) => props.parentVm.setYear(e, props.idx, idx2)}
                      maxLength="4"
                    />
                    <span className={Qst.birthText}>년</span>
                  </div>
                  <div className={Qst.birthWrap}>
                    <InputText
                      style={{ width: '108px' }}
                      placeholder="00"
                      name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.month`}
                      value={item2.causeDate?.month}
                      onChange={(e) => props.parentVm.setMonth(e, props.idx, idx2)}
                      maxLength="2"
                      onBlur={(e) => props.parentVm.monthBlur(e, props.idx, idx2)}
                    />
                    <span className={Qst.birthText}>월</span>
                  </div>
                  <div className={Qst.birthWrap}>
                    <InputText
                      style={{ width: '108px' }}
                      placeholder="00"
                      name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.day`}
                      value={item2.causeDate?.day}
                      onChange={(e) => props.parentVm.setDay(e, props.idx, idx2)}
                      maxLength="2"
                      onBlur={(e) => props.parentVm.dayBlur(e, props.idx, idx2)}
                    />
                    <span className={Qst.birthText}>일 {props.item.foreignerYn ? '변경' : '전입'}</span>
                  </div>
                </div>
                <div>
                  <Error
                    style={{ marginTop: '-27px' }}
                    name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.year`}
                    value={item2.causeDate?.year}
                    errorCase={{
                      required: '연도를 입력해 주세요.',
                      pattern: {
                        value: regExp.year().test(item2.causeDate?.year),
                        message: '올바른 연도를 입력해 주세요',
                      },
                    }}
                  />
                  <Error
                    style={{ marginTop: '-27px' }}
                    name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.month`}
                    value={item2.causeDate?.month}
                    errorCase={{
                      required: props.parentVm.displayBirthErrorMessage(props.idx, idx2, 'month')
                        ? '월을 입력해 주세요.'
                        : null,
                      pattern: {
                        value: props.parentVm.displayBirthErrorMessage(props.idx, idx2, 'month')
                          ? regExp.month().test(item2.causeDate?.month)
                          : null,
                        message: '올바른 월을 입력해 주세요',
                      },
                    }}
                  />
                  <Error
                    style={{ marginTop: '-27px' }}
                    name={`${props.name}[${props.idx}].newAddresses[${idx2}].causeDate.day`}
                    value={item2.causeDate?.day}
                    errorCase={{
                      required: props.parentVm.displayBirthErrorMessage(props.idx, idx2, 'day')
                        ? '일(날짜)을 입력해 주세요.'
                        : null,
                      pattern: {
                        value: props.parentVm.displayBirthErrorMessage(props.idx, idx2, 'day')
                          ? regExp.day().test(item2.causeDate?.day)
                          : null,
                        message: '올바른 일(날짜)을 입력해 주세요.',
                      },
                    }}
                  />
                </div>
                {idx2 !== 0 && (
                  <CloseBtn className={Qst.closeBtn} onClick={() => props.parentVm.deleteNewAddress(props.idx, idx2)} />
                )}
              </div>
            );
          })}
          <button
            type="button"
            className={Qst.newAddressAddBtn}
            onClick={() => props.parentVm.addNewAddress(props.idx)}
          >
            {props.item.foreignerYn ? '거소신고 사실증명상 도로명 주소' : '주민등록상 집주소'}{' '}
            <span className={Qst.bold}>변동내역 추가</span>하기
            <Icon icon="addGray" className={Qst.addGray} />
          </button>
        </div>
      </div>
    </>
  ));
}

export default Address;
