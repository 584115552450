import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import equal from '../../asset/icon/ico_equal.svg';
import arrow from '../../asset/icon/ico_arrow.svg';
import plusGray from '../../asset/icon/ico_plus_2.svg';
import plusBlack from '../../asset/icon/ico_plus_black.svg';
import mul from '../../asset/icon/ico_mul.svg';
import result from '../../asset/icon/ico_result.svg';
import won from '../../asset/icon/ico_won.svg';
import q from '../../asset/icon/Q.png';
import addGray from '../../asset/icon/btn_add_gray.svg';
import error from '../../asset/icon/ico_error.svg';
import png from '../../asset/icon/ico_png.svg';
import jpg from '../../asset/icon/ico_jpg.svg';
import pdf from '../../asset/icon/ico_pdf.svg';
import hwp from '../../asset/icon/ico_hwp.svg';
import docx from '../../asset/icon/ico_docx.svg';
import xlsx from '../../asset/icon/ico_xlsx.svg';
import copy from '../../asset/icon/icon_copy.svg';
import revise from '../../asset/icon/icon_revise.svg';
import equalThan from '../../asset/icon/equal_than.svg';
import less from '../../asset/icon/less.svg';
import greater from '../../asset/icon/greater.svg';
import plusCircle from '../../asset/icon/plus_circle.svg';
import arrowRight from '../../asset/icon/arrowRight.svg';
import lightArrow from '../../asset/icon/lightArrow.svg';
import lightArrowBlack from '../../asset/icon/lightArrow_black.svg';
import greater_large from '../../asset/icon/greater_large.svg';
import explainArrow from '../../asset/icon/explainArrow.svg';

const Icon = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 16px;
`;

function IconText(props) {
  return (
    <>
      {props.icon === 'equal' ? <Icon src={equal} className={props.className} style={props.style} alt="&#61;" /> : ''}
      {props.icon === 'arrow' ? <Icon src={arrow} className={props.className} style={props.style} alt="&rarr;" /> : ''}
      {props.icon === 'plusGray' ? (
        <Icon src={plusGray} className={props.className} style={props.style} alt="&#43;" />
      ) : (
        ''
      )}
      {props.icon === 'plusBlack' ? (
        <Icon src={plusBlack} className={props.className} style={props.style} alt="&#43;" />
      ) : (
        ''
      )}
      {props.icon === 'mul' ? <Icon src={mul} className={props.className} style={props.style} alt="&times;" /> : ''}
      {props.icon === 'result' ? <Icon src={result} className={props.className} style={props.style} alt="&#61;" /> : ''}
      {props.icon === 'won' ? <Icon src={won} className={props.className} style={props.style} alt="&#8361;" /> : ''}
      {props.icon === 'q' ? <Icon src={q} className={props.className} style={props.style} alt="Q." /> : ''}
      {props.icon === 'addGray' ? (
        <Icon src={addGray} className={props.className} style={props.style} alt="&#43;" />
      ) : (
        ''
      )}
      {props.icon === 'error' ? <Icon src={error} className={props.className} style={props.style} alt="경고" /> : ''}
      {props.icon === 'png' ? <Icon src={png} className={props.className} style={props.style} alt="png파일" /> : ''}
      {props.icon === 'jpg' ? <Icon src={jpg} className={props.className} style={props.style} alt="jpg파일" /> : ''}
      {props.icon === 'pdf' ? <Icon src={pdf} className={props.className} style={props.style} alt="pdf파일" /> : ''}
      {props.icon === 'hwp' ? <Icon src={hwp} className={props.className} style={props.style} alt="hwp파일" /> : ''}
      {props.icon === 'docx' ? <Icon src={docx} className={props.className} style={props.style} alt="docx파일" /> : ''}
      {props.icon === 'xlsx' ? <Icon src={xlsx} className={props.className} style={props.style} alt="xlsx파일" /> : ''}
      {props.icon === 'copy' ? (
        <Icon src={copy} className={props.className} style={props.style} alt="전체복사아이콘" />
      ) : (
        ''
      )}
      {props.icon === 'revise' ? (
        <Icon src={revise} className={props.className} style={props.style} alt="수정아이콘" />
      ) : (
        ''
      )}
      {props.icon === 'equalThan' ? (
        <Icon src={equalThan} className={props.className} style={props.style} alt="&#61;" />
      ) : (
        ''
      )}
      {props.icon === 'less' ? (
        <Icon src={less} className={props.className} style={props.style} alt="미만아이콘" />
      ) : (
        ''
      )}
      {props.icon === 'greater' ? (
        <Icon src={greater} className={props.className} style={props.style} alt="초과아이콘" />
      ) : (
        ''
      )}
      {props.icon === 'plusCircle' ? (
        <Icon src={plusCircle} className={props.className} style={props.style} alt="&#43;" />
      ) : (
        ''
      )}
      {props.icon === 'arrowRight' ? (
        <Icon src={arrowRight} className={props.className} style={props.style} alt="&#62;" />
      ) : (
        ''
      )}
      {props.icon === 'lightArrow' ? (
        <Icon src={lightArrow} className={props.className} style={props.style} alt="&rarr;" />
      ) : (
        ''
      )}
      {props.icon === 'lightArrowBlack' ? (
        <Icon src={lightArrowBlack} className={props.className} style={props.style} alt="&rarr;" />
      ) : (
        ''
      )}
      {props.icon === 'greater_large' ? (
        <Icon src={greater_large} className={props.className} style={props.style} alt="&rarr;" />
      ) : (
        ''
      )}
      {props.icon === 'explainArrow' ? (
        <Icon src={explainArrow} className={props.className} style={props.style} alt="&rarr;" />
      ) : (
        ''
      )}
    </>
  );
}

IconText.propTypes = {
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * icon 타입
   */
  icon: PropTypes.oneOf([
    'equal',
    'arrow',
    'plusGray',
    'plusBlack',
    'mul',
    'result',
    'won',
    'q',
    'addGray',
    'error',
    'png',
    'jpg',
    'pdf',
    'hwp',
    'docx',
    'xlsx',
    'copy',
    'revise',
    'equalThan',
    'less',
    'greater',
    'plusCircle',
    'arrowRight',
    'lightArrow',
    'lightArrowBlack',
    'greater_large',
    'explainArrow',
  ]),
  /**
   * icon 너비
   */
  width: PropTypes.string,
  /**
   * icon 높이
   */
  height: PropTypes.string,
};

IconText.defaultProps = {
  className: null,
  style: {},
  icon: null,
  width: 'auto',
  height: 'auto',
};

export default IconText;
