import { useObserver } from 'mobx-react';
import ExecutiveMemberChangeCheckVm from './ExecutiveMemberChangeCheckVm';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './ExecutiveMemberChangeCheck.module.scss';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Icon from '@comComponents/atoms/Icon/Icon';
import clsx from 'clsx';

function ExecutiveMemberChangeCheck() {
  return useObserver(() => (
    <form onSubmit={(e) => ExecutiveMemberChangeCheckVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={st.titleWrap}>
            {ExecutiveMemberChangeCheckVm.standbyFormYn ? (
              <h2 className={st.title}>
                아래는 고객님이 <span className={st.bold}>스탠바이</span>에서 입력하신 내용입니다.
              </h2>
            ) : (
              <h2 className={st.title}>
                아래는 고객님이 <span className={st.bold}>견적 단계에서 답변</span>해주신 내용입니다.
              </h2>
            )}

            <div className={st.executiveMemberChange}>
              <div className={st.header}>
                <div className={st.exist}>기존 등기부</div>
                <div className={st.new}>신규 등기부</div>
              </div>
              <div className={st.info}>
                {ExecutiveMemberChangeCheckVm.originExecutiveMembers.map((executiveMember, idx) => {
                  return (
                    <div key={idx} className={clsx(st.infoRow, idx % 2 === 0 ? st.even : st.odd)}>
                      <div className={clsx(st.originInfo, ExecutiveMemberChangeCheckVm.setFontSize(idx))}>
                        {executiveMember.originPosition && (
                          <>
                            <Icon className={st.arrow} icon="arrowRight" />
                            <div className={st.basicInfo}>
                              <div>
                                {executiveMember.koreanName} {executiveMember.originPosition}님
                              </div>
                            </div>
                          </>
                        )}
                        {executiveMember.englishName && (
                          <div className={st.englishName}>({executiveMember.englishName})</div>
                        )}
                      </div>
                      <div className={st.newInfo}>
                        <div className={st.changeTypeAndBasicInfo}>
                          <div className={st.changeType}>
                            {executiveMember.changeTypes?.map((type, idx2) => {
                              return (
                                <div
                                  key={idx2}
                                  className={ExecutiveMemberChangeCheckVm.setClassNameAndTitle(idx, idx2).className}
                                >
                                  {ExecutiveMemberChangeCheckVm.setClassNameAndTitle(idx, idx2).title}
                                </div>
                              );
                            })}
                          </div>
                          <div
                            className={clsx(
                              st.basicInfo,
                              ExecutiveMemberChangeCheckVm.setClassName(idx),
                              ExecutiveMemberChangeCheckVm.setFontSize(idx),
                            )}
                          >
                            {executiveMember.koreanName}{' '}
                            {executiveMember.newPosition ? executiveMember.newPosition : executiveMember.originPosition}
                            님
                          </div>
                        </div>
                        {ExecutiveMemberChangeCheckVm.changeTypes(idx).includes('보직변경') && (
                          <div className={st.changePosition}>
                            <div className={st.origin}>{executiveMember.originPositionTag}</div>
                            <Icon className={st.lightArrow} icon="lightArrow" />
                            <div className={st.new}>{executiveMember.newPositionTag}</div>
                          </div>
                        )}
                        {ExecutiveMemberChangeCheckVm.descriptionType(idx) === 'CASE1' && (
                          <div className={st.description}>
                            등기부상 명칭이{' '}
                            <span className={st.positionOnRegister}>{executiveMember.originPositionOnRegister}</span>
                            <Icon className={st.lightArrow} icon="lightArrowBlack" />
                            <span className={st.positionOnRegister}>
                              {executiveMember.newPositionOnRegister}
                            </span>로 변경되고 대표직위는 유지됩니다. (다른 {ExecutiveMemberChangeCheckVm.text}가 모두
                            퇴직하기 때문)
                          </div>
                        )}
                        {ExecutiveMemberChangeCheckVm.descriptionType(idx) === 'CASE2' && (
                          <div className={st.description}>
                            등기부상 명칭이{' '}
                            <span className={st.positionOnRegister}>{executiveMember.originPositionOnRegister}</span>
                            <Icon className={st.lightArrow} icon="lightArrowBlack" />
                            <span className={st.positionOnRegister}>
                              {executiveMember.newPositionOnRegister}
                            </span>로 변경되고 대표직위는 유지됩니다. (새로운 {ExecutiveMemberChangeCheckVm.text}님이
                            취임하기 때문) 다만, 대표님도 법원 절차상 전자서명 또는 인감증명서 제공이 필요합니다.
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </ContentBox>
      </Question>
      <Question>
        <ContentBox>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>위 내용대로 진행하는 것이 맞나요?</span>
                </h2>
              </div>
              <ul className={Qst.answerList}>
                <li>
                  <Radio
                    className={Qst.answerLabel}
                    value={true}
                    activeBackground
                    checked={ExecutiveMemberChangeCheckVm.keepProceedYn === true}
                    onChange={(e) => ExecutiveMemberChangeCheckVm.setKeepProceedYn(e)}
                    name="keepProceedYn"
                  >
                    네. 위 내용대로 진행합니다.
                  </Radio>
                </li>
                <li>
                  <Radio
                    className={Qst.answerLabel}
                    value={false}
                    activeBackground
                    checked={ExecutiveMemberChangeCheckVm.keepProceedYn === false}
                    onChange={(e) => ExecutiveMemberChangeCheckVm.setKeepProceedYn(e)}
                    name="keepProceedYn"
                  >
                    아니오. 위 내용과 다르게 진행합니다.
                  </Radio>
                </li>
              </ul>
              <Error
                name="keepProceedYn"
                value={ExecutiveMemberChangeCheckVm.keepProceedYn}
                errorCase={{ required: '진행하실 방법을 선택해주세요.' }}
              />
            </div>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}

export default ExecutiveMemberChangeCheck;
