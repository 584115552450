import { observable, runInAction } from 'mobx';

const HeadOfficeRelocationStepModel = observable({
  address: {},
  detailAddress: '',
  nameOfBuilding: '',
  causeDateYear: '',
  causeDateMonth: '',
  causeDateDay: '',
  setHeadOfficeAddressRelocation(data) {
    runInAction(() => {
      this.address = data?.address ?? {};
      this.detailAddress = data?.detailAddress ?? '';
      this.nameOfBuilding = data?.nameOfBuilding ?? '';
      if (data?.causeDate) {
        this.causeDateYear = data.causeDate.split('년')[0];
        this.causeDateMonth = data.causeDate.split('년')[1].split('월')[0];
        this.causeDateDay = data.causeDate.split('월')[1].replace('일', '');
      } else {
        this.causeDateYear = '';
        this.causeDateMonth = '';
        this.causeDateDay = '';
      }
    });
  },
});

export default HeadOfficeRelocationStepModel;
