import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import AnnounceMethodStepVm from './AnnounceMethodStepVm';

function AnnounceMethodStep(props) {
  return useObserver(() => (
    <>
      {props.step.hasHomepageYn && (
        <div className={Ast.row}>
          <div className={Ast.dName}>홈페이지 주소</div>
          <div className={Ast.data}>
            {props.step.homepageUrl ? (
              <CopyBtn onClick={() => copyText(props.step.homepageUrl)}>{props.step.homepageUrl}</CopyBtn>
            ) : (
              '-'
            )}
          </div>
        </div>
      )}
      <div className={Ast.row}>
        <div className={Ast.dName}>공고 신문사</div>
        <div className={Ast.data}>
          {props?.step?.newspaperCompany ? (
            <CopyBtn onClick={() => copyText(props.step.newspaperCompany)}>{props.step.newspaperCompany}</CopyBtn>
          ) : (
            '-'
          )}
        </div>
      </div>
      <div className={Ast.row}>
        <div className={Ast.dName}></div>
        <div className={Ast.data}>
          <CopyBtn onClick={() => copyText(AnnounceMethodStepVm.공고방법Text(props.step))}>[등기사유 복사]</CopyBtn>
        </div>
      </div>
    </>
  ));
}
export default AnnounceMethodStep;
