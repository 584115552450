import finalCheckService from '@service/changeRegistration/finalCheckService';
import finalCheckModel from '@model/changeRegistration/finalCheckModel';
class AfterFinalCheckVm {
  loadFinalCheck() {
    finalCheckService.load();
  }
  get submittedAt() {
    return finalCheckModel.submittedAt;
  }
  get isResponse() {
    return finalCheckModel.isResponse;
  }
  get steps() {
    return finalCheckModel.steps;
  }
}
export default new AfterFinalCheckVm();
