import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import StandByUpdateVm from './StandByUpdateVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandByUpdate.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Qna from '@comComponents/molecules/QnaBox/QnaBox';
import QText from '@comComponents/atoms/Text/QuestionText/QuestionText';
import Answer from '@comComponents/atoms/Text/SymbolText/SymbolText';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import HyperLink from '@comComponents/atoms/HyperLink/HyperLink/HyperLink';

function StandByUpdate() {
  return useObserver(() => (
    <form onSubmit={(e) => StandByUpdateVm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <div className={st.standByUpdate}>
        <span className={st.bold}>스탠바이 헬프미 이용고객</span>을 위한 서비스
      </div>
      <Question>
        <ContentBox className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
          <div className={Qst.infoSection}>
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                스탠바이 헬프미에 <span className={Qst.bold}>변경등기</span> 내용을 자동{' '}
                <span className={Qst.bold}>업데이트</span> 해드릴까요?
              </h2>
            </div>
            <ul className={Qst.answerList}>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={true}
                  activeBackground
                  checked={StandByUpdateVm.updateYn === true}
                  onChange={(e) => StandByUpdateVm.setUpdateYn(e)}
                  name="updateYn"
                  style={{ letterSpacing: '-1.2px' }}
                >
                  네. 업데이트 해주세요.
                  <div className={st.radioLabelExplain}>
                    (
                    <HyperLink
                      color="blue"
                      title="스탠바이 헬프미 업데이트를 위한 제3자 정보제공 동의로 이동"
                      href="https://docs.google.com/document/d/17xVr-2wVu5_bTeRZfnAvzb0gTYKWAZ8uRcPhHPayo5A/edit"
                      target="_blank"
                    >
                      스탠바이 헬프미 업데이트를 위한 제3자 정보제공 동의
                    </HyperLink>{' '}
                    포함)
                  </div>
                </Radio>
              </li>
              <li>
                <Radio
                  className={Qst.answerLabel}
                  value={false}
                  activeBackground
                  checked={StandByUpdateVm.updateYn === false}
                  onChange={(e) => StandByUpdateVm.setUpdateYn(e)}
                  name="updateYn"
                >
                  아니오.
                </Radio>
              </li>
            </ul>
            <Error
              name="updateYn"
              value={StandByUpdateVm.updateYn}
              errorCase={{ required: '자동 업데이트 여부를 선택해 주세요.' }}
            />
          </div>
          <div className={Qst.qnaSection}>
            <Qna tip="자동 업데이트 안내" className={Qst.qnaTitle}>
              <div className={Qst.qnaBox}>
                <QText>자동 업데이트를 신청하면 어떻게 되나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>
                    헬프미에서 변경하신 등기부, 정관, 주주명부의 모든 정보를 스탠바이 헬프미에 자동으로 등록해드려요.
                    법인관리를 원스톱으로 끝낼 수 있도록 도와드립니다.
                  </Answer>
                </div>
              </div>
              <div className={Qst.qnaBox}>
                <QText>자동 업데이트를 매번 신청해야 하나요?</QText>
                <div className="answerBox">
                  <Answer className={Qst.answer}>아니요. 한번만 신청하면 앞으로 계속 적용됩니다.</Answer>
                  <p className={Qst.explain}>
                    만약 자동 업데이트를 원하지 않으시면 우측 하단 채널톡을 통해 담당 매니저에게 말씀해주세요.
                  </p>
                </div>
              </div>
            </Qna>
          </div>
        </ContentBox>
      </Question>
    </form>
  ));
}
export default StandByUpdate;
