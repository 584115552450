import iconDown from '@common/components/asset/icon/ico_down_blue.svg';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import { useState } from 'react';

import ConsonantGroup from './components/ConsonantGroup';
import Group from './components/Group';
import useAllPurposeGroupsSelect from './hook/useAllPurposeGroupsSelect';
import useConsonantCategory from './hook/useConsonantCategory';
import st from './style.module.scss';

const AllPurposesModal = observer(() => {
  const { headers, results, groupSelectMob } = useAllPurposeGroupsSelect();
  const { scrollToByConsonant, consonantVisibilityRef, consonantGroupRefs, contentRef, consonantMob } =
    useConsonantCategory({ headers });

  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className={st.container}>
      <div className={st.header}>
        <div className={st.title}>추천 목적 전체보기</div>
        <div className={st.category}>
          <div className={st.itemsWrap}>
            {headers.map((item) => (
              <button
                key={item.consonant}
                className={clsx(st.item, consonantMob.consonant === item.consonant && st.active)}
                onClick={() => scrollToByConsonant(item.consonant)}
              >
                {`${item.consonant} (${item.count})`}
              </button>
            ))}
          </div>
          <button type="button" className={st.button} onClick={() => setIsExpanded(!isExpanded)}>
            {`전체 ${isExpanded ? '접기' : '펼치기'}`}
            <img src={iconDown} alt="arrow" className={clsx(st.icon, !isExpanded && st.rotate)} />
          </button>
        </div>
      </div>
      <div className={st.content} id="all-purpose-groups-container" ref={contentRef}>
        {results.map((item, index) => {
          return (
            <ConsonantGroup
              key={item.consonant}
              className={st.consonantGroup}
              ref={(el) => (consonantGroupRefs.current[item.consonant] = el)}
              index={index}
              consonant={item.consonant}
              consonantVisibility={consonantVisibilityRef.current}
            >
              {item.purposeGroups.map((group, index) => {
                return (
                  <Group
                    key={`${group.groupNames.join()}_${index}`}
                    groupNames={group.groupNames}
                    purposes={group.purposes}
                    isExpanded={isExpanded}
                    isSelected={groupSelectMob.checkGroupSelected(group.groupNames)}
                    onClick={() => groupSelectMob.toggleGroup(group.groupNames)}
                  />
                );
              })}
            </ConsonantGroup>
          );
        })}
      </div>
      <button type="button" className={st.submit} onClick={() => groupSelectMob.submit()}>
        선택완료
      </button>
    </div>
  );
});

export default AllPurposesModal;
