import { runInAction, observable } from 'mobx';
import StandbyPaidInCapitalIncreaseStepModel from '@model/changeRegistration/StandbyPaidInCapitalIncreaseStepModel';
import stepService from '@service/stepService';
import { onlyNumber } from '@common/module/replaceNumber';
import regExp from '@common/module/regExp';
import formDataToJson, { errorFocus, findPos } from '@common/module/submit';
import { _alert, _confirm } from '@model/dialogModel';
import josa from '@common/module/josa';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';

class viewModel {
  constructor() {
    this.state = observable({
      openIdx: null,
      errorArr: [],
    });
  }
  errorState() {
    const error = document.querySelectorAll('[data-error="error"]');
    let errorIdx = [];
    error.forEach((error) => {
      errorIdx.push(Number(error.closest('[data-eq]').dataset.eq));
    });
    errorIdx = errorIdx.filter((elem, idx) => {
      return errorIdx.indexOf(elem) === idx;
    });
    this.state.errorArr = errorIdx;
  }
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target, false).error) {
        //입력하지 않은 데이터가 있는 경우
        this.errorState();
        this.state.openIdx = this.state.errorArr[0];
        const findError = setInterval(() => {
          if (document.querySelector('.open')) {
            clearInterval(findError);
            errorFocus();
          }
        }, 30);
        return;
      } else {
        this.state.errorArr = [];
        this.state.openIdx = null;
        //올바를 데이터를 입력한 경우
        let param = formDataToJson(e.target).data;
        param.newSharesAcquirers.forEach((newSharesAcquirer, idx) => {
          if (newSharesAcquirer.shareholderType === 'FOREIGNER_PERSON') {
            param.newSharesAcquirers[idx].birth =
              newSharesAcquirer.year + '년' + newSharesAcquirer.month + '월' + newSharesAcquirer.day + '일';
            delete newSharesAcquirer.year;
            delete newSharesAcquirer.month;
            delete newSharesAcquirer.day;
          }
        });
        stepService.saveStep(param);
      }
    });
  }
  get NewSharesAcquirerDefaultValue() {
    var val = {
      year: '',
      month: '',
      day: '',
      detailAddress: '',
      englishName: '',
      foreignerCompanyRepresentatives: [
        {
          id: null,
          englishName: '',
          koreanName: '',
        },
      ],
      id: '',
      koreanAddress: '',
      englishAddress: '',
      foreignAddress: {
        englishAddress: '',
        koreanAddress: '',
      },
      koreanCompanyRepresentatives: [
        {
          id: null,
          name: '',
          position: '',
        },
      ],
      koreanName: '',
      kssn: '',
      nameOfBuilding: '',
      nationalityOthers: '',
      nationalityType: '',
      residenceRegistrationYn: null,
      shareholderType: '',
      resourceType: null,
      existingShareholdersYn: null,
      representatives: [
        {
          id: null,
          name: '',
        },
      ],
    };
    return val;
  }
  addNewSharesAcquirers() {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers.push(this.NewSharesAcquirerDefaultValue);
      this.state.openIdx = this.newSharesAcquirers.length - 1;
      const newPerson = setInterval(() => {
        clearInterval(newPerson);
        if (this.state.openIdx !== 0) {
          let top = findPos(document.querySelector('.open')) - 255;
          window.scroll(0, top);
        }
      }, 30);
    });
  }
  get newSharesAcquirers() {
    return StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers;
  }
  setTitle(idx) {
    const newSharesAcquirer = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers;
    let name = newSharesAcquirer[idx].koreanName; //이름
    let national = newSharesAcquirer[idx].shareholderType; //국적
    switch (national) {
      case 'KOREAN_PERSON':
        national = '한국인';
        break;
      case 'FOREIGNER_PERSON':
        national = '외국인';
        break;
      case 'KOREAN_COMPANY':
        national = '한국법인';
        break;
      case 'FOREIGNER_COMPANY':
        national = '외국법인';
        break;
      case 'FUND_AND_INVESTMENT_ASSOCIATION':
        national = '펀드/투자조합';
        break;
    }
    let title = '';
    if (!name && !national) {
      //이름, 국적 모두 없는 경우
      title = <span>신주인수인(=투자자)님이 되실 분의 정보를 입력해 주세요.</span>;
    } else if (!name) {
      //이름 없는 경우
      title = '신주인수인' + ' : ' + national;
    } else if (!national) {
      //국적 없는 경우
      title = name + ' : ' + '국적을 선택해 주세요';
    } else {
      title = name + ' : ' + national;
    }
    return title;
  }
  setIsOpen(idx) {
    return this.state.openIdx === idx;
  }
  setDropOpen(idx) {
    if (this.state.openIdx === idx) {
      this.state.openIdx = null;
      this.errorState();
    } else {
      this.state.openIdx = idx;
    }
  }
  personErrorYn(idx) {
    return this.state.errorArr.indexOf(idx) !== -1;
  }
  async setCloseBtn(idx) {
    const newSharesAcquirer = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers;
    let name = newSharesAcquirer[idx].koreanName;
    if (!name) {
      name = '해당 신주인수인';
    }
    let confString = name + '님의 정보를 삭제하시겠습니까?';
    const conf = await _confirm(confString);
    if (newSharesAcquirer.length === 1) {
      if (conf) {
        runInAction(() => {
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers = [];
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers.push(this.NewSharesAcquirerDefaultValue);
          this.state.openIdx = null;
        });
      } else {
        return;
      }
    } else {
      if (conf) {
        return newSharesAcquirer.splice(idx, 1);
      } else {
        return;
      }
    }
  }
  setShareholderType(e, idx) {
    //사람(법인) 선택
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].shareholderType = e.target.value;
    });
  }
  setExistingShareholdersYn(e, idx) {
    //기존 주주
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].existingShareholdersYn = e.target.value === 'true';
    });
  }
  setResourceType(e, idx) {
    //신주종류
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].resourceType = e.target.value;
    });
  }
  //한국인
  setKoreanName(e, idx) {
    //성함 입력
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanName = e.target.value;
    });
  }
  setKssn(e, idx) {
    //주민등록번호 입력
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].kssn = e.target.value;
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanAddress = e.target.value;
    });
  }
  //외국인
  residenceRegistrationYn(idx) {
    return StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].residenceRegistrationYn;
  }
  setResidenceRegistrationYn(e, idx) {
    //거소등록 여부
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].residenceRegistrationYn = e.target.value === 'true';
    });
  }
  setNationalityType(e, idx) {
    //국적 선택
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    //국적 입력
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].nationalityOthers = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    //로마자 성함 입력
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].englishName = e.target.value;
    });
  }
  setYear(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].year = onlyNumber(e.target.value);
    });
  }
  setMonth(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].month = onlyNumber(e.target.value);
    });
  }
  monthBlur(e, idx) {
    let month = e.target.value;
    if (month && month.length === 1) {
      e.target.value = '0' + month;
    }
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].month = e.target.value;
    });
  }
  setDay(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].day = onlyNumber(e.target.value);
    });
  }
  dayBlur(e, idx) {
    let day = e.target.value;
    if (day && day.length === 1) {
      e.target.value = '0' + day;
    }
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].day = e.target.value;
    });
  }
  displayBirthErrorMessage(idx, type) {
    const year = this.newSharesAcquirers[idx].year;
    const month = this.newSharesAcquirers[idx].month;

    if (type === 'month') {
      if (year && regExp.year().test(year)) {
        //연도가 제대로 입력되었을 때, 월에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    } else if (type === 'day') {
      if (year && regExp.year().test(year) && month && regExp.month().test(month)) {
        // 연도, 월이 제대로 입력 되었을 때, 일(날짜)에 대한 error 메시지
        return true;
      } else {
        return false;
      }
    }
  }
  setEnglishAddress(e, idx) {
    //거소등록X 로마자 주소
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].englishAddress = e.target.value;
    });
  }
  //한국법인
  addKoreanCompanyRepresentatives(idx) {
    if (StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[0].position) {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position:
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[0].position,
      });
    } else {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.push({
        id: null,
        name: '',
        position: '',
      });
    }
  }
  koreanCompanyRepresentatives_position(e, idx) {
    //직책
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives.forEach(
        (item, idx2) => {
          StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[idx2].position =
            e.target.value;
        },
      );
    });
  }
  koreanCompanyRepresentatives_name(e, idx, idx2) {
    //성함
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives[idx2].name =
        e.target.value;
    });
  }
  async setKoreanCompanyRepresentativesCloseBtn(idx, idx2) {
    const koreanCompanyRepresentatives =
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].koreanCompanyRepresentatives;
    let name = koreanCompanyRepresentatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return koreanCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  //외국법인
  foreignerCompanyRepresentatives_englishName(e, idx, idx2) {
    //대표자 로마자 성함
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignerCompanyRepresentatives[idx2].englishName =
        e.target.value;
    });
  }
  foreignerCompanyRepresentatives_koreanName(e, idx, idx2) {
    //대표자 한글 성함
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignerCompanyRepresentatives[idx2].koreanName =
        e.target.value;
    });
  }
  foreignerCompanyRepresentatives(idx) {
    StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignerCompanyRepresentatives.push({
      id: null,
      englishName: '',
      koreanName: '',
    });
  }
  setforeignAddress_englishAddress(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignAddress.englishAddress = e.target.value;
    });
  }
  setforeignAddress_koreanAddress(e, idx) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignAddress.koreanAddress = e.target.value;
    });
  }
  async setForeignerCompanyRepresentativesCloseBtn(idx, idx2) {
    const foreignerCompanyRepresentatives =
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].foreignerCompanyRepresentatives;
    let name = foreignerCompanyRepresentatives[idx2].koreanName;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return foreignerCompanyRepresentatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  //펀드투자조합
  setRepresentativesName(e, idx, idx2) {
    runInAction(() => {
      StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives[idx2].name = e.target.value;
    });
  }
  addRepresentatives(idx) {
    StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives.push({
      id: '',
      name: '',
    });
  }
  async setRepresentativesCloseBtn(idx, idx2) {
    const representatives = StandbyPaidInCapitalIncreaseStepModel.newSharesAcquirers[idx].representatives;
    let name = representatives[idx2].name;
    if (!name) {
      name = '해당 대표';
    }
    const conf = await _confirm(name + '님의 정보를 삭제하시겠습니까?');
    if (conf) {
      return representatives.splice(idx2, 1);
    } else {
      return;
    }
  }
  displayKoreanName(type, name) {
    if (type === 'KOREAN_PERSON' || type === 'FOREIGNER_PERSON') {
      if (name) {
        return (
          <span>
            <span className={Qst.bold}>{name}님</span>은
          </span>
        );
      } else {
        return (
          <span>
            <span className={Qst.bold}>신주인수인님</span>은
          </span>
        );
      }
    } else {
      if (name) {
        let _name = josa(name, '은는');
        let nameJosa = _name.charAt(_name.length - 1);
        return (
          <span>
            <span className={Qst.bold}>{name}</span>
            {nameJosa}
          </span>
        );
      } else {
        return (
          <span>
            <span className={Qst.bold}>신주인수인님</span>은
          </span>
        );
      }
    }
  }
}

export default new viewModel();
