import st from './ExecutiveMemberChangeStep.module.scss';
import smartformAppModel from '@model/smartformAppModel';
import finalCheckModel from '@model/changeRegistration/finalCheckModel';

class ExecutiveMemberChangeStepVm {
  setClassNameAndTitle(changeType) {
    let _title = '';
    let _className = null;
    switch (changeType) {
      case '임기연장':
        _title = '임기연장';
        _className = st.blue;
        break;
      case '보직변경':
        _title = '보직변경';
        _className = st.blue;
        break;
      case '집주소변경':
        _title = '집주소변경';
        _className = st.blue;
        break;
      case '사임_퇴임':
        _title = '사임/퇴임';
        _className = st.red;
        break;
      case '신규':
        _title = '신규';
        _className = st.green;
        break;
      default:
        _title = '변경없음';
        _className = st.gray;
        break;
    }
    return {
      className: _className,
      title: _title,
    };
  }
  displayAddress(data) {
    const changeTypeList = data.changeTypes;
    const representativeYn = data.representativeYn;
    const addressSameYn = data.addressSameYn;
    const newAddresses = data.newAddresses;
    if (representativeYn) {
      if (changeTypeList.includes('보직변경')) {
        if (newAddresses.length > 1) {
          //변경된 주소 여려개인 경우
          return '변경있음 - '; //아래 displayDetailAddress에서 자세한 주소 표시
        } else if (newAddresses.length === 1) {
          //변경된 주소가 1개인 경우
          if (newAddresses[0].addressCauseDate) {
            return `변경있음 - [${newAddresses[0].addressCauseDate} ${newAddresses[0].newAddress}]`;
          } else {
            return `변경있음 - [${newAddresses[0].newAddress}]`;
          }
        }
        if (addressSameYn) {
          return '변경없음';
        }
      }
      if (addressSameYn) {
        return '변경없음';
      } else {
        if (newAddresses.length > 1) {
          //변경된 주소 여려개인 경우
          return '변경있음 - '; //아래 displayDetailAddress에서 자세한 주소 표시
        } else if (newAddresses.length === 1) {
          //변경된 주소가 1개인 경우
          if (newAddresses[0].addressCauseDate) {
            return `변경있음 - [${newAddresses[0].addressCauseDate} ${newAddresses[0].newAddress}]`;
          } else {
            return `변경있음 - [${newAddresses[0].newAddress}]`;
          }
        }
      }
    }
  }
  displayDetailAddress(data) {
    const changeTypeList = data.changeTypes;
    const representativeYn = data.representativeYn;
    const addressSameYn = data.addressSameYn;
    const newAddresses = data.newAddresses;
    if (representativeYn) {
      if (changeTypeList.includes('보직변경') || !addressSameYn) {
        if (newAddresses.length > 1) {
          //변경된 주소가 여러개인 경우(위의 displayAddress 함수에서 단순 '변경없음'인 경우)
          return true;
        }
      }
    }
  }
  displayNewRepresentative(data) {
    let newCnt = 0;
    data.executiveMembers.forEach((item) => {
      if (item.newYn) {
        newCnt++;
      }
    });
    if (newCnt > 0) {
      return true;
    } else {
      return false;
    }
  }
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  farmerFisherText() {
    const smartFormType = this.smartFormType;
    if (smartFormType === 'AGRICULTURAL_STOCK_COMPANY') {
      return '농업';
    } else if (smartFormType === 'FISHERY_STOCK_COMPANY') {
      return '어업';
    }
  }
  get standbyYn() {
    return finalCheckModel.standbyYn;
  }
}

export default new ExecutiveMemberChangeStepVm();
