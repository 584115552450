import { useObserver } from 'mobx-react';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import clsx from 'clsx';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Badge from '@comComponents/atoms/BadgeBox/BadgeBox';
import SquareBtn from '@comComponents/atoms/Button/SquareBtn/SquareBtn';
import InputText from '@comComponents/atoms/Input/InputText/InputText';
import regExp from '@common/module/regExp';
import AddressVm from './AddressVm';

function Address(props) {
  return useObserver(() => (
    <>
      <div className={Qst.spaceBetween} style={{ paddingBottom: '30px' }}>
        <div className={Qst.infoSection}>
          {props.item.foreignerYn ? (
            <div className={Qst.titleWrap}>
              <h2 className={Qst.title}>
                <span className={Qst.bold}>{props.item.koreanName ? props.item.koreanName : '청산인'}</span>님의{' '}
                <span className={Qst.bold}>거소신고(외국인등록) 사실증명상 도로명주소</span>를 입력해 주세요.
              </h2>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                반드시 국내 거소신고(외국인등록) 사실증명과 정확히 일치하는 주소를 입력해주세요.
              </div>
              <div className={clsx(Qst.titleExplain, Qst.noneMark)} style={{ color: '#fd6200' }}>
                거소신고(외국인등록) 사실증명상 주소와 일치하지 않는 경우, 등기신청이 거절됩니다. (5~7일 더 걸림)
              </div>
            </div>
          ) : (
            <>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>{props.item.koreanName ? props.item.koreanName : '청산인'}</span>님의{' '}
                  <span className={Qst.bold}>주민등록등본상 신규 주소</span>를 입력해 주세요.
                </h2>
              </div>
              <Badge className={Qst.addressBadge} title="예시" type="example" border={false}>
                <img
                  className={Qst.addressBadgeImg}
                  src="/images/person_address_example.png"
                  alt="주민등록상 도로명주소 예시"
                />
              </Badge>
            </>
          )}
          <div className={Qst.rowWrap}>
            <InputText
              className={Qst.inputText}
              style={{ width: '380px' }}
              explain="도로명 주소"
              value={props.item?.newAddress?.address || ''}
              readOnly
              onClick={() => props.parentVm.openAddressModal(true, props.idx)}
              name={`${props.name}[${props.idx}].newAddress.address`}
              errorText={
                <Error
                  name={`${props.name}[${props.idx}].newAddress.address`}
                  value={props.item?.newAddress?.address}
                  errorCase={{ required: '도로명 주소를 입력해 주세요.' }}
                />
              }
            />
            <SquareBtn onClick={() => props.parentVm.openAddressModal(true, props.idx)}>도로명 주소 찾기</SquareBtn>
          </div>
          <div className={Qst.rowWrap}>
            <InputText
              className={Qst.inputText}
              style={{ width: '271px' }}
              explain="상세 주소"
              placeholder="상세주소 없을 시, [없음] 입력"
              value={props.item.detailAddress}
              onChange={(e) => props.parentVm.setDetailAddress(e, props.idx)}
              name={`${props.name}[${props.idx}].detailAddress`}
              errorText={
                <Error
                  name={`${props.name}[${props.idx}].detailAddress`}
                  value={props.item.detailAddress}
                  errorCase={{
                    required: '상세 주소를 입력해 주세요.',
                    pattern: {
                      value: regExp.onlyKorNum_hyphen_comma().test(props.item.detailAddress),
                      message: (
                        <span>
                          임대차 계약서상 영문이 있다면, <br />
                          한글로 옮겨적어주세요.
                          <br />
                          EX) B동 &gt; 비동
                        </span>
                      ),
                    },
                  }}
                />
              }
            />
            <div className={Qst.symbol}>
              <span className={Qst.symbolIcon}>&#40;&nbsp;</span>
              <span className={clsx(Qst.symbolText, AddressVm.bnameFontSizeChange(props.item) ? Qst.fontSize : null)}>
                {props.item?.newAddress?.bname || '법정동'},&nbsp;
              </span>
              <InputText
                style={{ width: '200px' }}
                explain="건물명"
                placeholder="없을 시, [없음] 입력"
                value={props.item.nameOfBuilding}
                onChange={(e) => props.parentVm.setNameOfBuilding(e, props.idx)}
                name={`${props.name}[${props.idx}].nameOfBuilding`}
                errorText={
                  <Error
                    name={`${props.name}[${props.idx}].nameOfBuilding`}
                    value={props.item.nameOfBuilding}
                    errorCase={{
                      required: '건물명을 입력해 주세요.',
                      pattern: {
                        value: regExp.onlyKorNum_hyphen_comma().test(props.item.nameOfBuilding),
                        message: (
                          <span>
                            건물명이 영문인 경우, 한글
                            <br />
                            발음으로 옮겨적어 주세요. <br />
                            EX) AB타워 &gt; 에이비 타워
                          </span>
                        ),
                      },
                    }}
                  />
                }
              />
              <span className={Qst.symbolIcon}>&nbsp;&#41;</span>
            </div>
          </div>
        </div>
      </div>
      {props.causeDate && (
        <div className={Qst.spaceBetween} style={{ marginTop: '70px' }}>
          <div className={Qst.infoSection}>
            {props.item.foreignerYn ? (
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>
                  <span className={Qst.bold}>{props.item.koreanName}님</span>의{' '}
                  <span className={Qst.bold}>외국인 서류상 주소변경일자</span>를 입력해 주세요.
                </h2>
              </div>
            ) : (
              <>
                <div className={Qst.titleWrap}>
                  <h2 className={Qst.title}>
                    <span className={Qst.bold}>{props.item.koreanName}님</span>의 전입신고일자를 입력해 주세요.
                  </h2>
                </div>
                <Badge className={Qst.reportDateBadge} title="예시" type="example" border={false}>
                  <img
                    className={Qst.reportDateBadgeImg}
                    src="/images/causedate_example.png"
                    alt="주민등록상 전입신고일자 예시"
                  />
                </Badge>
              </>
            )}
            <div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '130px' }}
                  placeholder="0000"
                  name={`${props.name}[${props.idx}].addressChangeDate.year`}
                  value={props.item.addressChangeDate?.year}
                  onChange={(e) => props.parentVm.setChangeDateYear(e, props.idx)}
                  maxLength="4"
                />
                <span className={Qst.birthText}>년</span>
              </div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '108px' }}
                  placeholder="00"
                  name={`${props.name}[${props.idx}].addressChangeDate.month`}
                  value={props.item.addressChangeDate?.month}
                  onChange={(e) => props.parentVm.setChangeDateMonth(e, props.idx)}
                  maxLength="2"
                  onBlur={(e) => props.parentVm.changeDateMonthBlur(e, props.idx)}
                />
                <span className={Qst.birthText}>월</span>
              </div>
              <div className={Qst.birthWrap}>
                <InputText
                  style={{ width: '108px' }}
                  placeholder="00"
                  name={`${props.name}[${props.idx}].addressChangeDate.day`}
                  value={props.item.addressChangeDate?.day}
                  onChange={(e) => props.parentVm.setChangeDateDay(e, props.idx)}
                  maxLength="2"
                  onBlur={(e) => props.parentVm.changeDateDayBlur(e, props.idx)}
                />
                <span className={Qst.birthText}>일</span>
              </div>
            </div>
            <div style={{ marginTop: '-27px' }}>
              <Error
                name={`${props.name}[${props.idx}].addressChangeDate.year`}
                value={props.item.addressChangeDate?.year}
                errorCase={{
                  required: '연도를 입력해 주세요.',
                  pattern: {
                    value: regExp.year().test(props.item.addressChangeDate?.year),
                    message: '올바른 연도를 입력해 주세요',
                  },
                }}
              />
              <Error
                name={`${props.name}[${props.idx}].addressChangeDate.month`}
                value={props.item.addressChangeDate?.month}
                errorCase={{
                  required: props.parentVm.displayChangeDateErrorMessage(props.idx, 'month')
                    ? '월을 입력해 주세요.'
                    : null,
                  pattern: {
                    value: props.parentVm.displayChangeDateErrorMessage(props.idx, 'month')
                      ? regExp.month().test(props.item.addressChangeDate?.month)
                      : null,
                    message: '올바른 월을 입력해 주세요',
                  },
                }}
              />
              <Error
                name={`${props.name}[${props.idx}].addressChangeDate.day`}
                value={props.item.addressChangeDate?.day}
                errorCase={{
                  required: props.parentVm.displayChangeDateErrorMessage(props.idx, 'day')
                    ? '일(날짜)을 입력해 주세요.'
                    : null,
                  pattern: {
                    value: props.parentVm.displayChangeDateErrorMessage(props.idx, 'day')
                      ? regExp.day().test(props.item.addressChangeDate?.day)
                      : null,
                    message: '올바른 일(날짜)을 입력해 주세요.',
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  ));
}

export default Address;
