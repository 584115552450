import { observable, runInAction } from 'mobx';

const capitalIncreaseStepModel = observable({
  newSharesAcquirers: [], //신주인수인
  newCapitals: [],
  capitalsOnRegister: {
    parValue: null,
    totalCapital: null,
  },
  setNewSharesAcquirer(data) {
    runInAction(() => {
      if (data?.newSharesAcquirers && data?.newSharesAcquirers.length) {
        this.newSharesAcquirers = data.newSharesAcquirers.map((newSharesAcquirer) => {
          let year = '',
            month = '',
            day = '';
          if (newSharesAcquirer.birth) {
            year = newSharesAcquirer.birth.substring(0, 4);
            month = newSharesAcquirer.birth.substring(5, 7);
            day = newSharesAcquirer.birth.substring(8, 10);
          }
          return {
            year: year || '',
            month: month || '',
            day: day || '',
            detailAddress: newSharesAcquirer.detailAddress || '',
            englishName: newSharesAcquirer.englishName || '',
            koreanAddress: newSharesAcquirer.koreanAddress || '',
            englishAddress: newSharesAcquirer.englishAddress || '',
            foreignAddress: newSharesAcquirer.foreignAddress || {
              englishAddress: '',
              koreanAddress: '',
            },
            foreignerCompanyRepresentatives: newSharesAcquirer.foreignerCompanyRepresentatives || [
              {
                id: '',
                englishName: '',
                koreanName: '',
              },
            ],
            id: newSharesAcquirer.id ?? '',
            koreanCompanyRepresentatives: newSharesAcquirer.koreanCompanyRepresentatives || [
              {
                id: '',
                name: '',
                position: '',
              },
            ],
            koreanName: newSharesAcquirer.koreanName || '',
            kssn: newSharesAcquirer.kssn || '',
            nameOfBuilding: newSharesAcquirer.nameOfBuilding || '',
            nationalityOthers: newSharesAcquirer.nationalityOthers || '',
            nationalityType: newSharesAcquirer.nationalityType || '',
            residenceRegistrationYn: newSharesAcquirer.residenceRegistrationYn ?? null,
            shareholderType: newSharesAcquirer.shareholderType || '',
            resourceType: newSharesAcquirer.resourceType || null,
            existingShareholdersYn: newSharesAcquirer.existingShareholdersYn ?? null,
            representatives: newSharesAcquirer.representatives || [
              {
                id: '',
                name: '',
              },
            ],
          };
        });
      } else {
        this.newSharesAcquirers = [];
      }
    });
  },
  setCapitalIncrease(data) {
    runInAction(() => {
      if (data?.newCapitals && data?.newCapitals.length) {
        this.newCapitals = data.newCapitals.map((newCapital) => {
          return {
            id: newCapital.id ?? '',
            name: newCapital.name || '',
            가수금: newCapital.가수금 || null,
            유상증자: newCapital.유상증자 || null,
          };
        });
      } else {
        this.newCapitals = [];
      }
      this.capitalsOnRegister.parValue = data?.capitalsOnRegister?.parValue || null;
      this.capitalsOnRegister.totalCapital = data?.capitalsOnRegister?.totalCapital || null;
    });
  },
});

export default capitalIncreaseStepModel;
