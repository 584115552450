import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './BranchOfficeInstallStep.module.scss';
import clsx from 'clsx';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';
import Icon from '@comComponents/atoms/Icon/Icon';

function BranchOfficeInstallStep(props) {
  return useObserver(() => (
    <div className={clsx(Ast.row, st.branchOfficeInstall)} style={{ display: 'block' }}>
      {props.step.branches.map((item, idx) => {
        return (
          <div className={st.installInfoBox} key={idx}>
            <div className={st.installBranchInfo}>
              <div className={st.dName}>신규지점</div>
              <div className={st.data}>
                <div>
                  주소 :
                  {item?.address !== null ? (
                    <>
                      <CopyBtn
                        onClick={() => copyText(item?.address?.sido)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                        style={{ marginLeft: '3px' }}
                      >
                        {item?.address?.sido}
                      </CopyBtn>
                      <CopyBtn
                        onClick={() => copyText(item?.address?.sigungu)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item?.address?.sigungu}
                      </CopyBtn>
                      {item?.address?.bname1 && (
                        <CopyBtn
                          onClick={() => copyText(item?.address?.bname1)}
                          className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                        >
                          {item?.address?.bname1}
                        </CopyBtn>
                      )}
                      <CopyBtn
                        onClick={() => copyText(item?.address?.roadname)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item?.address?.roadname}
                      </CopyBtn>
                      <CopyBtn
                        onClick={() => copyText(item?.address?.buildingNo)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item?.address?.buildingNo}
                      </CopyBtn>
                      ,
                      <CopyBtn
                        onClick={() => copyText(item?.address?.detailAddress)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item?.address?.detailAddress}
                      </CopyBtn>
                      <div className={Ast.addressCopyBracket}>
                        (
                        <CopyBtn
                          onClick={() => copyText(item?.address?.bname)}
                          className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                        >
                          {item?.address?.bname}
                        </CopyBtn>
                        ,
                        <CopyBtn
                          onClick={() => copyText(item?.address?.nameOfBuilding)}
                          className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                        >
                          {item?.address?.nameOfBuilding}
                        </CopyBtn>
                        )
                      </div>
                      <CopyBtn onClick={() => copyText(item?.address?.fullAddress)} className={Ast.addressCopyAllBtn}>
                        <Icon icon="copy" className={Ast.copyIcon} />
                      </CopyBtn>
                    </>
                  ) : (
                    ' -'
                  )}
                </div>
                <div>
                  지점명 :
                  {item.name ? (
                    <CopyBtn
                      onClick={() => copyText(item.name)}
                      className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                    >
                      {item.name}
                    </CopyBtn>
                  ) : (
                    ' -'
                  )}
                </div>
                <div>
                  지점 설치 일자 :
                  {item.installDate ? (
                    <CopyBtn
                      onClick={() => copyText(item.installDate)}
                      className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                    >
                      {item.installDate}
                    </CopyBtn>
                  ) : (
                    ' -'
                  )}
                </div>
              </div>
            </div>
            <div className={st.installBranchManagerInfo}>
              <div className={st.dName}>지배인 추가</div>
              <div className={st.data}>
                {item.hasManagerYn ? (
                  <>
                    <div>
                      이름 :{' '}
                      <CopyBtn
                        onClick={() => copyText(item.manager.name)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item.manager.name}
                      </CopyBtn>
                    </div>
                    <div>
                      {item.manager.koreanYn ? '주민등록번호' : '생년월일'} :{' '}
                      <CopyBtn
                        onClick={() => copyText(item.manager.kssnOrBirth)}
                        className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                      >
                        {item.manager.kssnOrBirth}
                      </CopyBtn>
                    </div>
                    {item.manager.koreanYn === false && (
                      <div className={st.managerNationality}>
                        국적 :{' '}
                        <CopyBtn
                          onClick={() => copyText(item.manager.nationality)}
                          className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                        >
                          {item.manager.nationality}
                        </CopyBtn>
                      </div>
                    )}
                    <div className={st.managerAddress}>
                      주소 :
                      {item?.manager?.address !== null && (
                        <>
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.sido)}
                            className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                            style={{ marginLeft: '3px' }}
                          >
                            {item?.manager?.address?.sido}
                          </CopyBtn>
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.sigungu)}
                            className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                          >
                            {item?.manager?.address?.sigungu}
                          </CopyBtn>
                          {item?.manager?.address?.bname1 && (
                            <CopyBtn
                              onClick={() => copyText(item?.manager?.address?.bname1)}
                              className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                            >
                              {item?.manager?.address?.bname1}
                            </CopyBtn>
                          )}
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.roadname)}
                            className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                          >
                            {item?.manager?.address?.roadname}
                          </CopyBtn>
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.buildingNo)}
                            className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                          >
                            {item?.manager?.address?.buildingNo}
                          </CopyBtn>
                          ,
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.detailAddress)}
                            className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                          >
                            {item?.manager?.address?.detailAddress}
                          </CopyBtn>
                          <div className={Ast.addressCopyBracket}>
                            (
                            <CopyBtn
                              onClick={() => copyText(item?.manager?.address?.bname)}
                              className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                            >
                              {item?.manager?.address?.bname}
                            </CopyBtn>
                            ,
                            <CopyBtn
                              onClick={() => copyText(item?.manager?.address?.nameOfBuilding)}
                              className={clsx(Ast.addressCopyBtn, st.addressCopyBtn)}
                            >
                              {item?.manager?.address?.nameOfBuilding}
                            </CopyBtn>
                            )
                          </div>
                          <CopyBtn
                            onClick={() => copyText(item?.manager?.address?.fullAddress)}
                            className={Ast.addressCopyAllBtn}
                          >
                            <Icon icon="copy" className={Ast.copyIcon} />
                          </CopyBtn>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div>추가하지 않음</div>
                  </>
                )}
              </div>
            </div>
            <div className={st.test}></div>
          </div>
        );
      })}
    </div>
  ));
}

export default BranchOfficeInstallStep;
