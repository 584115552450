import Question from '@commonComponents/QuestionContent/QuestionContent';
import { observer } from 'mobx-react';

import useChargeGuide from './components/AdditionalChargeGuide/hook/useChargeGuide.js';
import AdditionalChargeGuide from './components/AdditionalChargeGuide/index.jsx';
import Banner from './components/Banner/index.jsx';
import BusinessPurposeQna from './components/BusinessPurposeQna/index.jsx';
import PurposeSelectContainer from './components/PurposeSelectContainer/index.jsx';
import useBusinessPurposes from './useBusinessPurposes.js';

const BusinessPurpose = observer(() => {
  const { submit, smartformType } = useBusinessPurposes();
  const { exceedRecommendTypeCounts, exceedDirectTypeCounts } = useChargeGuide();

  return (
    <form onSubmit={submit}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <Banner />
        <div style={{ height: '16px' }} />
        <PurposeSelectContainer />
      </Question>
      <BusinessPurposeQna smartformType={smartformType} />
      {(exceedRecommendTypeCounts > 0 || exceedDirectTypeCounts > 0) && <AdditionalChargeGuide />}
    </form>
  );
});

export default BusinessPurpose;
