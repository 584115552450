import { useObserver } from 'mobx-react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './ConvertibleBondStep.module.scss';
import clsx from 'clsx';
import { toJS } from 'mobx';
import { addCommas, percent } from '@common/module/replaceNumber';

function ConvertibleBondStep(props) {
  return useObserver(() => (
    <>
      <table className={st.table}>
        <colgroup>
          <col width="290px" />
          <col width="394px" />
          <col width="158px" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th style={{ textAlign: 'center' }}>인적사항</th>
            <th style={{ textAlign: 'center' }}>자격</th>
            <th style={{ textAlign: 'right' }}>인수금액</th>
          </tr>
        </thead>
        <tbody>
          {props.step.convertibleBondAcquirers?.map((convertibleBondAcquirer, index) => (
            <tr key={index}>
              <td style={{ paddingLeft: '0' }}>
                <div className={st.name}>
                  {convertibleBondAcquirer.koreanName}
                  {convertibleBondAcquirer.englishName && (
                    <div className={st.englishName}>({convertibleBondAcquirer.englishName})</div>
                  )}
                </div>
              </td>
              <td style={{ textAlign: 'center' }}>
                {(convertibleBondAcquirer.acquirerType === 'FOREIGNER_COMPANY' ||
                  convertibleBondAcquirer.acquirerType === 'KOREAN_COMPANY') && (
                  <div style={{ marginBottom: '8px' }}>
                    {convertibleBondAcquirer.representatives.map((representative, index2) => (
                      <div key={index2}>
                        {representative.position} {representative.koreanName}
                        {representative.englishName && <span>({representative.englishName})</span>}
                      </div>
                    ))}
                  </div>
                )}
                {convertibleBondAcquirer.acquirerType === 'FUND_AND_INVESTMENT_ASSOCIATION' && (
                  <div style={{ marginBottom: '8px' }}>
                    {convertibleBondAcquirer.representatives.map((representative, index2) => (
                      <div key={index2}>대표 {representative.koreanName}</div>
                    ))}
                  </div>
                )}
                {convertibleBondAcquirer.englishName && (
                  <div style={{ marginBottom: '8px' }}>{convertibleBondAcquirer.nationality}</div>
                )}
                <div>
                  {convertibleBondAcquirer.koreanAddress}
                  {convertibleBondAcquirer.englishAddress && <div>({convertibleBondAcquirer.englishAddress})</div>}
                </div>
              </td>
              <td style={{ textAlign: 'center' }}>
                {convertibleBondAcquirer.existingShareholdersYn ? '주주' : '제3자'}
              </td>
              <td style={{ textAlign: 'right' }}>{addCommas(convertibleBondAcquirer.acquisitionAmount)}원</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={clsx(Cst.row)}>
        <div className={Cst.dName}>
          사채대금 입금
          <br />
          방법
        </div>
        <div className={Cst.data}>{props.step.depositProofType}</div>
      </div>
    </>
  ));
}
export default ConvertibleBondStep;
