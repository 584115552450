import { useObserver } from 'mobx-react';
import HeadOfficeAddressRelocation from './HeadOfficeAddressRelocation/HeadOfficeAddressRelocation';
import { Route } from 'react-router-dom';

function BusinessPurposeStep() {
  return useObserver(() => (
    <>
      {/* 목적 */}
      <Route
        path="/step/changeRegistration/HeadOfficeRelocationStep/HeadOfficeAddressRelocation"
        component={HeadOfficeAddressRelocation}
      ></Route>
    </>
  ));
}

export default BusinessPurposeStep;
