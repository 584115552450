import { observable, runInAction } from 'mobx';

const ConvertibleBondStepModel = observable({
  convertibleBondAcquirerInfos: [],
  convertibleBondAcquisitionAmountInfos: [],
  convertibleBondDepositProofType: '',
  setConvertibleBondAcquirer(data) {
    if (data.convertibleBondAcquirerInfos && data.convertibleBondAcquirerInfos.length !== 0) {
      this.convertibleBondAcquirerInfos = data.convertibleBondAcquirerInfos.map((item) => {
        return {
          id: item.id || null,
          acquirerType: item.acquirerType || '',
          koreanName: item.koreanName || '',
          koreanAddress: item.koreanAddress || '',
          existingShareholdersYn: item.existingShareholdersYn ?? null,
          englishName: item.englishName || '',
          foreignAddress: item.foreignAddress || '',
          nationalityOthers: item.nationalityOthers || '',
          nationalityType: item.nationalityType || '',
          residenceRegistrationYn: item.residenceRegistrationYn ?? null,
          koreanCompanyRepresentatives: item.koreanCompanyRepresentatives || [
            {
              id: null,
              name: '',
              position: '',
            },
          ],
          foreignerCompanyRepresentatives: item.foreignerCompanyRepresentatives || [
            {
              id: null,
              koreanName: '',
              englishName: '',
            },
          ],
          representatives: item.representatives || [
            {
              id: null,
              name: '',
            },
          ],
        };
      });
    } else {
      this.convertibleBondAcquirerInfos = [];
    }
  },
  setConvertibleBondAcquisitionAmount(data) {
    if (data.convertibleBondAcquisitionAmountInfos && data.convertibleBondAcquisitionAmountInfos.length !== 0) {
      this.convertibleBondAcquisitionAmountInfos = data.convertibleBondAcquisitionAmountInfos.map((data) => {
        return {
          id: data.id || null,
          name: data.name || '',
          existingShareholdersYn: data.existingShareholdersYn ?? null,
          acquisitionAmount: data.acquisitionAmount || null,
        };
      });
    } else {
      this.convertibleBondAcquisitionAmountInfos = [];
    }
  },
  setConvertibleBondDepositProof(data) {
    this.convertibleBondDepositProofType = data?.convertibleBondDepositProofType || '';
  },
});

export default ConvertibleBondStepModel;
