import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Btn = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  color: #000;
  font-size: 16px;
  font-weight: 400;
`;

function TextBtn(props) {
  return (
    <Btn type={props.type} className={props.className} style={props.style} onClick={(e) => props.onClick(e)}>
      {props.children}
    </Btn>
  );
}

TextBtn.propTypes = {
  /**
   * 버튼 타입
   */
  type: PropTypes.string,
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 버튼 텍스트 값
   */
  children: PropTypes.string,
};

TextBtn.defaultProps = {
  type: 'button',
  className: null,
  style: {},
  onClick: () => {},
  children: 'Empty',
};

export default TextBtn;
