import ConvertibleBondStepModel from '@model/changeRegistration/ConvertibleBondStepModel';
import { runInAction } from 'mobx';

class ForeignerPersonVm {
  setNationalityType(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].nationalityType = e.target.value;
    });
  }
  setNationalityOthers(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].nationalityOthers = e.target.value;
    });
  }
  setEnglishName(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].englishName = e.target.value;
    });
  }
  setKoreanName(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanName = e.target.value;
    });
  }
  setResidenceRegistrationYn(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].residenceRegistrationYn = e.target.value === 'true';
    });
  }
  setKoreanAddress(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].koreanAddress = e.target.value;
    });
  }
  setEnglishAddress(e, idx) {
    runInAction(() => {
      ConvertibleBondStepModel.convertibleBondAcquirerInfos[idx].foreignAddress = e.target.value;
    });
  }
}

export default new ForeignerPersonVm();
