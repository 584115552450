import { highlightUrls } from '@pages/Establish/EtcInfo/BusinessPurpose/utils/highlightUrls';

import st from './style.module.scss';

const NoteModal = ({ content, minCapital, cautions, postRegistrationRequirements }) => {
  return (
    <div className={st.Modal}>
      <div className={st.title}>해당 목적의 유의사항</div>
      <div className={st.modalContentWrapper}>
        <table>
          <thead>
            <tr>
              <th>목적</th>
              <th>최저자본금 제한</th>
              <th>사업자등록 등을 위한 주의사항</th>
              <th>등기 후 인허가, 등록, 신고 필요여부</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={st.purpose}>{content}</td>
              <td className={st.minCapital}>
                {minCapital.length > 0 ? (
                  <span style={{ color: '#eb4d59' }}>{minCapital}</span>
                ) : (
                  <span style={{ color: '#585a5f', fontWeight: '400' }}>없음</span>
                )}
              </td>
              <td className={st.registerCaution}>{highlightUrls(cautions || '없음')}</td>
              <td className={st.noticeNecessary}>{postRegistrationRequirements || '없음'}</td>
            </tr>
          </tbody>
        </table>
        <div className={st.text}>
          ※ 최저자본금 제한, 인허가가 필요한 목적은 등기 시 요건을 갖추지 않아도 됩니다(일부예외). 다만 추후 사업자등록
          시에는 증자를 하거나 인허가를 받아야합니다.
        </div>
      </div>
    </div>
  );
};

export default NoteModal;
