import { throttle } from '@common/module/function';
import { useLocalObservable } from 'mobx-react';
import { useCallback, useEffect, useRef } from 'react';

const useConsonantCategory = ({ headers }) => {
  const consonantVisibilityRef = useRef({});
  const consonantGroupRefs = useRef({});
  const contentRef = useRef();

  const consonantMob = useLocalObservable(() => ({
    consonant: 'ㄱ',
  }));

  const onGroupVisible = useCallback(
    throttle(
      () => {
        const index = Math.min(...Object.values(consonantVisibilityRef.current).filter((index) => index !== null));

        if (headers[index]) {
          consonantMob.consonant = headers[index].consonant;
        }
      },
      { wait: 100 },
    ),
    [headers],
  );

  const scrollToByConsonant = useCallback((consonant) => {
    if (consonantGroupRefs.current[consonant]) {
      consonantMob.consonant = consonant;
      consonantGroupRefs.current[consonant].scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('scroll', onGroupVisible);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener('scroll', onGroupVisible);
      }
    };
  }, [onGroupVisible]);

  useEffect(() => {
    // header의 consonant 중 첫 번째로 초기화
    const firstConsonant = headers[0]?.consonant;

    if (!firstConsonant) {
      return;
    }

    scrollToByConsonant(firstConsonant);
  }, [headers]);

  return {
    consonantGroupRefs,
    consonantVisibilityRef,
    consonantMob,
    contentRef,
    scrollToByConsonant,
  };
};

export default useConsonantCategory;
