import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import Cst from '../../../FinalCheck/FinalCheck.module.scss';
import st from './AddStockOptionStep.module.scss';
import clsx from 'clsx';

function AddStockOptionStep(props) {
  return useObserver(() => (
    <>
      <div className={Cst.row}>
        <div className={Cst.dName}>벤처기업인증</div>
        <div className={Cst.data}>인증 {props.step.ventureYn ? 'O' : 'X'}</div>
      </div>
      <div className={Cst.row}>
        <div className={Cst.dName}>규정</div>
        <div className={Cst.data}>
          {props?.step?.helpmeRegulationYn === true && <span>헬프미 표준규정</span>}
          {props?.step?.helpmeRegulationYn === false && (
            <>
              <span>자체규정</span>
              <div className={st.regulation}>{props?.step?.regulation}</div>
            </>
          )}
        </div>
      </div>
    </>
  ));
}
export default AddStockOptionStep;
