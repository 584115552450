import pageService from '@service/pageService';
import balanceCertificateModel from '@model/balanceCertificateModel';
import { runInAction, observable } from 'mobx';
import smartformAppModel from '@model/smartformAppModel';
import { _alert, _confirm } from '@model/dialogModel';

class viewModel {
  constructor() {
    this.state = observable({
      submitBtnDisabledYn: false,
    });
  }
  loadBalanceCertificate() {
    balanceCertificateModel.uploadFiles = [];
    pageService.loadBalanceCertificate();
  }
  get balanceCertificateModel() {
    return balanceCertificateModel;
  }
  get fileName() {
    return balanceCertificateModel.fileName;
  }
  get fileSize() {
    return Math.ceil(balanceCertificateModel.fileSize / 1000);
  }
  get fileExtension() {
    return balanceCertificateModel.fileName.split('.')[balanceCertificateModel.fileName.split('.').length - 1];
  }
  get fileUrl() {
    return balanceCertificateModel.url;
  }
  async upload() {
    if (balanceCertificateModel.uploadFiles && balanceCertificateModel.uploadFiles.length !== 0) {
      this.state.submitBtnDisabledYn = true;
      await pageService.uploadBalanceCertificate();
      await _alert('파일을 업로드 하였습니다.');
      this.state.submitBtnDisabledYn = false;
      if (smartformAppModel.corpId) {
        //대시보드에서 열었을 때(corpId가 있을 때)
        if (window.opener) {
          window.opener.close();
        }
        sessionStorage.clear();
        window.open(
          `${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`,
          '_blank',
        );
        window.close();
      } else {
        //어드민에서 열었을 때(corpId가 없을 때)
        sessionStorage.clear();
        window.close();
      }
    } else if (!balanceCertificateModel.fileName) {
      return _alert('파일을 업로드해 주세요.');
    } else {
      if (await _confirm('기존 파일 그대로 업로드하시겠습니까?')) {
        await _alert('기존 파일을 업로드 하였습니다.');
        if (smartformAppModel.corpId) {
          //대시보드에서 열었을 때(corpId가 있을 때)
          if (window.opener) {
            window.opener.close();
          }
          sessionStorage.clear();
          window.open(
            `${process.env.REACT_APP_DASHBOARD_URL}corps/${smartformAppModel.corpId}/progress-stages/request-documents`,
            '_blank',
          );
          window.close();
        } else {
          //어드민에서 열었을 때(corpId가 없을 때)
          sessionStorage.clear();
          window.close();
        }
      }
    }
  }
  smartFormType() {
    const type = smartformAppModel.smartFormType;
    switch (type) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return '주식회사';
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return '농업회사법인';
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return '어업회사법인';
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return '유한회사';
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return '유한책임회사';
      default:
        return;
    }
  }
  name() {
    const type = smartformAppModel.smartFormType;
    switch (type) {
      case 'ESTABLISHMENT_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_AGRICULTURAL_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_FISHERY_STOCK_COMPANY':
        return '주주';
      case 'ESTABLISHMENT_LIMITED_COMPANY':
        return '출자자';
      case 'ESTABLISHMENT_LIMITED_LIABILITY_COMPANY':
        return '출자자';
      default:
        return;
    }
  }
}

export default new viewModel();
