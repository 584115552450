import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const A = styled.a`
  cursor: pointer;
  color: #777c86;
  font-size: 16px;
  letter-spacing: -0.64px;
  text-decoration: underline;

  ${(props) =>
    props.colorType === 'blue' &&
    `color: #035aff;
    letter-spacing: -0.96px;
    `}

  &:visited {
    color: ${(props) => (props.colorType === 'blue' ? '#035aff' : '#777c86')};
  }
`;

function HyperLink(props) {
  return (
    <A
      className={props.className}
      style={props.style}
      onClick={(e) => props.onClick(e)}
      href={props.href}
      target={props.target}
      download={props.download}
      title={props.title}
      colorType={props.color}
    >
      {props.children}
    </A>
  );
}

HyperLink.propTypes = {
  /**
   * class명
   */
  className: PropTypes.string,
  /**
   * style 속성
   */
  style: PropTypes.object,
  /**
   * 클릭 이벤트
   */
  onClick: PropTypes.func,
  /**
   * URL
   */
  href: PropTypes.string,
  /**
   * target 속성
   */
  target: PropTypes.string,
  /**
   * 다운로드 여부
   */
  download: PropTypes.bool,
  /**
   * a링크 도움말
   */
  title: PropTypes.string,
  /**
   * style 속성
   */
  colorType: PropTypes.oneOf(['#777c86', 'blue']),
};

HyperLink.defaultProps = {
  className: null,
  style: {},
  onClick: () => {},
  href: null,
  target: '_self',
  download: null,
  title: null,
  colorType: '#777c86',
};

export default HyperLink;
