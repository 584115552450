import st from './ShareholderModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';

const modal = {
  modalContent: (
    <div className={st.Modal}>
      <div className={st.title}>
        <span className={st.bold}>거소등록한 외국인 주주 </span>분의 정보를 입력해 주세요.
      </div>
      <div className={st.modalContent}>
        <p className={st.content}>
          고객님! 현재 대표 겸 주주가 될 분의 정보를 입력하고 계신데, 거소등록하지 않은 외국인을 대표로 등록하는 경우,
          서비스 제공이 어렵습니다. 이 경우 아포스티유 인증 등이 필요하고 절차가 상당히 복잡하기 때문입니다. 양해
          부탁드립니다.
        </p>
      </div>
    </div>
  ),
  btn: [
    {
      title: '확인',
      type: 'positive',
      event: () => {
        portalModel.setModalClose();
      },
    },
  ],
};

export default modal;
