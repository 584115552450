import ChannelService from '@common/module/ChannelService';
import { dialogModel } from '@model/dialogModel';
import pageStepModel, { pageRouter } from '@model/pageStepModel';
import portalModel from '@model/portalModel';
import smartformAppModel from '@model/smartformAppModel';
import smartformAppService from '@service/smartformAppService';
import { runInAction } from 'mobx';

class viewModel {
  setPageState() {
    const splitPageData = pageRouter.location.pathname.split('/');

    runInAction(() => {
      if (splitPageData[1] === 'step') {
        pageStepModel.isStep = true;
        pageStepModel.cause = splitPageData[2];
        pageStepModel.nowStep = splitPageData[3];
        pageStepModel.nowPage = splitPageData[4];
        pageStepModel.singlePageUrl = '';
      } else {
        pageStepModel.singlePageUrl = pageRouter.location.pathname;
        pageStepModel.isStep = false;
        pageStepModel.cause = '';
        pageStepModel.nowStep = '';
        pageStepModel.nowPage = '';
      }
    });
  }
  get isStep() {
    return pageStepModel.isStep;
  }
  //modal 관련 함수들
  get modalContent() {
    return portalModel.modalContent;
  }
  get modalClassName() {
    return portalModel.modalClassName;
  }
  modalClose() {
    runInAction(() => {
      if (typeof portalModel.onClosePreCallback === 'function') {
        portalModel.onClosePreCallback();
      }

      portalModel.setModalClose();
    });
  }
  get btn() {
    return portalModel.btn;
  }
  get closeBtn() {
    return portalModel.closeBtn;
  }
  //dialog 관련 함수들
  get dialogType() {
    return dialogModel.type;
  }
  get dialogContent() {
    return dialogModel.content;
  }
  get dialogBtn() {
    return dialogModel.btn;
  }
  get isLoadData() {
    return smartformAppModel.isLoadData;
  }
  get userToken() {
    return smartformAppModel.userToken;
  }
  getToken() {
    if (!smartformAppModel.userToken && !sessionStorage.getItem('userToken')) {
      //토큰이 없으면 최초에 토큰 가져옴
      if (!window.opener) {
        alert('로그인 후 올바른 경로로 접근해 주세요.');
        window.location.href = process.env.REACT_APP_DASHBOARD_URL;
      }

      const setToken = (e) => {
        const token = e.data?.token;
        const corpId = e.data?.corpId;
        const authority = e.data?.authority;

        if (token) {
          smartformAppModel.setUserToken(token);
          smartformAppModel.setCorpId(corpId);
          smartformAppModel.setAuthority(authority);
          sessionStorage.setItem('userToken', token);
          sessionStorage.setItem('corpId', corpId);
          sessionStorage.setItem('authority', authority);
          window.removeEventListener('message', setToken);
        }
      };

      window.addEventListener('message', setToken);
      window.opener.postMessage('getToken', '*');
    } else if (sessionStorage.getItem('userToken')) {
      //새로고침해서 세션만 있으면 세션에 있는거 모델에 넣어줌
      smartformAppModel.setUserToken(sessionStorage.getItem('userToken'));
      smartformAppModel.setCorpId(sessionStorage.getItem('corpId'));
      smartformAppModel.setAuthority(sessionStorage.getItem('authority'));
    }
  }
  get smartformId() {
    return smartformAppModel.smartformId;
  }
  getParamFromUrl(name) {
    const url = window.location.href;

    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    const regexS = '[\\?&]' + name + '=([^&#]*)';
    const regex = new RegExp(regexS);
    const results = regex.exec(url);

    return results == null ? null : results[1];
  }
  async lunchService() {
    const smartformId = this.getParamFromUrl('smartformId');
    const manager = this.getParamFromUrl('manager'); //변경등기

    if (smartformId) {
      smartformAppModel.setSmartformId(smartformId);
      sessionStorage.setItem('smartformId', smartformId);
    } else if (sessionStorage.getItem('smartformId')) {
      smartformAppModel.setSmartformId(sessionStorage.getItem('smartformId'));
    } else if (!smartformId && !sessionStorage.getItem('smartformId')) {
      return alert('올바른 경로로 접근해 주세요.');
    } else {
      return alert('서비스 오류입니다 헬프미 채널톡으로 문의주세요.');
    }
    if (manager === 'true') {
      smartformAppModel.setAuthority('manager');
    }
    if (this.smartformId) {
      await smartformAppService.loadSmartformBasic(this.smartformId);
      ChannelService.boot({
        pluginKey: process.env.REACT_APP_Chnnel_Plugin_Key,
        memberId: smartformAppModel.userId,
      });
    }
    if (smartformAppModel.isEstablishment === true || smartformAppModel.authority === 'manager') {
      //설립일때만 본창에서 토큰 가져옴, 변경등기에서 권한이 매니저인 경우(어드민 폼2 관련)
      this.getToken();
    } else {
      this.changeRegistrationSetToken();
    }
  }
  get isEstablishment() {
    return smartformAppModel.isEstablishment;
  }
  changeRegistrationSetToken() {
    if (!smartformAppModel.userToken && sessionStorage.getItem('userToken')) {
      smartformAppModel.setUserToken(sessionStorage.getItem('userToken'));
    }

    const sendChanelListInterval = setInterval(function () {
      if (document.querySelector('#ch-plugin-script-iframe')) {
        clearInterval(sendChanelListInterval);
        const iframe = document.querySelector('#ch-plugin-script-iframe').contentWindow.document;
        const foeGcGInterval = setInterval(() => {
          if (iframe.querySelector('.foeGcG')) {
            iframe.querySelector('.foeGcG').style.display = 'none';
            clearInterval(foeGcGInterval);
          }
        }, 300);
      }
    }, 300);
  }
}

export default new viewModel();
