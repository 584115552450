import { useObserver } from 'mobx-react';
import agricultural from './agricultural/agricultural';
import fishery from './fishery/fishery';
import { Route } from 'react-router-dom';

function AgriculturalFisheryDocumentUpload() {
  return useObserver(() => (
    <>
      {/* 농업 설립 서류 업로드 */}
      <Route path="/AgriculturalFisheryDocumentUpload/Agricultural" component={agricultural}></Route>
      {/* 어업 설립 서류 업로드 */}
      <Route path="/AgriculturalFisheryDocumentUpload/Fishery" component={fishery}></Route>
    </>
  ));
}

export default AgriculturalFisheryDocumentUpload;
