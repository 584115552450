import StandbyParValueDivisionStepModel from '@model/changeRegistration/StandbyParValueDivisionStepModel';
import stepService from '@service/stepService';
import formDataToJson from '@common/module/submit';
import { runInAction, observable } from 'mobx';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class ParValueDivisionVm {
  submit(e) {
    runInAction(() => {
      e.preventDefault();
      if (formDataToJson(e.target).error === false) {
        let param = formDataToJson(e.target).data;
        param.newParValue = removeCommas(param.newParValue);
        stepService.saveStep(param);
      }
    });
  }
  get parValueOnRegister() {
    return StandbyParValueDivisionStepModel.parValueOnRegister;
  }
  get newParValue() {
    return StandbyParValueDivisionStepModel.newParValue;
  }
  setNewParValue(e) {
    runInAction(() => {
      StandbyParValueDivisionStepModel.newParValue = onlyNumber(removeCommas(e.target.value));
    });
  }
}

export default new ParValueDivisionVm();
