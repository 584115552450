class BalanceCertificateVm {
  발행등기Text(file) {
    const is잔고증명서 = file.details.find((item) => item.fileType === '잔고증명서');
    const is잔고증명서_전환사채 = file.details.find((item) => item.fileType === '잔고증명서_전환사채');
    if (is잔고증명서) {
      return '신주발행 등기';
    }
    if (is잔고증명서_전환사채) {
      return '전환사채 발행 등기';
    }
    return '';
  }
  인수대금Text(file) {
    const is잔고증명서 = file.details.find((item) => item.fileType === '잔고증명서');
    const is잔고증명서_전환사채 = file.details.find((item) => item.fileType === '잔고증명서_전환사채');
    if (is잔고증명서) {
      return '신주인수대금';
    }
    if (is잔고증명서_전환사채) {
      return '전환사채 인수대금';
    }
    return '';
  }
}

export default new BalanceCertificateVm();
