import st from './CompanySizeChangeModal.module.scss';
import { runInAction } from 'mobx';
import portalModel from '@model/portalModel';
import stepService from '@service/stepService';

const nextStep = (param) => {
  runInAction(() => {
    portalModel.setModalClose();
    stepService.saveStep(param);
  });
};

const modal = {
  modalContent: function (changeVal) {
    if (changeVal === 'OTHER') {
      return (
        <div className={st.Modal}>
          <div className={st.title}>
            참여인원을 <span className={st.bold}>2명에서 3명으로 변경</span>하면,
            <br />
            <span className={st.bold}>주주, 임원 정보는 다시 입력</span>해주셔야 합니다.
          </div>
          <div className={st.modalContent}>
            <p className={st.content}>
              참여인원이 3명 이상인 경우, 각자의 역할을 다시 지정해야 하기 때문에 이미 입력한 주주, 임원 정보는
              삭제되고, 다시 주주, 임원 정보를 입력해주셔야 합니다. 이미 입력한 주주, 임원 정보가 삭제되지 않기를
              희망하신다면, 원래 선택하신 보기를 선택해주세요.
            </p>
          </div>
        </div>
      );
    } else if (changeVal === 'TWO') {
      return (
        <div className={st.Modal}>
          <div className={st.title}>
            참여인원을 <span className={st.bold}>3명에서 2명으로 변경</span>하면,
            <br />
            <span className={st.bold}>주주, 임원 정보는 다시 입력</span>해주셔야 합니다.
          </div>
          <div className={st.modalContent}>
            <p className={st.content}>
              참여인원이 2명 이상인 경우, 각자의 역할을 다시 지정해야 하기 때문에 이미 입력한 주주, 임원 정보는
              삭제되고, 다시 주주, 임원 정보를 입력해주셔야 합니다. 이미 입력한 주주, 임원 정보가 삭제되지 않기를
              희망하신다면, 원래 선택하신 보기를 선택해주세요.
            </p>
          </div>
        </div>
      );
    } else if (changeVal === 'initiatorRoleChange') {
      return (
        <div className={st.Modal}>
          <div className={st.title}>
            설립에 참여하는 <span className={st.bold}>2명의 역할을 변경</span>하면,
            <br />
            <span className={st.bold}>주주, 임원 정보는 다시 입력</span>해주셔야 합니다.
          </div>
          <div className={st.modalContent}>
            <p className={st.content}>
              설립에 참여하는 2명의 역할을 변경하면, 입력할 정보가 달라지기 때문에 이미 입력한 주주, 임원 정보는
              삭제되고, 다시 주주, 임원 정보를 입력해주셔야 합니다. 이미 입력한 주주, 임원 정보가 삭제되지 않기를
              희망하신다면, 원래 선택하신 보기를 선택해주세요.
            </p>
          </div>
        </div>
      );
    }
  },
  btn: function (param) {
    return [
      {
        title: '수정하기',
        type: 'negative',
        event: () => {
          portalModel.setModalClose();
        },
      },
      {
        title: '저장 후 다음단계',
        type: 'positive',
        event: () => {
          nextStep(param);
        },
      },
    ];
  },
};

export default modal;
