import { observable, runInAction } from 'mobx';

const BranchOfficeInstallStepModel = observable({
  branchOffices: [],
  setBranchOfficeInstall(data) {
    runInAction(() => {
      if (data.branchOffices && data.branchOffices.length) {
        this.branchOffices = data.branchOffices.map((branchOffice, idx) => {
          let causeDateYear = '',
            causeDateMonth = '',
            causeDateDay = '';
          let birthYear = '',
            birthMonth = '',
            birthDay = '';
          if (branchOffice.causeDate) {
            causeDateYear = branchOffice.causeDate.substring(0, 4);
            causeDateMonth = branchOffice.causeDate.substring(5, 7);
            causeDateDay = branchOffice.causeDate.substring(8, 10);
          }
          if (branchOffice.manager?.birth) {
            birthYear = branchOffice.manager.birth.substring(0, 4);
            birthMonth = branchOffice.manager.birth.substring(5, 7);
            birthDay = branchOffice.manager.birth.substring(8, 10);
          }
          return {
            causeDateYear: causeDateYear || '',
            causeDateMonth: causeDateMonth || '',
            causeDateDay: causeDateDay || '',
            id: branchOffice.id || '',
            koreanYn: branchOffice.koreanYn ?? null,
            address: branchOffice.address || {},
            officeName: branchOffice.officeName || '',
            hasManagerYn: branchOffice.hasManagerYn ?? null,
            manager: {
              address: branchOffice.manager?.address || {},
              birthYear: birthYear || '',
              birthMonth: birthMonth || '',
              birthDay: birthDay || '',
              detailAddress: branchOffice.manager?.detailAddress || '',
              koreanYn: branchOffice.manager?.koreanYn ?? null,
              kssn: branchOffice.manager?.kssn || '',
              name: branchOffice.manager?.name || '',
              englishName: branchOffice.manager?.englishName || '',
              nameOfBuilding: branchOffice.manager?.nameOfBuilding || '',
              nationalityOthers: branchOffice.manager?.nationalityOthers || '',
              nationalityType: branchOffice.manager?.nationalityType || '',
              residenceRegistrationYn: branchOffice.manager?.residenceRegistrationYn || null,
            },
            nameOfBuilding: branchOffice.nameOfBuilding || '',
            detailAddress: branchOffice.detailAddress || '',
          };
        });
      } else {
        this.branchOffices = [];
      }
    });
  },
});

export default BranchOfficeInstallStepModel;
