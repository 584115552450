import smartformAppModel from '@model/smartformAppModel';

class StockholdersMeetingSealProvidingStepVm {
  get smartFormType() {
    return smartformAppModel.smartFormType;
  }
  get text() {
    const smartFormType = this.smartFormType;
    switch (smartFormType) {
      case 'STOCK_COMPANY':
        return '주주';
      case 'AGRICULTURAL_STOCK_COMPANY':
        return '주주';
      case 'FISHERY_STOCK_COMPANY':
        return '주주';
      case 'LIMITED_COMPANY':
        return '사원';
      case 'LIMITED_LIABILITY_COMPANY':
        return '사원';
    }
  }
}

export default new StockholdersMeetingSealProvidingStepVm();
