import { useObserver } from 'mobx-react';
import Question from '@commonComponents/QuestionContent/QuestionContent';
import ContentBox from '@commonComponents/ContentBox/ContentBox';
import vm from './StandByStockholderMeetingBasicInfoVm';
import Qst from '@commonComponents/QuestionContent/QuestionContent.module.scss';
import st from './StandByStockholderMeetingBasicInfo.module.scss';
import Error from '@comComponents/molecules/ErrorValidation/ErrorValidation';
import Radio from '@comComponents/atoms/Input/Radio/Radio';
import Check from '@comComponents/atoms/Input/Check/Check';
import { addCommas } from '@common/module/replaceNumber';
import clsx from 'clsx';

function StandByStockholderMeetingBasicInfo() {
  return useObserver(() => (
    <form onSubmit={(e) => vm.submit(e)}>
      <input type="submit" value="submit" id="stepForm" style={{ display: 'none' }} />
      <Question>
        <ContentBox>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>주주총회 안내</h2>
              </div>
              <div className={st.stockholderMeetingInfo}>
                <img src="/images/standby_stockholderMeeting_explain.png" />
              </div>
            </div>
          </div>
          <div className={Qst.spaceBetween}>
            <div className={Qst.infoSection}>
              <div className={Qst.titleWrap}>
                <h2 className={Qst.title}>주주총회 의장을 선택하세요.</h2>
                <div className={Qst.titleExplain}>대표님 중 한 분을 의장으로 선택하세요.</div>
                <div className={Qst.titleExplain}>
                  대표님이 아닌 분이 의장이 되려면 추가적인 이사회 결의를 해야하는 경우가 많아요.
                </div>
              </div>
              <ul className={Qst.answerList}>
                {vm.executiveMembers?.map((executiveMember, idx) => {
                  return (
                    <li key={idx} className={st.chairman}>
                      <Radio
                        className={Qst.answerLabel}
                        value={executiveMember.id}
                        activeBackground
                        checked={vm.chairmanId === executiveMember.id}
                        onChange={(e) => vm.setChairmanId(e)}
                        name="주주총회_의장_id"
                      >
                        {executiveMember.name} {executiveMember.position}
                      </Radio>
                      {executiveMember.representativeYn && <span className={st.tag}>추천</span>}
                    </li>
                  );
                })}
              </ul>
              {vm.executiveMembers.length !== 0 && (
                <Error name="주주총회_의장_id" value={vm.chairmanId} errorCase={{ required: '의장을 선택하세요.' }} />
              )}
            </div>
          </div>
        </ContentBox>
      </Question>
      {vm.chairmanId && (
        <Question>
          <ContentBox>
            <div className={st.title}>출석 주주를 선택하세요.</div>
            <table className={st.table}>
              <colgroup>
                <col width="75" />
                <col width="470" />
                <col width="295" />
                <col width="197" />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>
                    <Check
                      className={st.check}
                      style={{
                        paddingLeft: '30px',
                        fontSize: '16px',
                        fontWeight: '500',
                        letterSpacing: '-0.48px',
                        whiteSpace: 'pre',
                      }}
                      top="0"
                      checked={vm.allChecked}
                      onChange={(e) => vm.setCheckedAll(e)}
                    >
                      출석
                    </Check>
                  </th>
                  <th>주주명</th>
                  <th>주식종류</th>
                  <th>주식수</th>
                  <th>지분율</th>
                </tr>
              </thead>
              {vm.stockholders?.map((stockholder, idx) => (
                <tbody key={idx}>
                  {stockholder?.stocks?.map((stock, idx2) => (
                    <tr key={idx2}>
                      {idx2 === 0 && (
                        <>
                          <td rowSpan={stockholder.stocks.length}>
                            <Check
                              onlyCheckBox
                              className={st.check}
                              checked={stockholder.attendYn}
                              onChange={(e) => vm.setChecked(e, idx)}
                              name={`participants`}
                              value={stockholder.id}
                            />
                          </td>
                          <td rowSpan={stockholder.stocks.length}>
                            <span className={st.index}>{idx + 1}</span>
                            {stockholder.name}
                          </td>
                        </>
                      )}
                      <td className={st.stock}>{stock.stockType}</td>
                      <td className={st.amount}>{addCommas(stock.amount)}주</td>
                      <td className={st.rate}>{vm.rate(idx, idx2)}%</td>
                    </tr>
                  ))}
                </tbody>
              ))}
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <div className={st.checkState}>
                      {vm.checkState === '충분' && <div className={st.enough}>충분</div>}
                      {vm.checkState === '부족' && <div className={st.lack}>부족</div>}
                      <div className={st.explain}>
                        <div className={st.bold}>의사정족수 자동계산</div>
                        <div>주주를 선택하면, 상법에 맞는지 자동으로 계산해드려요.</div>
                      </div>
                    </div>
                  </td>
                  <td className={st.totalAmount}>총 {addCommas(vm.totalAmount)}주</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </ContentBox>
        </Question>
      )}
    </form>
  ));
}
export default StandByStockholderMeetingBasicInfo;
