import { observable } from 'mobx';

const minorDocumentUploadModel = observable({
  //기본증명서
  basicCertificate: [],
  basicCertificateUploadFiles: [], //파일 업로드
  basicCertificateDeleteFiles: [], //파일 삭제
  //가족관계증명서
  familyRelationshipCertificate: [],
  familyRelationshipCertificateUploadFiles: [], //파일 업로드
  familyRelationshipCertificateDeleteFiles: [], //파일 삭제
  setMinorShareholderDocuments(minorShareholderDocuments) {
    if (minorShareholderDocuments?.basicCertificate && minorShareholderDocuments?.basicCertificate.length !== 0) {
      this.basicCertificate = minorShareholderDocuments.basicCertificate.map((basicCertificate) => {
        return {
          fileName: basicCertificate.fileName || '',
          fileSize: basicCertificate.fileSize ?? null,
          id: basicCertificate.id || null,
          url: basicCertificate.url || '',
        };
      });
    } else {
      this.basicCertificate = [];
    }
    if (
      minorShareholderDocuments?.familyRelationshipCertificate &&
      minorShareholderDocuments?.familyRelationshipCertificate.length !== 0
    ) {
      this.familyRelationshipCertificate = minorShareholderDocuments.familyRelationshipCertificate.map(
        (familyRelationshipCertificate) => {
          return {
            fileName: familyRelationshipCertificate.fileName || '',
            fileSize: familyRelationshipCertificate.fileSize ?? null,
            id: familyRelationshipCertificate.id || null,
            url: familyRelationshipCertificate.url || '',
          };
        },
      );
    } else {
      this.familyRelationshipCertificate = [];
    }
  },
});

export default minorDocumentUploadModel;
