import { useObserver } from 'mobx-react';
import Ast from '../../AdminFinalCheckView.module.scss';
import st from './BoardMeetingSealProvidingStep.module.scss';
import copyText from '@common/module/copyText';
import CopyBtn from '@comComponents/atoms/Button/CopyBtn/CopyBtn';

function BoardMeetingSealProvidingStep(props) {
  return useObserver(() => (
    <>
      <div className={Ast.row}>
        <div className={Ast.dName} style={{ width: '250px' }}>
          이사회 공증용 인감증명서를 제공할 이사님
        </div>
        <div className={Ast.data} style={{ marginLeft: '10px' }}>
          {props.step.directors.length === 0 ? (
            '-'
          ) : (
            <>
              {props.step.directors.map((item, idx) => {
                return (
                  <span key={idx} className={st.director}>
                    {idx + 1}.{' '}
                    <CopyBtn onClick={() => copyText(item)} className={Ast.addressCopyBtn}>
                      {item}
                    </CopyBtn>
                  </span>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  ));
}
export default BoardMeetingSealProvidingStep;
