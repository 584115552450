import { useObserver } from 'mobx-react';
import { Route } from 'react-router-dom';
import StandByBoardMeetingBasicInfo from './StandByBoardMeetingBasicInfo/StandByBoardMeetingBasicInfo';
import StandByBoardMeetingParticipant from './StandByBoardMeetingParticipant/StandByBoardMeetingParticipant';

function StandByBoardMeetingInfoStep() {
  return useObserver(() => (
    <>
      {/* 주주총회-출석주주 */}
      <Route
        path="/step/changeRegistration/StandByBoardMeetingInfoStep/StandByBoardMeetingBasicInfo"
        component={StandByBoardMeetingBasicInfo}
      ></Route>
      {/* 주주총회-찬성여부&인감증명서 */}
      <Route
        path="/step/changeRegistration/StandByBoardMeetingInfoStep/StandByBoardMeetingParticipant"
        component={StandByBoardMeetingParticipant}
      ></Route>
    </>
  ));
}

export default StandByBoardMeetingInfoStep;
