function addCommas(x) {
  if (typeof x === 'number' || x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return '';
}

function removeCommas(x) {
  if (x) {
    return x.replace(/,/g, '');
  }

  return x;
}

function onlyNumber(x) {
  if (x === ' ') {
    return ' ';
  }

  return x.replace(/[^0-9]/g, '');
}

function percent(x) {
  return x.toFixed(2) + ' %';
}

export { addCommas, onlyNumber, percent, removeCommas };
